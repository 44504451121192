
import React, { useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Stack from "@mui/material/Stack";
import {CardMedia, Divider } from "@mui/material";
import AppBarLogo from "../../Assets/Icons/appBarLogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "../Sidebar/SideBar";
import Box from "@mui/material/Box";
import { useDrawer } from "../Sidebar/DrawerContext";
import Notificationdrawer from "./Notificationdrawer";
import ProfileDropdown from "./ProfileDropdown";
import { useSelector } from "react-redux";
import UserSidebar from "../Sidebar/UserSidebar";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

export default function AppBar() {
  const { isDarkMode, toggleDarkMode , CustomTypography } = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);

  const { handleDrawerOpen, handleDrawerClose, isDrawerOpen } = useDrawer();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const toggleProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  };

  return (
    <>
      <MuiAppBar
        elevation={0}
        position="fixed"
        sx={{
          transition: "margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          marginLeft: isDrawerOpen ? "20px" : "0",
          backgroundColor: "#F3F3F3",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: isDarkMode ? "#404040" : "#F3F3F3",
          p: 1,
        }}
      >
        <Stack direction="row" spacing={{ xs: 0.2, sm: 1 , md: 1.5 , lg: 2 , xl: 2 }}>
          <IconButton
            onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
            sx={{ display: { xs: "block", sm: "block", md: "block", lg: "block", xl: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <CardMedia  component="img" image={AppBarLogo} alt="Logo" sx={{ width: 'auto', height: { xs: 40, sm: 56 , md: 56 , lg: 56 , xl: 56 }  }}
            
          />
        </Stack>
        {currentUser && (
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            direction={{ xs: "column", sm: "row" }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  display: { xs: "none", sm: "flex" }, // Hide on screens smaller than sm
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: { xs: 0, sm: 3 },
                }}
              >
                <AssignmentIndIcon sx={{ color: "#424242" }} />
                <CustomTypography sx={{ color: "#424242", fontWeight: "bold" }}>
                  Register ID : {currentUser.regiNum}
                </CustomTypography>

              </Stack>
             
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "center", sm: "flex-start" }}
              > <IconButton sx={{  display: { xs: "none", sm: "flex" },}}>
              <Notificationdrawer />
            </IconButton>
                <Divider orientation="vertical" flexItem sx={{background : isDarkMode ? "#fff" : "#918f8e"}}/>
                <IconButton onClick={toggleProfileMenu}>
                  <Stack spacing={1} direction="row" sx={{mt: {xs: "-20px" , sm: "0px"}}}>
                    <AccountCircleIcon sx={{ color: "#424242" }}/>
                    <CustomTypography sx={{ fontWeight: "bold" }}>{currentUser.username}</CustomTypography>
                    <ArrowDropDownIcon />
                  </Stack>
                </IconButton>
              </Stack>
            </Box>
          </Stack>
        )}
      </MuiAppBar>

      {currentUser && <ProfileDropdown open={openProfileMenu} onClose={toggleProfileMenu} />}
      {currentUser && (
        <Sidebar
          isDrawerOpen={isDrawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      )}
      {currentUser && currentUser.roles.includes("ROLE_USER") && (
        <UserSidebar
          isDrawerOpen={isDrawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      )}
    </>
  );
}
