import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Input,
    InputAdornment,
   Paper,
   
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import SearchIcon from "@mui/icons-material/Search";
  import SettingsIcon from "@mui/icons-material/Settings";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useTableContext } from "../../Screens/tableContext/TableContext";
import axios from "axios";
import { useSelector } from "react-redux";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useNavigate, useParams } from "react-router-dom";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
  

const EditTax = () => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const currentUser = useSelector((state) => state.auth.user.data);
    const { showSuccessSnackbar } = useSuccessSnackbar();
    const { showErrorSnackbar } = useErrorSnackbar();
    const {serialNo} = useParams();
    const [Tax, settax] = useState([]);
    const {
    
      capitalizeFirstLetter,
      inputStyles,
      submitButtonStyles,
      errorMessageStyles,
      labelStyle,
    } = useDrawer();
    const {
      searchInput,
      handleSearchChange,
    
    } = useTableContext();
    const [opens, setOpens] = React.useState(false);

    const handleClickOpen = () => {
      setOpens(true);
    };
  
    const handleClickClose = () => {
      setOpens(false);
    };
 
    const [formData, setFormData] = useState({
        name: "",
        rate: "",
        createdBy: currentUser.createdBy,
        updatedBy: currentUser.createdBy,
        storeidfk: currentUser.storeid,
    
      });
      const [errors, setErrors] = useState({
        name: "",
        rate: "",
       });

      const validateField = (fieldName, value) => {
        let error = "";
        const capitalizedFieldName = capitalizeFirstLetter(fieldName);
        
        if (value.trim() === "") {
          error = `${capitalizedFieldName} is Mandatory`;
        } else if (fieldName === "name") {
          if (!/^[A-Za-z]+$/.test(value)) {
            error = `${capitalizedFieldName} should only contain alphabets`;
          } else if (value.length > 20) {
            error = `${capitalizedFieldName} should not exceed 20 characters`;
          }
        }
        else if (fieldName === "rate" ) {
          if (value <= 0) {
              error = `${capitalizedFieldName} should be a positive number`;
          }
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: error
        }));
        return error;
    };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const error = validateField(name, value);
        setErrors({ ...errors, [name]: error });
      };
      const navigate =useNavigate();
      const fetchData = async () => {
        console.log(serialNo);
        try {
          const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/tax/${serialNo}`);
          const userData = response.data.data;
          if(String(response.data.data.storeidfk) !== String(currentUser.storeid))
          {
             navigate('/unauthorized');
          }
          else{
          setFormData({
            name: userData.name,
            rate: userData.rate,
            createdBy: currentUser.username,
            updatedBy: userData.username,
            storeidfk: currentUser.storeid,
          });
        }
        } catch (error) {
          alert("Failed to fetch user data: " + error);
          // Handle error display here
        }
      };
      
   

      useEffect(() => { 
      fetchData();
    }, []);
    
       
     


    const handleSubmit = async (e) => {
      if (Object.values(errors).every((error) => error === "")) {
        e.preventDefault();
      
          try {
            const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/tax/id/${serialNo}`, formData)
      
            if (response.data.status === true) {
              showSuccessSnackbar("Form Submit Successful !");
           
            }
          } catch (error) {
            showErrorSnackbar(error.response.data.message);
          }
        }
      };
  return (
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper elevation={12} sx={{ backgroundColor: "#EBEBEB", width: { xs: "100%", sm: "80%", md: "60%", lg: "60%", xl: "60%" }, p: 3, mt: 5 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item>
          <Box display="flex" alignItems="center" bgcolor="primary.light" p={1} sx={{ fontWeight: "500", backgroundColor: "#28A497", fontSize: "24px", color: "white", borderRadius: 1 }}>
            <PersonAddIcon fontSize="large" />
            <span style={{ marginLeft: '8px' }}>Update Tax</span>
          </Box>
        </Grid>
      </Grid>
              <DialogContent>
                <FormControl
                  sx={{ mt: 3 }}
                  fullWidth
                  error={!!errors.name}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-name"
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    Tax Type
                  </InputLabel>
                  <Input
                    sx={inputStyles}
                    id="outlined-adornment-name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    disableUnderline
                  />
                  <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                  >
                    {errors.name}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  sx={{ mt: 3 }}
                  fullWidth
                  error={!!errors.rate}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-rate"
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    Tax Rate (%)
                  </InputLabel>
                  <Input
                    sx={inputStyles}
                    id="outlined-adornment-rate"
                    type="number"
                    name="rate"
                    value={formData.rate}
                    onChange={handleInputChange}
                    disableUnderline
                  />
                  <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                  >
                    {errors.rate}
                  </FormHelperText>
                </FormControl>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
            type="submit"
             className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={{ ...submitButtonStyles, mb: 3 }}
             onClick={handleSubmit} // Move the click event inside the form
    >
                  Submit
                </Button>
              </DialogActions>
             
           
           </Paper>
         
      </Box> 
    
  )
}

export default EditTax