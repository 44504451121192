
import { Box, Grid, } from '@mui/material';
import React from 'react'
import NavLinkCard from '../../Components/StoreDashboardComponent/NavLinkCard';
import { useDarkMode } from '../DarkMode/DarkMode';

function TechnicianManagementSuperadmin() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const data = [
    { id: 1, title: 'Add Technician', image: '../images/cash-reg-icon.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/add-technician", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 2, title: 'Technician List', image: '../images/cash-reg-list-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/technician-dashboard/technician-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },

  ]


  return (
    <Box sx={{ display: "flex" }}>

      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CustomTypography sx={{ mb: 4, fontWeight: "600", fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' }, }} variant="p" className="link-cart-title">
          Technician Management
        </CustomTypography>
        <Grid lg={11} md={12} sm={12} xs={12} xl={10} container spacing={1.7}  >
          {data.map(card => (
            <NavLinkCard key={card.id} card={card} />
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
export default TechnicianManagementSuperadmin;
