import { configureStore } from "@reduxjs/toolkit";
import foodSlice from "./foodSlice";
import roleSlice from "./roleSlice";
import authSlice from "./authSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    managementSections: roleSlice,
    cart: foodSlice,
  },
});
