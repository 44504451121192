import { Box, Button, Drawer, Badge, Paper, Divider } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';

function Notificationdrawer() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const [state, setState] = React.useState({
        right: false,
    });

    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const drawerRef = useRef(null);

    const closeDrawer = (anchor) => () => {
        setState({ ...state, [anchor]: false });
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    'https://billing.ubsbill.com/apptest/v2/sys/notification/getall'
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                const data = responseData.data;
                setNotifications(data);
                setUnreadCount(
                    data.filter((notification) => !notification.read).length
                );
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const handleClearNotifications = () => {
        setNotifications([]);
        setUnreadCount(0);
    };

    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            setState({ ...state, right: false });
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Badge badgeContent={unreadCount} color="error">
                        <NotificationsIcon
                            sx={{ color: isDarkMode ? "#fff" : "#424242" }}
                            onClick={toggleDrawer(anchor, true)}
                        />
                    </Badge>
                    <Drawer
                        variant="persistent"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        id="notification"
                        PaperProps={{
                            sx: {
                                backgroundColor: isDarkMode ? "#404040" : '#EAFCFC',
                                top: '71px',
                            },
                        }}
                        ref={drawerRef}
                    >
                        <Box
                            sx={{
                                width:
                                    anchor === 'top' || anchor === 'bottom'
                                        ? 'auto'
                                        : 350,
                                textAlign: 'left',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 3,
                                maxHeight: '70vh',
                                height: '70vh',
                                overflowY: 'auto',
                            }}
                            role="presentation"
                            onKeyDown={closeDrawer(anchor)}
                        >
                            <CustomTypography
                                variant="h6"
                                sx={{
                                    fontWeight: '600',
                                    fontSize: {
                                        sm: '15px',
                                        md: '20px',
                                        xs: '17px',
                                    },
                                }}
                            >
                                Notifications
                            </CustomTypography>
                            {Array.isArray(notifications) &&
                                notifications.map((notification, index) => (
                                    <Paper
                                        key={index}
                                        elevation={3}
                                        sx={{ p: 2, mb: 2 , background: isDarkMode ? "#636363" : "#fff"}}
                                    >
                                        <CustomTypography
                                            variant="body1"
                                            sx={{
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                fontSize: {
                                                    sm: '16px',
                                                    md: '16px',
                                                    xs: '16px',
                                                    lg: '16px',
                                                },
                                            }}
                                        >
                                            {notification.message}
                                        </CustomTypography>
                                    </Paper>
                                ))}

                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                style={{
                                    background: '#D9D9D9',
                                    color: '#000',
                                }}
                                onClick={handleClearNotifications}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}

export default Notificationdrawer;
