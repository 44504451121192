import React, { useEffect, useState } from "react";
import {
  Card,
  Box,

} from "@mui/material";

import { useDrawer } from "../../Sidebar/DrawerContext";
import axios from "axios";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";




export const StoreDetailsSuperadmin = ({storeId}) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const {
    runningOrderTitle,runningOrderTable
    
  } = useDrawer();
  const [storeDetails, setStoreDetails] = useState(null);
  useEffect(() => {
    const fetchStoreDetails = async () => {
      try {
        const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/api/auth/store/${storeId}`);
        setStoreDetails(response.data.data);
      } catch (error) {
        console.error('Error fetching store details:', error);
      }
    };
  
    if (storeId) {
      fetchStoreDetails();
    }
  }, [storeId]);
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: "32vw", md: "37vw", sm: "60vw", xs: "90vw", xl: "25vw" },
  };
  
  return (
    
        <Box sx={style}>
          {storeDetails && (

       
          <Card
            sx={{
              background: isDarkMode ? "#83838a" : "#F3F3F3",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "20px 0px 0px 0px",
              borderRadius: "10px",
            }}
          >
            <Box
              className="align-items-center"
              sx={{
                background:"#28A497",
                p: "2% 4% ",
                mt: "5px",
              }}
            >
              <CustomTypography sx={{ ...runningOrderTitle, color: "#fff" , textTransform: "capitalize" }}>
              {storeDetails.storeName}
              </CustomTypography>
             
            </Box>
            <Box
              sx={{
                padding: { lg: 3.5, md: 1.7, sm: 1.7, xs: 1.2 ,  },
                display: "flex",
                flexDirection: "column",
                gap: { xs: 0, sm: 2, xl: 2, lg: 2 },
              }}
            >
            
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600",  }}>Store Id : <span style={{fontWeight:"500", marginLeft: "10px" }}>{storeDetails.storeid}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600",  }}>Store Name : <span style={{fontWeight:"500", marginLeft: "10px" }}>{storeDetails.storeName}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600",  }}>User Name : <span style={{fontWeight:"500", marginLeft: "10px" }}>{storeDetails.username}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Registration Number : <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.registrationNo}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Email : <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.email}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Contact : <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.contact}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Address : <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.address}, {storeDetails.storeAddress}, {storeDetails.state}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Created By: <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.createdBy}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Created Date: <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.createdDate}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Subscription Type: <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.subscriptionType}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>GST No: <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.gstNo}</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Subscription Expiration: <span style={{fontWeight:"500", marginLeft: "10px"}}>{storeDetails.subscriptionExpiration}</span></CustomTypography>

                <Box
              sx={{
                mt: { xl: "15px", sm: "15px", xs: "30px" },
                padding: "0px 10px 10px",display:"flex", 
              }}
            >
               
                </Box>
             
           </Box>
          </Card>
             )}
        </Box>
   
  );
};

