import { Alert, AlertTitle, Box, Button, Card, Grid,  } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDrawer } from "../Sidebar/DrawerContext";
import { RunningOrderModal } from "./Modal/RunningOrderModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

function KotOrderCards() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data)
  const navigate = useNavigate();
  const { runningOrderDetails ,runningOrderButton} = useDrawer();
  const [kotOrderCards ,setKotOrderCards] = useState([]);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/bill/statusreport?orderStatus=Preparing&storeId=${currentUser.storeid}`);
      setKotOrderCards(response.data.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };



   const handleOrderStatus = async (id) => {
    console.log(id)
    try {
      const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/bill/updatebyStatus/${id}/?orderStatus=Prepared`);
      
      if (response.data.status === true) {
        fetchData();   
      }
    } catch (error) {
      console.error("Error occurred while updating order status:", error);
    }
  }
  

 return (
    <Box sx={{position:"relative",mb: 5}}>
      <Grid xl={12} container spacing={3} sx={{  }}>
      {kotOrderCards.length === 0 ? (
    <Box  sx={{mt:"1vh",ml:"1vw"}}> 
    <Alert severity="error" sx={{ marginTop: "30px" , minWidth:"80vw"}}>
    <AlertTitle>Info</AlertTitle>
    Currently, There Are No KOT Orders!
  </Alert></Box>
  ) : (
        kotOrderCards.map((data, index)=> (
         <Grid xs={11.5} sm={5.5} md={3.7} lg={3.2} xl={3.3} item>
          <Card
            sx={{
              background: isDarkMode ? "#5E5E5E" : "#F3F3F3",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "20px 0px 0px 0px", borderRadius:"10px"
            }}
          >
            
            <Box sx={{padding: { lg: 2.5, md: 1.7 , sm: 1.7, xs: 1.2} , display:"flex", flexDirection:"column", gap: {xs: 0, sm: 2,xl: 2, lg: 2 } }}>
            <Box sx={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", maxWidth:{xl:"95%",lg:"100%"}}}>
               <CustomTypography className="food-card-text" sx={runningOrderDetails}>Table No : {data.order[0].tableNo}</CustomTypography>
               <CustomTypography className="food-card-text" sx={runningOrderDetails}>Order No : {data.order[0].orderId}</CustomTypography>
            </Box>
            <CustomTypography className="food-card-text" sx={runningOrderDetails}>Order Type : {data.order[0].orderType}</CustomTypography>
            <CustomTypography className="food-card-text" sx={runningOrderDetails}>Order Date : {data.order[0].orderDate}</CustomTypography>

            <Box sx={{mt:{xl: "15px", sm:"15px", xs:"0px"},display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
            <RunningOrderModal orderData={data.order[0]} order={data}  serialNo={data.order[0].serialNo} totalData={data} handleOrderStatus={handleOrderStatus} orderNo={data.order[0].orderNo}/>
            
            <Button  onClick={() => handleOrderStatus(data.order[0].serialNo)}
            className='food-card-text mode-button border-radius-10'
                label="CARD"
                sx={runningOrderButton }
             >Prepared</Button>
            
            
            </Box>
            </Box>
          </Card>
        </Grid>
        ))
        )}

      </Grid>
    </Box>
  );
}

export default KotOrderCards;
