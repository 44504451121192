import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import UserNavLinkCard from '../Components/UserDashboardComponents/UserNavLinkCard';
import NavLinkCard from '../Components/StoreDashboardComponent/NavLinkCard';
import { useDarkMode } from './DarkMode/DarkMode';
function OrderManagement() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [subMenuData, setSubMenuData] = useState([]);
  const currentUser = useSelector((state) => state.auth.user.data); 
  const userRoles = currentUser.roles || []; 

  useEffect(() => {
    if (userRoles.includes("ROLE_USER")) {
      axios.get(`https://billing.ubsbill.com/apptest/v2/sys/usersidebar/username?username=${currentUser.username}`)
        .then(response => {
          const menuData = response.data.data;
          const orderManagementMenu = menuData.menu.find(menu => menu.title === "Order Management");
          if (orderManagementMenu) {
            setSubMenuData(orderManagementMenu.subMenu || []); 
          } else {
            console.error("Menu item 'Order Management' not found in menu data");
          }
        })
        .catch(error => {
          console.error("Error fetching submenu data:", error);
        });
    } else {
      setSubMenuData([]);
    }
  }, [userRoles]); 


  const data = [
    { id: 1, title: 'New Order', image: '../images/new-order-icon.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/new-order", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 2, title: 'Pending Order', image: '../images/pending-order-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/pending-order", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 3, title: 'KOT', image: '../images/add-addon-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/kitchen-order-ticket", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 4, title: 'Orders List', image: '../images/order-list-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/order-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 5, title: 'Bill List', image: '../images/order-list-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/bill-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
  ]

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomTypography sx={{ mb: 4, fontWeight: "500", fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' } }} variant="p" className="link-cart-title">
          Order Management
        </CustomTypography>
        <Grid lg={11} md={12} sm={12} xs={12} xl={9} container spacing={1.7}  >
        {userRoles.includes("ROLE_USER") ? (
            subMenuData.map(submenuItem => (
              submenuItem.status && <UserNavLinkCard key={submenuItem.id} card={submenuItem} />
            ))
          ) : (
            data.map(card => (
              <NavLinkCard key={card.id} card={card} />
            ))
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default OrderManagement;
