import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useTableContext } from "../../Screens/tableContext/TableContext";
import "jspdf-autotable";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
const rowsPerPage = 10;
function OrderList() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data);
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles,
    listTabStyles,
    listTabsStyles,
    fromAndToTextStyles,
    dateFieldStyles,
    filterAndCancelIcon,dailyStyle
  } = useDrawer();

  const {

    filterRows,
    renderStyledCell,
    getFontWeight,
    generatePdf,
    downloadExcel, resetSearchInput , 
    currentPage,
    handleChangePage, 
    searchInput,
    handleSearchChange, resetCurrentPage
  
  } = useTableContext();
 
  const [ordersList, setordersList] = useState([]);
  const [value, setValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const filteredRows = filterRows(ordersList);
   const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
    resetCurrentPage();
  }, [location.pathname]);

  const tableHeaders = [
    { label: "No" },
    { label: "Order Date" },
    { label: "Order No" },
    { label: "Order Type" },
    { label: "Table No" },
    { label: "Bill Type" },
    { label: "Order Status" },
    { label: "Payment Status" },
  ];

  const downloadPDF = () => {
    generatePdf("Order List", tableHeaders, filteredRows);
  };

  const fetchData = async () => {
    try {

        const url =
            fromDate && toDate
                ? `https://billing.ubsbill.com/apptest/v2/sys/bill/storeid/${currentUser.storeid}?startDate=${fromDate}&endDate=${toDate}`
                : `https://billing.ubsbill.com/apptest/v2/sys/bill/storeid/${currentUser.storeid}`;
        const response = await fetch(url);
        const json = await response.json();
        const reorderedData = json.data.bill.map((bill , index) => ({
            id: index+1,
            orderDate: bill.billDate, 
            orderId: bill.id, 
            orderType: bill.order[0].orderType,
            tableNumber: bill.order[0].tableNumber || "N/A",
            billStatus: bill.billStatus,
            orderStatus: bill.order[0].orderStatus,
            payment: bill.payment || ""
        }));
       setordersList(reorderedData); 

    } catch (error) {
        console.error("Error fetching inventory data:", error);
    }
};




  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const filterDailyRecords = () => {
    const today = new Date();
    const fromDateFormatted = formatDate(today);
    const toDateFormatted = formatDate(today);

    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);
    resetCurrentPage();


  };

  const filterMonthlyRecords = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const fromDateFormatted = formatDate(firstDayOfMonth);
    const toDateFormatted = formatDate(lastDayOfMonth);
    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);

    resetCurrentPage();
};


  const filterYearlyRecords = () => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

    const fromDateFormatted = formatDate(firstDayOfYear);
    const toDateFormatted = formatDate(lastDayOfYear);
    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);

     resetCurrentPage();

  };

 
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        filterDailyRecords();
        break;
      case 1:
        filterMonthlyRecords();
        break;
      case 2:
        filterYearlyRecords();
        break;
      default:
        break;
    }
  };

  const filteredRowsBySearch = filteredRows.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = filteredRowsBySearch.slice(startIndex, endIndex);

  const handleClearFields = () => {
    setFromDate(''); // Clear fromDate
    setToDate('');
    resetSearchInput();   // Clear toDate
    setValue("");
  };

  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box bgcolor="primary.light" sx={listMainBox}>
            <ReceiptLongIcon sx={listIconStyles} />
            <Typography variant="span" sx={{ ml: "8px" , color: isDarkMode ? "#fff" :"#fff", }}>
              Order List
            </Typography>
          </Box>
          <Box sx={spaceBetweenTopComponent}>
            <Button
              component={Link}
              to="/new-order"
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles}
            >
              New Order
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles}
              onClick={downloadPDF}
            >
              PDF
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles}
              onClick={() =>
                downloadExcel(tableHeaders, filteredRows, "OrderList")
              }
            >
              Excel
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={3} lg={2.5} xl={2}>
          <Box bgcolor="#D9D9D9" p={1} sx={searchboxStyles}>
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
            <Divider
              orientation="vertical"
              sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
            />
            <Input
              fullWidth
              placeholder="Search"
              disableUnderline
              sx={{ color: isDarkMode ? "#fff" : "#000",}}
              value={searchInput}
              onChange={handleSearchChange}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={4.2} md={4} lg={2.7} xl={3}>
          <Box
            bgcolor="#D9D9D9"
            sx={dailyStyle}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="white"
              indicatorColor="white"
              aria-label="tabs"
              sx={listTabsStyles}
            >
              <Tab
                label="Daily"
                value={0}
                className="tabs"
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 0 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 0 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
              <Tab
                label="Monthly"
                value={1}
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 1 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 1 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
              <Tab
                label="Yearly"
                value={2}
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 2 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 2 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={7} sm={4} md={4} lg={2.6} xl={2.3}>
          <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
            From
            <Input
              fullWidth
              type="date"
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              sx={dateFieldStyles}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              disableUnderline
            />
          </Box>
        </Grid>
        <Grid item xs={7} sm={4} md={4} lg={2.6} xl={2.3}>
          <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
            To
            <Input
              fullWidth
              type="date"
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              value={toDate}
              sx={dateFieldStyles}
              onChange={(e) => setToDate(e.target.value)}
              disableUnderline
            />
          </Box>
        </Grid>
        <Grid item>
          <Box bgcolor="#D9D9D9" p={0.5} sx={filterAndCancelIcon}>
            <IconButton onClick={fetchData}>
              <FilterAltIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item>
          <Box bgcolor="#D9D9D9" sx={filterAndCancelIcon}>
            <IconButton onClick={handleClearFields}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>
          <div style={{ padding: "10px" }}>
            <Table aria-label="simple table">
              <TableHead sx={tableHeadStyles}>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      className="all-list-tabelcell"
                      align="right"
                      sx={tableCellStyles}
                    >
                      {header.label}
                   
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <div style={{ padding: "10px" }} />
              <TableBody>
                {displayedRows.map((orderData, index) => (
                 
                      <TableRow key={index} sx={contentTableRowStyles}>
                          {renderStyledCell(
                          orderData.id,
                          getFontWeight(orderData.id)
                        )}
                        {renderStyledCell(
                          orderData.orderDate,
                          getFontWeight(orderData.orderDate)
                        )}
                         {renderStyledCell(
                          orderData.orderId,
                          getFontWeight(orderData.id)
                        )}
                        {renderStyledCell(
                          orderData.orderType,
                          getFontWeight(orderData.orderType)
                        )}
                        {renderStyledCell(
                          orderData.tableNumber,
                          getFontWeight(orderData.tableNumber)
                        )}
                        {renderStyledCell(
                          orderData.billStatus,
                          getFontWeight(orderData.billStatus)
                        )}
                        {renderStyledCell(
                          orderData.orderStatus,
                          getFontWeight(orderData.orderStatus)
                        )}
                        {renderStyledCell(
                          orderData.payment,
                          getFontWeight(orderData.payment)
                        )}
                        
                      </TableRow>
                   
                ))}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
          <Pagination
            count={Math.ceil(filteredRows.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default OrderList;
