import { CustomTypography, Typography } from '@mui/material';
import React, { createContext, useState, useContext, useEffect } from 'react';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Load dark mode status from cookies when component mounts
    const darkModeStatus = localStorage.getItem('darkMode');
    if (darkModeStatus) {
      setIsDarkMode(JSON.parse(darkModeStatus));
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      // Store dark mode status in cookies
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  const toggleMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

 
  // Custom CustomTypography component with color adjustment based on dark mode
  const CustomTypography = ({ children, ...otherProps }) => {
    return (
      <Typography
        {...otherProps}
        style={{ color: isDarkMode ? 'white' : 'black' }}
      >
        {children}
      </Typography>
    );
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode, CustomTypography , toggleMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => useContext(DarkModeContext);
