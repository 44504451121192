import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function EditVendor() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    selectStyles,
    formMainBox,
    formPaperStyles,
  } = useDrawer();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const currentUser = useSelector((state) => state.auth.user.data);
  const { serialNo } = useParams();
  const formDataFields = [
    { name: "vendorName", label: "Vendor Name", type: "text" },
    { name: "vendorCode", label: "Vendor Code", type: "text" },
    { name: "vendorAddress", label: "Vendor Address", type: "text" },
    { name: "mobileNo", label: "Mobile No", type: "number" },
    { name: "bankName", label: "Bank Name", type: "text" },
    { name: "branch", label: "Branch", type: "text" },
    { name: "accountNo", label: "Account No", type: "number" },
    { name: "ifscCode", label: "IFSC Code", type: "text" },
    { name: "upiId", label: "UPI Id", type: "text" },
  ];

  const [formData, setFormData] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
    storeId: currentUser.storeid,
  });

  const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );

  const validateField = (fieldName, value) => {
    let error = "";
    const contactRegex = /^[0-9]{10}$/; 
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const numberRegex = new RegExp(/^\d{1,10}$/);
    const alphabeticRegexWithSpace = /^[a-zA-Z\s]+$/;
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const maxLength = 40;

    if (value.trim() === "") {
        error = `${capitalizedFieldName} is mandatory`;
    }
     else if (capitalizedFieldName === "Price") {
        if (!numberRegex.test(value)) {
            error = `${capitalizedFieldName} should be a number`;
        }
    } 
    else if (fieldName === "mobileNo") {
        if (!contactRegex.test(value)) {
            error = "Please enter a valid 10-digit contact number";
        }
    } 
    else if (fieldName === "vendorCode") {
        if (value.length > 5) {
            error = `${capitalizedFieldName} should not exceed 5 characters`;
        }
    }
    else if (fieldName === "vendorAddress") {
      if (value.length > 250) {
          error = `${capitalizedFieldName} should not exceed 250 characters`;
      }

    }
    else if (fieldName === "accountNo") {
      if (value.length > 15) {
          error = `${capitalizedFieldName} should not exceed 15 characters`;
      }

    }
    else if (fieldName === "ifscCode") { // Handling IFSC code
      if (!ifscRegex.test(value)) {
          error = "Please enter a valid IFSC code";
      }
    }
    else if (fieldName === "upiId") {
      if (value.length > maxLength) {
        error = `${capitalizedFieldName} cannot exceed 40 characters`;
      }

    }
    else if (fieldName === "vendorName" || fieldName === "bankName" || fieldName === "branch") {
      if (!alphabeticRegexWithSpace.test(value)) {
        error = `${capitalizedFieldName} must contain only alphabetic characters`;
      } else if (value.length > maxLength) {
        error = `${capitalizedFieldName} cannot exceed 40 characters`;
      }
    }

    return error;
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  
  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };
 const navigate =useNavigate();
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/vendor/id/${serialNo}`);
        const vendorData = response.data.data;
       if(response.data.data.storeId !== currentUser.storeid)
        {
           navigate('/unauthorized');
        }
        else{
        setFormData({
          vendorName: vendorData.vendorName,
          vendorCode: vendorData.vendorCode,
          vendorAddress: vendorData.vendorAddress,
          mobileNo: vendorData.mobileNo,
          bankName: vendorData.bankName,
          branch: vendorData.branch,
          accountNo: vendorData.accountNo,
          ifscCode: vendorData.ifscCode,
          upiId: vendorData.upiId,
          createdBy: vendorData.createdBy,
          updatedBy: currentUser.usename,
          storeId:currentUser.storeid ,
        });
      }
      } catch (error) {
        showErrorSnackbar("Failed to fetch vendor data");
      }
    };

    fetchData();
  }, [serialNo, showErrorSnackbar]);


  // Placeholder implementation for buttonDisabled function
  const buttonDisabled = () => {
    return Object.values(errors).some((error) => error !== "");
  };


  const handleVendorUpdate = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
       const totals = parseFloat(formData.price) * parseFloat(formData.quantity);
       formData.total = totals;

       try {
          const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/vendor/id/${serialNo}`, formData);
          if (response.data.status === true) {
             showSuccessSnackbar("Form updated successfully!");
             
          } else {
             showErrorSnackbar(response.data.message);
            
          }
       } catch (error) {
          if (error.response && error.response.data) {
             showErrorSnackbar(error.response.data.message);
          } else {
            
             showErrorSnackbar("Form update failed. Please try again.");
          }
       }
    }
 };


  return (
    <Box
      component="main"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="primary.light"
              p={1}
              sx={formMainBox}
            >
              <InventoryIcon fontSize="large" />
              <span style={{ marginLeft: "8px" }}>Update Vendor</span>
            </Box>
          </Grid>
        </Grid>

        {Array.from({ length: 5 }).map((_, index) => (
          <Stack
            key={index}
            spacing={4}
            direction={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            sx={{ marginBottom: 1 }}
          >
            {formDataFields
              .slice(index * 2, index * 2 + 2)
              .map((formDataItem, innerIndex) => (
                <FormControl
                  key={innerIndex}
                  fullWidth
                  error={!!errors[formDataItem.name]}
                  variant="outlined"
                >
                  <CustomTypography
                    htmlFor={`outlined-adornment-${formDataItem.name}`}
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    {formDataItem.label}
                  </CustomTypography>

                  {formDataItem.type === "select" ? (
                    <Select
                      name={formDataItem.name}
                      value={
                        formData[formDataItem.name] ||
                        formDataItem.options[0].label
                      }
                      onChange={handleInputChange}
                      sx={selectStyles}
                    >
                      {formDataItem.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      sx={inputStyles}
                      type={formDataItem.type}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      disableUnderline
                    />
                  )}
                  <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                  >
                    {errors[formDataItem.name]}
                  </FormHelperText>
                </FormControl>
              ))}
          </Stack>
        ))}
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleVendorUpdate}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles}
            disabled={buttonDisabled()}
          >
            Update
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

// Placeholder implementation for capitalizeFirstLetter function
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default EditVendor;
