
import React, { useState } from "react";
import {
  Button,
  Card,
  Box,
  
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDrawer } from "../../Sidebar/DrawerContext";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "32vw", md: "37vw", sm: "60vw", xs: "90vw", xl: "25vw" },
};

export const TechDetailsSuperadmin = () => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const {
    runningOrderTitle,runningOrderTable
    
  } = useDrawer();
 
 
  return (
    
        <Box sx={style}>
          <Card
            sx={{
              background: "#F3F3F3",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "20px 0px 0px 0px",
              borderRadius: "10px",
            }}
          >
            <Box
              className="align-items-center"
              sx={{
                background:"#28A497",
                p: "2% 4% ",
                mt: "5px",
              }}
            >
              <CustomTypography sx={{ ...runningOrderTitle, color: "#fff" }}>
                Technician Name: 
              </CustomTypography>
             
            </Box>
            <Box
              sx={{
                padding: { lg: 3.5, md: 1.7, sm: 1.7, xs: 1.2 ,  },
                display: "flex",
                flexDirection: "column",
                gap: { xs: 0, sm: 2, xl: 2, lg: 2 },
              }}
            >
            
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600",  }}>Tech Id : <span style={{fontWeight:"500", marginLeft: "10px" }}>1</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Email : <span style={{fontWeight:"500", marginLeft: "10px"}}>abc@gmail.com</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Contact : <span style={{fontWeight:"500", marginLeft: "10px"}}>7888777766</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Address : <span style={{fontWeight:"500", marginLeft: "10px"}}>Pune</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Created By: <span style={{fontWeight:"500", marginLeft: "10px"}}>Sandip</span></CustomTypography>
             <CustomTypography   className="food-card-text" sx={{ ...runningOrderTable, fontWeight: "600" }}>Created Date: <span style={{fontWeight:"500", marginLeft: "10px"}}>06/10/2024</span></CustomTypography>
          
            
                <Box
              sx={{
                mt: { xl: "15px", sm: "15px", xs: "30px" },
                padding: "0px 10px 10px",display:"flex", 
              }}
            >
                <Box sx={{ marginLeft: "auto" }}>
                  <Button
                    className="food-card-text mode-button border-radius-10"
                    sx={{
                      bgcolor: "#D9D9D9",
                      p: "5px",
                      maxWidth: "45px",
                      width: "45px",
                      minWidth: "45px",
                      mr: 1,
                    }}
                  >
                    <EditIcon sx={{ color: "#424242" }} />
                  </Button>
                  <Button
                    className="food-card-text mode-button border-radius-10"
                    sx={{
                      bgcolor: "#D9D9D9",
                      p: "5px",
                      maxWidth: "45px",
                      width: "45px",
                      minWidth: "45px",
                    }}
                  >
                    <DeleteIcon sx={{ color: "#424242" }} />
                  </Button>
                </Box>
                </Box>
             
           </Box>
          </Card>
        </Box>
   
  );
};
