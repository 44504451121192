import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import QuickAccessLinks from "../Components/StoreDashboardComponent/QuickAccessLinks";
import SideBar from "../Components/Sidebar/SideBar";
import AppMenu from "../Components/StoreDashboardComponent/AppMenu";
import SummaryCard from "../Components/StoreDashboardComponent/SummaryCard";
import AppBar from "../Components/Appbar/Appbar";
import { useSelector } from "react-redux";
import { useDarkMode } from "./DarkMode/DarkMode";

function Dashboard() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const accessLinkData = [
    {
      id: 1,
      title: "New Order",
      image: "../images/new-order-icon.png",
      link: "/new-order",
    },
    {
      id: 2,
      title: "Pending Order",
      image: "../images/pending-order-icon.png",
      link: "/pending-order",
    },
    {
      id: 3,
      title: "Order List",
      image: "../images/order-list-icon.png",
      link: "/order-list",
    },
  ];
  // const fetchData = () => {
  //   fetch("https://billing.ubsbill.com/apptest/v2/sys/dashboard/getbyidandstatus/1")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setQuickAccessLinks(json.data.quickaccess);

  //     });
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const appMenuData = [
    {
      id: 1,
      title: "Add Food",
      image: "../images/addFoodIcon.png",
      boxShadow: "0px -9px 0px 0px #FACE47",
      link: "/food-management-dashboard/add-menu",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 2,
      title: "Bill Report",
      image: "../images/bill-report-icon.png",
      boxShadow: "0px -9px 0px 0px #28A497",
      link: "/bill-report",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 3,
      title: "Add User",
      image: "../images/add-user-icon.png",
      boxShadow: "0px -9px 0px 0px #47C01C",
      link: "/add-user",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "50px",
    },
    {
      id: 5,
      title: "Add Inventory",
      image: "../images/add-inventory-icon.png",
      boxShadow: "0px -9px 0px 0px #47C01C",
      link: "/add-inventory",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 6,
      title: "Profile Setting",
      image: "../images/profile-sett-icon.png",
      boxShadow: "0px -9px 0px 0px #FACE47",
      link: "/profile-setting",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
  ];

  const [AppMenuLinks, setAppMenuLinks] = useState([]);
 



  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomTypography
          sx={{ mb: 4, fontWeight: isDarkMode ? "500" : "600" }}
          variant="p"
          className="link-cart-title"
        >
          <span className="hand">🖐</span> Hello , {currentUser.username} | 
          <span
            className="link-cart-title"
            style={{ fontWeight: "500", color: isDarkMode ? "#fff" :"#424242" , }}
          >
             {currentDate}
          </span>
        </CustomTypography>
        <SummaryCard />
        <CustomTypography sx={{ mb: 4 , fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' } }} variant="p" className="link-cart-title">
          Quick Access Links
        </CustomTypography>
       
        <QuickAccessLinks data={accessLinkData}/>
       
        <CustomTypography sx={{ mb: 4 }} variant="p" className="link-cart-title">
          App Menu
        </CustomTypography>
      
        <AppMenu/>
     
      </Box>
    </Box>
  );
}
export default Dashboard;
  