import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Stack,
    FormControl,
    FormHelperText,
    Grid,
    Paper,
    Input,
    MenuItem,
    Select,

} from "@mui/material";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useDrawer } from "../Sidebar/DrawerContext";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function EditVendorInventory() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const { labelStyle, inputStyles, errorMessageStyles, submitButtonStyles, selectStyles, formPaperStyles, formMainBox, capitalizeFirstLetter, buttonDisabled } = useDrawer();
    const { showSuccessSnackbar } = useSuccessSnackbar();
    const { showErrorSnackbar } = useErrorSnackbar();
    const {serialNo} = useParams();
    const currentUser = useSelector((state) => state.auth.user.data);

    const navigate =useNavigate();
    useEffect(() => {
    
        const fetchData = async () => {
           try {
              const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/invoice/id/${serialNo}`);
              const inventoryData = response.data.data; 
              if(response.data.data.storeId !== currentUser.storeid)
              {
                 navigate('/unauthorized');
              }
              else{
              setFormData({
                 vendorName: inventoryData.vendorName,
                 itemName: inventoryData.itemName,
                 price: inventoryData.price,
                 quantity: inventoryData.quantity,
                 unit: inventoryData.unit ,
                 inventoryCode: inventoryData.inventoryCode,
                 discount: inventoryData.discount,
                 createdBy: currentUser.username,
                 updatedBy: currentUser.username,
                 storeId: currentUser.storeid,
              });
            }
           } catch (error) {
              showErrorSnackbar("Failed to fetch inventory data");
           }
        };
  
        fetchData();
     }, [serialNo]);


    const formDataFields = [
        { name: 'vendorName', label: 'Vendor Name', type: 'text' },
        {
            name: "itemName",
            label: "Item Name",
            type: "select",
            options: [
                { value: "Select Item", label: "Select Item" },
                { value: "Tea", label: "Tea" },
                { value: "Salt", label: "Salt" },
                { value: "Sugar", label: "Sugar" },
            ],
        },
        { name: 'inventoryCode', label: 'Inventory Code', type: 'text' },
        { name: 'quantity', label: 'Quantity', type: 'number' },
        {
            name: "unit",
            label: "Unit",
            type: "select",
            options: [
                { value: "Select unit", label: "Select unit" },
                { value: "kg", label: "Kg" },
                { value: "gram", label: "Gram" },
                { value: "mg", label: "MG" },
            ],
        },
        { name: 'price', label: 'Price Per Unit', type: 'number' },
        { name: 'discount', label: 'Discount (%)', type: 'number' },
        // { name: 'total', label: 'Total', type: 'number' },
    ];

    const [formData, setFormData] = useState({
        ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
        storeId: currentUser.storeid,
        createdBy: currentUser.username,
        updatedBy: currentUser.username,
        paymentStatus: "Pending"
    });
    const [errors, setErrors] = useState(
        formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
    );
    const validateField = (fieldName, value) => {
        let error = "";
        const numberRegex = new RegExp(/^\d{1,10}$/);
        const capitalizedFieldName = capitalizeFirstLetter(fieldName);
        if (value.trim() === "") {
            error = `${capitalizedFieldName} is Mandatory`;
        }
        if (capitalizedFieldName === "price") {
            if (!numberRegex.test(value)) {
                error = `${capitalizedFieldName} should be a number`;
            }
        }
        else if (fieldName === "quantity" || fieldName === "price") {
            if (value <= 0) {
                error = `${capitalizedFieldName} should be a positive number`;
            } else if (value.length > 10) {
                error = `${capitalizedFieldName} should not exceed 10 digits`;
            }
        }
        else if (fieldName === "discount" ){
            if (value <= 0) {
                error = `${capitalizedFieldName} should be a positive `;
            } else if (value.length > 2) {
                error = `${capitalizedFieldName} should not exceed 2 digits`;
            }
        }
        return error;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const error = validateField(name, value);
        setErrors({ ...errors, [name]: error });
    };
    const handleVendorInventory = async (e) => {
        e.preventDefault();

        if (Object.values(errors).every((error) => error === "")) {
          
            const Total = formData.quantity * formData.price
            const Discount = Total / 100 * formData.discount
            formData.total = Total - Discount
 
            try {
                const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/invoice/id/${serialNo}`, formData)
                if (response.data.status === true) {
                  
       
                    showSuccessSnackbar("Form Submit Successfull !");
                }
            } catch (error) {
                showErrorSnackbar("Form Submission Failed");

            }
        }
    };
    const isSubmitDisabled = buttonDisabled(formData, errors);
    return (
        <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper elevation={12} sx={formPaperStyles}>
                <Grid container spacing={1} sx={{ mb: 4 }}>
                    <Grid item>
                        <Box display="flex" alignItems="center" bgcolor="primary.light" p={1} sx={formMainBox}>
                            <AccountBalanceWalletIcon fontSize="large" />
                            <span style={{ marginLeft: '8px' }}>Edit Vendor Inventory</span> {/* Text */}
                        </Box>
                    </Grid>
                </Grid>

                {Array.from({ length: 5 }).map((_, index) => (
                    <Stack
                        key={index}
                        spacing={4}
                        direction={{
                            xs: "column",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                        }}
                        sx={{ marginBottom: 1 }}
                    >
                        {formDataFields
                            .slice(index * 2, index * 2 + 2)
                            .map((formDataItem, innerIndex) => (
                                <FormControl
                                    key={innerIndex}
                                    fullWidth
                                    error={!!errors[formDataItem.name]}
                                    variant="outlined"
                                >
                                    <CustomTypography
                                        htmlFor={`outlined-adornment-${formDataItem.name}`}
                                        className="food-card-text"
                                        sx={labelStyle}
                                    >
                                        {formDataItem.label}
                                    </CustomTypography>

                                    {formDataItem.type === "select" ? (
                                        <Select
                                            name={formDataItem.name}
                                            value={
                                                formData[formDataItem.name] ||
                                                formDataItem.options[0].label
                                            }
                                            onChange={handleInputChange}
                                            sx={selectStyles}
                                        >
                                            {formDataItem.options.map((option, optionIndex) => (
                                                <MenuItem key={optionIndex} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Input
                                            sx={inputStyles}
                                            type={formDataItem.type}
                                            name={formDataItem.name}
                                            value={formData[formDataItem.name]}
                                            onChange={handleInputChange}
                                            disableUnderline
                                        />
                                    )}
                                    <FormHelperText
                                        className="food-card-text"
                                        sx={errorMessageStyles}
                                    >
                                        {errors[formDataItem.name]}
                                    </FormHelperText>
                                </FormControl>
                            ))}
                    </Stack>
                ))}
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={handleVendorInventory} className='food-card-text mode-button border-radius-10 cart-button final-button' sx={submitButtonStyles} disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </Box>
            </Paper>
        </Box>
    );

}
export default EditVendorInventory;
