import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bill: {
    contact: "",
    updatedBy: "",
    billId: "",
    createdBy: "",
    paymentMode: "",
    discount: 0,
    total: 0,
    storeId: "",
    billStatus: "",
    order: [
      {
        tableNo: "",
        orderStatus: "",
        orderType: "",
        createdName: "",
        updatedBy: "",
        storeId: "",
        orderFoods: [],
      },
    ],
  },
  orderFood: [],
};
const foodSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add(state, action) {
      if (action.payload.orderType !== undefined) {
        state.bill.order[0].orderType = action.payload.orderType;
      }
      if (action.payload.orderStatus !== undefined) {
        state.bill.order[0].orderStatus = action.payload.orderStatus;
      }
      if (action.payload.tableNo !== undefined) {
        state.bill.order[0].tableNo = action.payload.tableNo;
      } else if (
        action.payload.orderType === "TAKE AWAY" ||
        action.payload.orderType === "DELIVERY"
      ) {
        state.bill.order[0].tableNo = "";
      }
      if (action.payload.billStatus !== undefined) {
        state.bill.billStatus = action.payload.orderStatus;
      }
      // Handle discount
      if (action.payload.discount !== undefined) {
        state.bill.discount = action.payload.discount;
      }
      // Handle paymentmode
      if (action.payload.paymentMode) {
        state.bill.paymentMode = action.payload.paymentMode;
      }
      // Handle orderFoods and total
      if (action.payload.price && action.payload.foodName) {
        const existingItem = state.bill.order[0].orderFoods.find(
          (item) =>
            item.foodId === action.payload.foodId &&
            item.foodName === action.payload.foodName
        );
        if (existingItem) {
          existingItem.quantity += 1;
        } else {
          state.bill.order[0].orderFoods.push({
            ...action.payload,
            quantity: 1,
          });
        }
        if (action.payload.price) {
          state.bill.total += action.payload.price;
        }
        state.orderFood = state.bill.order[0].orderFoods.map((food) => ({
          ...food,
        }));
      }
    },
    remove(state, action) {
      const itemToRemove = state.bill.order[0].orderFoods[action.payload];
      if (itemToRemove) {
        state.bill.total -= itemToRemove.price * itemToRemove.quantity;
        // Update orderFoods
        state.bill.order[0].orderFoods.splice(action.payload, 1);
        // Update orderFood
        state.orderFood = state.bill.order[0].orderFoods.map((food) => ({
          ...food,
        }));
      }
      return state;
    },
    removeOneItem(state, action) {
      const itemToRemove = state.bill.order[0].orderFoods[action.payload];
      if (itemToRemove && itemToRemove.quantity > 0) {
        state.bill.total -= itemToRemove.price;
        itemToRemove.quantity -= 1;
        // Update orderFoods
        if (itemToRemove.quantity === 0) {
          state.bill.order[0].orderFoods.splice(action.payload, 1);
        }
        // Update orderFood
        state.orderFood = state.bill.order[0].orderFoods.map((food) => ({
          ...food,
        }));
      }
      return state;
    },
    fetchedData: (state, action) => {
      const newData = action.payload;
      state.bill = newData;
    },
    resetState(state, action) {
      return initialState;
    },
    updateOrderStatus: (state, action) => {},
  },
});
//
const calculateTotalQuantity = (orderFoods) => {
  return orderFoods.reduce((total, item) => total + item.quantity, 0);
};
//to show total quanity
export const selectTotalQuantity = (state) => {
  return calculateTotalQuantity(state.cart.bill.order[0].orderFoods);
};
//to found discounted subtotal
export const calculateSubtotal = (total, discount) => {
  const discountAmount = (total * discount) / 100;
  return total - discountAmount;
};
// export const selectSubtotal = (state) => {
//   const total = state.cart.bill.total;
//   const discountPercent = state.cart.bill.discount;
//   const discountAmount = (total * discountPercent) / 100;
//   const subtotal = total - discountAmount;
//   return { subtotal, discountAmount };
// };



export const selectSubtotal = (state) => {
  const bill = state.cart.bill;
  if (!bill) {
    return { subtotal: 0, discountAmount: 0 };
  }
  const total = bill.total;
  const discountPercent = bill.discount;
  const discountAmount = (total * discountPercent) / 100;
  const subtotal = total - discountAmount;
  return { subtotal, discountAmount };
};




export const {
  add,
  remove,
  resetState,
  removeOneItem,
  updateOrderStatus,
  postBillOrderPending,
  postBillOrderSuccess,
  postBillOrderFailure,fetchedData
} = foodSlice.actions;
export default foodSlice.reducer;