import {
    Box,
    Button,
    Grid,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useDrawer } from "../Sidebar/DrawerContext";
  import { Link, useLocation } from "react-router-dom";
  import PendingOrderCard from "../Cards/PendingOrderCard";
import KotOrderCards from "../Cards/KotOrderCards";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
  
  function PendingOrder() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const pendingOrderCard = [
      { text: "Running Order", link: "/pending-order" },
      { text: "New Order", link: "/new-order" },
      { text: "Order List", link: "/order-list" },
      { text: "KOT", link: "/kitchen-order-ticket" },
    ];
    const { submitButtonStyles } = useDrawer();
    const location = useLocation();
    const currentPath = location.pathname;
    const isPendingOrderPage = location.pathname === '/pending-order';
    const isKotPage = location.pathname === '/kitchen-order-ticket';
    
    return (
      <Box >
        <Grid
          container
          spacing={2}
          sx={{ mt: 1,minHeight:{lg:"9vh", sm:"7vh", } }}
        >
          {pendingOrderCard.map((button, index) => (
            <Grid key={index} item>
              <Link to={button.link}>
              <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={{
                ...submitButtonStyles,
                 background: currentPath === button.link ? (isDarkMode ? '#fff' : '#28A497') : (isDarkMode ? '#8f8D8D' : '#D9D9D9'),
                  color: currentPath === button.link ? (isDarkMode ? '#000' : '#fff') : (isDarkMode ? '#fff' : '#000000')
                
              }}
            >
                  {button.text}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Box className="pendingOrderBox" sx={{ position: "relative", height:"78vh", maxHeight:"78vh", overflowY:"auto", mt:"10px" }}>
        {isPendingOrderPage && <PendingOrderCard />}
        {isKotPage && <KotOrderCards />}
        </Box>
      </Box>
    );
  }
  
  export default PendingOrder;
  