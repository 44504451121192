import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import SideBar from "../Components/Sidebar/SideBar";
import AppBar from "../Components/Appbar/Appbar";
import { useDarkMode } from "./DarkMode/DarkMode";


export default function Shortcuts() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const keyMappings = {
      N: "/overview/order",
      R: "/pendingorder",
      B: "/overview/bill_list",
      I: "/inventory",
      1: "/inventory/Inventory_list",
      V: "/vendordashboard",
      P: "/payment",
      T: "/VendorInventory",
      M: "/food/food",
      F: "/Food/Food_list",
      A: "/food/add_ons",
      2: "/addOn/addOn_list",
      G: "/receipe",
      3: "/receipe_list",
      4: "/reports/vendor_list",
      5: "/reports/payment_list",
      6: "/reports/vendor_invoice_list",
      U: "/user/adduser",
      7: "/user/userlist",
      8: "/reports/balance_list",
      X: "/settings/taxsetting",
      S: "/settings",
      Y: "/settings/payment_setting",
      Z: "/category",
      C: "/balanceform",
      O: "/overView/order_list",
      H: "/dashbord",
      K: "/kot",
    };

    const handleKeyPress = (e) => {
      // Check if e.key is defined before calling toUpperCase
      const key = e.key ? e.key.toUpperCase() : null;

      if (e.altKey && keyMappings[key]) {
        navigate(keyMappings[key]);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate]);

  const rows = [
    { operation: "New Order", shortcut: "Alt + N" },
    { operation: "Running Order", shortcut: "Alt + R" },
    { operation: "Order List", shortcut: "Alt + O" },
    { operation: "Add Inventory", shortcut: "Alt + I" },
    { operation: "Inventory List", shortcut: "Alt + 1" },
    { operation: "Add Vendor", shortcut: "Alt + V" },
    { operation: "Add Payment", shortcut: "Alt + P" },
    { operation: "Add Vendor Inventory", shortcut: "Alt + T" },
    { operation: "Add Food", shortcut: "Alt + M" },
    { operation: "Food List", shortcut: "Alt + F" },
    { operation: "Add Addon", shortcut: "Alt + A" },
    { operation: "Addon List", shortcut: "Alt + 2" },
    { operation: "Add Recipe", shortcut: "Alt + G" },
    { operation: "Recipe List", shortcut: "Alt + 3" },
    { operation: "Vendor List", shortcut: "Alt + 4" },
    { operation: "Payment List", shortcut: "Alt + 5" },
    { operation: "Vendor Inventory List", shortcut: "Alt + 6" },
    { operation: "Add User", shortcut: "Alt + U" },
    { operation: "User List", shortcut: "Alt + 7" },
    { operation: "Tax Setting", shortcut: "Alt + X" },
    { operation: "Store Setting", shortcut: "Alt + S" },
    { operation: "Payment Setting", shortcut: "Alt + Y" },
    { operation: "Dashboard", shortcut: "Alt + H" },
    { operation: "Cash Register", shortcut: "Alt + C" },
    { operation: "Cash List", shortcut: "Alt + 8" },
  ];

  return (
    <Box >
       
      <Box component="main">
        <CustomTypography variant="h5">
          <Table
            sx={{ minWidth: 700, border: "1px solid #ddd" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#03989e",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Sr.No
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#03989e",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Operation
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#03989e",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Shortcut
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell className="food-card-text" sx={{color: isDarkMode ? "white" : "#000"}}>{index + 1}</TableCell>
                  <TableCell className="food-card-text" sx={{color: isDarkMode ? "white" : "#000"}}>{row.operation}</TableCell>
                  <TableCell className="food-card-text" sx={{color: isDarkMode ? "white" : "#000"}}>{row.shortcut}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              className="food-card-text"
              count={rows.length}
              sx={{color: isDarkMode ? "white" : "#000"}}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </CustomTypography>
      </Box>
    </Box>
  );
}
