import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NavLinkCard from '../Components/StoreDashboardComponent/NavLinkCard';
import axios from 'axios';
import { useDarkMode } from './DarkMode/DarkMode';

function UserRoleAccessManagement() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const cashdata = [
    { id: 1, title: 'User Role Access', image: '../images/ura-icon.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/user-role-management-dashboard/user-roles-access", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 2, title: 'Role List', image: '../images/role-list-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/user-role-management-dashboard/users-role-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 3, title: 'Update Role Access', image: '../images/update-role-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/user-role-management-dashboard/update-user-roles-access", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },

  ]



  return (
    <Box sx={{ display: "flex" }}>

      <Box
        component="main"
        sx={{
         
          width: "100%",
          display: "flex",
         
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
       <CustomTypography sx={{ mb: 4, fontWeight: "500" , fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' }, }} variant="p" className="link-cart-title">
          Role Permissions
        </CustomTypography>
        <Grid  lg={11} md={12} sm={12} xs={12} xl={10} container spacing={1.7}  >
          {cashdata.map(card => (
            <NavLinkCard key={card.id} card={card} />
          ))}

          {/* {UserRoleAccessLinks.map((card) => (
            card.adminSubMenu.map((subMenuItem, index) => (
              <NavLinkCard key={index} card={subMenuItem} index={index} />
            ))
          ))} */}

        </Grid>
      </Box>
    </Box>
  )
}
export default UserRoleAccessManagement;