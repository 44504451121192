import { Alert, AlertTitle, Box, Button, Card, Grid, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDrawer } from "../Sidebar/DrawerContext";
import EditIcon from '@mui/icons-material/Edit';
import { RunningOrderModal } from "./Modal/RunningOrderModal";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PrintOrderModal } from "./Modal/PrintOrderModal";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

function PendingOrderCard() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { runningOrderTitle,runningOrderDetails,calculateTotal } = useDrawer();
  const currentUser = useSelector((state) => state.auth.user.data.storeid)
  const[pendingOrder , setPendingOrder] = useState([]);

  const [tableNumbers, setTableNumbers] = useState([]);

  const fetchPendingData = () => {
    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/bill/statusreport?orderStatus=Prepared&orderStatus=pending&orderStatus=Running&storeId=${currentUser}`)
      .then((response) => {
       
        setPendingOrder(response.data.data);
        
        const numbers = response.data.data.map(order => order.order[0].tableNo);
       
        setTableNumbers(numbers);
       
      
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  
  useEffect(() => {
    fetchPendingData();
  }, []);
  
    
  return (
    <Box sx={{position:"relative",mb: 5}}>
     <Grid xl={12} container spacing={3} sx={{}}>
  {pendingOrder.length === 0 ? (
    <Box  sx={{mt:"1vh",ml:"1vw"}}> 
    <Alert severity="error" sx={{ marginTop: "30px" , minWidth:"80vw"}}>
    <AlertTitle>Info</AlertTitle>
    Currently, There Are No Running Orders!
  </Alert></Box>
  ) : (
        pendingOrder.map((data) => (       
        <Grid xs={11} sm={5.5} md={3.7} lg={3.2} xl={3} item>
          <Card
            sx={{
              background: isDarkMode ? "#5E5E5E" : "#F3F3F3",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "20px 0px 0px 0px", borderRadius:"10px"
            }}
          >
            <Box
              className="align-items-center"

              sx={{
                background: data.order[0].orderStatus === "Running" ? (isDarkMode ? "#48484a" : "#9A9A9A") :
                            data.order[0].orderStatus === "pending" ? (isDarkMode ? "#134a46" : "#28A497") :
                            data.order[0].orderStatus === "Prepared" ? (isDarkMode ? "#48484a" : "#000") :
                            null
                , p: "2% 4%" }}
            >
              <Typography sx={{ ...runningOrderTitle, color: "#fff" , textTransform: "capitalize"}}>
                {data.order[0].orderStatus} Order
              </Typography>
            </Box>
            <Box sx={{padding: { lg: 2.5, md: 1.7 , sm: 1.7, xs: 1.2} , display:"flex", flexDirection:"column", gap: {xs: 0, sm: 2,xl: 2, lg: 2 } }}>
            <Box sx={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", maxWidth:{xl:"95%",lg:"100%"}}}>
               <CustomTypography className="food-card-text" sx={runningOrderDetails}>Table No : {data.order[0].tableNo}</CustomTypography>
               <CustomTypography className="food-card-text" sx={runningOrderDetails}>Order No : {data.order[0].orderId}</CustomTypography>
            </Box>
            <CustomTypography className="food-card-text" sx={runningOrderDetails}>Order Type : {data.order[0].orderType}</CustomTypography>
            <CustomTypography className="food-card-text" sx={runningOrderDetails}>Order Date : {data.order[0].orderDate}</CustomTypography>
            <CustomTypography className="food-card-text" sx={runningOrderDetails}>Total : {calculateTotal(data)}</CustomTypography>

            <Box sx={{mt:{xl: "15px", sm:"15px", xs:"0px"},display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
               <RunningOrderModal  totalData={data} orderData={data.order[0]} order={data} fetchPendingData={fetchPendingData} billId={data.billId}/>
             <Box sx={{display:"flex"}}>
              
             <Button  component={Link} to={`/update-order/${data.serialNo}`}  className='food-card-text mode-button border-radius-10' sx={{bgcolor: isDarkMode ? "#8f8D8D" : '#D9D9D9',p:"5px", maxWidth:"45px", width:"45px", minWidth:"45px", mr: 1}}>
               <EditIcon sx={{color: isDarkMode ? "#fff" : "#424242"}}/>
             </Button>
              <Box>
            <PrintOrderModal totalData={data} orderData={data.order[0]} order={data} fetchPendingData={fetchPendingData} billId={data.billId}/>
            </Box>  
         
             </Box>
            </Box>
            </Box>
          </Card>
        </Grid>
         ))
         )}
      </Grid>
    
    </Box>
  );
}

export default PendingOrderCard;
