import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Checkbox,

} from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";

import jsPDF from "jspdf";
import VisibilityIcon from '@mui/icons-material/Visibility';
import "jspdf-autotable";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useDrawer } from "../../Sidebar/DrawerContext";
import { useTableContext } from "../../../Screens/tableContext/TableContext";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";
const rowsPerPage = 10;
function TechListSuperadmin() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles,
   
  } = useDrawer();



  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    getFontWeight, resetSearchInput, resetCurrentPage,
  } = useTableContext();

  const [techList, setTechList] = useState([]);
  const [total,setTotal] = useState();
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
    resetCurrentPage();
  }, [location.pathname]);

  const filteredRows = filterRows(techList);

  const tableHeaders = [
    { label: "SR No." },
    { label: "Technician Name" },
    { label: "Email" },
    { label: "Contact" },
    { label: "Country" },
    { label: "State" },
    { label: "Created By" },
    { label: "Created Date" },


  ];



  const fetchData = async () => {
    try {
      // const url = fromDate && toDate ?
      //     // `https://billing.ubsbill.com/apptest/v2/sys/payment/store/${currentUser.storeid}?startDate=${fromDate}&endDate=${toDate}` :
      //     `https://billing.ubsbill.com/apptest//v2/api/auth/tech/`;
      const response = await fetch(`https://billing.ubsbill.com/apptest/v2/api/auth/tech/?page=${currentPage -1}&size=10`);
      const json = await response.json();
      setTechList(json.data.technician);
      setTotal(json.data.technician[0].totalPages)

    } catch (error) {
      console.error("Error fetching Vender data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };


  const filteredRowsBySearch = filteredRows.filter((row) =>
    Object.values(row).some(
      (value) =>
        value && value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = filteredRowsBySearch;

  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box bgcolor="primary.light" sx={listMainBox}>
            <ReceiptLongIcon sx={listIconStyles} />
            <CustomTypography variant="span" sx={{ ml: "8px" }}>
              Technician List
            </CustomTypography>
          </Box>
            <Grid item xs={6}  mx={5}  sm={4} md={3} lg={2.5} xl={2}>
              <Box bgcolor="#D9D9D9" p={1} sx={searchboxStyles}>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                <Divider
                  orientation="vertical"
                  sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
                />
                <Input
                  fullWidth
                  placeholder="Search"
                  disableUnderline
                  value={searchInput}
                  onChange={handleSearchChange}
                />
              </Box>
            </Grid>
         

          <Box sx={spaceBetweenTopComponent}>
            <Button
              className='food-card-text mode-button border-radius-10 cart-button final-button'
              component={Link}
              to="/add-technician" // Specify the link destination here
              sx={pdfAndExcelButtonStyles}
            >
              + Technician
            </Button>

          </Box>

        </Grid>
      </Grid>

      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>

          <div style={{ padding: "10px" }}>
            <Table aria-label="simple table">
              <TableHead sx={tableHeadStyles}>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      className="all-list-tabelcell"
                      align="right"
                      sx={tableCellStyles}
                    >
                      {header.label}
                      {header.label === "Select All" && (
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <div style={{ padding: "10px" }} />
              <TableBody>
                {displayedRows.map((storeData, index) => (
                  <React.Fragment key={index}>

                    <TableRow key={index} sx={contentTableRowStyles}>
                      {renderStyledCell(index + 1, getFontWeight(index + 1))}
                      {renderStyledCell(storeData.username, getFontWeight(storeData.username))}
                      {renderStyledCell(storeData.email, getFontWeight(storeData.email))}
                      {renderStyledCell(storeData.contact, getFontWeight(storeData.contact))}
                      {renderStyledCell(storeData.country, getFontWeight(storeData.country))}
                      {renderStyledCell(storeData.state, getFontWeight(storeData.state))}
                      {renderStyledCell(storeData.createdBy, getFontWeight(storeData.createdBy))}
                      {renderStyledCell(storeData.date, getFontWeight(storeData.date))}


                    </TableRow>

                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
      <Grid item>
          <Pagination
            count={total}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default TechListSuperadmin;



