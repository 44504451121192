import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    managementSections:  {
      username: "",
      storeId: "",
      menu: [
        {
          title: "Dashboard",
          path: "/user-dashboard",
          icon: "../images/sidebar/sidebar-vector-icon.png",
          status: false,
          subMenu: [
            {
              icon: "/",
              title: "User Dashboard",
              path: "/user-dashboard",
              status: false,
            },
          ],
        },
        {
          title: "Order Management",
          path: "/order-management-dashboard",
          icon: "../images/sidebar/sidebar-order-mgmt-icon.png",
          status: false,
          subMenu: [
            {
              icon: "../images/new-order-icon.png",
              title: "New Order",
              path: "/new-order",
              status: false,
            },
            {
              icon: "../images/pending-order-icon.png",
              title: "Pending Order",
              path: "/pending-order",
              status: false,
            },
            {
                icon: "../images/add-addon-icon.png",
              title: "KOT",
              path: "/kitchen-order-ticket",
              status: false,
            },
            {
                icon: "../images/order-list-icon.png",
              title: "Order List",
              path: "/order-list",
              status: false,
            },
            {
              icon: "../images/order-list-icon.png",
            title: "Bill List",
            path: "/bill-list",
            status: false,
          },
          ],
        },
        {
          title: "Cash Management",
          path: "/cash-management-dashboard",
          icon: "../images/sidebar/sidebar-cash-register-icon.png",
          status: false,
          subMenu: [
            {
                icon: "../images/cash-reg-icon.png",
              title: "Cash Register",
              path: "/cash-management-dashboard/cash-register",
              status: false,
            },
            {
                icon: "../images/cash-reg-list-icon.png",
              title: "Cash Register List",
              path: "/cash-management-dashboard/cash-register-list",
              status: false,
            },
          ],
        },
        {
          title: "Inventory Management",
          path: "/inventory-management-dashboard",
          icon: "../images/sidebar/sidebar-inventory-icon.png",
          status: false,
          subMenu: [
            {
                icon: "../images/add-inv-icon.png",
              title: "Add Inventory",
              path: "/inventory-management-dashboard/add-inventory",
              status: false,
            },
            {
                icon: "../images/inv-mgmt-icon.png",
              title: "Inventory List",
              path: "/inventory-management-dashboard/inventory-list",
              status: false,
            },
          ],
        },
        {
          title: "Vendor Management",
          path: "/vendor-management-dashboard",
          icon: "../images/sidebar/sidebar-cart-icon.png",
          status: false,
          subMenu: [
            {
                icon: "../images/add-vendor-icon.png",
              title: "Add Vendor",
              path: "/vendor-management-dashboard/add-vendor",
              status: false,
            },
            {
                icon: "../images/vendor-payment.png",
              title: "Vendor Payment",
              path: "/vendor-management-dashboard/vendor-payment-details",
              status: false,
            },
            {
                icon: "../images/vendor-inv-icon.png",
              title: "Vendor Inventory",
              path: "/vendor-management-dashboard/vendor-inventory",
              status: false,
            },
            {
              icon: "../images/inv-mgmt-icon.png",
              title: "Vendor List",
              path: "/vendor-management-dashboard/vendor-list",
             status: false,
            },
          ],
        },
        {
          title: "Food Management",
          path: "/food-management-dashboard",
          icon: "../images/sidebar/sidebar-food-managent-icon.png",
          status: false,
          subMenu: [
            {
                icon: "../images/add-menu-icon.png",
              title: "Add Menu",
              path: "/food-management-dashboard/add-menu",
              status: false,
            },
            {
                icon: "../images/food-list-icon.png",
              title: "Food List",
              path: "/food-management-dashboard/food-list",
             status: false,
            },
            {
               icon: "../images/add-addon-icon.png",
              title: "Add Adddons",
              path: "/food-management-dashboard/add-addons",
             status: false,
            },
            {
                icon: "../images/add-on-list.png",
              title: "Add-OnList",
              path: "/food-management-dashboard/addon-list",
              status: false,
            },
            {
                icon: "../images/add-recipe.png",
              title: "Add Receipe",
              path: "/food-management-dashboard/add-recipe",
              status: false,
            },
            {
                icon: "../images/recipe-list-icon.png",
              title: "Receipe List",
              path: "/food-management-dashboard/recipe-list",
             status: false,
            },
          ],
        },
        {
          title: "User Management",
          path: "/user-management-dashboard",
          icon: "../images/sidebar/sidebar-profile-icon.png",
          status: false,
          subMenu: [
            {
                icon: "../images/adduser-icon.png",
              title: "Add User",
              path: "/user-management-dashboard/add-user",
               status: false,
            },
            {
                icon: "../images/user-list-icon.png",
              title: "User List",
              path: "/user-management-dashboard/users-list",
            status: false,
            },
          ],
        },
        {
          title: "Reports",
          path: "reports-management-dashboard",
          icon: "../images/sidebar/sidebar-report-icon.png",
          status: false,
          subMenu: [
            {
                icon: "/",
              title: "Sales Report",
              path: "/order-list",
             status: false,
            },
            {
                icon: "/",
              title: "Inventory Report",
              path: "/order-list",
              status: false,
            },
            {
                icon: "/",
              title: "Vendor Report",
              path: "/order-list",
             status: false,
            },
            {
                icon: "/",
              title: "Vendor Inventory Report",
              path: "/order-list",
               status: false,
            },
            {
                icon: "/",
              title: "Billing Report",
              path: "/order-list",
              status: false,
            },
          ],
        },
        {
          title: "Profile",
          path: "/profile-settings",
          icon: "../images/sidebar/sidebar-setting-icon.png",
          status: false,
          subMenu: [
            {
                icon: "../images/store-settings-icon.png",
              title: "Store Setting",
              path: "/profile-settings/store-settings",
              status: false,
            },
            {
                icon: "../images/payment-icon.png",
              title: "Payment Setting",
              path: "/profile-settings/payment-settings",
             status: false,
            },
            {
                icon: "../images/tax-icon.png",
              title: "Tax Setting",
              path: "/profile-settings/tax-settings",
              status: false,
            },
            {
                icon: "../images/pos-icon.png",
              title: "POS Setting",
              path: "/profile-settings/pos-settings",
             status: false,
            },
          ],
        },
      ],
    },
};

const roleSlice = createSlice({
    name: 'managementSections',
    initialState,
    reducers: {
      updateManagementStatus: (state, action) => {
        const { title, status } = action.payload;
      
       
        const menuItem = state.managementSections.menu.find(
          (item) => item.title === title
        );
      
        if (menuItem) {
         
          menuItem.status = status;
        }
      },
      
      updateSubmenuStatus: (state, action) => {
        const { section, name } = action.payload;
  
        const menuIndex = state.managementSections.menu.findIndex(menuItem => menuItem.title === section);
        const submenuIndex = state.managementSections.menu[menuIndex].subMenu.findIndex(subItem => subItem.title === name);
  
        if (menuIndex !== -1 && submenuIndex !== -1) {
          state.managementSections.menu[menuIndex].subMenu[submenuIndex].status = !state.managementSections.menu[menuIndex].subMenu[submenuIndex].status;
        }
      },
       updateUsername: (state, action) => {
        const newUsername = action.payload;
        state.managementSections.username = newUsername;
      },
      addStoreid: (state, action) => {
        const addtoreid = action.payload;
        state.managementSections.storeId = addtoreid;
      },
      fetchDataAction: (state, action) => {
        const newData = action.payload;
      
        state.managementSections = newData;
      },
      resetState(state, action) {
        return initialState; 
      }
    },
  });

  
  export const { updateMainSectionStatus , updateSubmenuStatus , updateUsername , updateManagementStatus , fetchDataAction, resetState,addStoreid} =
  roleSlice.actions;
  
  export default roleSlice.reducer;
  