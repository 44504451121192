import React, { useEffect, useState } from "react";
import {
  Box,

  Button,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  MenuItem,
  Input,
 styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import SettingsIcon from '@mui/icons-material/Settings';
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useDrawer } from "../Sidebar/DrawerContext";
import axios from "axios";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function StoreSettings() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data);
  const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    formMainBox,
    formPaperStyles,
    capitalizeFirstLetter,
    buttonDisabled
  } = useDrawer();
  const formDataFields = [
    { name: "storeName", label: "Store Name", type: "text" },
    { name: "username", label: "Username", type: "text" },
    { name: "email", label: "Store Email", type: "text" },
    { name: "storeAddress", label: "Store Address", type: "text" },
    { name: "contact", label: "Store Contact", type: "number" },
    // { name: "logo", label: "Image", type: "file" },
    { name: "gstNo", label: "GST No", type: "text" },
    { name: "storeid", label: "Store ID", type: "text" },
    { name: "pinCode", label: "Pin Code", type: "Number" },
    { name: "address", label: "Personal Address", type: "text" },
    { name: "upi", label: "UPI ID", type: "text" },
    { name: "balanceTime", label: "Cash Register Closure ", type: "time" }
    
  ];
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const [formData, setFormData] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const validateField = (fieldName, value) => {
    let error = "";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;
    const numberRegex = new RegExp(/^\d{1,10}$/);
    const contactRegex = /^[6-9][0-9]{9}$/;
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}Z[\dA-Z]$/;

    const userNameRegex = /^(?=.*[A-Z])[a-zA-Z0-9_]{4,20}$/; 
    
    if (fieldName === "price") {
      if (!numberRegex.test(value)) {
        error = `${capitalizedFieldName} should be a number`;
      }
    }
     if (fieldName === "storeName" || fieldName === "username" || fieldName === "email" || fieldName === "upi") {
      if (value.length > 40) {
        error = `${capitalizedFieldName} should not exceed 40 characters`;

      }
    } 
    if (fieldName === "storeEmail") {
      if (!emailRegex.test(value)) {
        error = "Please enter a valid email in the format username@example.com";
      }
    }
    if (fieldName === "storeAddress" || fieldName === "address") {
      if (value.length > 100) {
        error = `${capitalizedFieldName} should not exceed 100 characters`;
      }
    }
  
    if (fieldName === "contact") {
      if (!contactRegex.test(value)) {
        error = "Please enter a valid 10-digit contact number";
      }
    }
    else if (fieldName === "pinCode"){
      if (value.length !== 6) {
          error = `${capitalizedFieldName} should be 6 digits only`;
      }
  }
    if (fieldName === "gstNo") {
      if (!gstRegex.test(value)) {
        error = "Please enter a valid GST NO";
      }
    }
  if (fieldName === "contact" || fieldName === "pinCode" ) {
            if (value <= 0) {
                error = `${capitalizedFieldName} should be a positive number`;
            }
          }
          else if (fieldName === "username" ){
            if (!userNameRegex.test(value)) {
               error = `Username Must Contain One Uppercase Letter & No Special CharaCters Allowed and 20 below characters!`;
            }
        }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  

    return error;
  };
  
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };
  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/api/auth/store/${currentUser.storeid}`)
      .then((response) => {
        const { data } = response.data;
        const updatedFormData = { ...formData };
        Object.keys(data).forEach(key => {
          if (formData.hasOwnProperty(key)) {
            updatedFormData[key] = data[key];
          }
        });
        setFormData(updatedFormData);
        console.log(updatedFormData)
      })
      .catch(error => {
       
      });
  }, []);
  const handleStoreUpdate = async (e) => {
    e.preventDefault();
  
    if (Object.values(errors).every((error) => error === "")) {
     
      const queryString = Object.keys(formData)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key]))
        .join("&");
  
      // Append the query string to the URL
      const url = `https://billing.ubsbill.com/apptest/v2/api/auth/store/${currentUser.storeid}?` + queryString;
  
      try {
        const response = await axios.patch(url);
        if (response.data.status === true) {
          showSuccessSnackbar("Form Submit Successfull !");
       
        }
      } catch (error) {
        showErrorSnackbar(error.response.data.message);
      }
    }
  };
  
  const isSubmitDisabled = buttonDisabled(formData,errors);
 
  return (
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="primary.light"
              p={1}
              sx={formMainBox}
            >
              <SettingsIcon fontSize="large" />
              <span style={{ marginLeft: "8px" }}>Store Setting</span> {/* Text */}
            </Box>
          </Grid>
        </Grid>
        {Array.from({ length: 6 }).map((_, index) => (
          <Stack
            key={index}
            spacing={4}
            direction={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            sx={{ marginBottom: 1 }}
          >
            {formDataFields
              .slice(index * 2, index * 2 + 2)
              .map((formDataItem, innerIndex) => (
                <FormControl
                  key={innerIndex}
                  fullWidth
                  error={!!errors[formDataItem.name]}
                  variant="outlined"
                >
                  <CustomTypography
                    htmlFor={`outlined-adornment-${formDataItem.name}`}
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    {formDataItem.label}
                  </CustomTypography>
                  {formDataItem.type === "file" ? (
                    <Input
                    sx={inputStyles}
                    type="file"
                    name={formDataItem.name}
                    value={formData[formDataItem.name]}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    disableUnderline
                  />
                  
                  ) : (
                    <Input
                      sx={inputStyles}
                      type={formDataItem.type}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      readOnly={formDataItem.name === "storeid"} 
                      disableUnderline
                    />
                  )}
                      <FormHelperText className="food-card-text" sx={errorMessageStyles}>{errors[formDataItem.name]}</FormHelperText>
                </FormControl>
             
            ))}
          </Stack>
        ))}
         <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleStoreUpdate}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles} disabled={isSubmitDisabled}
          >
            Update Store
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
export default StoreSettings;
