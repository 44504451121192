import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import BrunchDiningIcon from "@mui/icons-material/BrunchDining";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useDrawer } from "../Sidebar/DrawerContext";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function AddRecipe() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data)
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const [repeatCount, setRepeatCount] = useState(1);
  const [productNames, setProductNames] = useState([]);
  const [ingredientNames, setIngredientNames] = useState([]);
  const [ingredientFormData, setIngredientFormData] = useState([{
    ingredientName: "",
    quantity: "",
    unit: ""
  }]);
  const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    formMainBox,
    formPaperStyles,
    selectStyles,
    capitalizeFirstLetter,
    buttonDisabled,
  } = useDrawer();

  const [formData, setFormData] = useState({
    productName: "",
    ingredients: [
      {
        ingredientName: "",
        quantity: "",
        unit: "",
        storeId: currentUser.storeid
      }
    ],

  });
  const [errors, setErrors] = useState({
    productName: "",
    ingredientName: "",
    quantity: "",
    unit: "",
  });
  const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const numberRegex = new RegExp(/^\d{1,10}$/);
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    if (capitalizedFieldName === "price") {
      if (!numberRegex.test(value)) {
        error = `${capitalizedFieldName} should be a number`;
      }
    }
    return error;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };
  useEffect(() => {

    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/food/store_all/${currentUser.storeid}`)
      .then(response => {
        const fetchedProductNames = response.data.data;
        if (Array.isArray(fetchedProductNames)) {
          setProductNames(fetchedProductNames);
        } else {
          console.error('Invalid response format: expected an array');
        }
      })
      .catch(error => {
        console.error('Error fetching product names:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/inventory/store/${currentUser.storeid}`)
      .then(response => {
        const inventoryList = response.data.data.inventoryList;
        const names = inventoryList.map(item => item.name);
        setIngredientNames(names);
      })
      .catch(error => {
        console.error('Error fetching ingredient names:', error);
      });
  }, []);

  const handleRecipe = async (e) => {
    e.preventDefault();
  
    if (Object.values(errors).every((error) => error === "")) {
      try {
        const recipeData = {
          name: formData.productName,
          storeId: currentUser.storeid,
          ingredients: ingredientFormData
            .filter(ingredient => ingredient.ingredientName.trim() !== "" && ingredient.quantity.trim() !== "" && ingredient.unit.trim() !== "")
            .map(ingredient => ({
              name: ingredient.ingredientName,
              quantity: ingredient.quantity,
              unit: ingredient.unit,
              storeid: currentUser.storeid
            }))
        };
  
        const formDataIsValid = recipeData.ingredients.length > 0;
  
        if (!formDataIsValid) {
          showErrorSnackbar("Please fill in at least one ingredient.");
          return;
        }
  
        const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/receipe/", recipeData);
  
        if (response && response.data && response.data.status === true) {
          setFormData({
            productName: "",
            ingredients: [
              {
                ingredientName: "",
                quantity: "",
                unit: "",
                storeId: currentUser.storeid
              }
            ],

          });
          showSuccessSnackbar("Recipe Added Successfull !");
          console.log(response.data);
        } else {
          showErrorSnackbar(response.data.message);
          console.log(response);
        }
      } catch (error) {
        showErrorSnackbar("Error submitting form: " + error.message);
      }
    }
  };
  
  
  


  const isSubmitDisabled = buttonDisabled(formData, errors);
  const handleMoreButtonClick = () => {
    setRepeatCount(repeatCount + 1);
    setIngredientFormData([...ingredientFormData, { ingredientName: null, quantity: "", unit: "" }]);
  };

  const handleLessButtonClick = () => {
    if (repeatCount > 1) {
      setRepeatCount(repeatCount - 1);
      setIngredientFormData(ingredientFormData.slice(0, repeatCount - 1));

    }
  };
  const handleIngredientInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormData = [...ingredientFormData];
    updatedFormData[index] = { ...updatedFormData[index], [name]: value };
    setIngredientFormData(updatedFormData);
  };

  return (
    <Box
      component="main"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="primary.light"
              p={1}
              sx={formMainBox}
            >
              <BrunchDiningIcon fontSize="large" />
              <span style={{ marginLeft: "8px" }}>Add Recipe</span> {/* Text */}
            </Box>
          </Grid>
        </Grid>

        <Stack
          spacing={4}
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          }}
          sx={{}}
        >
          <FormControl
            fullWidth
            error={!!errors.productName}
            variant="outlined"
          >
            <CustomTypography
              htmlFor="outlined-adornment-username"
              className="food-card-text"
              sx={labelStyle}
            >
              Product Name
            </CustomTypography>

            <Box sx={{ display: "flex" }}>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  name="productName"
                  value={formData.productName || "Select Product Name"}
                  onChange={handleInputChange}
                  sx={selectStyles}
                  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                >
                  <MenuItem value="Select Product Name">
                    Select Product Name
                  </MenuItem>
                  {productNames.map(product => (
                    <MenuItem key={product.foodId} value={product.foodName}>
                      {product.foodName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Link to="/food-management-dashboard/add-menu">
                <Button
                  className="food-card-text mode-button border-radius-10 cart-button final-button"
                  sx={{ ...submitButtonStyles, maxHeight: "60px", ml: 2 }}
                >
                  <span style={{ whiteSpace: "nowrap" }}>+ Food</span>
                </Button>
              </Link>
            </Box>

            <FormHelperText className="food-card-text" sx={errorMessageStyles}>
              {errors.ingredientName}
            </FormHelperText>
          </FormControl>
        </Stack>
        {[...Array(repeatCount)].map((_, index) => (
          <Stack
            key={index}
            spacing={4}
            direction={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            sx={{ mt: 4 }}
          >
            <FormControl
              fullWidth
              error={!!errors.ingredientName}
              variant="outlined"
            >
              <CustomTypography
                htmlFor="outlined-adornment-username"
                className="food-card-text"
                sx={labelStyle}
              >
                Ingredient Name
              </CustomTypography>

              <Select
                name="ingredientName"
                fullWidth
                value={ingredientFormData[index].ingredientName || "Select Ingredient"}
                onChange={(e) => handleIngredientInputChange(index, e)}
                sx={selectStyles}
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}

              >
                <MenuItem value="Select Ingredient">Select Ingredient</MenuItem>
                {ingredientNames.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className="food-card-text" sx={errorMessageStyles}>
                {errors.ingredientName}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth error={!!errors.quantity} variant="outlined">
              <CustomTypography
                htmlFor="outlined-adornment-username"
                className="food-card-text"
                sx={labelStyle}
              >
                Quantity
              </CustomTypography>
              <Input
                sx={inputStyles}
                type="number"
                name="quantity"
                value={ingredientFormData[index].quantity}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value <= 1000) {
                    handleIngredientInputChange(index, e);
                  } else {
                    showErrorSnackbar("Limit the quantity to a maximum of 1000");
                  }
                }}
                disableUnderline
              />

              <FormHelperText className="food-card-text" sx={errorMessageStyles}>
                {errors.quantity}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth error={!!errors.unit} variant="outlined">
              <CustomTypography
                htmlFor="outlined-adornment-unit"
                className="food-card-text"
                sx={labelStyle}
              >
                Unit
              </CustomTypography>
              <Select
                name="unit"
                fullWidth
                value={ingredientFormData[index].unit || "Select Unit"}
                onChange={(e) => handleIngredientInputChange(index, e)}
                sx={selectStyles}
              >
                <MenuItem value="Select Unit">Select Unit</MenuItem>
                <MenuItem value="kg">Kg</MenuItem>
                <MenuItem value="gm">Gram</MenuItem>
                <MenuItem value="mg">Milligram</MenuItem>
                <MenuItem value="pc">Pieces</MenuItem>
                <MenuItem value="dozen">Dozen</MenuItem>
                <MenuItem value="liter">Liter</MenuItem>
                <MenuItem value="ml">Milliliter</MenuItem>

              </Select>
              <FormHelperText className="food-card-text" sx={errorMessageStyles}>
                {errors.unit}
              </FormHelperText>
            </FormControl>
          </Stack>
        ))}


        <Stack
          spacing={4}
          direction={{
            xs: "row",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          }}
          sx={{
            mt: { xl: 4, lg: 4, md: 0, sm: 0, xs: 0 },
            marginLeft: {
              xl: "auto",
              lg: "auto",
              md: "auto",
              sm: "0",
              xs: "0",
            },
            flexWrap: "wrap",
          }}
        >
          <Button
            onClick={handleMoreButtonClick}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={{ ...submitButtonStyles, maxHeight: "60px", ml: 2 }}
          >
            <span style={{ whiteSpace: "nowrap" }}>+ More</span>
          </Button>

          {repeatCount > 1 && ( // Render the Less button only when there are more than one stack
            <Button
              onClick={handleLessButtonClick}
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={{ ...submitButtonStyles, maxHeight: "60px", ml: 2 }}
            >
              <span style={{ whiteSpace: "nowrap" }}>- Less</span>
            </Button>
          )}
          <Link to="/inventory-management-dashboard/add-inventory">
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={{ ...submitButtonStyles, maxHeight: "60px", ml: 2 }}
            >
              <span style={{ whiteSpace: "nowrap" }}>+ Ingredient</span>
            </Button>
          </Link>
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 7 }}>
          <Button onClick={handleRecipe}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
export default AddRecipe;
