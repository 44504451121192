import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, Pagination, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Paper, Modal, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import PaymentSettingsForm from './PaymentSettingsForm';
import { useTableContext } from '../../../Screens/tableContext/TableContext';
import { useDrawer } from '../../Sidebar/DrawerContext';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import PopOverModal from '../../Cards/Modal/PopOverModal';
import { useSuccessSnackbar } from '../../../Screens/snackbar/SuccessSnackbarProvider';
import { useSelector } from 'react-redux';
import { useDarkMode } from '../../../Screens/DarkMode/DarkMode';

function PaymentSettings() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
const [paymentList , setPaymentList] = useState([]);
const currentUser = useSelector((state) => state.auth.user.data);
const { showSuccessSnackbar } = useSuccessSnackbar();

   const {
    
      tableCellStyles,
      searchboxStyles,
      listMainGridStyles,
      contentTableCellStyles,
      editAndDeleteButtonStyles,
      contentTableRowStyles,
      listMainBox,
      spaceBetweenTopComponent,
      pdfAndExcelButtonStyles,
      listIconStyles,
      tableHeadStyles
     
    } = useDrawer();
   
    const {
      searchInput,
      handleSearchChange,
      filterRows,
      renderStyledCell,
      getFontWeight, resetSearchInput
      
    } = useTableContext();

 const filteredRows = filterRows(paymentList);
  const paymentDetailsRef = useRef(null);
  
  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
  }, [location.pathname]);

  const handleAddDetailsClick = () => {
    if (paymentDetailsRef.current) {
     
      paymentDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
    const handleOpen = (serialNo) => {
       setSerialNoToDelete(serialNo); 
        setOpen(true);
    };



  const fetchList = () => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/storePayment/store/${currentUser.storeid}`)
      .then((response) => {
        setPaymentList(response.data.data)
   
      })
      .catch((error) => {
        
        console.error("Error fetching Payment list:", error);
      });
  };
  

  useEffect(() => {
    fetchList();
  }, []);
  

  const handlePaymentDelete = async (serialNo) => {

    try {
      const response = await axios.delete(
        `https://billing.ubsbill.com/apptest/v2/sys/storePayment/${serialNo}`
      );

      if (response.data.status === false) {
       showSuccessSnackbar("Payment Deleted successfully.. !");
        handleClose();
        fetchList();
      }
    } catch (error) {
      console.error("Error occurred while deleting Payment:", error);
    
    }
  };
 return (
    <Box component="main" sx={{ p: 2}}>
   <Grid container spacing={1} sx={{ mb: 4 }}>
  <Grid item xs={12}   sx={listMainGridStyles}>
    <Box
      display="flex"
      max
      bgcolor="primary.light"
      sx={listMainBox}
      
    >
      <AccountBalanceIcon sx={listIconStyles} /> {/* Icon */}
      <Typography variant='span' sx={{ ml: "8px", color: isDarkMode ? "#fff" :"#fff",}}>Save Account Details</Typography>
    </Box>
    <Box sx={spaceBetweenTopComponent}>
      <Box
        bgcolor="#D9D9D9"
        p={1}
        sx={searchboxStyles}
      >
            <InputAdornment position="start">
             <SearchIcon />
            </InputAdornment>
            <Divider orientation="vertical" sx={{ height: 24, mx: 1 , backgroundColor:"#424242" }} />
            <Input
            fullWidth
             placeholder="Search"
             sx={{ color: isDarkMode ? "#fff" : "#000",}}
             disableUnderline 
             value={searchInput}
             onChange={handleSearchChange}  
            />
         </Box>
         <Button className='food-card-text mode-button border-radius-10 cart-button final-button' onClick={handleAddDetailsClick}  sx={pdfAndExcelButtonStyles}>
  <span style={{ marginRight: '5px' }}>+</span> ADD Details
</Button>
       
</Box>
        </Grid>
     </Grid>
     <Grid container sx={{ mt: 4 }}>
     <TableContainer className='cart-table' component={Paper} sx={{background: isDarkMode ? "#5e5e5e" :"#fff" ,   minHeight:{ sm: '40vh', md: '25vh', xs: '75vh', xl: '40vh', lg: '35vh' } , maxHeight:{ sm: '50vh', md: '45vh', xs: '75vh', xl: '40vh', lg: '40vh' } }}>
         <Table sx={{ maxWidth:"100%" ,   }}  aria-label="simple table">
            <TableHead  sx={tableHeadStyles}>
             <TableRow>
                <TableCell className='all-list-tabelcell' sx={tableCellStyles}>SR No.</TableCell>
                <TableCell className='all-list-tabelcell' align="right"  sx={tableCellStyles}>Store Name</TableCell>
                <TableCell className='all-list-tabelcell' align="right"  sx={tableCellStyles}>Bank Name</TableCell>
                <TableCell className='all-list-tabelcell' align="right"  sx={tableCellStyles}>Account No</TableCell>
                <TableCell className='all-list-tabelcell' align="right"  sx={tableCellStyles}>IFSC Code</TableCell>
                <TableCell className='all-list-tabelcell' align="right"  sx={tableCellStyles}>UPI ID</TableCell>
              <TableCell className='all-list-tabelcell' align="right"  sx={tableCellStyles}>Action</TableCell>
                
              
             </TableRow>
            </TableHead>
            
            <TableBody sx={{overflowY: 'hidden', maxHeight: '35vh' , }}>
          
             {filteredRows.map((row,index) => (
                <TableRow
                 key={index}
                 sx={contentTableRowStyles}
                >
               {renderStyledCell(index+1, getFontWeight(index+1))}
               {renderStyledCell(row.storeName, getFontWeight(row.storeName))}
               {renderStyledCell(row.bankName, getFontWeight(row.bankName))}
               {renderStyledCell(row.accountNo, getFontWeight(row.accountNo))}
               {renderStyledCell(row.ifscCode, getFontWeight(row.ifscCode))}
               {renderStyledCell(row.upiId, getFontWeight(row.upiId))}
                <TableCell sx={contentTableCellStyles} align="left" >
                    <Box className="align-items-center">
                    <Box
                          className="justify-all-center"
                          component={Link} to={`/profile-settings/payment-settings/update-payment-details/${row.serialNo}`}
                          sx={{
                            ...editAndDeleteButtonStyles,
                            border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                          }}
                        >
                          <ModeEditOutlineRoundedIcon sx={{  color: isDarkMode ?  "white" : "#4FC3A1" }} />
                        </Box>
                        <Box
                          className="justify-all-center"
                          onClick={() => handleOpen(row.serialNo)}
                          sx={{
                            ...editAndDeleteButtonStyles,
                            border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                          }}
                        >
                          <DeleteIcon sx={{ color: isDarkMode ?  "white" : "#f55651" }} />
                        </Box>
                       
                    </Box>
                 </TableCell>
                
                </TableRow>
             ))}
              <Modal
                           open={open}
                           onClose={handleClose}
                           aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description"
                          >
                          <PopOverModal
                              
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handlePaymentDelete(serialNoToDelete)}
                              onClickTwo={handleClose}
                              />
                              </Modal>
            </TableBody>
         </Table>
        </TableContainer>
       
     </Grid>
     
     <Box ref={paymentDetailsRef} id="payment-details" sx={{mt: 2}}>
        <PaymentSettingsForm />
        </Box>
    
    </Box >
)
}

export default PaymentSettings;