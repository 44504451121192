import { Box, Divider, Grid, Typography,  } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { useDrawer } from '../../Sidebar/DrawerContext';
import { useDarkMode } from '../../../Screens/DarkMode/DarkMode';

function KotBill({orderNo}) {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
    const {billNormalData } = useDrawer;
const foodList = useSelector((state) => state.cart.bill.order[0].orderFoods);
const currentDate = new Date();
const date = currentDate.toLocaleDateString('en-GB'); // Format date as "DD/MM/YYYY"
const time = currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });


  return (
    <Box >
      <Box sx={{padding: "0 1% 3%"}}>
     <Typography  className='kot-card-text' sx={{...billNormalData}}>Order No: {orderNo}</Typography>
     <Typography  className='kot-card-text' sx={{...billNormalData}}>Date: {date} {time}</Typography>
     </Box>
       <Box  sx={{padding: "0 4%", display: "flex", flexDirection:"column"}}>

       <Divider sx={{ color: 'black',
                border: "none",
                borderTop: "2px dashed #999", 
                height: 0, margin:"7px 0px"}}/>

       <Grid xs={12} container>
        <Grid item xs={0.7}>
          <Typography className='kot-card-text'  align="left"  >
            Sr.
          </Typography>
        </Grid>
        <Grid  item xs={3.8} sx={{marginLeft: "20px"}}>
          <Typography className='kot-card-text' align="left">
            Item
          </Typography>
        </Grid>
        <Grid  item xs={2} sx={{marginLeft: "45px"}}>
          <Typography className='kot-card-text' align="left">
            Quantity
          </Typography>
        </Grid>
        </Grid>
        <Divider sx={{ color: 'black',
                border: "none",
                borderTop: "2px dashed #999", 
                height: 0, margin:"7px 0px"}}/>
         {foodList.map((food, index) => (
      <Grid xs={12} container className="cart-table" key={index} spacing={4}>
      <Grid item xs={0.7}>
        <Typography className='kot-card-text' align="left">
          {index + 1}
        </Typography>
      </Grid>
      <Grid item xs={3.8} sx={{  marginRight: "10px" }}>
        <Typography className='kot-card-text' align="left" sx={{ minWidth: "40vw" }}>
          {food.foodName}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ marginLeft: "45px" }}>
        <Typography className='kot-card-text' align="left">
          {food.quantity}
        </Typography>
      </Grid>
    </Grid>
    
         
          ))}
          
          </Box >
    </Box>
  )
}

export default KotBill