import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useTableContext } from "../../Screens/tableContext/TableContext";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import PopOverModal from "../Cards/Modal/PopOverModal";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
const rowsPerPage = 10;

function FoodList() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);
  
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableCellStyles,
    editAndDeleteButtonStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles,
  } = useDrawer();

  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    resetCurrentPage,
    getFontWeight, generatePdf, downloadExcel, currentPage, handleChangePage, resetSearchInput,
  } = useTableContext();
 

  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
    resetCurrentPage();
  }, [location.pathname]);

  const [selectedItem, setSelectedItem] = useState('');

  const [FoodList, setFoodList] = useState([]);
const [deleteTrigger , setDeleteTrigger] = useState ([]) ;
  const [page, setPage] = React.useState(1);
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const [size, setSize] = useState([]);

  const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
    const handleOpen = (serialNo) => {
       setSerialNoToDelete(serialNo); 
        setOpen(true);
    };

  const tableHeaders = [
    { label: 'SR No.', align: 'right' },
    { label: 'Food Name', align: 'right' },
    { label: 'Food Code', align: 'right' },
    { label: 'Category', align: 'right' },
    { label: 'Sub Category', align: 'right' },
    { label: 'Price', align: 'right' },
    // { label: 'Image', align: 'right' },
    { label: 'Action', align: 'right' },
  
  ];

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const filteredRows = filterRows(FoodList);

  const downloadPDF = () => {
    generatePdf("FoodList", tableHeaders, reorderedData , 8.5);
  };

  const handleDeleteId = async (serialNo) => {
   
    try {
      const response = await axios.delete(`https://billing.ubsbill.com/apptest/v2/sys/food/id/${serialNo}`);
      
      if (response.data.status === true) {
       
        showSuccessSnackbar("Food Delete successfully.. !");
        handleClose();
       
        setDeleteTrigger(!deleteTrigger);
      }
    } catch (error) {
      console.error("Error occurred while deleting Food Data:", error);
      handleClose();
    }
  };
  
  const [reorderedData , setReorderedData] = useState("");
  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/food/store/${currentUser.storeid}`)
      .then((response) => {

        const reorderedData = response.data.data.food.map((food , index) => ({
          no: index+1,
          productName: food.productName, 
          foodCode: food.foodCode,
          category: food.category,
          subCategory: food.subCategory,
          price: food.price,
         }));
      setReorderedData(reorderedData);
        setFoodList(response.data.data.food);

      })
      .catch((error) =>{

      })
  }, [deleteTrigger])


  const filteredRowsBySearch = filteredRows.filter((row) =>
    Object.values(row).some(
      (value) =>
        value && value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = filteredRowsBySearch.slice(startIndex, endIndex);

  const [selectedSubcategory, setSelectedSubcategory] = useState(''); 
  const [selectedCategory, setSelectedCategory] = useState('');
  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
    setSelectedCategory('');
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubcategory('');
  };
 
   
  const filteredDisplayedRows = displayedRows.filter(row => {
   
    if (selectedCategory !== 'Select Category' && selectedCategory !== '') {
      
      return row.category === selectedCategory;
    }
    
    if (selectedSubcategory !== 'Select Sub Category' && selectedSubcategory !== '') {
      
      return row.subCategory === selectedSubcategory;
    }
   
    return true;
  });
  
  
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetch(`https://billing.ubsbill.com/apptest/v2/sys/categorybutton/store/${currentUser.storeid}`)
      .then(response => response.json())
      .then(data => {
       
        setCategories(data.data.map(category => category.name));
       
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, [currentUser.storeid]);

  const handleCcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
  };
  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid
          item
          xs={12}
          sx={listMainGridStyles}
        >
          <Box
            display="flex"
            max
            bgcolor="primary.light"
            sx={listMainBox}
          >
            <RestaurantMenuIcon
              sx={listIconStyles}
            />{" "}

            <CustomTypography variant="span" sx={{ ml: "8px" }}>
              Food List
            </CustomTypography>
          </Box>
          <Box
            sx={spaceBetweenTopComponent}
          >

            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles} component={Link} to="/food-management-dashboard/add-menu"

            >
              <span style={{ marginRight: "5px" }}>+</span> Menu
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"

              sx={pdfAndExcelButtonStyles} onClick={downloadPDF}

            >
              PDF
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles}
              onClick={() => downloadExcel(tableHeaders, reorderedData, "FoodList")}
            >
              Excel
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ display: "flex", alignItems: "center", }}>
        <Box
          bgcolor="#D9D9D9"
          p={1}
          sx={searchboxStyles}
        >
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
          <Divider
            orientation="vertical"
            sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
          />
          <Input fullWidth placeholder="Search" disableUnderline value={searchInput}
            onChange={handleSearchChange}  sx={{ color: isDarkMode ? "#fff" : "#000",}}/>
        </Box>
        <Box>
  <FormControl sx={{ m: 1, minWidth: 200 }}>
    {!selectedSubcategory && (
      <InputLabel
        id="demo-simple-select-autowidth-label"
        shrink={false}
        sx={{ mt: "-5px",  color: isDarkMode ? "#fff" : "#424242", }}
        className="link-cart-title"
      >
        Select Sub Category
      </InputLabel>
    )}
    <Select
      labelId="demo-simple-select-autowidth-label"
      id="demo-simple-select-autowidth"
      sx={{
        backgroundColor: isDarkMode ? "#7a7a7a" : "#D9D9D9",
        color: isDarkMode ? "#fff" : "#424242",
        borderRadius: "10px",
        height: '42px',
        maxHeight: "42px",
        minHeight: "42px"
      }}
      autoWidth
      value={selectedSubcategory}
      onChange={handleSubcategoryChange}
    >
      <MenuItem  value="">
        <em>All</em>
      </MenuItem>
      <MenuItem value={"Veg"}>Veg</MenuItem>
      <MenuItem value={"Nonveg"}>Non Veg</MenuItem>
      <MenuItem value={"Egg"}>Egg</MenuItem>
    </Select>
  </FormControl>
</Box>

        <Box>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            {!selectedCategory && (
              <InputLabel
                id="demo-simple-select-autowidth-label"
                shrink={false}
                sx={{ mt: "-5px",color: isDarkMode ? "#fff" : "#424242" }}
                className="link-cart-title"
              >
                Select Category
              </InputLabel>
            )}
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              sx={{
                backgroundColor: isDarkMode ? "#7a7a7a" : "#D9D9D9",
                color: isDarkMode ? "#fff" : "#424242",
                borderRadius: "10px",
                height: '42px',
                maxHeight: "42px",
                minHeight: "42px"
              }}
              autoWidth
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <MenuItem value="Select Category">
                <em>None</em>
              </MenuItem>
              {categories.map(category => (
            <MenuItem key={category} value={category}>
              {category}
              </MenuItem>
          ))}
            </Select>
          </FormControl>
        </Box>


      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>
          <Table aria-label="simple table">
            <TableHead
              sx={tableHeadStyles}
            >

              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    className="all-list-tabelcell"
                    align={header.align}
                    sx={tableCellStyles}
                  >
                    {header.label}
                   
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody>
              {filteredDisplayedRows.map((row, index) => (

                <TableRow
                  key={index}
                  sx={contentTableRowStyles}
                >
                  {renderStyledCell(startIndex + index + 1, getFontWeight(startIndex + index + 1))}
                  {renderStyledCell(row.productName, getFontWeight(row.foodName))}
                  {renderStyledCell(row.foodCode, getFontWeight(row.foodCode))}
                  {renderStyledCell(row.category, getFontWeight(row.category))}
                  {renderStyledCell(row.subCategory, getFontWeight(row.subCategory))}
                  {renderStyledCell(row.price, getFontWeight(row.price))}
                  {/* {renderStyledCell("https://drive.google.com/thumbnail?id="+row.image, getFontWeight("https://drive.google.com/thumbnail?id="+row.image))} */}
                  {/* <TableCell sx={contentTableCellStyles} align="left">
                    {row.image && (
                      <img
                        height="60px"
                        width="60px"
                        src={`https://drive.google.com/thumbnail?id=` + row.image}
                        alt=""
                      />
                    )}
                  </TableCell> */}


                  <TableCell sx={contentTableCellStyles} align="left">
                    <Box className="align-items-center">
                      <Box
                        className="justify-all-center"
                        component={Link} to={`/food-management-dashboard/food-list/edit-food/${row.serialNo}`}
                        sx={{
                          ...editAndDeleteButtonStyles,
                          border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                        }}
                      >
                        <ModeEditOutlineRoundedIcon sx={{color: isDarkMode ?  "white" : "#4FC3A1" }} />
                      </Box>
                      <Box
                        className="justify-all-center"
                        
                        sx={{
                          ...editAndDeleteButtonStyles,
                          border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                        }}
                        onClick={() => handleOpen(row.serialNo)} 
                      >
                       
                        <DeleteIcon sx={{ color: isDarkMode ?  "white" : "#f55651" }} />

                      </Box>
                  
                     
                </Box>
                  </TableCell>
                 
                </TableRow>
              ))}
             <Modal
                           open={open}
                           onClose={handleClose}
                           aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description"
                          >
                          <PopOverModal
                              
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handleDeleteId(serialNoToDelete)}
                              onClickTwo={handleClose}
                              />
                              </Modal>
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
          <Pagination

            count={Math.ceil(filteredRows.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}

            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FoodList;
