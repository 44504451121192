import React, { useState } from "react";
import {
Box,
Button,
Stack,
FormControl,
FormHelperText,
Grid,
Paper,
Select,
MenuItem,
Input,
styled,
} from "@mui/material";
import { useSuccessSnackbar } from "../../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../../Screens/snackbar/ErrorSnackbarProvider";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useDrawer } from "../../Sidebar/DrawerContext";
import axios from "axios";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";
function SubscriptionSuperadmin() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    formMainBox,
    formPaperStyles,
    capitalizeFirstLetter,
    buttonDisabled
} = useDrawer();
const formDataFields = [
    // { name: "UserName", label: "Store Username", type: "text" },
    { name: "emailID", label: "Store Email", type: "email" },
    { name: "year", label: "Subscription Year", type: "number" },

];

const { showSuccessSnackbar } = useSuccessSnackbar();
const { showErrorSnackbar } = useErrorSnackbar();

const [formData, setFormData] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
);
const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
);
const validateField = (fieldName, value) => {
    let error = "";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;

    if (value.trim() === "") {
     error = `${capitalizeFirstLetter(fieldName)} is mandatory`;
    } else if (fieldName === "storeEmail" && !emailRegex.test(value)) {
     error = "Please enter a valid email in the format username@example.com";
    }

    return error;
};

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
     setErrors({ ...errors, [name]: "" });
    }
};

const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
};
const handlesubscribtion = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
     try {

        const response = axios.patch(`https://billing.ubsbill.com/apptest/v2/api/auth/store/renewSubscription?emailID=${formData.emailID}&year=${formData.year}`)
        setFormData(
            formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
        );
        showSuccessSnackbar("Subscription Successfull")
     } catch (error) {
        setFormData(
            formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
        );
    showErrorSnackbar("Subscription Failed")
     }
    }
};
const isSubmitDisabled = buttonDisabled(formData,errors);

return (
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
         <Grid item>
            <Box
             display="flex"
             alignItems="center"
             bgcolor="primary.light"
             p={1}
             sx={formMainBox}
            >

             <CardMembershipIcon fontSize="large" />
             <span style={{ marginLeft: "8px" }}>Subscription Access</span> {/* Text */}
            </Box>
         </Grid>
        </Grid>

        {Array.from({ length: 4 }).map((_, index) => (
         <Stack
            key={index}
            spacing={4}
            direction={{
             xs: "column",
             sm: "row",
             md: "row",
             lg: "row",
             xl: "row",
            }}
            sx={{ marginBottom: 1 }}
         >
         {formDataFields
             .slice(index * 2, index * 2 + 2)
             .map((formDataItem, innerIndex) => (
                <FormControl
                 key={innerIndex}
                 fullWidth
                 error={!!errors[formDataItem.name]}
                 variant="outlined"
                >
                 <CustomTypography
                    htmlFor={`outlined-adornment-${formDataItem.name}`}
                    className="food-card-text"
                    sx={labelStyle}
                 >
                    {formDataItem.label}
                 </CustomTypography>
                
                    <Input
                     sx={inputStyles}
                     type={formDataItem.type}
                     name={formDataItem.name}
                     value={formData[formDataItem.name]}
                     onChange={handleInputChange}
                     onBlur={handleInputBlur}
                     disableUnderline
                    />

                 <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                 >
                    {errors[formDataItem.name]}
                 </FormHelperText>
                </FormControl>

            ))}
         </Stack>
        ))}
         <Box style={{ display: "flex", justifyContent: "center" }}>
         <Button onClick={handlesubscribtion}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles} disabled={isSubmitDisabled}
         >
            Submit
         </Button>
        </Box>
     </Paper>
    </Box>
);
}
export default SubscriptionSuperadmin;
