import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, Stack } from '@mui/material'; // Assuming you're using Material-UI
import { useSelector } from 'react-redux';
import { useDarkMode } from '../../../Screens/DarkMode/DarkMode';

function AddOnIcons({ onCategorySelect }) {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [categoryButton, setCategoryButton] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const currentUser = useSelector((state) => state.auth.user.data);

  const generateBoxShadow = (index) => {
    const boxShadowValues = [
      "0px -6px 0px 0px #47C01C",
      "0px -6px 0px 0px #28A497",
      "0px -6px 0px 0px #FACE47",
    ];
    return boxShadowValues[index % boxShadowValues.length];
  };

  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/categorybutton/store/${currentUser.storeid}`)
      .then((response) => {
        setCategoryButton(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching category buttons:', error);
      });
  }, [currentUser.storeid]);

  const handleCardClick = (index, name) => {
    setActiveIndex(null);
    onCategorySelect(name);
  };



  
  return (
    <Box sx={{ display: "flex", flexDirection: { sm: "row", md: "row", xs: "row", lg: "column", xl: "column" } }}>
  {categoryButton.map((card, index) => (
    <Box key={index} sx={{ position: "relative", height: "fit-content" }}>
      {activeIndex === index && (
        <Box sx={{ position: "absolute", top: "-5px", left: "6px", height: { sm: "40px", md: "40px", xs: "2px" }, borderLeft: '3px double #28A497', }} />
      )}
      
      <Card
        onClick={() => handleCardClick(index, card.name)}
        className={`justify-left-mobile link-card-style ${activeIndex === index ? 'active' : ''}`}
        sx={{
          marginBottom: "20px",
          cursor: "pointer",
          marginLeft: { sm: "3px", md: "15px", xs: "0px" },
          marginRight: { sm: "15px", md: "0px", xs: "5px" },
          maxWidth: { sm: "140px", md: "130px", xs: "100px", lg: "200px", xl: "200px" },
          minWidth: { sm: "120px", md: "120px", xs: "100px", lg: "20px", xl: "20px" },
          maxHeight: { sm: "35px", md: "35px", xs: "2px" },
          padding: { sm: "2px 10px", md: "2px 14px", xs: "2px 10px", lg: "2px 10px", xl: "2px 15px" },
          boxShadow: generateBoxShadow(index),
           background: isDarkMode ? "#5e5e5e"  :"#EEEEEE",
          marginBottom: index !== categoryButton.length - 1 ? "35px" : "0px",
         
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }} >
          <CustomTypography
            variant="p"
            className="link-cart-title"
            sx={{
              fontSize: { sm: '15px', md: '14px', xs: '12px', xl: '18px' },
              marginLeft: { sm: '4px', md: '4px', xs: '4px', lg: '4px', xl: '4px' },
              lineHeight: "30px",
              overflow: "hidden"
            }}
          >
            {card.name}
          </CustomTypography>
        </Stack>
      </Card>
      
    </Box>
  ))}
</Box>

  
  )
}

export default AddOnIcons;
