import {
  Avatar,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  styled,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { logout } from "../../Screens/store/authSlice";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

function ProfileDropdown({ open, onClose}) {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSuccessSnackbar } = useSuccessSnackbar();


  const handleLogoutEvent = () => {
    sessionStorage.removeItem("user"); 
    window.location.href = "/login"; 

  };

  const setupLogoutListener = () => {
    const broadcastChannel = new BroadcastChannel("logoutChannel");
    broadcastChannel.addEventListener("message", (event) => {
      if (event.data.type === "logout") {
        handleLogoutEvent();
      }
    });
  };

  setupLogoutListener();

  const broadcastLogout = () => {
    const broadcastChannel = new BroadcastChannel("logoutChannel");
    broadcastChannel.postMessage({ type: "logout" });
  };

  const logOut = useCallback(async () => {
    let userData = localStorage.getItem("user") || sessionStorage.getItem("user");
    if (!userData) {
      console.error("User data not found in localStorage.");
      return;
    }

    let userRoles = null;

    try {
      userRoles = userData ? JSON.parse(userData)?.data?.roles : null;
    } catch (error) {
      console.error("Error parsing user data from localStorage:", error);
      return;
    }

    if (!userRoles) {
      console.error("No roles found in user data.");
      return;
    }

    try {
      await Promise.all(userRoles.map(roles => dispatch(logout())));
      
      localStorage.removeItem("user");
      sessionStorage.removeItem("user"); 
      broadcastLogout();
      navigate("/login");
      showSuccessSnackbar("Logout Successful");
    } catch (error) {
      console.error("Error during logout:", error.message);
    }
  }, [dispatch, navigate]);



  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#424242",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#DEDDDD" : "#DEDDDD",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#DEDDDD" : "#D9D9D9",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      elevation={0}
      sx={{ top: "60px" }}
      PaperProps={{
        style: {
          backgroundColor: isDarkMode ? "#636363" : "#F9F9F9",
        },
      }}
    >
      <MenuItem >
        <Avatar sx={{ height: "30px", width: "30px", m: 1 }} />
        <CustomTypography sx={{ fontSize: "20px", ml: 2 }}>Profile</CustomTypography>
      </MenuItem>
      <MenuItem>
        <FormControlLabel control={<IOSSwitch checked={isDarkMode} onChange={toggleDarkMode} sx={{ ml: 2 }} />} />
        <CustomTypography sx={{ fontSize: "20px" }}>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</CustomTypography>
      </MenuItem>
      <MenuItem onClick={logOut}>
        <PowerSettingsNewIcon sx={{ height: "30px", width: "30px", m: 1 }} />
        <CustomTypography sx={{ fontSize: "20px", ml: 2 }}>Logout</CustomTypography>
      </MenuItem>
    </Menu>
  );
}

export default ProfileDropdown;
