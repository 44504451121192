import { Box, Grid,  } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NavLinkCard from '../Components/StoreDashboardComponent/NavLinkCard';
import { useSelector } from 'react-redux';
import axios from 'axios';
import UserNavLinkCard from '../Components/UserDashboardComponents/UserNavLinkCard';
import { useDarkMode } from './DarkMode/DarkMode';
function VendorManagement() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [subMenuData, setSubMenuData] = useState([]);
  const currentUser = useSelector((state) => state.auth.user.data);
  const userRoles = currentUser.roles || [];

  useEffect(() => {
    if (userRoles.includes("ROLE_USER")) {
      axios.get(`https://billing.ubsbill.com/apptest/v2/sys/usersidebar/username?username=${currentUser.username}`)
        .then(response => {
          const menuData = response.data.data;
          const vendorManagementMenu = menuData.menu.find(menu => menu.title === "Vendor Management");
          if (vendorManagementMenu) {
            setSubMenuData(vendorManagementMenu.subMenu || []);
          } else {
            console.error("Menu item 'Cash Management' not found in menu data");
          }
        })
        .catch(error => {
          console.error("Error fetching submenu data:", error);
        });
    } else {
      setSubMenuData([]);
    }
  }, [userRoles]);
  const data = [
    { id: 1, title: 'Add Vendor', image: '../images/add-vendor-icon.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/vendor-management-dashboard/add-vendor", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 2, title: 'Vendor Payment', image: '../images/vendor-payment.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/vendor-management-dashboard/vendor-payment-details", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 3, title: 'Vendor Inventory', image: '../images/vendor-inv-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/vendor-management-dashboard/vendor-inventory", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 4, title: 'Vendor List', image: '../images/inv-mgmt-icon.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/vendor-management-dashboard/vendor-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 5, title: 'Vendor Payment List', image: '../images/inv-mgmt-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/vendor-management-dashboard/vendor-Payment-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 6, title: 'Vendor Inventory List', image: '../images/inv-mgmt-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/vendor-management-dashboard/vendor-Inventory-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
  ]
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CustomTypography sx={{ mb: 4, fontWeight: "500", fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' }, }} variant="p" className="link-cart-title">
          Vendor Management
        </CustomTypography>
        <Grid lg={11} md={12} sm={12} xs={12} xl={10} container spacing={1.7}  >
          {userRoles.includes("ROLE_USER") ? (
            subMenuData.map(submenuItem => (
              submenuItem.status && <UserNavLinkCard key={submenuItem.id} card={submenuItem} />
            ))
          ) : (
            data.map(card => (
              <NavLinkCard key={card.id} card={card} />
            ))
          )}
        </Grid>
      </Box>
    </Box>
  )
}
export default VendorManagement;
