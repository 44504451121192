import React from 'react';
import { Box, Card, Button } from '@mui/material';
import { useDrawer } from '../../Sidebar/DrawerContext';
import { useDarkMode } from '../../../Screens/DarkMode/DarkMode';
  // replace with the actual import

const PopOverModal = ({  buttonTextOne, buttonTextTwo ,  printText, onClickOne , onClickTwo ,iconPath , modalTopBorder , extraText, topHeading}) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { modalMiddle, modalbutton } = useDrawer();

  const handleClickOne = () => {
   
    if (onClickOne) {
      onClickOne();
    }
  };

  const handleClickTwo = () => {
    
    if (onClickTwo) {
      onClickTwo();
    }
  };

  return (
    <Box sx={modalMiddle}>
     <Card
  
  sx={{
    background :  isDarkMode ? "#83838a" : "#fff" ,
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "20px 0px 20px 0px",
    borderRadius: "10px",
    borderTop: modalTopBorder,
    display:"flex",
    flexDirection: "column",
    justifyContent: "center", 
    alignItems: "center",
  }}
>
        <Box sx={{ display: "flex", flexDirection: "row",  justifyContent: "center" , alignItems:"center" , width: "86%" }}>
          <img src={iconPath} alt="Successfull" style={{ width: "35px", height: "35px", marginRight: "5%" }} />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CustomTypography className="food-card-text" sx={{ fontSize: { sm: "14px", md: "16px", xs: "13px", xl: "18px", lg: "17px" }, fontWeight: "500", lineHeight: "30px", whiteSpace: "nowrap" }}>{topHeading}</CustomTypography>
            <CustomTypography className="food-card-text" sx={{ fontSize: { sm: "12px", md: "13px", xs: "12px", xl: "15px", lg: "15px" }, fontWeight: "500", lineHeight: "24px" , maxWidth: "90%"}}>{printText}</CustomTypography>
          </Box>
        </Box>
        <CustomTypography className="food-card-text" sx={{ fontSize: { sm: "12px", md: "13px", xs: "12px", xl: "14px", lg: "14px" }, fontWeight: "500", lineHeight: "24px" , maxWidth: "80%", color:"#C62717"}}>{extraText}</CustomTypography>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 5 }}>
       
          <Button
           onClick={handleClickOne}
            className='food-card-text mode-button border-radius-10 cart-button final-button'
            sx={{ ...modalbutton, marginRight: 5 }}>
            {buttonTextOne}
          </Button>
          <Button
            className='food-card-text mode-button border-radius-10 cart-button final-button'
            onClick={handleClickTwo}
            sx={modalbutton}>
            {buttonTextTwo}
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default PopOverModal;
