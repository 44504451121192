// DrawerContext.js
import React, { createContext, useContext, useState } from 'react';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';

const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const commonStyles = {
    mainContainer: {
      flexGrow: 1,
      p: 3, 
      marginTop: "60px"
    },
  };
  const listMainGridStyles = {
    flex: "1 0 auto",
    flexWrap: "wrap",
    display: "flex",
    flexDirection: {
      sm: "row",
      xs: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
    alignItems: {
      sm: "left",
      xs: "left",
      md: "center",
      lg: "center",
      xl: "center",
  }
}
const listIconStyles = {
  fontSize: {
    sm: "18px",
    md: "22px",
    xs: "18px",
    xl: "30px",
    lg: "28px",
  },
}
const listTabStyles = {
  fontFamily: '"Poppins", sans-serif !important',
  height:"98%",
  maxHeight:"98%",
  minHeight:"98%",
  fontSize: {xs: '9px', sm:"10px", md:'12px', lg:"13px",},
  borderRadius:"5px"
}
const listTabsStyles = {
  '& .MuiTabs-flexContainer': {
    flexWrap: 'wrap',
   },
   '& .MuiTab-root': {
    flex: '1 1 0',
    minWidth: 'auto',
    maxWidth: 'none',
  
   },
   height:"100%",
   maxHeight:"100%",
   minHeight:"100%",
 }
 const fromAndToTextStyles = {
  fontWeight: "600", borderRadius: 1 ,  height:"100%" , padding:"5px" , alignItems: 'center' , display:"flex",
  backgroundColor: isDarkMode ? "#7a7a7a" : "#D9D9D9", color: isDarkMode ? "#fff" : "#000"
}
 const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
 const dateFieldStyles = {
  ml: "10px" , borderRadius:"4px", background: isDarkMode ? "#999999" :"white" , padding:"0px 10px",fontSize: { sm: '12px', md: '14px', xs: '10px', xl: '16px', lg: "12px" , 
  color: isDarkMode ? "#fff" :"#000" },
 }
const filterAndCancelIcon = {
 fontWeight: "600", borderRadius: 1  , height:"100%" , padding:"5px", backgroundColor: isDarkMode ? "#7a7a7a" : "#D9D9D9",

} 

const iconColor = {
  color: isDarkMode ? "#fff" : "#000",
}
  const pdfAndExcelButtonStyles = {
    padding:{ sm: '5px 15px', md: '5px 10px', xs: '4px 10px',xl: '6px 10px', lg: "5px 15px" },
    fontSize: { sm: '12px', md: '14px', xs: '12px', xl: '16px', lg: "12px" },
    minWidth: { sm: '15px', md: '14px', xs: '12px', xl: '90px', lg: '60px' }, 
     background: isDarkMode ? "#8f8d8d" : "#D9D9D9", color:  isDarkMode ? "#fff" : "#000000",
      fontWeight:"500", marginRight:"10px",textTransform:"capitalize",  fontFamily: "poppins, sans-serif" 
  };
  const tableHeadStyles = {
    backgroundColor: isDarkMode ? "#48484a" : '#28A497', p: 2, mb: 2, borderRight:  isDarkMode ? "10px solid #48484a" :"10px solid #28A497", borderLeft:  isDarkMode ? "10px solid #48484a" :"10px solid #28A497"
   };
   const tableCellStyles = {
    fontSize: { xs: "12px", sm: "14px", md: "15px", lg: "16px", xl: "16px" },
    fontFamily: '"Poppins", sans-serif !important'
};

   const contentTableCellStyles = {
    fontSize: { xs: "11px", sm: "14px", md: "14px", lg: "14px", xl: "15px" },
    color: isDarkMode ? "#fff" :"#000",fontFamily: "poppins, sans-serif" 
  };
  const contentTableRowStyles = {
  backgroundColor: isDarkMode ? "#7a7a7a" : "white", pt: 2, borderBottom:  isDarkMode ? "10px solid #48484a" :  "10px solid #EAFCFC", borderTop:  isDarkMode ? "10px solid #48484a" : "10px solid #EAFCFC", borderRight:  isDarkMode ? "10px solid #48484a" : "10px solid #EAFCFC", borderLeft:  isDarkMode ? "10px solid #48484a" : "10px solid #EAFCFC", 
  };
  const editAndDeleteButtonStyles = {
   cursor:"pointer" , borderRadius:"5px", width:"30px", height:"30px", mr: 1
};
const searchboxStyles = {
  fontWeight: "600",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  mr: 2,
  backgroundColor: isDarkMode ? "#7a7a7a" : "#D9D9D9",
  color: isDarkMode ? "#fff" : "#000",
   padding: 1,
}
const dailyStyle = {
  fontWeight: "600",
  borderRadius: 1,
  height: "100%",
  padding: "5px",
  backgroundColor: isDarkMode ? "#7a7a7a" : "#D9D9D9"
}
const listMainBox = {
display:"flex",
minWidth:"150px",
fontWeight: "500",
backgroundColor: isDarkMode ? "#48484a" :"#28A497",
fontSize: { sm: '16px', md: '18px', xs: '16px', xl: '24px', lg: "22px" },
color: "white",
borderRadius: 2,
alignItems: 'center',
padding: "7px 15px",
maxWidth:{sm: '30vw', md: '25vw', xs: '50vw', xl: '25vw', lg: "25vw"},
mb: { sm: '16px', md: '0', xs: '16px', xl: '0', lg: "0" },
};
const spaceBetweenTopComponent = {
  marginLeft: {
    sm: "auto",
    md: "auto",
    xs: "0",
    xl: "auto",
    lg: "auto",
  },
  display: "flex",

  mt: { xs: 1, sm: 0 },
}
const pagignationStyles = {
 
    "& .MuiPaginationItem-root": {
      color: isDarkMode ?  "#fff" : "#00000",
      border: isDarkMode ?  "1px solid #fff" : "1px solid #00000",
    },
    "& .Mui-selected": {
      backgroundColor: isDarkMode ? "#b9babd !important" : "#28A497 !important",
      color:  isDarkMode ? "#000" : "white !important",
    },
  
}
/*Forms  */
const labelStyle = {
  lineHeight: { sm: "23px", lg: "25px", xl: "25px", md: "20px" },fontWeight:"400",
  fontSize: { xl: "18px", lg: "20px",md: "20px",xs: "13px",sm: "14px",}, mb: 1
}
const inputStyles = {
  bgcolor: isDarkMode ? '#8f8d8d' : 'white',
  borderRadius: "10px",
  height: '50px',
  padding: "0px 15px",
  color: isDarkMode ? '#fff' : 'black',
  fontFamily: "poppins, sans-serif" // Corrected property name to fontFamily
};


const errorMessageStyles = {
  fontSize:"13px", fontWeight:"400px", marginLeft:"0px", color: isDarkMode ? "#fff !important" : "#d32f2f !important" ,
}
const submitButtonStyles = {
  
  padding:  { sm: '6px 8px', md: '8px 16px', xs: '8px 12px', xl: '10px 18px', lg: "8px 22px" },
  fontSize: { sm: '14px', md: '16px', xs: '13px', xl: '16px', lg: "15px" },
  minWidth: { sm: '60px', md: '60px', xs: '60px', xl: '90px', lg: '60px' },
  background: isDarkMode ? "#8f8d8d" : "#D9D9D9", color: isDarkMode ? "#fff" : "#000", fontWeight: "500", marginRight: "10px",
  textTransform: "capitalize"
}
const  modalbutton = {
  
  padding:  { sm: '5px 8px', md: '3px 16px', xs: '4px 8px', xl: '5px 28px', lg: "5px 22px" },
  fontSize: { sm: '14px', md: '16px', xs: '13px', xl: '16px', lg: "15px" },
  width: { sm: '100px', md: '110px', xs: '90px', xl: '130px', lg: '130px' },
  background: isDarkMode ? "#fff" : "#D9D9D9", color: isDarkMode ? "#000" :  "#000000", fontWeight: "500", marginRight: "10px",
  textTransform: "capitalize"
}
const selectStyles = {
  bgcolor: isDarkMode ? '#8f8d8d' : 'white',
  color: isDarkMode ? '#fff' : 'black',
  fontFamily: "poppins, sans-serif",
  borderRadius: "10px",
  height: '50px',
  maxHeight: "50px",
  minHeight: "50px",
  outline: "none",
}
const addFileButtonStyles = {
 
    padding: '10px 25px',
    fontSize: {
      sm: '15px',
      md: '14px',
      xs: '12px',
      xl: '17px',
      lg: "12px"
    },
    width: "100px",
    background: "#D9D9D9",
    color: "#000000",
    fontWeight: "500",
    marginRight: "10px",
    textTransform: "capitalize"
 
}
const formMainBox = {
  fontWeight: "500",
  backgroundColor: isDarkMode ? "#48484a" :"#28A497",
  fontSize: "24px",
  color: "white",
  borderRadius: 1,
}
const formPaperStyles ={
  backgroundColor: isDarkMode ? "#5e5e5e" :"#EBEBEB",
  width: { xs: "100%", sm: "80%", md: "60%", lg: "60%", xl: "60%" },
  p: 3,
  mt: 5,
}

const runningOrderTitle = {
  lineHeight: { sm: "30px", lg: "25px", xl: "35px", md: "20px" },fontWeight:"500",
  fontSize: { xl: "20px", lg: "18px",md: "17px",xs: "16px",sm: "18px",},color:"#000000"
}
const runningOrderDetails = {
  lineHeight: { sm: "15px", lg: "15px", xl: "15px", md: "15px" },fontWeight:"500",
  fontSize: { xl: "17px", lg: "16px",md: "15px",xs: "13px",sm: "13px",},color:"#000000"
}
const kotOrderDetails = {
  lineHeight: { sm: "15px", lg: "15px", xl: "15px", md: "15px" },fontWeight:"500",
  fontSize: { xl: "15px", lg: "16px",md: "15px",xs: "13px",sm: "13px",},
}

const runningOrderButton = {
  lineHeight: { sm: "15px", lg: "15px", xl: "15px", md: "15px" },fontWeight:"500",
  fontSize: { xl: "17px", lg: "16px",md: "15px",xs: "13px",sm: "13px",}, bgcolor: isDarkMode ? "#8f8D8D" : '#D9D9D9',
  color: isDarkMode ? "#fff" : 'inherit',textTransform:"capitalize",
  padding:{ sm: '10px 10px', md: '10px 8px', xs: '5px 15px', xl: '10px 15px', lg: "10px 5px" },
  minWidth: { sm: '15px', md: '14px', xs: '12px', xl: '90px', lg: '60px' }

}
const runningOrderTable = {
  lineHeight: { sm: "15px", lg: "15px", xl: "16px", md: "15px" },fontWeight:"400",
  fontSize: { xl: "14.8px", lg: "14px",md: "14px",xs: "12px",sm: "13px",},
  color: isDarkMode ? "#fff" : "#000000"
}
const runningOrderTableTotal = {
  lineHeight: { sm: "15px", lg: "15px", xl: "16px", md: "15px" },fontWeight:"600",
  fontSize: { xl: "16px", lg: "15px",md: "15px",xs: "13px",sm: "14px",},
  color:"#000000"
}
const modalMiddle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "32vw", md: "37vw", sm: "60vw", xs: "90vw", xl: "25vw" },

}
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const openCart = () => {
    setCartOpen(true);
  };

  const closeCart = () => {
    setCartOpen(false); 
  };
  const billNormalData = {
    lineHeight: { sm: "5px", lg: "12px", xl: "15px", md: "15px" },fontWeight:"500",
    fontSize: { xl: "35px", lg: "36px",md: "35px",xs: "33px",sm: "33px",},
  }
 
 
  const buttonDisabledWithCheckbox = (isChecked , formData , errors) => {
    return !isChecked || 
           Object.values(formData).some(value => typeof value !== 'string' || value.trim() === "") || 
           Object.values(errors).some(error => error !== ""); 
  };

  const buttonDisabled = (formData, errors) => {
    return Object.values(formData).some(value => typeof value === 'string' && value.trim() === "") || 
           Object.values(errors).some(error => error !== ""); 
};

  const resetCartState = () => {
    setCartOpen(false);
   
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
//to calculate total 
  const calculateTotal = (order) => {
    let total = 0;

    order.order.forEach((orderItem) => {
      orderItem.orderFoods.forEach((foodItem) => {
        total += foodItem.quantity * foodItem.price;
      });
    });
  return total;
  };
  return (
    <DrawerContext.Provider value={{open, handleOpen, handleClose, isDrawerOpen, handleDrawerOpen, handleDrawerClose, isCartOpen, openCart, 
    closeCart, commonStyles , resetCartState , pdfAndExcelButtonStyles , tableCellStyles , contentTableCellStyles,editAndDeleteButtonStyles,tableHeadStyles,contentTableRowStyles ,
     listMainBox,searchboxStyles,listMainGridStyles,spaceBetweenTopComponent,pagignationStyles,listIconStyles,listTabsStyles,listTabStyles,fromAndToTextStyles,dateFieldStyles,
     filterAndCancelIcon,labelStyle,inputStyles,errorMessageStyles,submitButtonStyles,addFileButtonStyles,selectStyles,formMainBox,formPaperStyles,capitalizeFirstLetter,buttonDisabledWithCheckbox,buttonDisabled,
     runningOrderTitle,runningOrderDetails,dailyStyle,kotOrderDetails,runningOrderButton,runningOrderTable,runningOrderTableTotal,calculateTotal,iconColor ,billNormalData,modalMiddle,modalbutton}}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  return useContext(DrawerContext);
};
