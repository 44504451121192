
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormHelperText,
  Snackbar,
  Grid,
  TextField,
  Paper,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Input,
  OutlinedInput,
  InputAdornment,
  IconButton,
  styled,
} from "@mui/material";
import IcecreamIcon from '@mui/icons-material/Icecream';
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useDrawer } from "../Sidebar/DrawerContext";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function EditFood() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { labelStyle, inputStyles, errorMessageStyles, submitButtonStyles, buttonDisabled, capitalizeFirstLetter } = useDrawer();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const currentUser = useSelector((state) => state.auth.user.data);
  const {serialNo} = useParams();

  const [subCategory, setSubCategory] = useState("");
  const [fetchedCategory, setCategory] = useState("");
 
  
  useEffect(() => {
    const apiUrl = `https://billing.ubsbill.com/apptest/v2/sys/categorybutton/store/${currentUser.storeid}`; 

    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const categories = data.data.map(item => ({ value: item.name, label: item.name }));

        const updatedFormDataFields = [...formDataFields];
        const categoryFieldIndex = updatedFormDataFields.findIndex(field => field.name === 'category');
        if (categoryFieldIndex !== -1) {
          updatedFormDataFields[categoryFieldIndex].options = [
            { value: '', label: 'Select Category' },
            ...categories,
           
          ];
        } else {
          console.error('Category field not found in formDataFields');
        }

        setFormDataFields(updatedFormDataFields);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);
  
  const [formDataFields, setFormDataFields] = useState([
    { name: 'foodName', label: 'Food Name', type: 'text' },
    { name: 'foodCode', label: 'Food Code', type: 'text' },
    { name: 'price', label: 'Price', type: 'number' },
    { name: 'category', label: 'Category', type: 'select', 
    options: [{ value: fetchedCategory, label: 'Select Category' }] },
    {
      name: 'subCategory', label: 'Sub Category', type: 'select', options: [
        { value: subCategory, label: 'Select Sub Category' },
        { value: 'Veg', label: 'Veg' },
        { value: 'Nonveg', label: 'Non veg' },
        { value: 'Egg', label: 'Egg' },
       
      ]
    },
    { name: 'description', label: 'Description', type: 'text' },
    { name: 'image', label: 'Select Image', type: 'text' },
  ]);
  const [formData, setFormData] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }),
      {}),
    storeId: currentUser.storeid,
    createdBy: currentUser.username,
    updateBy: currentUser.username,

  });



  const [errors, setErrors] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})

  });

  const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const numberRegex = new RegExp(/^\d{1,10}$/);
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    if (fieldName === "price") {
      if (!numberRegex.test(value)) {
        error = `${capitalizedFieldName} should be a number`;
      }
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };
  const navigate =useNavigate();
  const fetchData = async () => {
    console.log(serialNo);
    try {
      const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/food/Id/${serialNo}`);
      if(String(response.data.data.storeId) !== String(currentUser.storeid))
      {
         navigate('/unauthorized');
      }
      else{
      const userData = response.data.data;
      setCategory(response.data.data.category)
      setSubCategory(response.data.data.subCategory)
      
      setFormData({
        foodName: userData.foodName,
        foodCode: userData.foodCode,
        price: userData.price,
        category: userData.category,
        subCategory: userData.subCategory,
        description: userData.description,
        image: userData.image,
        createdBy: currentUser.username,
        updatedBy: userData.username,
        storeId: userData.storeId,
      });
    }
    } catch (error) {
      alert("Failed to fetch user data: " + error);
      // Handle error display here
    }
  };
  


  useEffect(() => { 
  fetchData();
}, []);

   


  const handleMenu = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {


      try {
        const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/food/${serialNo}`, formData);
        if (response.data.status === true) {
          showSuccessSnackbar("Form Update Successfull !");
          fetchData();
        }
        else {
          showErrorSnackbar(response.data.message);

          fetchData();

        }

      } catch (error) {
   
        showErrorSnackbar(error.response.data.message);
        fetchData();
      }
    }
  };

  const isSubmitDisabled = buttonDisabled(formData, errors);

  return (
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper elevation={12} sx={{ backgroundColor: "#EBEBEB", width: { xs: "100%", sm: "80%", md: "60%", lg: "60%", xl: "60%" }, p: 3, mt: 5 }}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box display="flex" alignItems="center" bgcolor="primary.light" p={1} sx={{ fontWeight: "500", backgroundColor: "#28A497", fontSize: "24px", color: "white", borderRadius: 1 }}>
              <IcecreamIcon fontSize="large" />
              <span style={{ marginLeft: '8px' }}>Edit Menu</span> {/* Text */}
            </Box>
          </Grid>
        </Grid>

        {Array.from({ length: 4 }).map((_, index) => (
          <Stack key={index} spacing={4} direction={{ xs: "column", sm: "row", md: "row", lg: "row", xl: "row" }} sx={{ marginBottom: 1 }}>
            {formDataFields
              .slice(index * 2, index * 2 + 2)
              .map((formDataItem, innerIndex) => (
                <FormControl key={innerIndex} fullWidth error={!!errors[formDataItem.name]} variant="outlined">
                  <CustomTypography htmlFor={`outlined-adornment-${formDataItem.name}`} className="food-card-text" sx={labelStyle}>
                    {formDataItem.label}
                  </CustomTypography>

                  {formDataItem.type === 'select' ? (
                    <Select
                      labelId={`select-${formDataItem.name}`}
                      id={`select-${formDataItem.name}`}
                      name={formDataItem.name}
                      value={
                        formData[formDataItem.name] ||
                        formDataItem.options[0].label
                      }
                      onChange={handleInputChange}
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        height: '50px',
                        maxHeight: "50px",
                        minHeight: "50px",
                        outline: "none",
                      }}
                    >
                      {formDataItem.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  ) : formDataItem.type === "file" ? (
                    <Input
                      sx={inputStyles}
                      type="file"
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      disableUnderline
                    />

                  ) : (
                    <Input
                      sx={inputStyles}
                      type={formDataItem.type}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      disableUnderline
                    />
                  )}

                  <FormHelperText className="food-card-text" sx={errorMessageStyles}>{errors[formDataItem.name]}</FormHelperText>
                </FormControl>

              ))},
          </Stack>
        ))}

        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleMenu}
            className='food-card-text mode-button border-radius-10 cart-button final-button'
            sx={submitButtonStyles} disabled={isSubmitDisabled}>
            Submit
          </Button>
        </Box>
      </Paper>
    </Box>
  );

}
export default EditFood;
