import React from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDarkMode } from "../Screens/DarkMode/DarkMode";

function PageNotFound() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "60vh",
      }}
    >
      <Stack
        spacing={1}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <SentimentDissatisfiedOutlinedIcon style={{ height: "150px", width: "150px" , color: isDarkMode ? "#fff" : "#000" }} />
        <CustomTypography variant="h1">404</CustomTypography>
      </Stack>
      <Stack direction="column" spacing={5}>
        <CustomTypography style={{ textAlign: "center" }} variant="h4">
          Page Not Found !
        </CustomTypography>
        {currentUser && currentUser.roles.includes("ROLE_ADMIN") && (
            <Button component={Link} to={"/dashboard"} variant="contained">Go to Homepage</Button>
        )}
        {currentUser && currentUser.roles.includes("ROLE_SUPER_ADMIN") && (
            <Button component={Link} to={"/superadmin-dashboard"} variant="contained">Go to Homepage</Button>
        )}
        {currentUser && currentUser.roles.includes("ROLE_USER") && (
            <Button component={Link} to={"/user-dashboard"} variant="contained">Go to Homepage</Button>
        )}
         {currentUser && currentUser.roles.includes("ROLE_SUPPORT") && (
            <Button component={Link} to={"/technician-dashboard"} variant="contained">Go to Homepage</Button>
        )}
      </Stack>
    </Box>
  );
}
export default PageNotFound;
