
import React, {  useState } from "react";
import {
   Box,
   Button,
   Stack,
   InputAdornment,
   IconButton,
   FormControl,
   OutlinedInput,
   InputLabel,
   FormHelperText,
   CircularProgress,
   Grid,
   Card,
   CardContent,
   Alert,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch,  } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { loginUser } from "../../Screens/store/authSlice";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";


function Login() {

   const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();

   const navigate = useNavigate();
   const dispatch = useDispatch();


   const { showSuccessSnackbar } = useSuccessSnackbar();
   const { showErrorSnackbar } = useErrorSnackbar();

   const errorMessages = {
      emptyFieldError: "This field Should Not be Empty",
      usernameFormatError:
         "username Must Contain One Uppercase Letter & No Special Charaters Allowed !",
      passwordFormatError:
         "Password must contain at least one uppercase letter, one special character (@$!%*?&), and one number.",
   };

   const [formData, setFormData] = useState({
      username: "",
      password: "",
   });

   const [errors, setErrors] = useState({
      username: "",
      password: "",
   });

   const [showPassword, setShowPassword] = useState(false);
   const [showLoginLoader, setShowLoginLoader] = useState(false);
   const handleTogglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   const validateField = (fieldName, value) => {
      let error = "";
      const passwordRegex =
         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,15}$/;
      const userNameRegex = /^(?=.*[A-Z])[a-zA-Z0-9_]{4,20}$/;
      //Validation Username
      if (fieldName === "username") {
         if (value.trim() === "") {
            error = errorMessages.emptyFieldError;
         } else if (!userNameRegex.test(value)) {
            error = errorMessages.usernameFormatError;
         }

      }

      //Validation Password
      if (fieldName === "password") {
         if (value.trim() === "") {
            error = errorMessages.emptyFieldError;
         } else if (!passwordRegex.test(value)) {
            error = errorMessages.passwordFormatError;
         }
      }
      return error;
   };

   const handleResetPasswordButton = () => {
      navigate("/reset-password");
   };

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });

      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });
   };


   const handleLogin = async (e) => {
      e.preventDefault();
      setShowLoginLoader(true);
   
      if (Object.values(errors).every((error) => error === "")) {
         try {
            const rolesToTry = ["ROLE_USER", "ROLE_ADMIN", "ROLE_SUPPORT", "ROLE_SUPER_ADMIN"];
            let successfulLoginResult = null;
   
            for (const role of rolesToTry) {
               if (successfulLoginResult) {
                  break; // Exit the loop if a successful login has been detected
               }
   
               const loginResult = await dispatch(loginUser({ username: formData.username, password: formData.password, roles: role }));
   
               if (loginResult.payload) {
                  successfulLoginResult = loginResult;
               }
            }
   
            if (successfulLoginResult) {
               const { roles } = successfulLoginResult.payload; // Assuming the payload contains role information
               let route = "/";
               switch (roles) {
                  case "ROLE_USER":
                     route = "/user-dashboard";
                     break;
                  case "ROLE_ADMIN":
                     route = "/dashboard";
                     break;
                  case "ROLE_SUPPORT":
                     route = "/technician-dashboard";
                     break;
                  case "ROLE_SUPER_ADMIN":
                     route = "/superadmin-dashboard";
                     break;
                  default:
                     route = "/";
                     break;
               }
               navigate(route);
               showSuccessSnackbar("Login Successful");
            } else {
               showErrorSnackbar("Bad Credential !!");
            }
         } catch (error) {
            const errorMessage = error.response?.data;
            console.log('Error message:', errorMessage);
         } finally {
            setShowLoginLoader(false);
         }
      } else {
         setShowLoginLoader(false);
      }
   };

   const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          handleLogin(e);
      }
  };



   const isFormValid = Object.values(formData).some((value) => value === "");

   return (
      <Box sx={{ display: "flex", backgroundColor: "#EAFCFC", minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>


         <Grid container item justifyContent="center" xs={12} sm={10} md={8} lg={6} xl={4}>
            <Card style={{ maxWidth: 400, width: '100%', margin: '0 auto', padding: "10px" }}>
               <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                  <AccountCircleIcon fontSize="large" />
                  <CustomTypography sx={{ fontWeight: 600, fontSize: "32px", ml: 2 }}>Sign In</CustomTypography>
               </Box>
               <CardContent>
                  <Stack spacing={3}>
                     <FormControl error={!!errors.username} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                        <OutlinedInput
                           label="Username"
                           name="username"
                           value={formData.username}
                           onChange={handleInputChange}
                           onKeyPress={handleKeyPress}
                           sx={{ bgcolor: '#EBEBEB' }}
                        />
                        <FormHelperText>{errors.username}</FormHelperText>
                     </FormControl>
                     <FormControl error={!!errors.password} variant="outlined">
                        <InputLabel>Password</InputLabel>
                        <OutlinedInput
                           label="Password"
                           type={showPassword ? 'text' : 'password'}
                           name="password"
                           value={formData.password}
                           onChange={handleInputChange}
                           onKeyPress={handleKeyPress}
                           sx={{ bgcolor: '#EBEBEB' }}
                           endAdornment={
                              <InputAdornment position="end">
                                 <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>
                              </InputAdornment>
                           }
                        />
                        <FormHelperText>{errors.password}</FormHelperText>
                     </FormControl>
                     {showLoginLoader ? (
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                           <CircularProgress />
                        </Box>
                     ) : (
                        <Button variant="contained" onClick={handleLogin} disabled={isFormValid}>
                           Login
                        </Button>
                     )}
                     <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px' }}>Need Help ?</span>
                        <Button onClick={handleResetPasswordButton}>Forgot Password</Button>
                     </Box>
                  </Stack>
               </CardContent>
            </Card>
         </Grid>
      </Box>
   );
}

export default Login;
