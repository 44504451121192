import { Box, Grid,  } from "@mui/material";
import React, { useState } from "react";

import QuickAccessLinks from "../../Components/StoreDashboardComponent/QuickAccessLinks";
import AppMenu from "../../Components/StoreDashboardComponent/AppMenu";
import SuperAdminAppmenu from "../../Components/StoreDashboardComponent/SuperAdminAppmenu";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDarkMode } from "../DarkMode/DarkMode";

function TechnicianDashboard() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });



  const appMenuData = [
    {
      id: 1,
      title: "Store Management",
      image: "../images/addFoodIcon.png",
      boxShadow: "0px -9px 0px 0px #FACE47",
      link: "/store-management-dashboard",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 3,
      title: "Settings",
      image: "../images/setting-icon.png",
      boxShadow: "0px -9px 0px 0px #47C01C",
      link: "/technician-profile-settings",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "50px",
    },
   
  ];


  

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomTypography
          sx={{ mb: 4, fontWeight: "600" }}
          variant="p"
          className="link-cart-title"
        >
          🖐 Hello , {currentUser.username} |  
          <span
            className="link-cart-title"
            style={{ fontWeight: "500",color: isDarkMode ? "#fff" :"#424242" , }}
          >
             {currentDate}
          </span>
        </CustomTypography>
       
       
        <CustomTypography sx={{ mb: 4 }} variant="p" className="link-cart-title">
          App Menu
        </CustomTypography>
       
         <SuperAdminAppmenu data={appMenuData} />
       
       
      </Box>
    </Box>
  );
}
export default TechnicianDashboard;
