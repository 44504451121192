import React, { useEffect, useState } from "react";
import {
   Box,
   Button,
   Stack,
   FormControl,
   FormHelperText,
   Grid,
   Paper,
   Input,
   Select,
   MenuItem,

} from "@mui/material";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useDrawer } from "../Sidebar/DrawerContext";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";


function EditVendorPayment() {
   const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
   const { labelStyle, inputStyles, errorMessageStyles, submitButtonStyles, selectStyles, formPaperStyles, formMainBox, capitalizeFirstLetter, buttonDisabled } = useDrawer();
   const { showSuccessSnackbar } = useSuccessSnackbar();
   const { showErrorSnackbar } = useErrorSnackbar();
   const {serialNo}= useParams();
   const currentUser = useSelector((state) => state.auth.user.data)
   const formDataFields = [
      { name: 'vendorName', label: 'Vendor Name', type: 'text' },
      { name: 'dueDate', label: 'DueDate', type: 'date' },
      { name: 'bankName', label: 'Bank Name', type: 'text' },
      { name: 'branch', label: 'Branch Name', type: 'text' },
      { name: 'accountNo', label: 'Account No', type: 'number' },
      { name: 'ifscCode', label: 'IFSC Code', type: 'text' },
      { name: 'upiId', label: 'UPI Id', type: 'text' },
      { name: 'total', label: 'Total', type: 'number' },
   ];

   const [formData, setFormData] = useState({
      ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
      paymentStatus: "Pending",
      storeId: currentUser.storeid,
      createdBy: currentUser.username,
      updatedBy: currentUser.username,
   });
   const [errors, setErrors] = useState(
      formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
   );
   const validateField = (fieldName, value) => {
      let error = "";
      const numberRegex = new RegExp(/^\d{1,10}$/);
      const capitalizedFieldName = capitalizeFirstLetter(fieldName);
      const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      const maxLength = 40;
      const alphabeticRegexWithSpace = /^[a-zA-Z\s]+$/;
      if (value.trim() === "") {
         error = `${capitalizedFieldName} is Mandatory`;
      }
      if (capitalizedFieldName === "price") {
         if (!numberRegex.test(value)) {
            error = `${capitalizedFieldName} should be a number`;
         }
      }
      else if (fieldName === "upiId") {
         if (value.length > maxLength) {
           error = `${capitalizedFieldName} cannot exceed 40 characters`;
         }
   
       }
       else if (fieldName === "ifscCode") { 
         if (!ifscRegex.test(value)) {
             error = "Please enter a valid IFSC code";
         }
       }
       else if (fieldName === "accountNo") {
         if (value <= 0) {
             error = `${capitalizedFieldName} should be a positive number`;
         } else if (value.length > 15) {
             error = `${capitalizedFieldName} should not exceed 15 digits`;
         }
     }
     else if (fieldName === "total") {
      if (value <= 0) {
          error = `${capitalizedFieldName} should be a positive number`;
      } else if (value.length > 8) {
          error = `${capitalizedFieldName} should not exceed 8 digits`;
      }
  }
     else if (fieldName === "vendorName" || fieldName === "bankName" || fieldName === "branch") {
         if (!alphabeticRegexWithSpace.test(value)) {
           error = `${capitalizedFieldName} must contain only alphabetic characters`;
         } else if (value.length > maxLength) {
           error = `${capitalizedFieldName} cannot exceed 40 characters`;
         }
       }
      return error;
   };
   const navigate =useNavigate();
   useEffect(() => {

      const fetchData = async () => {
        try {
          const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/payment/id/${serialNo}`);
          const vendorData = response.data.data;
          if(response.data.data.storeId !== currentUser.storeid)
          {
             navigate('/unauthorized');
          }
          else{
          setFormData({
            vendorName: vendorData.vendorName,
            dueDate: vendorData.dueDate,
            bankName: vendorData.bankName,
            branch: vendorData.branch,
            accountNo: vendorData.accountNo,
            ifscCode: vendorData.ifscCode,
            upiId: vendorData.upiId,
            total: vendorData.total,
            createdBy: currentUser.username,
            updatedBy: currentUser.username,
            storeId: currentUser.storeid,
          });
         }
        } catch (error) {
          showErrorSnackbar("Failed to fetch vendor data");
        }
      };
  
      fetchData();
    }, [serialNo, showErrorSnackbar]);


   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });
   };
   const handleVendorPayment = async (e) => {
      e.preventDefault();

      if (Object.values(errors).every((error) => error === "")) {

         try {
            const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/payment/${serialNo}`, formData)
            if (response.data.status === true) {
               setFormData({
                 
                  paymentStatus: "Pending",
                  storeId: currentUser.storeid,
                  createdBy: currentUser.username,
                  updatedBy: currentUser.username,
               });
               showSuccessSnackbar("Form Submit Successfull !");
          
            }
         } catch (error) {
            showErrorSnackbar("Form Submit Failed");
         }
      }
   };

   const isSubmitDisabled = buttonDisabled(formData, errors);
   return (
      <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <Paper elevation={12} sx={formPaperStyles}>
            <Grid container spacing={1} sx={{ mb: 4 }}>
               <Grid item>
                  <Box display="flex" alignItems="center" bgcolor="primary.light" p={1} sx={formMainBox}>
                     <AccountBalanceWalletIcon fontSize="large" />
                     <span style={{ marginLeft: '8px' }}>Edit Vendor Payment</span> {/* Text */}
                  </Box>
               </Grid>
            </Grid>

            {Array.from({ length: 5 }).map((_, index) => (
               <Stack
                  key={index}
                  spacing={4}
                  direction={{
                     xs: "column",
                     sm: "row",
                     md: "row",
                     lg: "row",
                     xl: "row",
                  }}
                  sx={{ marginBottom: 1 }}
               >
                  {formDataFields
                     .slice(index * 2, index * 2 + 2)
                     .map((formDataItem, innerIndex) => (
                        <FormControl
                           key={innerIndex}
                           fullWidth
                           error={!!errors[formDataItem.name]}
                           variant="outlined"
                        >
                           <CustomTypography
                              htmlFor={`outlined-adornment-${formDataItem.name}`}
                              className="food-card-text"
                              sx={labelStyle}
                           >
                              {formDataItem.label}
                           </CustomTypography>
                           {formDataItem.type === "select" ? (
                              <Select
                                 name={formDataItem.name}
                                 value={
                                    formData[formDataItem.name] ||
                                    formDataItem.options[0].label
                                 }
                                 onChange={handleInputChange}
                                 sx={selectStyles}
                              >
                                 {formDataItem.options.map((option, optionIndex) => (
                                    <MenuItem key={optionIndex} value={option.value}>
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </Select>
                           ) : (
                              <Input
                                 sx={inputStyles}
                                 type={formDataItem.type}
                                 name={formDataItem.name}
                                 value={formData[formDataItem.name]}
                                 onChange={handleInputChange}
                                 disableUnderline
                              />
                           )}
                           <FormHelperText
                              className="food-card-text"
                              sx={errorMessageStyles}
                           >
                              {errors[formDataItem.name]}
                           </FormHelperText>
                        </FormControl>
                     ))}
               </Stack>
            ))}
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
               <Button onClick={handleVendorPayment} className='food-card-text mode-button border-radius-10 cart-button final-button' sx={submitButtonStyles} disabled={isSubmitDisabled}>
                  Submit
               </Button>
            </Box>
         </Paper>
      </Box>
   );

}
export default EditVendorPayment;