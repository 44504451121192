import React, { useState, useEffect } from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function DrawerLink({ name, route, handleNavigation, currentRoute, image, isDrawerOpen, closeDrawer }) {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [isActive, setIsActive] = useState(route === currentRoute);
  const location = useLocation();

  useEffect(() => {
    setIsActive(route === currentRoute);
  }, [currentRoute, route]);
  useEffect(() => {
    setIsActive(location.pathname === route);
  }, [location.pathname, route]);
  const handleClick = () => {
    setIsActive(true);
    handleNavigation(route);
    closeDrawer();
  };

  const getImageFilter = () => {
    return isDarkMode ? 'invert(1)' : 'invert(0)';
  };
  const getBackgroundColor = () => {
    if (isDarkMode) {
      return isActive ? "#333" : "transparent"; // Set different colors for dark mode
    } else {
      return isActive ? "#fff" : "transparent"; // Set different colors for light mode
    }
  };
  return (
    <ListItem sx={{ width: "95%", }} disablePadding onClick={handleClick}>
      <ListItemButton
        sx={{
          backgroundColor: getBackgroundColor(),
          borderRadius: "0px 30px 30px 0px",
          border: isActive ? `2px solid #4992A3` : "none",
          height: "60px",
          display: "flex",
        }}
      >
        <ListItemIcon sx={{ minWidth: 0, width: "20%" }}>
          <img src={image} alt={name} style={{ width: "25px", height: "25px" ,  filter: getImageFilter()  }} />
        </ListItemIcon>
        <CustomTypography className="sidebar-link-title" sx={{
          fontWeight: "500", textTransform: "capitalize", opacity: {
            xs: isDrawerOpen ? 1 : 0,
            sm: isDrawerOpen ? 1 : 0,
            md: isDrawerOpen ? 1 : 0,
            lg: isDrawerOpen ? 1 : 0,
            xl: isDrawerOpen ? 1 : 1,
          },
          transition: "opacity 0.3s ease",
          fontSize: { sm: '12px', md: '12px', xs: '10px', lg: "14px", xl: "16px" },
        }}>
          {name}
        </CustomTypography>
      </ListItemButton>
    </ListItem>
  );
}

export default DrawerLink;
