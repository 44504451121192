import React, { useEffect, useState } from "react";
import {
  Box,
  ListItem,
  Paper,

  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  InputAdornment,
  IconButton,
  Input,
  Modal,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useDrawer } from "../Sidebar/DrawerContext";

import axios from "axios";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import PopOverModal from "../Cards/Modal/PopOverModal";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
const CashRegister = () => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const { submitButtonStyles } = useDrawer();

  //for add amount
  const [addingAmount, setAddingAmount] = useState("");
 
  const currentUser = useSelector((state) => state.auth.user.data)

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddAmount = async (e) => {
    if (addingAmount === "0" || addingAmount < "0") {
      showErrorSnackbar("Amount Must Be Greater than Zero!!")
      return;
    }
    try {
      const formData = {
        storeId: currentUser.storeid,
        createdBy: currentUser.username,
        updatedBy: currentUser.username,
        addMoreAmount: addingAmount,
      };

      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/balance/open", formData);
      if (response.data.status === true) {
        showSuccessSnackbar("Balance Added Successfully!!")
        setAddingAmount("");
      }
      else {
        showErrorSnackbar(response.data.message);
        setAddingAmount("");
      }
    } catch (error) {
      showErrorSnackbar("After closing you dont have access to add amount")
    }
  };


  const [paymentMode, setpaymentMode] = useState({});
  // we fetch card , upi , cash balance so to compare with entered amount
  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/bill/total/${currentUser.storeid}`)
      .then((response) => {
        setpaymentMode(response.data.data)
      })
  }, []);


  // we fetch remaining balance so to compare with entered amount
  const [remainingBalance, setRemainingBalance] = useState(null);
  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/balance/remaining_balance/${currentUser.storeid}`)
      .then((response) => {
        setRemainingBalance(response.data.data)
      })
      .catch((error) => {

      })

  }, []);


  const handleInputChange = (field, value) => {
    setTableData({
      ...tableData,
      [field]: value,
    });
  };


  // Yester-Day Closing Balance 
  const [yestardayBalance, setyestardayBalance] = useState(null);
  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/balance/yesterday_closing_balance/${currentUser.storeid}`)
      .then((response) => {
        setyestardayBalance(response.data.data)
      })
      .catch((error) => {

      })

  }, []);


  const [tableData, setTableData] = useState([]);

  const isSubmitDisabled = ['expense', 'amount'].some(field => tableData[field] === '');
  //we send table data to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasNullValue = ['expense', 'amount'].some(field => tableData[field] === '');
    if (hasNullValue) {
      showErrorSnackbar("Please fill in both expense and amount fields before submitting.");
      return;
    }
    if (tableData.amount > remainingBalance) {
      showErrorSnackbar("Entered Amount is greater than Remaining Amount.");
      return;
    }
    tableData.storeId = currentUser.storeid
    tableData.cashier = currentUser.username

    try {

      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/transaction/", tableData);
      setTableData({
        expense: '',
        amount: '',
      });
      if (response.data.status === true) {
       
        showSuccessSnackbar("Form Submit Successfull !");
      
      }
      else {
        setTableData({
          expense: '',
          amount: '',
        }
        );
        showErrorSnackbar(response.data.message);
        
      }

    } catch (error) {
      showErrorSnackbar(error.response.data.message);
      setTableData({
        expense: '',
        amount: '',
      }
      );
    }

  };

  //NEW=====for handed amount
  const currentDate = new Date();
  const [finalData, setFinalData] = useState({
    finalAmount: '',
    finalHandedOverTo: '',
    finalClosingBalance: '',
    storeId: currentUser.storeid,
    createdby: "sandip",
    updatedby: "sandip",
    date: currentDate,
  });
  const handleDataInputChange = (field, value) => {
    setFinalData({
      ...finalData,
      [field]: value,
    });
  };



  const isfinalSubmitDisabled = ['finalAmount', 'finalHandedOverTo'].some(field => finalData[field] === '');
  const handleFinalAmountSubmit = async (e) => {
    e.preventDefault();


    const data = ['finalAmount', 'finalHandedOverTo'].some(field => tableData[field] === '');
    if (data) {
      showErrorSnackbar("Please fill in both finalAmount and finalHandedOverTo fields before submitting.");
      return;
    }
    const calculatedFinalClosingBalance =
      remainingBalance - finalData.finalHandedOverTo;

    if (calculatedFinalClosingBalance < 0) {
      showErrorSnackbar("Entered Amount is greater than Remaining Amount.");
      return;
    }
    setFinalData({
      ...finalData,

      finalClosingBalance: calculatedFinalClosingBalance
    })

    try {
      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/balance/close", finalData);
      if (response.data.status === true) {
        showSuccessSnackbar("Form Submit Successfull !");
        setFinalData({
          finalAmount: '',
          finalHandedOverTo: '',
        });
      }
      else {
        showErrorSnackbar(response.data.message);
        setFinalData({
          finalAmount: '',
          finalHandedOverTo: '',
        }
        );
      }

    } catch (error) {
      showErrorSnackbar(error.response.data.message);
      setFinalData({
        finalAmount: '',
        finalHandedOverTo: '',
      }
      );
    }

  };



  //final closing

  const handleFinalClosing = () => {

    showSuccessSnackbar("Final Closing")
  }


  return (
    <Box component="main">
      <Grid sx={{ p: 5 }} xl={12}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="primary.light"
              p={1}
              sx={{
                fontWeight: "500",
                backgroundColor: isDarkMode ? "#48484A" : "#28A497",
                fontSize: "24px",
                color: "white",
                borderRadius: 1,
              }}
            >
              <AccountBalanceWalletIcon fontSize="large" /> {/* Icon */}
              <span style={{ marginLeft: "8px" }}>Cash Register</span>{" "}
              {/* Text */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item>
            <Box
             
              p={1}
              sx={{ fontWeight: "600", borderRadius: 1 ,  background :  isDarkMode ? "#7A7A7A" : "#D9D9D9" , color: isDarkMode ? "#fff" : "#000"}}
            >
              Previous Closing Balance
              <Input
                disabled
                value={parseFloat(yestardayBalance).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                sx={{ width: "20ch", backgroundColor: "white", ml: 2, borderRadius: "5px", p: "0px 8px", fontWeight: "bold" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Total Rs.</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              p={1}
              sx={{ fontWeight: "600", borderRadius: 1 ,  background :  isDarkMode ? "#7A7A7A" : "#D9D9D9" , color: isDarkMode ? "#fff" : "#000" }}
            >
              Add More Amount
              <Input
                type="number"
                sx={{ width: "20ch", backgroundColor: "white", ml: 2, borderRadius: "5px", p: "0px 8px" }}
                value={addingAmount}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (inputValue !== '0' && !isNaN(inputValue) && Number(inputValue) >= 0) {
                    if (Number(inputValue) > 50000) {
                      inputValue = "50000";
                      showErrorSnackbar("Maximum amount allowed is 50,000");
                    }
                    setAddingAmount(inputValue);
                  } else {
                    setAddingAmount('');
                    showErrorSnackbar("Invalid amount");
                  }
                }}
                disableUnderline
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
           
              p={0.5}
              sx={{ fontWeight: "600", borderRadius: 1 ,  background :  isDarkMode ? "#7A7A7A" : "#D9D9D9" , color: isDarkMode ? "#fff" : "#000" }}
            >
              <IconButton onClick={handleAddAmount}>
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item>
            <Box
            
              p={1}
              sx={{ fontWeight: "600", borderRadius: 1 ,  background :  isDarkMode ? "#7A7A7A" : "#D9D9D9" , color: isDarkMode ? "#fff" : "#000" }}
            >
              Cashier
              <Input
                disabled
                value={currentUser.username}
                disableUnderline
                sx={{ width: "20ch", backgroundColor: "white", ml: 2, borderRadius: "5px", p: "0px 8px", fontWeight: "bold" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 4 }}>
          <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>
            <Table aria-label="simple table">
              <TableHead sx={{ background :  isDarkMode ? "#48484a" : "#28A497" ,  p: 2, mb: 2 }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "24px",
                      p: 2,
                      color: "white",
                      borderRight: "1px solid #fff",
                      textAlign: "center",
                    }}
                  >
                    Sales
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "24px",
                      p: 2,
                      color: "white",
                      borderRight: "1px solid #fff",
                      textAlign: "center",
                    }}
                    align="right"
                  >
                    Expense Details
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "24px",
                      p: 2,
                      color: "white",
                      borderRight: "1px solid #fff",
                      textAlign: "center",
                    }}
                    align="right"
                  >
                    Expense Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "600",
                      fontSize: "24px",
                      p: 2,
                      color: "white",
                      textAlign: "center",
                    }}
                    align="right"
                  >
                    Remaining Balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <div style={{ padding: "10px" }} />
              <TableBody sx={{ background :  isDarkMode ? "#7A7A7A" : "#EAFCFC" , pt: 2 }}>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderRight: "1px solid #000" }}
                  >
                    <Box direction="column">
                      <ListItem>
                        <CustomTypography
                          sx={{
                            fontWeight: "400",
                            fontSize: "24px",
                            color: "black",
                            width: "60px",
                          }}
                        >
                          Cash
                        </CustomTypography>
                        <Input
                          disabled
                          disableUnderline
                          sx={{
                            fontWeight: "bold",
                            width: "50%",
                            minWidth: "100px",
                            backgroundColor: "white",
                            border: "3px solid #D9D9D9",
                            ml: 2, borderRadius: "5px", p: "0px 8px"
                          }}
                          value={parseFloat(paymentMode.cash).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}

                        />
                      </ListItem>
                      <ListItem>
                        <CustomTypography
                          sx={{
                            fontWeight: "400",
                            fontSize: "24px",
                            color: "black",
                            width: "60px",
                          }}
                        >
                          Upi
                        </CustomTypography>
                        <Input
                          disabled
                          disableUnderline
                          sx={{
                            fontWeight: "bold",
                            width: "50%",
                            minWidth: "100px",
                            backgroundColor: "white",
                            border: "3px solid #D9D9D9",
                            ml: 2, borderRadius: "5px", p: "0px 8px"
                          }}
                          value={parseFloat(paymentMode.upi).toLocaleString('en-IN', { style: 'currency', currency: 'INR', })}
                        />
                      </ListItem>
                      <ListItem>
                        <CustomTypography
                          sx={{
                            fontSize: "24px",
                            color: "black",
                            width: "60px",
                          }}
                        >
                          Card
                        </CustomTypography>
                        <Input
                          disabled
                          disableUnderline
                          sx={{
                            fontWeight: "bold",
                            width: "50%",
                            minWidth: "100px",
                            backgroundColor: "white",
                            border: "3px solid #D9D9D9",
                            ml: 2, borderRadius: "5px", p: "0px 8px"
                          }}
                          value={parseFloat(paymentMode.card).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                        />
                      </ListItem>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #000" }}
                  >
                    <Input
                      type="text"
                      sx={{
                        width: "70%",
                        minWidth: "100px",
                        backgroundColor: "white",
                        ml: 2, borderRadius: "5px", p: "0px 8px", border: "3px solid #D9D9D9",
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        handleInputChange('expense', inputValue);
                      }}
                      value={tableData.expense}
                      disableUnderline
                      required
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #000" }}
                  >
                    <Input
                      type="number" 
                      value={tableData.amount}
                      sx={{
                        width: "70%",
                        minWidth: "100px",
                        backgroundColor: "white",
                        ml: 2, borderRadius: "5px", p: "0px 8px", border: "3px solid #D9D9D9",
                      }}
                      disableUnderline
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (inputValue !== '0' && !isNaN(inputValue) && Number(inputValue) >= 0) {
                          if (Number(inputValue) > 50000) {
                            inputValue = "50000";
                            showErrorSnackbar("Maximum amount allowed is 50,000");
                          }
                          handleInputChange('amount', inputValue);
                        } else {
                          showErrorSnackbar("Invalid amount");
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell align="center">
                    <Input
                      disableUnderline
                      sx={{
                        fontWeight: "bold",
                        width: "70%",
                        minWidth: "100px",
                        backgroundColor: "white",
                        ml: 2, borderRadius: "5px", p: "0px 8px", border: "3px solid #D9D9D9",
                      }}
                      value={parseFloat(remainingBalance).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Button
          className="food-card-text mode-button border-radius-10 cart-button final-button"
          onClick={handleSubmit}
          sx={{ ...submitButtonStyles, mt: 2 }}
          disabled={isSubmitDisabled}
        >
          Submit
        </Button>
        <Grid container spacing={1} sx={{ mt: 3 }}>
          <Grid item xs={6}>
            <Box
              bgcolor="primary.light"
              p={1}
              sx={{
                fontWeight: "500",
                background :  isDarkMode ? "#48484a" : "#28A497" ,
                fontSize: "24px" ,
                color: "white",
                borderRadius: 1,

              }}
            >
              Final Given Amount
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item>
            <Box
              sx={{
                background :  isDarkMode ? "#7A7A7A" : "#D9D9D9" , color: isDarkMode ? "#fff" : "#000" ,
                fontWeight: "600",
                p: 1,
                borderRadius: 1,
              }}
            >
              Handed Over to
              <Input
                type="text"
                sx={{ width: "25ch", backgroundColor: "white", ml: 2, borderRadius: "5px", p: "0px 8px" }}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^A-Za-z]/g, '');
                  handleDataInputChange('finalHandedOverTo', inputValue);
                }}
                value={finalData.finalHandedOverTo}
                disableUnderline
                required
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              className="justify-all-center"
              sx={{
                background :  isDarkMode ? "#7A7A7A" : "#D9D9D9" , color: isDarkMode ? "#fff" : "#000" , 
                fontWeight: "600",
                p: 1,
                borderRadius: 1,
              }}
            >
              Handed Amount
              <Input
                type="number"
                sx={{ width: "25ch", backgroundColor: "white", ml: 2, borderRadius: "5px", p: "0px 8px" }}
                disableUnderline
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (inputValue !== '0' && !isNaN(inputValue) && Number(inputValue) >= 0) {
                    if (Number(inputValue) > 50000) {
                      inputValue = "50000";
                      showErrorSnackbar("Maximum amount allowed is 50,000");
                    }
                    handleDataInputChange('finalAmount', inputValue);
                  } else {
                    showErrorSnackbar("Invalid amount");
                  }
                }}
                value={finalData.finalAmount}
                required
              />

            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              onClick={handleFinalAmountSubmit}
              sx={submitButtonStyles}
            // disabled= {isfinalSubmitDisabled}
            >
              Submit
            </Button>
          </Grid>
          <Grid item>
            {/* <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={submitButtonStyles}
              onClick={handleOpen}
            >
              Final
            </Button> */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <PopOverModal
                handleClose={handleClose}
                iconPath="../images/warning.png"
                buttonTextTwo="No"
                extraText="*NOTE: After this process you won’t be able to use or make any modification in the cash register"
                modalTopBorder="10px solid #FF8701"
                buttonTextOne="Yes"
                printText="Are you sure you want to close and update today’s final amount ?"
                onClickOne={handleFinalClosing}
                onClickTwo={handleClose}

              />
            </Modal>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CashRegister;
