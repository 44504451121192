import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "../Components/Login/Login";
import ResetPassword from "../Components/Login/ResetPassword";
import PageNotFound from "../PageNotFound/PageNotFound";
import AddVendor from "../Components/VendorManagement/AddVendor";
import InventoryManagment from "../Screens/InventoryManagement";
import AddInventory from "../Components/InventoryManagement/AddInventory";
import Shortcuts from "../Screens/Shortcuts";
import CardMainComponent from "../Components/Cards/CardMainComponent";
import Dashboard from "../Screens/Dashboard";
import CashManagement from "../Screens/CashManagement";
import VendorManagement from "../Screens/VendorManagement";
import FoodManagement from "../Screens/FoodManagement";
import UserManagement from "../Screens/UserManagement";
import UserRoleAccessManagement from "../Screens/UserRoleAccessManagement";
import UserProfileSettings from "../Screens/UserProfileSettings";
import OrderManagement from "../Screens/OrderManagement";
import NewOrder from "../Components/OrderManagement/NewOrder";
import AppBar from "../Components/Appbar/Appbar";
import { useDrawer } from "../Components/Sidebar/DrawerContext";
import { Box } from "@mui/material";
import OrdersList from "../Components/OrderManagement/OrdersList";
import InventoryList from "../Components/InventoryManagement/InventoryList";
import RecipeList from "../Components/FoodManagement/RecipeList";
import AddOnList from "../Components/FoodManagement/AddonList";
import FoodList from "../Components/FoodManagement/FoodList";
import AddUser from "../Components/UserManagement/AddUser";
import AddMenu from "../Components/FoodManagement/AddMenu";
import UserRoleList from "../Components/RoleAccessManagement/UserRoleList";
import CashRegister from "../Components/CashManagement/CashRegister";
import UserList from "../Components/UserManagement/UserList";
import AddAddons from "../Components/FoodManagement/CreateAddons";
import AddRecipe from "../Components/FoodManagement/CreateRecipe";
import VendorPayment from "../Components/VendorManagement/VendorPayment";
import CashRegisterList from "../Components/CashManagement/CashRegisterList";
import VendorInventory from "../Components/InventoryManagement/VendorInventory";
import VendorList from "../Components/VendorManagement/VendorList";
import VendorPaymentList from "../Components/VendorManagement/VendorPaymentList";
import VendorInventoryList from "../Components/InventoryManagement/VendorInventoryList";
import UserRoleAccess from "../Components/RoleAccessManagement/UserRoleAccess";
import StoreSettings from "../Components/UserProfilesSettings/StoreSettings";
import PaymentSettings from "../Components/UserProfilesSettings/PaymentSettings/PaymentSettings";
import TaxSettings from "../Components/UserProfilesSettings/TaxSettings";
import PosSettings from "../Components/UserProfilesSettings/POSSettings";
import CustomizedBreadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import PendingOrder from "../Components/OrderManagement/PendingOrders";
import SuperadminDashboard from "../Screens/Superadmin/SuperadminDashboard";
import StoreManagementSuperadmin from "../Screens/Superadmin/StoreManagementSuperadmin";
import SubscriptionSuperadmin from "../Components/SuperadminComponents/StoreManagement/SubscriptionSuperadmin";
import TechnicianManagementSuperadmin from "../Screens/Superadmin/TechnicianManagementSuperadmin";
import StoreListSuperadmin from "../Components/SuperadminComponents/StoreManagement/StoreListSuperadmin";
import AddStoreSuperadmin from "../Components/SuperadminComponents/StoreManagement/AddStoreSuperadmin";
import FreeTrialSuperadmin from "../Components/SuperadminComponents/StoreManagement/FreeTrialSuperadmin";
import AddTechSuperadmin from "../Components/SuperadminComponents/TechnicianManagement/AddTechSuperadmin";
import { TechDetailsSuperadmin } from "../Components/SuperadminComponents/TechnicianManagement/TechDetailsSuperadmin";
import TechnicianDashboard from "../Screens/Technician/TechnicianDashboard";
import StoreManagementTechnician from "../Screens/Technician/StoreManagementTechnician";
import TechListSuperadmin from "../Components/SuperadminComponents/TechnicianManagement/TechListSuperadmin";
import ProfileSettingTech from "../Components/TechnicianComponent/ProfileSettingsTech";
import ProfileSettingSuperAdmin from "../Components/SuperadminComponents/ProfileSettingSuperAdmin";
import PushNotification from "../Components/TechnicianComponent/Notification";
import { StoreDetailsSuperadmin } from "../Components/SuperadminComponents/StoreManagement/StoreDetailsSuperadmin";
import { useSelector } from "react-redux";
import Footer from "../Components/Footer/Footer";
import Profile from "../Screens/Profile";
import UserDashboard from "../Screens/User/UserDashboard";
import BillList from "../Components/OrderManagement/BillList";
import EditInventory from "../Components/InventoryManagement/EditInventory";
import EditRecipe from "../Components/FoodManagement/EditRecipe";
import EditVendor from "../Components/VendorManagement/EditVendor";
import UpdateRoleAccess from "../Components/RoleAccessManagement/UpdateRoleAccess";
import EditUser from "../Components/UserManagement/EditUser";
import UpdateOrder from "../Components/OrderManagement/UpdateOrder";
import QuickBill from "../Components/OrderManagement/NewOrderComponents/QuickBill";
import EditPaymentSettingsForm from "../Components/UserProfilesSettings/UpdatePaymentSetting";
import EditTax from "../Components/UserProfilesSettings/EditTax";
import EditFood from "../Components/FoodManagement/EditFood";
import EditAddon from "../Components/FoodManagement/EditAddon";
import EditPosSettings from "../Components/UserProfilesSettings/EditPOSSettings";
import EditAddons from "../Components/FoodManagement/EditAddon";
import EditVendorInventory from "../Components/InventoryManagement/EditVendorInventory";
import EditVendorPayment from "../Components/VendorManagement/EditVendorPayment";
import Reports from "../Screens/Reports";

import { useDarkMode } from "../Screens/DarkMode/DarkMode";

import axios from "axios";




const Routers = () => {

  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const { isDrawerOpen, handleDrawerOpen, handleDrawerClose, commonStyles } =
    useDrawer();

  const currentUser = useSelector((state) => state.auth.user);
  const userRoles = useMemo(() => currentUser ? currentUser?.data?.roles : [], [currentUser]);

  const [menuData, setMenuData] = useState([]);
  useEffect(() => {
    if (userRoles.includes("ROLE_USER")) {
      axios
        .get(
          `https://billing.ubsbill.com/apptest/v2/sys/usersidebar/username?username=${currentUser.data.username}`
        )
        .then((response) => {
          const menuData = response.data.data;
          // console.log(menuData);
          if (
            typeof menuData === "object" &&
            menuData.menu &&
            Array.isArray(menuData.menu)
          ) {
            setMenuData(menuData.menu);
          } else {
            console.error("Invalid menu data format:", menuData);
          }
        })
        .catch((error) => {
          console.error("Error fetching menu data:", error);
        });
    } else {
      setMenuData([]);
    }
  }, [currentUser, userRoles]);
  return (
    <Router >
      <AppBar/>
      {currentUser ? (
        <Box sx={{ backgroundColor: isDarkMode ? "#2e2d2d" : "#fff", minHeight:"93.7vh"}}>
          <Box
            sx={{
              marginLeft: {
                xs: isDrawerOpen ? "0px" : "0px",
                sm: isDrawerOpen ? "200px" : "60px",
                md: isDrawerOpen ? "220px" : "60px",
                lg: isDrawerOpen ? "270px" : "60px",
                xl: "300px",
              },
              transition: "margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
             
            }}
          >
            <Box component="main" sx={{ ...commonStyles.mainContainer }}>
              <CustomizedBreadcrumbs />


              <Routes>

                {/* Super Admin Routes */}
                {userRoles.includes("ROLE_SUPER_ADMIN") && (
                  <>
                    <Route path="/superadmin-dashboard" element={<SuperadminDashboard />} />
                    <Route path="/technician-management-dashboard" element={<TechnicianManagementSuperadmin />} />
                    <Route path="/add-technician" element={<AddTechSuperadmin />} />
                    <Route path="/technician-dashboard/technician-list" element={<TechListSuperadmin />} />
                    <Route path="/technician-details" element={<TechDetailsSuperadmin />} />
                    <Route path="/superadmin-profile-settings" element={<ProfileSettingSuperAdmin />} />
                  </>
                )}

                {/* Support Routes */}
                {userRoles.includes("ROLE_SUPPORT") && (
                  <>
                    <Route path="/technician-dashboard" element={<TechnicianDashboard />} />
                    <Route path="/technician-profile-settings" element={<ProfileSettingTech />} />
                    <Route path="/push-notification" element={<PushNotification />} />
                  </>
                )}


                {/* Shared Routes for Super Admin and Support */}
                {(userRoles.includes("ROLE_SUPER_ADMIN") || userRoles.includes("ROLE_SUPPORT")) && (
                  <>
                    <Route path="/store-management-dashboard" element={<StoreManagementSuperadmin />} />
                    <Route path="/store-management-dashboard/subscription-access" element={<SubscriptionSuperadmin />} />
                    <Route path="/store-management-dashboard/store-list" element={<StoreListSuperadmin />} />
                    <Route path="/store-management-dashboard/store-list/store-details" element={<StoreDetailsSuperadmin />} />
                    <Route path="/store-management-dashboard/add-new-store" element={<AddStoreSuperadmin />} />
                    <Route path="/store-management-dashboard/free-tral-access-management" element={<FreeTrialSuperadmin />} />
                    <Route path="/store-management-technician-dashboard" element={<StoreManagementTechnician />} />
                  </>
                )}

                {/* Routes for Admin */}
                {userRoles.includes("ROLE_ADMIN") && (
                  <>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/inventory-management-dashboard/add-inventory" element={<AddInventory />} />
                    <Route path="/food-management-dashboard" element={<FoodManagement />} />
                    <Route path="/addvendor" element={<AddVendor />} />
                    <Route path="/user-management-dashboard/add-user" element={<AddUser />} />
                    <Route path="/user-management-dashboard/users-list/edit-user/:serialNo" element={<EditUser />} />
                    <Route path="/food-management-dashboard/add-menu" element={<AddMenu />} />
                    <Route path="/user-management-dashboard/users-list" element={<UserList />} />
                    <Route path="/food-management-dashboard/add-addons" element={<AddAddons />} />
                    <Route path="/food-management-dashboard/add-recipe" element={<AddRecipe />} />
                    <Route path="/inventory-management-dashboard/inventory-list" element={<InventoryList />} />
                    <Route path="/new-order" element={<NewOrder />} />
                    <Route path="/bill-list" element={<BillList />} />
                    <Route path="/order-management-dashboard" element={<OrderManagement />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/profile-settings" element={<UserProfileSettings />} />
                    <Route path="/cash-management-dashboard" element={<CashManagement />} />
                    <Route path="/user-management-dashboard" element={<UserManagement />} />
                    <Route path="/user-role-management-dashboard" element={<UserRoleAccessManagement />} />
                    <Route path="/vendor-management-dashboard/add-vendor" element={<AddVendor />} />
                    <Route path="/vendor-management-dashboard/vendor-list/update-vendor/:serialNo" element={<EditVendor />} />
                    <Route path="/vendor-management-dashboard/vendor-inventory" element={<VendorInventory />} />
                    <Route path="/vendor-management-dashboard/vendor-payment-details" element={<VendorPayment />} />
                    <Route path="/inventory-management-dashboard" element={<InventoryManagment />} />
                    <Route path="/cash-register-list" element={<CashRegisterList />} />
                    <Route path="/vendor-management-dashboard" element={<VendorManagement />} />
                    <Route path="/shortcut-keys" element={<Shortcuts />} />
                    <Route path="/cardMainComponent" element={<CardMainComponent />} />
                    <Route path="/order-list" element={<OrdersList />} />
                    <Route path="/food-management-dashboard/recipe-list" element={<RecipeList />} />
                    <Route path="/food-management-dashboard/addon-list" element={<AddOnList />} />
                    <Route path="/food-management-dashboard/food-list" element={<FoodList />} />
                    <Route path="/food-management-dashboard/addon-list/update-addons/:serialNo" element={<EditAddon />} />
                    <Route path="/vendor-management-dashboard/vendor-list" element={<VendorList />} />
                    <Route path="/vendor-management-dashboard/vendor-Payment-list" element={<VendorPaymentList />} />
                    <Route path="/vendor-management-dashboard/vendor-Inventory-list" element={<VendorInventoryList />} />
                    <Route path="/user-role-management-dashboard/users-role-list" element={<UserRoleList />} />
                    <Route path="/cash-management-dashboard/cash-register" element={<CashRegister />} />
                    <Route path="/user-role-management-dashboard/user-roles-access" element={<UserRoleAccess />} />
                    <Route path="/cash-management-dashboard/cash-register-list" element={<CashRegisterList />} />
                    <Route path="/profile-settings/store-settings" element={<StoreSettings />} />
                    <Route path="/profile-settings/payment-settings" element={<PaymentSettings />} />
                    <Route path="/profile-settings/tax-settings" element={<TaxSettings />} />
                    <Route path="/profile-settings/pos-settings" element={<PosSettings />} />
                    <Route path="/profile-settings/pos-settings/update-subcategory/:id" element={<EditPosSettings />} />
                    <Route path="/pending-order" element={<PendingOrder />} />
                    <Route path="/kitchen-order-ticket" element={<PendingOrder />} />
                    <Route path="/user-role-management-dashboard/update-user-roles-access" element={<UpdateRoleAccess />} />
                    <Route path="/inventory-management-dashboard/inventory-list/update-inventory/:serialNo" element={<EditInventory />} />
                    <Route path="/profile-settings/payment-settings/update-payment-details/:paymentId" element={<EditPaymentSettingsForm />} />
                    <Route path="/food-management-dashboard/recipe-list/update-recipe/:serialNo" element={<EditRecipe />} />
                    <Route path="/profile-settings/tax-settings/update-tax/:serialNo" element={<EditTax />} />
                    <Route path="/food-management-dashboard/food-list/edit-food/:serialNo" element={<EditFood />} />
                    <Route path="/quick-bill" element={<QuickBill />} />
                    <Route path="/update-order/:serialNo" element={<UpdateOrder />} />
                    <Route path="/food-management-dashboard/addon-list/update-addons/:serialNo" element={<EditAddons />} />
                    <Route path="/vendor-management-dashboard/vendor-Payment-list/edit-vendor-payment/:serialNo" element={<EditVendorPayment />} />
                    <Route path="/vendor-management-dashboard/vendor-Inventory-list/update-vendor-inventory/:serialNo" element={<EditVendorInventory />} />
                    <Route path="/reports-management-dashboard" element={<Reports />} />
                  </>
                )}

                {/* User Routes with Role Permission */}
                {userRoles.includes("ROLE_USER") && (
                  <>
                  {/* Render routes for menus */}
                    {menuData.map((menuItem) => {
                      if (menuItem.status) {
                        return (
                          <Route
                            key={menuItem.menuId}
                            path={menuItem.path}
                            element={
                              menuItem.path === "/user-dashboard" ? (
                                <UserDashboard />
                              ) : menuItem.path === "/cash-management-dashboard" ? (
                                <CashManagement />
                              ) : menuItem.path === "/order-management-dashboard" ? (
                                <OrderManagement />
                              ) : menuItem.path === "/inventory-management-dashboard" ? (
                                <InventoryManagment />
                              ) : menuItem.path === "/vendor-management-dashboard" ? (
                                <VendorManagement />
                              ) : menuItem.path === "/food-management-dashboard" ? (
                                <FoodManagement />
                              ) : menuItem.path === "/user-management-dashboard" ? (
                                <UserManagement />
                              ) : menuItem.path === "/reports-management-dashboard" ? (
                                <Reports />
                              ) : menuItem.path === "/profile-settings" ? (
                                <UserProfileSettings />
                              ) : null
                            }
                          />
                        );
                      }
                    })}
                    {/* Render routes for submenus */}
                    {menuData.map((menuItem) => {
                      if (menuItem.subMenu && menuItem.subMenu.length > 0) {
                        return menuItem.subMenu.map((subMenuItem) => {
                          if (subMenuItem.status) {
                            return (
                              <Route
                                key={subMenuItem.id}
                                path={subMenuItem.path}
                                element={
                                  subMenuItem.path === "/new-order" ? (
                                    < NewOrder />
                                  ) : subMenuItem.path === "/pending-order" ? (
                                    <PendingOrder />
                                  ) : subMenuItem.path === "/kitchen-order-ticket" ? (
                                    <PendingOrder />
                                  ) : subMenuItem.path === "/order-list" ? (
                                    <OrdersList />
                                  ) : subMenuItem.path === "/cash-management-dashboard/cash-register" ? (
                                    <CashRegister />
                                  ) : subMenuItem.path === "/cash-management-dashboard/cash-register-list" ? (
                                    <CashRegisterList />
                                  ) : subMenuItem.path === "/inventory-management-dashboard/add-inventory" ? (
                                    <AddInventory />
                                  ) : subMenuItem.path === "/inventory-management-dashboard/inventory-list" ? (
                                    <InventoryList />
                                  ) : subMenuItem.path === "vendor-management-dashboard/add-vendor" ? (
                                    <AddVendor />
                                  ) : subMenuItem.path === "/vendor-management-dashboard/vendor-payment-details" ? (
                                    <VendorPayment />
                                  ) : subMenuItem.path === "/vendor-management-dashboard/vendor-inventory" ? (
                                    <VendorInventory />
                                  ) : subMenuItem.path === "/vendor-management-dashboard/vendor-list" ? (
                                    <VendorList />
                                  ) : subMenuItem.path === "/vendor-management-dashboard/vendor-Payment-list" ? (
                                    <VendorPaymentList />
                                  ) : subMenuItem.path === "/vendor-management-dashboard/vendor-Inventory-list" ? (
                                    <VendorInventoryList />
                                  ) : subMenuItem.path === "/food-management-dashboard/add-menu" ? (
                                    <AddMenu />
                                  ) : subMenuItem.path === "/food-management-dashboard/food-list" ? (
                                    <FoodList />
                                  ) : subMenuItem.path === "/food-management-dashboard/add-addons" ? (
                                    <AddAddons />
                                  ) : subMenuItem.path === "/food-management-dashboard/addon-list" ? (
                                    <AddOnList />
                                  ) : subMenuItem.path === "/food-management-dashboard/add-recipe" ? (
                                    <AddRecipe />
                                  ) : subMenuItem.path === "/food-management-dashboard/recipe-list" ? (
                                    <RecipeList />
                                  ) : subMenuItem.path === "/user-management-dashboard/add-user" ? (
                                    <AddUser />
                                  ) : subMenuItem.path === "/user-management-dashboard/users-list" ? (
                                    <UserList />
                                  ) : subMenuItem.path === "/user-role-management-dashboard/user-roles-access" ? (
                                    <UserRoleAccess />
                                  ) : subMenuItem.path === "/user-role-management-dashboard/users-role-list" ? (
                                    <UserRoleList />
                                  ) : subMenuItem.path === "/user-role-management-dashboard/update-user-roles-access" ? (
                                    <UpdateRoleAccess />
                                  ) : subMenuItem.path === "/profile-settings/store-settings" ? (
                                    <StoreSettings />
                                  ) : subMenuItem.path === "/profile-settings/payment-settings" ? (
                                    <PaymentSettings />
                                  ) : subMenuItem.path === "/profile-settings/tax-settings" ? (
                                    <TaxSettings />
                                  ) : subMenuItem.path === "/profile-settings/pos-settings" ? (
                                    <PosSettings />
                                  ) : null
                                }
                              />
                            );
                          } else {
                            return null;
                          }
                        });
                      } else {
                        return null;
                      }
                    })}
                  </>
                )}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Routes>
            <Route path="/*" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
};

export default Routers;
