import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Modal,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState, useEffect, useHistory, useCallback } from "react";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import axios from "axios";
import InventoryIcon from "@mui/icons-material/Inventory";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useTableContext } from "../../Screens/tableContext/TableContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddInventory from "./AddInventory";
import { useSelector } from "react-redux";
import PopOverModal from "../Cards/Modal/PopOverModal";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function InventoryList() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [inventryList, setInventoryList] = useState([]);
  const rowsPerPage = 10;
  const currentUser = useSelector((state) => state.auth.user.data);
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableCellStyles,
    editAndDeleteButtonStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles,
    listTabStyles,
    listTabsStyles,
    fromAndToTextStyles,
    dateFieldStyles,
    filterAndCancelIcon,
    iconColor,dailyStyle
  } = useDrawer();

  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    getFontWeight,
    generatePdf,
    downloadExcel,
    resetSearchInput, resetCurrentPage,

  } = useTableContext();
  const [currentPage, setCurrentPage] = useState(1);
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const [value, setValue] = useState("");
  const [total, settotal] = useState([]);
 

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/inventory-management-dashboard/add-inventory");
  };
  const tableHeaders = [
    { label: "SR No." },
    { label: "Product Name" },
    { label: "Issued Date" },
    { label: "Product Code" },
    { label: "Quantity" },
    { label: "Unit" },
    { label: "Category" },
    { label: "Price" },
    { label: "Expiry Date" },
    { label: "Action" },
   
  ];

  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
    resetCurrentPage();
  }, [location.pathname]);
  
  const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
const handleClose = () => {
  setOpen(false);
};
  const handleOpen = (serialNo) => {
     setSerialNoToDelete(serialNo); 
      setOpen(true);
  };
  const handleDeleteId = async (id) => {
    try {
      const response = await axios.delete(
        `https://billing.ubsbill.com/apptest/v2/sys/inventory/${id}`
      );

      if (response.data.status === true) {
       showSuccessSnackbar("Invetory Deleted successfully.. !");
        handleClose();
        fetchData();
      }
    } catch (error) {
      console.error("Error occurred while deleting Inventory Data:", error);
      // Handle error appropriately
    }
  };

  const [reorderedData, setReorderedData] = useState([]);
  const fetchData = async () => {
    try {
      const url =
        fromDate && toDate
          ? `https://billing.ubsbill.com/apptest/v2/sys/inventory/store/${currentUser.storeid}?page=${currentPage -1}&size=10&startDate=${fromDate}&endDate=${toDate}`
          : `https://billing.ubsbill.com/apptest/v2/sys/inventory/store/${currentUser.storeid}?page=${currentPage -1}&size=10`;
      const response = await fetch(url);
      const json = await response.json();
      const reorderedData = json.data.inventoryList.map((inventory , index) => ({
        id: index+1,
        name: inventory.name, 
        inventoryDate: inventory.inventoryDate,
        inventoryCode: inventory.inventoryCode,
        quantity: inventory.quantity,
        unit: inventory.unit,
        category: inventory.category,
        price: inventory.price,
        expiryDate: inventory.expiryDate,
    }));
      setReorderedData(reorderedData);
      setInventoryList(json.data.inventoryList);
      settotal(json.data.totalCount);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, currentPage]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const filterDailyRecords = () => {
    const today = new Date();
    const fromDateFormatted = formatDate(today);
    const toDateFormatted = formatDate(today);

    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);
    resetCurrentPage();
  };

  const filterMonthlyRecords = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const fromDateFormatted = formatDate(firstDayOfMonth);
    const toDateFormatted = formatDate(lastDayOfMonth);
    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);
    resetCurrentPage();
  };

  const filterYearlyRecords = () => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

    const fromDateFormatted = formatDate(firstDayOfYear);
    const toDateFormatted = formatDate(lastDayOfYear);

    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);
    resetCurrentPage();
  };


  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isThisMonth = (date) => {
    const today = new Date();
    return (
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isThisYear = (date) => {
    const today = new Date();
    return date.getFullYear() === today.getFullYear();
  };

 
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        filterDailyRecords();
        break;
      case 1:
        filterMonthlyRecords();
        break;
      case 2:
        filterYearlyRecords();
        break;
      default:
        break;
    }
  };

  const filteredRows = filterRows(inventryList);
  const filteredRowsBySearch = filteredRows.filter((inventoryItem) =>
    Object.values(inventoryItem).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  
  const displayedRows = filteredRowsBySearch;

  const downloadPDF = () => {
    generatePdf("InventoryList", tableHeaders, reorderedData);
  };

 

  
  
  const handleCheckboxChange = (index) => {
      setSelectedCheckboxes((prevSelected) => {
       const newSelected = [...prevSelected];
       newSelected[index] = !newSelected[index];
       return newSelected;
      });
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
const areAnyCheckboxesChecked = selectedCheckboxes.some((isChecked) => isChecked);

const handleSelectAllChange = useCallback(() => {
  setSelectedCheckboxes((prevSelected) => {
      
      if (prevSelected.every((isChecked) => isChecked)) {
          return Array.from({ length: inventryList.length }).fill(false);
      } else {
          // Otherwise, check all checkboxes
          return Array.from({ length: inventryList.length }).fill(true);
      }
  });
}, [inventryList]);





const handleClearFields = () => {
  setFromDate(``);
  setToDate(``);
  resetSearchInput();   
  setValue("");
};


  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box display="flex" bgcolor="primary.light" sx={listMainBox}>
            <InventoryIcon sx={listIconStyles} />
            <Typography variant="span" sx={{ ml: "8px", color: isDarkMode ? "#fff" :"#fff",  }}>
              Inventory List
            </Typography>
          </Box>
          <Box sx={spaceBetweenTopComponent}>
            <Button
              onClick={handleNavigate}
              to="/inventory-management-dashboard/add-inventory"
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles}
            >
              + Inventory
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              onClick={downloadPDF}
              sx={pdfAndExcelButtonStyles}
            >
              PDF
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              onClick={() =>
                downloadExcel(tableHeaders, reorderedData, "InventoryList")
              }
              sx={pdfAndExcelButtonStyles}
            >
              Excel
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
          <Box sx={searchboxStyles}>
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
            <Divider
              orientation="vertical"
              sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
            />
            <Input
              fullWidth
              placeholder="Search"
              sx={{ color: isDarkMode ? "#fff" : "#000",}}
              disableUnderline
              value={searchInput}
              onChange={handleSearchChange}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={4.2} md={4} lg={2.4} xl={2.6}>
          <Box
            
            sx={dailyStyle}
            
          >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="white"
              indicatorColor="white"
              aria-label="tabs"
              sx={listTabsStyles}
            >
              <Tab
                label="Daily"
                value={0}
                className="tabs"
                sx={{
                  ...listTabStyles,
                 bgcolor: value === 0 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 0 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),                
                }}
              />
              <Tab
                label="Monthly"
                value={1}
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 1 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 1 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
              <Tab
                label="Yearly"
                value={2}
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 2 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 2 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={7} sm={4} md={4} lg={2} xl={2.1}>
          <Box  p={1} sx={fromAndToTextStyles}>
            From
            <Input
              fullWidth
              type="date"
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              value={fromDate}
              sx={dateFieldStyles}
              onChange={(e) => setFromDate(e.target.value)}
              disableUnderline
            />
          </Box>
        </Grid>
        <Grid item xs={7} sm={4} md={4} lg={2} xl={2.1}>
          <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
            To
            <Input
              fullWidth
              type="date"
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              value={toDate}
              sx={dateFieldStyles}
              onChange={(e) => setToDate(e.target.value)}
              disableUnderline
            />
          </Box>
        </Grid>
        <Grid item>
          <Box bgcolor="#D9D9D9" p={0.5} sx={filterAndCancelIcon}>
            <IconButton onClick={fetchData}>
              <FilterAltIcon sx={iconColor}/>
            </IconButton>
          </Box>
        </Grid>
        <Grid item>
          <Box bgcolor="#D9D9D9" sx={filterAndCancelIcon}>
            <IconButton onClick={handleClearFields}>
              <CloseIcon sx={iconColor} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item>
      
            </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>
          <Table aria-label="simple table">
            <TableHead sx={tableHeadStyles}>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    className="all-list-tabelcell"
                    align="right"
                    sx={tableCellStyles}
                  >
                    {header.label}
                    
                  
                    
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody>
              {displayedRows.map((row, index) => (
                <TableRow key={index} sx={contentTableRowStyles}>
                  {renderStyledCell(startIndex + index + 1, getFontWeight(startIndex + index + 1))}
                  {renderStyledCell(row.name, getFontWeight(row.name))}
                  {renderStyledCell(
                    row.inventoryDate,
                    getFontWeight(row.inventoryDate)
                  )}
                  {renderStyledCell(
                    row.inventoryCode,
                    getFontWeight(row.inventoryCode)
                  )}
                  {renderStyledCell(row.quantity, getFontWeight(row.quantity))}
                  {renderStyledCell(row.unit, getFontWeight(row.unit))}
                  {renderStyledCell(row.category, getFontWeight(row.category))}
                  {renderStyledCell(row.price, getFontWeight(row.price))}
                  {renderStyledCell(
                    row.expiryDate,
                    getFontWeight(row.expiryDate)
                  )}
                  <TableCell sx={contentTableCellStyles} align="left">
                    <Box className="align-items-center">
                      <Box
                      component={Link} to={`/inventory-management-dashboard/inventory-list/update-inventory/${row.serialNo}`}
                        className="justify-all-center"
                        sx={{
                          ...editAndDeleteButtonStyles,
                          border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                        }}
                      >
                        <ModeEditOutlineRoundedIcon sx={{ color: isDarkMode ?  "white" : "#4FC3A1" }} />
                      </Box>
                      <Box
                        className="justify-all-center"
                        sx={{
                          ...editAndDeleteButtonStyles,
                          border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                        }}
                      >
                        <DeleteIcon
                          sx={{ color: isDarkMode ?  "white" : "#f55651" }}
                          onClick={() => handleOpen(row.serialNo)}
                        />
                      </Box>
                   
                    </Box>
                  </TableCell>
                 
                </TableRow>
              ))}
                 <Modal
                           open={open}
                           onClose={handleClose}
                           aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description"
                          >
                          <PopOverModal
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handleDeleteId(serialNoToDelete)}
                              onClickTwo={handleClose}
                              />
                              </Modal>
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
        <Pagination
            count={total}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default InventoryList;