import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Hidden,
  IconButton,
  Input,
} from "@mui/material";
import React, { useState } from "react";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useDispatch, useSelector } from "react-redux";
import { add, selectTotalQuantity } from "../../Screens/store/foodSlice";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

export const QuickOrderFoodCard = ({ food }) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
 const { openCart } = useDrawer();
 const dispatch = useDispatch();
  const addToOrder=(food) =>{
dispatch(add(food))
 }
 
  const total = useSelector(state => state.cart.total);
  const totalQuantity = useSelector(selectTotalQuantity);
  return (
    <Grid
      key={food.id}
      item
      xl={3.5}
      lg={3.7}
      md={3.5}
      sm={2.8}
      xs={5.5}
      sx={{
        margin: { sm: "4px", md: "4px", lg: "0px 6px 12px", xs: "4px", xl: "0px 10px 15px" }, 
      }}
    >
      <Card
       onClick={() => addToOrder(food)}
        sx={{
          borderRadius: "9px",
          display: "flex",
          cursor: "pointer",
          borderRight: (() => {
            if (food.subCategory === "Veg") {
              return {
                xs: "3px solid #47C01C",
                sm: "3px solid #47C01C",
                md: "5px solid #47C01C",
                lg: "5px solid #47C01C",
              };
            } else if (food.subCategory === "Nonveg") {
              return {
                xs: "3px solid #C62717",
                sm: "3px solid #C62717",
                md: "5px solid #C62717",
                lg: "5px solid #C62717",
              };
            } else if (food.subCategory === "Egg") {
              return {
                xs: "3px solid #f2f536",
                sm: "3px solid #f2f536",
                md: "6px solid #f2f536",
                lg: "6px solid #f2f536",
              };
            }
          })(),

          flexDirection: {
            sm: "column",
            md: "column",
            lg: "row",
            xs: "column",
            xl: "row",
          },
          padding: { sm: "8px", md: "3px", lg: "8px", xs: "8px" , xs: "0px"},
          background: {
            sm: isDarkMode ? "#5e5e5e" : "#fff", 
            md: isDarkMode ? "#5e5e5e" : "#EBEBEB",
            lg: isDarkMode ? "#5e5e5e" : "#EBEBEB",
            xs: isDarkMode ? "#5e5e5e" : "#fff",
          },
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          position: "relative",
          minHeight: {sm: "90%", md: "90%", lg: 120, xs: "90%", xl: 125 },
          maxHeight: {sm: "90%", md: "90%", lg: 120, xs: "90%", xl: 125 },

          '&:active': {
            animation: 'beat 0.2s',
          },
          '@keyframes beat': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(0.95)' },
            '100%': { transform: 'scale(1)' },
          },
        }}
      >
        {food.image && (
        <CardMedia
          component="img"
          sx={{
            maxWidth: { sm: "100%", md: "97%", lg: 90, xs: "100%", xl: 90 },
            aspectRatio: "3/2",
            objectFit: "cover",
            borderRadius: "10px",
            boxShadow: "0px 2px 8.300000190734863px 0px #000000",
          }}
          image={`https://drive.google.com/thumbnail?id=` +food.image}
          alt={food.foodName}
        />
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent
            sx={{
              flex: "1 0 auto",
              display: "flex",
              flexDirection: "column",
              gap:{md: 0.6, lg:2 , xl: 2}
            }}
          >
            <CustomTypography
              component="div"
              variant="h5"
              title={food.foodName}
              className="food-card-text"
              sx={{
                textTransform: "capitalize",
                fontWeight: 500,
               
                lineHeight: { sm: "23px", lg: "25px", xl: "20px", md: "20px" },
              
                fontSize: {
                  xl: "16px",
                  lg: "14px",
                  md: "14px",
                  xs: "12px",
                  sm: "16px",
                },
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {food.foodName}
            </CustomTypography>
            <CustomTypography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              className="food-card-text"
              sx={{
                color: "#000000",
                fontWeight: "500",
                fontSize: {
                  xl: "20px",
                  lg: "16px",
                  md: "16px",
                  xs: "14px",
                  sm: "17px",
                },
              }}
            >
              ₹{`${food.price}`}
            </CustomTypography>
           
          </CardContent>
        </Box>
      </Card>
      {
    totalQuantity !== 0 && total !== 0 ? (
      <Hidden mdUp>
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            right: "0",
            width: { sm: "calc(100vw - 60px)", xs: "100vw" },
            height: { sm: "70px", xs: "60px" },
            background: "#03989e",
            display:"flex",
            alignItems:"center"
           
          }}
          className=" cart-bar-color"
        >
          <Box
          
            sx={{
              width: "100%",
              display:"flex",
              alignItems:"center",
              justifyContent:"space-between",
              padding:{ sm: "0px 40px", xs: "0px 10px" },
            }}
          >
             <CustomTypography variant="h7" sx={{ color: "#fff" }}>
            {totalQuantity} Item | ₹{total}
            </CustomTypography>

            <Button
              variant="h7"
              onClick={openCart}
              sx={{
                color: "#fff",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              SHOW CART <LocalMallIcon sx={{ marginLeft: "5px" }} />
            </Button>
          </Box>
        </Box>
      </Hidden>
      ) : null }
    </Grid>
  );
};
