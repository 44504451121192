import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Input,
  InputAdornment,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useTableContext } from "../../Screens/tableContext/TableContext";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import PopOverModal from "../Cards/Modal/PopOverModal";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function RecipeList() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const rowsPerPage = 10;
  const [Recipe, setRecipeList] = useState([]);
  const currentUser = useSelector((state) => state.auth.user.data);
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableCellStyles,
    editAndDeleteButtonStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles
  } = useDrawer();

  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    getFontWeight,
    generatePdf,
    downloadExcel,
    currentPage,
    handleChangePage, resetSearchInput,resetCurrentPage,
  } = useTableContext();

  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
    resetCurrentPage();
  }, [location.pathname]);

  const tableHeaders = [
    { label: "SR No." },
    { label: "Recipe Name" },
    { label: "Ingredient Name" },
    { label: "Ingredient Quantity" },
    { label: "Ingredient Unit" },
    { label: "Action" },
  ];
 

  const filteredRows = filterRows(Recipe);
  const downloadPDF = () => {
    generatePdf("RecipeList", tableHeaders, filteredRows);
  };
 
  const fetchRecipeList = () => {
    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/receipe/store/${currentUser.storeid}`)
      .then((response) => {
        // Map over each recipe and extract relevant data
        const reorderedData = [];
        response.data.data.forEach((recipe) => {
          // For each recipe, loop over its ingredients and create a separate entry for each ingredient
          recipe.ingredients.forEach((ingredient) => {
            reorderedData.push({
              serialNo: recipe.serialNo,
              productName: recipe.name,
              ingredientName: ingredient.name,
              quantity: ingredient.quantity,
              unit: ingredient.unit
            });
          });
        });

        // Set the reordered data as the recipe list
        setRecipeList(reorderedData);

      })
      .catch((error) => {
        // Handle the error if needed
        console.error("Error fetching recipe list:", error);
      });
  };

  

  useEffect(() => {
    fetchRecipeList();
  }, []);


  const filteredRowsBySearch = filteredRows.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = filteredRowsBySearch.slice(startIndex, endIndex);
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();

  const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
    const handleOpen = (serialNo) => {
       setSerialNoToDelete(serialNo); 
        setOpen(true);
    };
  const handleRecipeDelete = async (serialNo) => {

    try {
      const response = await axios.delete(
        `https://billing.ubsbill.com/apptest/v2/sys/receipe/${serialNo}`
      );
   
      if (response.data.status === true) {
       showSuccessSnackbar("Recipe Deleted successfully.. !");
       fetchRecipeList();
        handleClose();
       
      }
    } catch (error) {
      console.error("Error occurred while deleting Recipe Data:", error);
      showErrorSnackbar("Error occurred while deleting Recipe Data:", error);
    }
  };
  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box display="flex" max bgcolor="primary.light" sx={listMainBox}>
            <FastfoodIcon sx={listIconStyles} /> {/* Icon */}
            <Typography variant="span" sx={{ ml: "8px" ,  color: isDarkMode ? "#fff" :"#fff", }}>
              Recipe List
            </Typography>
          </Box>
          <Box sx={spaceBetweenTopComponent}>
            <Box bgcolor="#D9D9D9" p={1} sx={searchboxStyles}>
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
              <Divider
                orientation="vertical"
                sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
              />
              <Input
                fullWidth
                placeholder="Search"
                sx={{ color: isDarkMode ? "#fff" : "#000",}}
                disableUnderline
                value={searchInput}
                onChange={handleSearchChange}
              />
            </Box>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles}
              component={Link}
              to="/food-management-dashboard/add-recipe"
            >
              <span style={{ marginRight: "5px" }}>+</span> Recipe
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              onClick={downloadPDF}
              sx={pdfAndExcelButtonStyles}
            >
              PDF
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              onClick={() =>
                downloadExcel(tableHeaders, filteredRows, "RecipeList")
              }
              sx={pdfAndExcelButtonStyles}
            >
              Excel
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>
          <Table aria-label="simple table">
            <TableHead sx={tableHeadStyles}>
              <TableRow>
                {tableHeaders.map((cell, index) => (
                  <TableCell
                    key={index}
                    className="all-list-tabelcell"
                    align="right"
                    sx={tableCellStyles}
                  >
                    {cell.label}
                    {cell.checkbox && (
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody>
              {displayedRows.slice().reverse().map((item, index) => (
               
                    <TableRow key={index} sx={contentTableRowStyles}>
                      {renderStyledCell(startIndex + index + 1, getFontWeight(startIndex + index + 1))}
                      {renderStyledCell(item.productName, getFontWeight(item.productName))}
                      {renderStyledCell(item.ingredientName, getFontWeight(item.ingredientName) )}
                      {renderStyledCell(item.quantity, getFontWeight(item.quantity) )}
                      {renderStyledCell( item.unit, getFontWeight(item.unit) )}
                      <TableCell sx={contentTableCellStyles} align="left">
                        <Box className="align-items-center">
                          <Box
                            className="justify-all-center"
                            component={Link} to={`/food-management-dashboard/recipe-list/update-recipe/${item.serialNo}`}
                            sx={{
                              ...editAndDeleteButtonStyles,
                              border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                            }}
                          >
                            <ModeEditOutlineRoundedIcon
                              sx={{ color: isDarkMode ?  "white" : "#4FC3A1" }}
                            />
                          </Box>
                          <Box
                            className="justify-all-center"
                            onClick={() => handleOpen(item.serialNo)}
                            sx={{
                              ...editAndDeleteButtonStyles,
                              border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                            }}
                          >
                            <DeleteIcon sx={{color: isDarkMode ?  "white" : "#f55651" }} />
                          </Box>
                        
                        </Box>
                      </TableCell>

                    </TableRow>
                
              ))}
                <Modal
                           open={open}
                           onClose={handleClose}
                           aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description"
                          >
                          <PopOverModal
                              
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handleRecipeDelete(serialNoToDelete)}
                              onClickTwo={handleClose}
                              />
                              </Modal>
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
          <Pagination
            count={Math.ceil(filteredRows.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecipeList;
