import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import DrawerLink from "./DrawerLink";
import { Drawer, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDrawer } from "./DrawerContext";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

export default function Sidebar() {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const { isDrawerOpen, handleDrawerOpen, handleDrawerClose } = useDrawer();
  const theme = useTheme();
  const drawerRef = useRef(null);
  const [currentRoute, setCurrentRoute] = useState("");
  const currentUser = useSelector((state) => state.auth.user?.data);
  const userRoles = currentUser.roles || [];

  const navigate = useNavigate();
  const handleNavigation = (navigationLink) => {
    navigate(navigationLink);
    setCurrentRoute(navigationLink);
  };

  // const fetchData = () => {
  //   fetch("https://billing.ubsbill.com/apptest/v2/sys/adminmenu/getbystoreidandstatus/10")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setNavigationLinks(json.data || []); // Ensure setting an empty array if no data is fetched
  //      
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);



  const navLinks = [
    {
      id: 1,
      name: "Dashboard",
      routeLink: "/dashboard",
      image: "../images/sidebar/sidebar-vector-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 2,
      name: "Order Management",
      routeLink: "/order-management-dashboard",
      image: "../images/sidebar/sidebar-order-mgmt-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 3,
      name: "Cash Management",
      routeLink: "/cash-management-dashboard",
      image: "../images/sidebar/sidebar-cash-register-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 4,
      name: "Inventory Management",
      routeLink: "/inventory-management-dashboard",
      image: "../images/sidebar/sidebar-inventory-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 5,
      name: "Vendor Management",
      routeLink: "/vendor-management-dashboard",
      image: "../images/sidebar/sidebar-cart-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 6,
      name: "Food Management",
      routeLink: "/food-management-dashboard",
      image: "../images/sidebar/sidebar-food-managent-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 7,
      name: "User Management",
      routeLink: "/user-management-dashboard",
      image: "../images/sidebar/sidebar-profile-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 8,
      name: "Role Permission",
      routeLink: "/user-role-management-dashboard",
      image: "../images/sidebar/sidebar-lock-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 9,
      name: "Reports",
      routeLink: "/reports",
      image: "../images/sidebar/sidebar-report-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 10,
      name: "Profile Settings",
      routeLink: "/profile-settings",
      image: "../images/sidebar/sidebar-setting-icon.png",
      requiredRoles: ["ROLE_ADMIN"]

    },
    {
      id: 11,
      name: "Shortcut Keys",
      routeLink: "/shortcut-keys",
      image: "../images/sidebar/sidebar-shortcut-icon.png",
      requiredRoles: ["ROLE_ADMIN"]
    },
    {
      id: 13,
      name: "Dashboard",
      routeLink: "/technician-dashboard",
      image: "../images/sidebar/sidebar-vector-icon.png",
      requiredRoles: ["ROLE_SUPPORT"]
    },
    {
      id: 14,
      name: "Store Management",
      routeLink: "/store-management-dashboard",
      image: "../images/sidebar/sidebar-shortcut-icon.png",
      requiredRoles: ["ROLE_SUPER_ADMIN", "ROLE_SUPPORT"]
    },
    {
      id: 15,
      name: "Technician Management ",
      routeLink: "/technician-management-dashboard",
      image: "../images/store-settings-icon.png",
      requiredRoles: ["ROLE_SUPER_ADMIN"]
    },
    {
      id: 16,
      name: "Profile Settings ",
      routeLink: "/superadmin-profile-settings",
      image: "../images/sidebar/sidebar-setting-icon.png",
      requiredRoles: ["ROLE_SUPER_ADMIN"]
    },
    {
      id: 17,
      name: "Profile Settings ",
      routeLink: "/technician-profile-settings",
      image: "../images/sidebar/sidebar-setting-icon.png",
      requiredRoles: ["ROLE_SUPPORT"]
    },
    {
      id: 18,
      name: "Notification management",
      routeLink: "/push-notification",
      image: "../images/sidebar/sidebar-setting-icon.png",
      requiredRoles: ["ROLE_SUPPORT"]
    },
  ];
  const filteredNavLinks = navLinks.filter(link => {
    return link.requiredRoles.some(role => userRoles.includes(role));
  });
  const [navigationLinks, setNavigationLinks] = useState([]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={isDrawerOpen}
        ref={drawerRef}
        PaperProps={{
          sx: {
            backgroundColor: isDarkMode ? "#404040" : "#EAFCFC",
            border: "none",
            alignItems: "flex-start",

            top: {xs: "62px" , sm: "72px"},
            width: {
              xs: isDrawerOpen ? "200px" : "0px",
              sm: isDrawerOpen ? "200px" : "60px",
              md: isDrawerOpen ? "220px" : "60px",
              lg: isDrawerOpen ? "270px" : "60px",
              xl: "300px",
            },


            transition: "width 0.3s ease",
          },
        }}
      >
        {filteredNavLinks.map((menu) => (
          <DrawerLink
            key={menu.id}
            name={menu.name}
            route={menu.routeLink}
            image={menu.image}
            handleNavigation={handleNavigation}
            currentRoute={currentRoute}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={handleDrawerClose}
          />
        ))}
      </Drawer>
    </Box>
  );
}
