import React, { useEffect, useState } from "react";
import { Alert, Box, CssBaseline, Drawer } from "@mui/material";
import DrawerLink from "./DrawerLink";
import { useNavigate } from "react-router-dom";
import { useDrawer } from "./DrawerContext";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

export default function UserSidebar() {
    const { isDarkMode, toggleDarkMode } = useDarkMode();
    const { isDrawerOpen, handleDrawerClose } = useDrawer();
    const [currentRoute, setCurrentRoute] = useState("");
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.auth.user.data);
    const userRoles = currentUser.roles || [];
    const [navLinks, setNavLinks] = useState([]);

    const handleNavigation = (navigationLink) => {
        navigate(navigationLink);
        setCurrentRoute(navigationLink);
    };

    useEffect(() => {
        if (userRoles.includes("ROLE_USER")) {
            axios.get(`https://billing.ubsbill.com/apptest/v2/sys/usersidebar/username?username=${currentUser.username}`)
                .then(response => {
                    const menuData = response.data.data;
                    if (typeof menuData === 'object' && menuData.menu && Array.isArray(menuData.menu)) {
                        const allMenus = menuData.menu.reduce((acc, curr) => {
                            acc.push(curr);
                            return acc;
                        }, []);
                        const menuTitles = allMenus
                            .filter(menu => menu.status === true)
                            .map(menu => ({
                                id: menu.menuId,
                                title: menu.title,
                                route: menu.path,
                                image: menu.icon
                            }));
                        setNavLinks(menuTitles);
                       
                    } else {
                        console.error("Invalid menu data format:", menuData);
                    }
                })
                .catch(error => {
                    console.error("Error fetching menu data:", error);
                });
        } else {
            setNavLinks([
            ]);
        }
    }, [userRoles]);
    
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                open={isDrawerOpen}
                PaperProps={{
                    sx: {
                        backgroundColor: isDarkMode ? "#404040" : "#EAFCFC",
                        border: "none",
                        alignItems: "flex-start",
                        top: "72px",
                        width: {
                            xs: isDrawerOpen ? "200px" : "0px",
                            sm: isDrawerOpen ? "200px" : "60px",
                            md: isDrawerOpen ? "220px" : "60px",
                            lg: isDrawerOpen ? "270px" : "60px",
                            xl: "300px",
                        },
                        transition: "width 0.3s ease",
                    },
                }}
            >
               {navLinks.length > 0 ? (
                    navLinks.map((menu) => (
                        <DrawerLink
                            key={menu.id}
                            name={menu.title}
                            route={menu.route}
                            image={menu.image}
                            handleNavigation={handleNavigation}
                            currentRoute={currentRoute}
                            isDrawerOpen={isDrawerOpen}
                            closeDrawer={handleDrawerClose}
                        />
                    ))
                ) : (
                    <>
                        <Box sx={{ marginTop: "50px" }}>
                            <Alert severity="error" sx={{ fontWeight: "600" }}>
                                No roles or permissions have been allocated to the user..!
                            </Alert>
                            <Alert severity="warning" sx={{ fontWeight: "600" }}>Contact to store admin..!</Alert>
                        </Box>
                    </>
                )}
            </Drawer>
        </Box>
    );
}
