import { Box, Button, Checkbox, Divider, Grid, IconButton, Input, InputAdornment, Modal, Pagination, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDrawer } from '../Sidebar/DrawerContext';
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useTableContext } from '../../Screens/tableContext/TableContext';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PopOverModal from '../Cards/Modal/PopOverModal';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';
function AddOnList() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data);
  const [Addon, setAddon] = useState([]);
  const rowsPerPage = 10;
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableCellStyles,
    editAndDeleteButtonStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles,
  } = useDrawer();

  const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
    const handleOpen = (serial_no) => {
       setSerialNoToDelete(serial_no); 
        setOpen(true);
    };

  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    getFontWeight, generatePdf, downloadExcel, currentPage, handleChangePage, resetSearchInput, resetCurrentPage
  } = useTableContext();

  const { showSuccessSnackbar } = useSuccessSnackbar();
  const [deleteTrigger , setDeleteTrigger] = useState ([]) ;

  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
    resetCurrentPage();
  }, [location.pathname]);

  const tableHeaders = [
    { label: "SR No." },
    { label: "Addon Name" },
    { label: "Addon Code" },
    { label: "Category" },
    { label: 'Sub Category' },
    { label: "Price" },
    // { label: "Image" },
    { label: "Action" },
    
  ];
  const filteredRows = filterRows(Addon);
  const downloadPDF = () => {
    generatePdf("AddonList", tableHeaders, reorderedData);
  };

  const handleDeleteId = async (serialNo) => {
    try {
      const response = await axios.delete(`https://billing.ubsbill.com/apptest/v2/sys/food/id/${serialNo}`);
      
      if (response.data.status === true) {

        showSuccessSnackbar("Addon Delete successfully.. !")
        handleClose();
      
        setDeleteTrigger(!deleteTrigger);
      }
    } catch (error) {
      console.error("Error occurred while updating order status:", error);
      // Handle error appropriately
    }
  };
  const [reorderedData , setReorderedData] = useState("");
  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/food/addon/${currentUser.storeid}`)
      .then((ressponse) => {

        const reorderedData = ressponse.data.data.map((add , index) => ({
          no:  index+1,
          foodName: add.foodName,
          foodCode: add.foodCode,
          category: add.category,
          subCategory: add.subCategory,
          price: add.price,
          image: add.image,
        }))
        setReorderedData(reorderedData);
        setAddon(ressponse.data.data);
      })
  }, [deleteTrigger])

  const filteredRowsBySearch = filteredRows.filter((row) =>
    Object.values(row).some(
      (value) =>
        value && value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = filteredRowsBySearch.slice(startIndex, endIndex);


 

  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box
            display="flex"
            max
            bgcolor="primary.light"
            sx={listMainBox}
          >
            <FastfoodIcon sx={listIconStyles} /> {/* Icon */}
            <Typography variant='span' sx={{ ml: "8px" , color: isDarkMode ? "#fff" :"#fff",  }}>Addon List</Typography>
          </Box>
          <Box sx={spaceBetweenTopComponent}>
            <Box
              bgcolor="#D9D9D9"
              p={1}
              sx={searchboxStyles}
            >
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
              <Divider orientation="vertical" sx={{ height: 24, mx: 1, backgroundColor: "#424242" }} />
              <Input
                fullWidth
                placeholder="Search"
                sx={{ color: isDarkMode ? "#fff" : "#000",}}
                disableUnderline
                value={searchInput}
                onChange={handleSearchChange}
              />
            </Box>
            <Button className='food-card-text mode-button border-radius-10 cart-button final-button' sx={pdfAndExcelButtonStyles} component={Link} to="/food-management-dashboard/add-addons">
              <span style={{ marginRight: '5px' }}>+</span> Addons
            </Button>
            <Button className='food-card-text mode-button border-radius-10 cart-button final-button' onClick={downloadPDF} sx={pdfAndExcelButtonStyles}>
              PDF
            </Button>
            <Button className='food-card-text mode-button border-radius-10 cart-button final-button'
              onClick={() => downloadExcel(tableHeaders, reorderedData, "AddonsList")}
              sx={pdfAndExcelButtonStyles}>
              Excel
            </Button></Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: '100%', overflowX: 'auto', borderRadius: 1 }}>
          <Table aria-label="simple table">
            <TableHead sx={tableHeadStyles}>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    className="all-list-tabelcell"
                    align="right"
                    sx={tableCellStyles}
                  >
                    {header.label}
                    {header.label === "Select All" && (
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>

            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody >
              {displayedRows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={contentTableRowStyles}
                >
                  {renderStyledCell(startIndex + index + 1, getFontWeight(startIndex + index + 1))}
                  {renderStyledCell(row.foodName, getFontWeight(row.foodName))}
                  {renderStyledCell(row.foodCode, getFontWeight(row.foodCode))}
                  {renderStyledCell(row.category, getFontWeight(row.category))}
                  {renderStyledCell(row.subCategory, getFontWeight(row.subCategory))}
                  {renderStyledCell(row.price, getFontWeight(row.price))}
                  {/* <TableCell sx={contentTableCellStyles} align="left">
                    {row.image && (
                      <img
                        height="60px"
                        width="60px"
                        src={`https://drive.google.com/thumbnail?id=` + row.image}
                        alt=""
                      />
                    )}
                  </TableCell> */}
                  <TableCell sx={contentTableCellStyles} align="left" >
                    <Box className="align-items-center">
                      <Box
                        className="justify-all-center"
                        component={Link} to={`/food-management-dashboard/addon-list/update-addons/${row.serial_no}`}
                        sx={{
                          ...editAndDeleteButtonStyles,
                          border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                        }}
                      >
                        <ModeEditOutlineRoundedIcon sx={{ color: isDarkMode ?  "white" : "#4FC3A1" }} />
                      </Box>
                      <Box
                        className="justify-all-center"
                        onClick={() => handleOpen(row.serial_no)} 
                        sx={{
                          ...editAndDeleteButtonStyles,
                          border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                        }}
                      >
                        <DeleteIcon sx={{  color: isDarkMode ?  "white" : "#f55651"  }} />
                      </Box>
                      <Modal
                           open={open}
                           onClose={handleClose}
                           aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description"
                          >
                          <PopOverModal
                              
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handleDeleteId(serialNoToDelete)}
                              onClickTwo={handleClose}
                              />
                              </Modal>
                    </Box>
                  </TableCell>
          
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
          <Pagination
          count={Math.ceil(filteredRows.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box >
  )
}

export default AddOnList;