import React, { useState } from "react";
import { Box, Button, Stack, FormControl,FormHelperText,Grid,Paper,CircularProgress,InputAdornment,Input,IconButton, Select, MenuItem} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import LockClockIcon from '@mui/icons-material/LockClock';
import axios from "axios";
import { useSuccessSnackbar } from "../../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../../Screens/snackbar/ErrorSnackbarProvider";
import { useDrawer } from "../../Sidebar/DrawerContext";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";

function FreeTrialSuperadmin() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { labelStyle, inputStyles,  formPaperStyles , formMainBox , selectStyles ,errorMessageStyles, submitButtonStyles, capitalizeFirstLetter, buttonDisabled } = useDrawer();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginLoader, setShowLoginLoader] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const currentUser = useSelector((state) => state.auth.user?.data);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const formDataFields = [
    { name: 'storename', label: 'Store Name', type: 'text' },
    { name: 'username', label: 'User Name', type: 'text' },
    {name: 'country', label: 'Select Country', type: 'select', options: [
          { value: 'Select Country', label: 'Select Country' },
          { value: 'india', label: 'India' },
          { value: 'nepal', label: 'nepal' },
          { value: 'china', label: 'China' },
        ]
      },
    { name: 'countryCode', label: 'Country Code', type: 'text' },
    { name: 'currency', label: 'Currency', type: 'text' },
    { name: 'state', label: 'State', type: 'text' },
    { name: 'pinCode', label: 'Pin Code', type: 'number' },
    { name: 'address', label: 'Personal Address', type: 'text' },
    { name: 'storeAddress', label: 'Store Address', type: 'text' },
    { name: 'contact', label: 'Store Contact', type: 'number' },
    { name: 'storeGST', label: 'Store GST', type: 'text' },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'upi', label: 'Upi', type: 'text' },
    { name: 'password', label: 'Password', type: '' },
    { name: 'comfirmpassword', label: 'Confirm Password', type: '' },
    { name: 'createdBy', label: 'Created By', type: 'text' },
    { name: 'updatedBy', label: 'Updated By', type: 'text' },
    { name: 'freeTrialType', label: 'Free Trial Days*', type: 'number' },
  ];

  const [formData, setFormData] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
    createdBy: currentUser.username,
    subscriptionType: "Free", 
    updatedBy: currentUser.username,
    freeTrialRequested:"true"
  });
  const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );

  const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,15}$/;
    const contactRegex = /^[6-9][0-9]{9}$/;
    const userNameRegex = /^(?=.*[A-Z])[a-zA-Z0-9_]{4,20}$/; 
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;
    const alphabeticRegexWithSpace = /^[a-zA-Z\s]+$/;
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}Z[\dA-Z]$/;
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    if (fieldName === "email") {
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;
      } else if (!emailRegex.test(value)) {
        error = "Please enter a valid email in the format username@example.com";
      }
    }
    if (fieldName === "storeContact") {
      if (!contactRegex.test(value)) {
        error = "Please enter a valid 10-digit contact number";
      }
    }
    else if (fieldName === "username" ){
      if (!userNameRegex.test(value)) {
         error = `Username Must Contain One Uppercase Letter & No Special Charaters Allowed and 20 below characters!`;
      }
  }

    if (fieldName === "password") {
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;
      } else if (!passwordRegex.test(value)) {
        error = `${capitalizedFieldName} Must Have One Upprcase Letter, One Special Charater & Atleast one Number`;
      }
    }
    if (fieldName === "comfirmpassword") {
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;
      } else if (value !== formData.password) {
        error = "The confirmed password does not match the entered password";
      }
    }
    else if (fieldName === "upi" || fieldName === "email" ) {
      if (value.length > 40) {
        error = `${capitalizedFieldName} cannot exceed 40 characters`;
      }
    }
    else if (fieldName === "storeName" || fieldName === "address"  || fieldName === "storeAddress" ) {
       if (value.length > 100) {
        error = `${capitalizedFieldName} cannot exceed 100 characters`;
      }
    }
    if (fieldName === "pinCode" || fieldName === "contact" ) {
      if (value <= 0) {
          error = `${capitalizedFieldName} should be a positive number`;
      }
    }
    if (fieldName === "gstNo") {
      if (!gstRegex.test(value)) {
        error = "Please enter a valid GST NO";
      }
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };


  const handleUser = async (e) => {
    e.preventDefault();
    setShowLoginLoader(true);
    if (Object.values(errors).every((error) => error === "")) {
      //Axios Call Methods here

      try {
        const response = await axios.post("https://billing.ubsbill.com/apptest/v2/api/auth/store/freeTrialRegistration", formData)
        if (response.data.status === true) {
          setFormData({
            ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
          });
          showSuccessSnackbar("Form Submit Successfull !");
        }

      } catch (error) {
        showErrorSnackbar(error.response.data.message);
      } finally {
        setShowLoginLoader(false);
      }
    }
  };

  const isSubmitDisabled = buttonDisabled(formData, errors);
  return (
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box display="flex" alignItems="center" bgcolor="primary.light" p={1}  sx={formMainBox}>
              <LockClockIcon fontSize="large" />
              <span style={{ marginLeft: '8px' }}>Free Trial Access</span>
            </Box>
          </Grid>
        </Grid>

        {Array.from({ length: 9 }).map((_, index) => (
          <Stack key={index} spacing={4} direction={{ xs: "column", sm: "row", md: "row", lg: "row", xl: "row" }} sx={{ marginBottom: 1 }}>
            {formDataFields
              .slice(index * 2, index * 2 + 2)
              .map((formDataItem, innerIndex) => (

                <FormControl key={innerIndex} fullWidth error={!!errors[formDataItem.name]} variant="outlined">
                  <CustomTypography htmlFor={`outlined-adornment-${formDataItem.name}`} className="food-card-text" sx={labelStyle}>
                    {formDataItem.label}
                  </CustomTypography>
                  {formDataItem.type === 'select' ? (
                    <Select
                      labelId={`select-${formDataItem.name}`}
                      id={`select-${formDataItem.name}`}
                      name={formDataItem.name}
                      value={
                        formData[formDataItem.name] ||
                        formDataItem.options[0].label
                      }
                      onChange={handleInputChange}
                      sx={selectStyles}
                    >
                      {formDataItem.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  ) : formDataItem.name === "storeId" ? (
                    <Input
                      type={formDataItem.type}
                      sx={inputStyles}
                      onBlur={handleInputBlur}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      readOnly
                      disableUnderline
                    />
                  ) : formDataItem.name === "password" ? (
                    <Input
                      type={showPassword ? "text" : "password"}
                      sx={inputStyles}
                      onBlur={handleInputBlur}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      disableUnderline
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  ) : formDataItem.name === "comfirmpassword" ? (
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      sx={inputStyles}
                      onBlur={handleInputBlur}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      disableUnderline
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleConfirmPasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                  ) : (
                    <Input
                      type={formDataItem.type}
                      sx={inputStyles}
                      onBlur={handleInputBlur}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      disableUnderline
                      readOnly={formDataItem.name === "createdBy" || formDataItem.name === "updatedBy" || formDataItem.name === "storeSubscriptionType"}
                    />

                  )}
                  <FormHelperText className="food-card-text" sx={errorMessageStyles}>{errors[formDataItem.name]}</FormHelperText>
                </FormControl>

              ))}
          </Stack>
        ))}
        {showLoginLoader ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleUser} className='food-card-text mode-button border-radius-10 cart-button final-button' sx={submitButtonStyles} disabled={isSubmitDisabled}>
              Submit
            </Button>
          </Box>
        )}

      </Paper>
    </Box>
  )
}

export default FreeTrialSuperadmin;
