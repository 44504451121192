import { Box, Button, Checkbox, Divider, Grid, IconButton, Input, InputAdornment, Modal, Pagination, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import InventoryIcon from '@mui/icons-material/Inventory';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDrawer } from '../Sidebar/DrawerContext';
import { useTableContext } from '../../Screens/tableContext/TableContext';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import PopOverModal from '../Cards/Modal/PopOverModal';
import { useSuccessSnackbar } from '../../Screens/snackbar/SuccessSnackbarProvider';
import { useSelector } from 'react-redux';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';

const rowsPerPage = 10;
function VendorPaymentList() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const [vendorPaymentList, setVendorPaymentList] = useState([])
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");



    const {
        pdfAndExcelButtonStyles,
        tableCellStyles,
        searchboxStyles,
        listMainGridStyles,
        contentTableCellStyles,
        editAndDeleteButtonStyles,
        contentTableRowStyles,
        tableHeadStyles,
        listMainBox,
        spaceBetweenTopComponent,
        pagignationStyles,
        listIconStyles,
        listTabStyles,
        listTabsStyles,
        fromAndToTextStyles,
        dateFieldStyles,
        filterAndCancelIcon, 
        dailyStyle
    } = useDrawer();
    const {
        searchInput,
        handleSearchChange,
        filterRows,
        renderStyledCell,
        getFontWeight, generatePdf, downloadExcel, currentPage, handleChangePage , resetCurrentPage , resetSearchInput
    } = useTableContext();
    const [value, setValue] = useState("");
    const { showSuccessSnackbar } = useSuccessSnackbar();

    const location = useLocation();
  
    useEffect(() => {
      resetSearchInput();
      resetCurrentPage();
    }, [location.pathname]);


    const tableHeaders = [
        { label: "SR No." },
        {label : "Payment Date"},
        { label: "Vendor Name" },
        { label: "Payment Mode" },
        { label: "Payment Status" },
        { label: "Due Date" },
        { label: "Branch" },
        { label: "Account No" },
        { label: "IFSC Code" },
        { label: "UPI ID" },
        { label: "Action" },
     
    ];

  const [open, setOpen] = useState(false);
    const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
//to open modal and to pass actual serialno to modal
    const handleOpen = (serialNo) => {
       setSerialNoToDelete(serialNo); 
        setOpen(true);
    };

    const filteredRows = filterRows(vendorPaymentList);
    const downloadPDF = () => {
        generatePdf("VendorPaymentList", tableHeaders, reorderedData , 7);
    };
    const filteredRowsBySearch = filteredRows.filter((vendorPaymentList) =>

        Object.values(vendorPaymentList).some(
            (value) =>
                value && value.toString().toLowerCase().includes(searchInput.toLowerCase())
        )
    );

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedRows = filteredRowsBySearch.slice(startIndex, endIndex);
    const currentUser = useSelector((state) => state.auth.user.data);


    const [reorderedData , setReorderedData] = useState("");

    const fetchData = async () => {
        try {
            const url = fromDate && toDate ?
                `https://billing.ubsbill.com/apptest/v2/sys/payment/store/${currentUser.storeid}?startDate=${fromDate}&endDate=${toDate}` :
                `https://billing.ubsbill.com/apptest/v2/sys/payment/store/${currentUser.storeid}`;
            const response = await fetch(url);
            
            const json = await response.json();

            const reorderedData = json.data.paymentList.map((payment , index) => ({
                no: index+1,
                paymentDate: payment.paymentDate, 
                vendorName: payment.vendorName,
                paymentMode: payment.paymentMode,
                paymentStatus: payment.paymentStatus,
                dueDate: payment.dueDate,
                branch: payment.branch,
                accountNo: payment.accountNo,
                ifscCode: payment.ifscCode,
                upi: payment.upi,
               
            }));
            setReorderedData(reorderedData);
            setVendorPaymentList(json.data.paymentList);
         } catch (error) {
            console.error("Error fetching Vender data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fromDate, toDate]);


    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const filterDailyRecords = () => {
        const today = new Date();
        const fromDateFormatted = formatDate(today);
        const toDateFormatted = formatDate(today);

        setFromDate(fromDateFormatted);
        setToDate(toDateFormatted);
        resetCurrentPage();
    };


    const filterMonthlyRecords = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const fromDateFormatted = formatDate(firstDayOfMonth);
        const toDateFormatted = formatDate(lastDayOfMonth);
        setFromDate(fromDateFormatted);
        setToDate(toDateFormatted);
        resetCurrentPage();
    };


    const filterYearlyRecords = () => {
        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const lastDayOfYear = new Date(today.getFullYear(), 11, 31);
        const fromDateFormatted = formatDate(firstDayOfYear);
        const toDateFormatted = formatDate(lastDayOfYear);

        setFromDate(fromDateFormatted);
        setToDate(toDateFormatted);
        resetCurrentPage();
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                filterDailyRecords();
                break;
            case 1:
                filterMonthlyRecords();
                break;
            case 2:
                filterYearlyRecords();
                break;
            default:
                break;
        }
    };


    const handleVendorDelete = async (serialNo) => {

        try {
            // Make a DELETE request to your API endpoint
            const response = await axios.delete(`https://billing.ubsbill.com/apptest/v2/sys/payment/${serialNo}`);
            if (response.data.status === true) {
                showSuccessSnackbar("Vendor Deleted successfully.. !");
                handleClose();
                fetchData();
            }
        } catch (error) {
            console.error("Error deleting vendor:", error);
            // Handle the error or update your UI accordingly
        }
    };
   

    const handleClearFields = () => {
        setFromDate(''); 
        setToDate('');   
        resetSearchInput();   
        setValue("");
      };
      
    return (
        <Box component="main" sx={{ p: 2 }}>
            <Grid container spacing={1} sx={{ mb: 4, }}>
                <Grid item xs={12} sx={listMainGridStyles}>
                    <Box
                        display="flex"
                        bgcolor="primary.light"
                        sx={listMainBox}

                    >
                        <InventoryIcon sx={listIconStyles} />
                        <Typography variant='span' sx={{ ml: "8px",  color: isDarkMode ? "#fff" :"#fff" }}>Vendor Payment List</Typography>
                    </Box>
                    <Box sx={spaceBetweenTopComponent}>
                        <Button className='food-card-text mode-button border-radius-10 cart-button final-button'
                            component={Link} to="/vendor-management-dashboard/vendor-payment-details"
                            sx={pdfAndExcelButtonStyles}>
                            + Vendor Payment List
                        </Button>
                        <Button className='food-card-text mode-button border-radius-10 cart-button final-button'
                            onClick={downloadPDF} sx={pdfAndExcelButtonStyles}>
                            PDF
                        </Button>
                        <Button className='food-card-text mode-button border-radius-10 cart-button final-button' onClick={() => downloadExcel(tableHeaders, reorderedData, "VendorPaymentList")} sx={pdfAndExcelButtonStyles}>
                            Excel
                        </Button></Box>

                </Grid>
            </Grid>
            <Grid container spacing={1} >
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                    <Box
                        bgcolor="#D9D9D9"
                        p={1}
                        sx={searchboxStyles}
                    >
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                        <Divider orientation="vertical" sx={{ height: 24, mx: 1, backgroundColor: "#424242" }} />
                        <Input
                            fullWidth
                            placeholder="Search"
                            sx={{ color: isDarkMode ? "#fff" : "#000",}}
                            disableUnderline
                            value={searchInput}
                            onChange={handleSearchChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={4.2} md={4} lg={2.4} xl={2.6}>
                    <Box   sx={dailyStyle}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="white"
                            indicatorColor="white"
                            aria-label="tabs"
                            sx={listTabsStyles}
                        >
                            <Tab
                                label="Daily"
                                value={0}
                                className='tabs'
                                sx={{
                                    ...listTabStyles,
                                    bgcolor: value === 0 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                                    color: value === 0 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                                }}
                            />
                            <Tab
                                label="Monthly"

                                value={1}
                                sx={{
                                    ...listTabStyles,
                                    bgcolor: value === 1 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                                    color: value === 1 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),

                                }}
                            />
                            <Tab
                                label="Yearly"

                                value={2}
                                sx={{
                                    ...listTabStyles,
                                    bgcolor: value === 2 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                                    color: value === 2 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),

                                }}
                            />

                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={7} sm={4} md={4} lg={2} xl={2.1}>
                    <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
                        From


                        <Input fullWidth type="date" id="outlined-basic" label="Outlined"  value={fromDate} onChange={(e) => setFromDate(e.target.value)}

                            variant="outlined" sx={dateFieldStyles} disableUnderline />
                    </Box>

                </Grid>
                <Grid item xs={7} sm={4} md={4} lg={2} xl={2.1}>
                    <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
                        To


                        <Input fullWidth type="date" id="outlined-basic" label="Outlined"  value={toDate} onChange={(e) => setToDate(e.target.value)}

                            variant="outlined" sx={dateFieldStyles} disableUnderline />
                    </Box>
                </Grid>
                <Grid item>
                    <Box bgcolor="#D9D9D9" p={0.5} sx={filterAndCancelIcon}>
                        <IconButton onClick={fetchData}>
                            <FilterAltIcon />

                        </IconButton>
                    </Box>
                </Grid>
                <Grid item>
                    <Box bgcolor="#D9D9D9" sx={filterAndCancelIcon}>
                        <IconButton onClick={handleClearFields}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mt: 4 }}>
                <Box sx={{ width: '100%', overflowX: 'auto', borderRadius: 1 }}>
                    <Table aria-label="simple table">
                        <TableHead sx={tableHeadStyles}>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        className="all-list-tabelcell"
                                        align="right"
                                        sx={tableCellStyles}
                                    >
                                        {header.label}
                                       
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <div style={{ padding: "10px" }} />
                        <TableBody >
                            {displayedRows.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={contentTableRowStyles}>
                                    {renderStyledCell(startIndex + index + 1, getFontWeight(startIndex + index + 1))}
                                    {renderStyledCell(row.paymentDate, getFontWeight(row.paymentDate))}
                                    {renderStyledCell(row.vendorName, getFontWeight(row.vendorName))}
                                    {renderStyledCell(row.paymentMode, getFontWeight(row.paymentMode))}
                                    {renderStyledCell(row.paymentStatus, getFontWeight(row.paymentStatus))}
                                    {renderStyledCell(row.dueDate, getFontWeight(row.dueDate))}
                                    {renderStyledCell(row.branch, getFontWeight(row.branch))}
                                    {renderStyledCell(row.accountNo, getFontWeight(row.accountNo))}
                                    {renderStyledCell(row.ifscCode, getFontWeight(row.ifscCode))}
                                    {renderStyledCell(row.upi, getFontWeight(row.upi))}
                                    <TableCell sx={contentTableCellStyles} align="left" >

                                        <Box className="align-items-center">

                                            <Box className='justify-all-center'
                                                component={Link} to={`/vendor-management-dashboard/vendor-Payment-list/edit-vendor-payment/${row.serialNo}`} sx={{
                                                    ...editAndDeleteButtonStyles,
                                                    border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                                                }}><ModeEditOutlineRoundedIcon sx={{ color: isDarkMode ?  "white" : "#4FC3A1"}} /></Box>
                                            <Box className='justify-all-center'
                                               onClick={() => handleOpen(row.serialNo)} sx={{
                                                    ...editAndDeleteButtonStyles,
                                                    border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                                                }}><DeleteIcon sx={{ color: isDarkMode ?  "white" : "#f55651" }} />
                                            </Box>
                                            <Modal
                                                open={open}
                                               
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <PopOverModal

                                                    iconPath="../images/delete.png"
                                                    buttonTextTwo="No"
                                                    topHeading="Are you sure you want to Delete ?"
                                                    modalTopBorder="10px solid #C62717"
                                                    buttonTextOne="Yes"
                                                    printText=""
                                                    onClickOne={() => handleVendorDelete(serialNoToDelete)}
                                                    onClickTwo={handleClose}
                                                />
                                            </Modal>

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
                <Grid item>
                    <Pagination
                        count={Math.ceil(filteredRows.length / rowsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={pagignationStyles}
                    />
                </Grid>
            </Grid>
        </Box >
    )
}

export default VendorPaymentList;
