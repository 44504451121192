import { Box, Grid,Stack, Card, CardMedia } from "@mui/material";
import React, { useState, useEffect } from "react";
import NavLinkCard from "./NavLinkCard";
import axios from "axios";
import { Link } from "react-router-dom";

import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

import "../VendorManagement/card.css";



function AppMenu() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const data = [
    {
      id: 1,
      title: "Add Food",
      image: "../images/addFoodIcon.png",
      boxShadow: "0px -9px 0px 0px #FACE47",
      link: "/food-management-dashboard/add-menu",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 2,
      title: "Bill Report",
      image: "../images/bill-report-icon.png",
      boxShadow: "0px -9px 0px 0px #28A497",
      link: "/bill-list",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 3,
      title: "Add User",
      image: "../images/add-user-icon.png",
      boxShadow: "0px -9px 0px 0px #47C01C",
      link: "/user-management-dashboard/add-user",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "50px",
    },
    {
      id: 5,
      title: "Add Inventory",
      image: "../images/add-inventory-icon.png",
      boxShadow: "0px -9px 0px 0px #47C01C",
      link: "/inventory-management-dashboard/add-inventory",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 6,
      title: "Profile Setting",
      image: "../images/profile-sett-icon.png",
      boxShadow: "0px -9px 0px 0px #FACE47",
      link: "/profile-settings/store-settings",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
  ];

  const [AppMenuLinks, setAppMenuLinks] = useState([]);
  // const fetchData = () => {
  //   fetch("https://billing.ubsbill.com/apptest/v2/sys/dashboard/getbyidandstatus/1")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setAppMenuLinks(json.data.appMenu);

  //     });
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get("https://billing.ubsbill.com/apptest/v2/sys/dashboard/getbyidandstatus/2")
  //     .then((response) => {
  //       setAppMenuLinks(response.data.data.appMenu || []);
  //     });
  // }, []);





  const boxShadows = [
    "0px -9px 0px 0px #28A497",
    "0px -9px 0px 0px #FACE47",
    "0px -9px 0px 0px #47C01C",
  ];

  return (

    <Grid lg={11} md={11} sm={9.3} xs={12} xl={11} container spacing={1.7} sx={{ display: "flex" }}>
      {data.map((card, index) => (
        <Grid item key={card.id} lg={4} md={4} sm={6} xs={11.5} xl={4}>
          <Link to={card.link} style={{ textDecoration: "none" }}>
            <Card
              className="justify-all-center justify-left-mobile link-card-style"
              sx={{
                background: isDarkMode ? "#5e5e5e"  :"#EEEEEE",
                marginBottom: "30px",
                padding: { sm: 0.2, md: 1.0, xs: 2.3, lg: 1.8 },
                maxWidth: { sm: 300, md: 500, xs: "100%", lg: 300, xl: 430 },
                boxShadow: card.boxShadow,
                minHeight: { sm: "10vh", md: "12vh", xs: "7vh", lg: "13vh", xl: "12vh" },
                iconWidth: "50px",
                iconHeight: "50px",
                titleFontWeight: "500",
                cursor:"pointer",
              }}
            >
              <Stack direction="row" className="justify-all-center">

                <CardMedia
                  component="img"
                  alt={`${card.title} Icon`}

                  image={card.image}
                  sx={{ width: { sm: 30, md: 40, xs: 30, lg: 45, xl: 50 } , filter: isDarkMode ? "invert(1)" : "none", }}
                />
                <CustomTypography
                  variant="p"
                  className="link-cart-title"
                  sx={{
                    fontSize: { sm: "13px", md: "15px", xs: "17px ", lg: "16px", xl: "18px" },
                    marginLeft: {
                      sm: "10px",
                      md: "15px",
                      xs: "17px ",
                      lg: "20px",

                    },
                  }}
                >
                  {card.title}
                </CustomTypography>
              </Stack>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>

  );
}
export default AppMenu;
