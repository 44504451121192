import { TableCell } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx'; 
import { useDarkMode } from '../DarkMode/DarkMode';
const TableContext = createContext();
export const TableProvider = ({ children }) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [searchInput, setSearchInput] = useState("");
const handleSearchChange = (event) => {
  setSearchInput(event.target.value);
  setCurrentPage(1);
};
const resetSearchInput = () => {
  setSearchInput("");
};
const getFontWeight = (cellValue) => {
  if (cellValue && typeof cellValue === 'string') {
    return searchInput && cellValue.toLowerCase().includes(searchInput.toLowerCase()) ? 'bold' : 'normal';
  }
  return 'normal';
};
const filterRows = (rows) => {
  return rows.filter((row) =>
    Object.values(row).some((value) => {
      if (value !== null && (typeof value === 'number' || !isNaN(value))) {
        // Convert numeric value to string for comparison
        const stringValue = value.toString();
        return stringValue.includes(searchInput.toLowerCase());
      } else if (value !== null && typeof value === 'string') {
        // Handle string values
        return value.toLowerCase().includes(searchInput.toLowerCase());
      }
      return false;
    })
  );
};


const generatePdf = (title, tableHeaders, filteredRows , fontsize = 10) => {

  const doc = new jsPDF();

  doc.setFontSize(15);

  doc.text(title, 10, 10);
  const tableData = [];
  const headersToExclude = ["Action", "Select All", "Image", "View"];


  const headersWithoutExcluded = tableHeaders.filter(
      (header) => !headersToExclude.includes(header.label)
  );
  tableData.push(headersWithoutExcluded.map((header) => header.label));


  let serialCounter = 1; // Counter for manual numbering

  filteredRows.forEach((row) => {
      const rowData = [];
      Object.keys(row).forEach((key) => {
          let value = row[key] !== null ? row[key].toString() : "";
          // If the key is serialNo, replace the value with manual numbering
          if (key === "serialNo") {
              value = serialCounter.toString();
              serialCounter++;
          }
          rowData.push(value);
      });
      tableData.push(rowData);
  });

  doc.autoTable({
      head: [tableData[0]],
      body: tableData.slice(1),
       styles: {
          fontSize: fontsize,
      },
  });

  doc.save(`${title}.pdf`);
};



  const downloadExcel = (tableHeaders, filteredRows, title) => {
    const headersToExclude = ["Action", "Select All", "Image","View"];
    const filteredTableHeaders = tableHeaders.filter(
      (header) => !headersToExclude.includes(header.label)
    );
  
    const rowsForExcel = filteredRows.map((row) => {
      const { image, ...rowWithoutImage } = row;
      return rowWithoutImage;
    });
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      filteredTableHeaders.map((header) => header.label),
      ...rowsForExcel.map((row) => Object.values(row)),
    ]);
  
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };
  
 
 
  const renderStyledCell = (value) => (
    <TableCell className='food-card-text' sx={{fontSize: { xs: "11px", sm: "14px", md: "14px", lg: "14px", xl: "15px" }, textTransform:"capitalize" , color: isDarkMode ? "#fff" : "#000"}} align="left"  >
      <span style={{ fontWeight: getFontWeight(value) }}>{value}</span>
    </TableCell>
  );
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const resetCurrentPage = () => {
    setCurrentPage(1);
  };
  return (
    <TableContext.Provider value={{ searchInput, handleSearchChange, resetSearchInput , resetCurrentPage , getFontWeight, filterRows, renderStyledCell, generatePdf , downloadExcel,currentPage,handleChangePage}}>
      {children}
    </TableContext.Provider>
  );
};
export const useTableContext = () => {
  return useContext(TableContext);
};