import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Icon, SnackbarContent, Typography, } from '@mui/material';
import { useDarkMode } from '../DarkMode/DarkMode';

// Success Snackbar Context
const SuccessSnackbarContext = createContext();

export const SuccessSnackbarProvider = ({ children, defaultSuccessMessage }) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(defaultSuccessMessage || '');

  const showSuccessSnackbar = (newMessage) => {
    setMessage(newMessage || defaultSuccessMessage);
    setOpen(true);
  };

  const hideSuccessSnackbar = () => {
    setOpen(false);
  };

  const successSnackbarValue = {
    showSuccessSnackbar,
    hideSuccessSnackbar,
  };

  return (
    <SuccessSnackbarContext.Provider value={successSnackbarValue}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={hideSuccessSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          sx={{ backgroundColor: 'green', mt: '7vh' }}
          message={
            <React.Fragment>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ marginRight: '8px', color: "#fff" }}>check_circle</Icon>
                <Typography variant="body1" sx={{ color: 'white' }}>
                  {message}
                </Typography>
              </div>
            </React.Fragment>
          }
          action={
            <IconButton size="small" color="inherit" onClick={hideSuccessSnackbar} sx={{ color: 'white' }}>
              <Icon>close</Icon>
            </IconButton>
          }
        />
      </Snackbar>
    </SuccessSnackbarContext.Provider>
  );
};

export const useSuccessSnackbar = () => {
  return useContext(SuccessSnackbarContext);
};
