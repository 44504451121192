import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    Paper,  

  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useDrawer } from "../Sidebar/DrawerContext";
  import RiceBowlIcon from '@mui/icons-material/RiceBowl';
  import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
  import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
  import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
  function EditPosSettings() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const {
      formMainBox,
      formPaperStyles,
      errorMessageStyles,
      labelStyle, inputStyles, submitButtonStyles, buttonDisabled, capitalizeFirstLetter, handleOpen, handleClose, open
    } = useDrawer();
    const currentUser = useSelector((state) => state.auth.user.data);
    const { id } = useParams();
    const { showSuccessSnackbar } = useSuccessSnackbar();
    const { showErrorSnackbar } = useErrorSnackbar();
    const rows = [
      { no: 1, subCategory: "Veg" },
      { no: 2, subCategory: "Non-Veg" },
      { no: 3, subCategory: "Indian" },
    ];
  
    const [formData, setFormData] = useState({
      name: '',
  
      storeId: `${currentUser.storeid}`,
      cratedBy: `${currentUser.username}`,
      updatedBy: `${currentUser.username}`,
  
    });
    const [errors, setErrors] = useState({
      name: '',
    });
    const validateField = (fieldName, value) => {
      let error = "";
      const capitalizedFieldName = capitalizeFirstLetter(fieldName);
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;

      }
     if(value.length >= 40){
        error = `${capitalizedFieldName} should not exceed 40 characters`;
      
      }
     
      return error;
    };
  
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if(value.length>15){
        return
      }
      setFormData({ ...formData, [name]: value });
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });
    };
    const navigate =useNavigate();
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/categorybutton/id/${id}`);
            const posData = response.data.data;
            if(String(response.data.data.storeId) !== String(currentUser.storeid))
            {
               navigate('/unauthorized');
            }
            else{
            setFormData({
                name: posData.name,
            
            });
          }
          } catch (error) {
            console.error("Failed to fetch user data:", error);
          }
        };
    
        fetchData();
      }, [id]);

      const handlePosUpdate = async (e) => {
        e.preventDefault();
    
        if (Object.values(errors).every((error) => error === "")) {
          
    
           try {
              const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/categorybutton/id/${id}`, formData);
              if (response.data.status === true) {
                 showSuccessSnackbar("Form updated successfully!");
                 
              } else {
                 showErrorSnackbar(response.data.message);
                
              }
           } catch (error) {
              if (error.response && error.response.data) {
                 showErrorSnackbar(error.response.data.message);
              } else {
                
                 showErrorSnackbar("Form update failed. Please try again.");
              }
           }
        }
     };
 
   
    const isSubmitDisabled = buttonDisabled(formData, errors);
    return (
      <Box component="main" sx={{ p: 2 }}>
        <Grid xl={12} container spacing={4} sx={{ mb: 4, mt: 4 }}>
          <Grid item xs={12} xl={4} lg={4} md={4} sm={10}>
  
            <Paper elevation={12} sx={{ ...formPaperStyles, width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" }, boxShadow: "0px 4px 4px 0px #00000040", mt: 0 }}>
  
              <Box
                display="flex"
                alignItems="center"
                bgcolor="primary.light"
                p={1}
                sx={formMainBox}
              >
  
                <RiceBowlIcon fontSize="large" />
                <CustomTypography variant="span" sx={{ ml: "8px" }}>
                  Edit POS Settings
                </CustomTypography>
              </Box>
              <FormControl
  
                fullWidth
                error={!!errors.name}
                variant="outlined"
                sx={{ mt: 6, mb: 3 }}
              >
                <CustomTypography
                  htmlFor={`outlined-adornment-name`}
                  className="food-card-text"
                  sx={labelStyle}
                >
                 Category Name
                </CustomTypography>
  
                <Input
                  sx={inputStyles}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  disableUnderline
                />
                <FormHelperText
                  className="food-card-text"
                  sx={errorMessageStyles}
                >
                  {errors.name}
                </FormHelperText>
              </FormControl>
              <Button onClick={handlePosUpdate}
                className="food-card-text mode-button border-radius-10 cart-button final-button"
                sx={{ ...submitButtonStyles, mb: 3 }} disabled={isSubmitDisabled}
              >
                Submit
              </Button>
  
  
            </Paper>
  
          </Grid>
        
        </Grid>
      </Box>
    );
  }
  
  export default EditPosSettings;
  