import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  MenuItem,
  Input,
} from "@mui/material";

import BrunchDiningIcon from "@mui/icons-material/BrunchDining";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useDrawer } from "../Sidebar/DrawerContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function EditRecipe() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    formMainBox,
    formPaperStyles,
    selectStyles,
    capitalizeFirstLetter,
    buttonDisabled,
  } = useDrawer();
  const currentUser = useSelector((state) => state.auth.user?.data)
  const { serialNo } = useParams();
  const [formData, setFormData] = useState({
    productName: "",
    ingredients: [
      {
        ingredientName: "",
        quantity: "",
        unit: "",
        storeId: currentUser.storeid
      }
    ],

  });
  const [errors, setErrors] = useState({
    productName: "",
    ingredientName: "",
    quantity: "",
    unit: "",
  });
  const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const numberRegex = new RegExp(/^\d{1,10}$/);
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    if (capitalizedFieldName === "price") {
      if (!numberRegex.test(value)) {
        error = `${capitalizedFieldName} should be a number`;
      }
    }
    return error;
  };

  const navigate =useNavigate();
  const [selectedIngredientName, setSelectedIngredientName] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/receipe/${serialNo}`);
        if(String(response.data.data.storeId) !== String(currentUser.storeid))
        {
           navigate('/unauthorized');
        }
        else{
        const recipeData = response.data.data;
        setSelectedIngredientName(response.data.data.ingredients[0].name)
        setSelectedUnit(response.data.data.ingredients[0].unit)
        setFormData({
          id1: recipeData.id,
          id2: recipeData.ingredients[0].id,
          productName: recipeData.name,
          ingredientName: recipeData.ingredients[0].name,
          quantity: recipeData.ingredients[0].quantity, // Convert to string assuming it's expected as a string in the input
          unit: recipeData.ingredients[0].unit
        });
      }
      } catch (error) {
        showErrorSnackbar("Failed to fetch inventory data");
      }
    };
  
    fetchData();
  }, [serialNo]);


  const [ingredientNames, setIngredientNames] = useState([]);
 

  useEffect(() => {
    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/inventory/store/${currentUser.storeid}`)
      .then((response) => {
        const fetchedIngredientNames = response.data.data.inventoryList.map(ingredient => ingredient.name); // Extracting ingredientName from each object
        setIngredientNames(fetchedIngredientNames);
      })
      .catch((error) => {
        console.error("Error fetching ingredient names:", error);
      });
  }, []);

  const [productNames, setProductNames] = useState([]);

  useEffect(() => {
    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/food/store_all/${currentUser.storeid}`)
      .then((response) => {
        const fetchedProductNames = response.data.data.map(food => food.foodName); // Extracting foodName from each object
        setProductNames(fetchedProductNames);
      })
      .catch((error) => {
        console.error("Error fetching product names:", error);
      });
  }, []);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "ingredientName") {
      setSelectedIngredientName(value);
    }
    if (name === "unit") {
      setSelectedUnit(value);
    } else {
      setFormData({ ...formData, [name]: value });
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });

    }
  
  };



 
  const handleEditRecipe = async () => {
   
    const updatedData = {
      id: formData.id1,
      name: formData.productName,
      createdBy: currentUser.username,
      updatedBy: currentUser.username,
      storeId: currentUser.storeid,
      ingredients: [
        {
          id: formData.id2,
          name: selectedIngredientName,
          quantity: formData.quantity,
          unit: selectedUnit,
          storeid: currentUser.storeid 
        }
      ],
      serialNo: serialNo 
    };

   console.log("update data" , updatedData)
    try {
      const response = await axios.patch(
        `https://billing.ubsbill.com/apptest/v2/sys/receipe/id/${serialNo}`,
        updatedData
      );
      if ( response.data.status === true) {
      showSuccessSnackbar("Recipe Updated Successfull !");
      }
    } catch (error) {
      console.error("Failed to update data:", error);
    }
  };
 
  const isSubmitDisabled = buttonDisabled(formData, errors);
  return (
    <Box
      component="main"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="primary.light"
              p={1}
              sx={formMainBox}
            >
              <BrunchDiningIcon fontSize="large" />
              <span style={{ marginLeft: "8px" }}>Update Recipe</span> {/* Text */}
            </Box>
          </Grid>
        </Grid>

        <Stack
          spacing={4}
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          }}
          sx={{}}
        >
          <FormControl
            fullWidth
            error={!!errors.productName}
            variant="outlined"
          >
            <CustomTypography
              htmlFor="outlined-adornment-username"
              className="food-card-text"
              sx={labelStyle}
            >
              Product Name
            </CustomTypography>

            <Box sx={{ display: "flex" }}>
            <Select
            sx={inputStyles}
            name="productName"
            fullWidth
            value={formData.productName}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Product
            </MenuItem>
            {productNames.map((productName) => (
              <MenuItem key={productName} value={productName}>
                {productName}
              </MenuItem>
            ))}
          </Select>
            
             
            </Box>

            <FormHelperText className="food-card-text" sx={errorMessageStyles}>
              {errors.ingredientName}
            </FormHelperText>
          </FormControl>
        </Stack>
        <Stack
          spacing={4}
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          }}
          sx={{ mt: 4 }}
        >
          <FormControl
            fullWidth
            error={!!errors.ingredientName}
            variant="outlined"
          >
            <CustomTypography
              htmlFor="outlined-adornment-username"
              className="food-card-text"
              sx={labelStyle}
            >
              Ingredient Name
            </CustomTypography>

            <Select
            sx={inputStyles}
            name="ingredientName"
            fullWidth
            value={selectedIngredientName}
            onChange={handleInputChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Ingredient
            </MenuItem>
            {ingredientNames.map((ingredientName) => (
              <MenuItem key={ingredientName} value={ingredientName}>
                {ingredientName}
              </MenuItem>
            ))}
          </Select>
            <FormHelperText className="food-card-text" sx={errorMessageStyles}>
              {errors.ingredientName}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth error={!!errors.quantity} variant="outlined">
            <CustomTypography
              htmlFor="outlined-adornment-username"
              className="food-card-text"
              sx={labelStyle}
            >
              Quantity
            </CustomTypography>
            <Input
              sx={inputStyles}
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleInputChange}
              disableUnderline
            />
            <FormHelperText className="food-card-text" sx={errorMessageStyles}>
              {errors.quantity}
            </FormHelperText>
          </FormControl>
        </Stack>
        <Stack
          spacing={4}
          direction={{
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          }}
          sx={{ mt: 4 }}
        >
          <FormControl fullWidth error={!!errors.unit} variant="outlined">
            <CustomTypography
              htmlFor="outlined-adornment-unit"
              className="food-card-text"
              sx={labelStyle}
            >
              Unit
            </CustomTypography>
            <Select
                name="unit"
                fullWidth
                value={selectedUnit}
                onChange={handleInputChange}
                sx={selectStyles}
              >
                <MenuItem value="Select Unit">Select Unit</MenuItem>
                <MenuItem value="kg">Kg</MenuItem>
                <MenuItem value="gm">Gram</MenuItem>
                <MenuItem value="mg">Milligram</MenuItem>
                <MenuItem value="pc">Pieces</MenuItem>
                <MenuItem value="dozen">Dozen</MenuItem>
                <MenuItem value="liter">Liter</MenuItem>
                <MenuItem value="ml">Milliliter</MenuItem>

              </Select>
          
            <FormHelperText className="food-card-text" sx={errorMessageStyles}>
              {errors.unit}
            </FormHelperText>
          </FormControl>
         
        </Stack>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 7 }}>
          <Button onClick={handleEditRecipe}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
export default EditRecipe;


