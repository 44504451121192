import { Card, CardMedia, Grid,  } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./links.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

import "../VendorManagement/card.css";


function SummaryCard() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [todaysSellData, setTodaysSellData] = useState(null);
  const [mostSellingFood, setMostSellingFood] = useState(null);
  const [data, setData] = useState([
    {
      id: 1,
      title: "Today's Income",
      subtitle: "",
      image: "../images/rs-icon.png",
      backgroundColor: isDarkMode ?  "#404040 !important" : "#EFFCEA !important",
    },
    {
      id: 2,
      title: "Most Selling Food",
      subtitle: "",
      image: "../images/food-icon.png",
      backgroundColor: isDarkMode ?  "#404040 !important" : "#FFF9D9 !important",
    }, 
  ]);

  const currentUser = useSelector((state) => state.auth.user?.data);

  useEffect(() => {
    // Fetch today's sell data
    axios
      .get(`https://billing.ubsbill.com/apptest/v1/sys/bill/total-payments/${currentUser.storeid}/today`)
      .then((response) => {
        const todaySell = response.data;
        setTodaysSellData(todaySell);

        // Update the subtitle of the first item in the data array
        setData((prevData) => [
          {
            ...prevData[0],
            subtitle: todaySell,
          },
          ...prevData.slice(1), // Keep the rest of the data unchanged
        ]);
      })
      .catch((error) => {
        console.error("Error fetching today's sell data:", error);
      });

    // Fetch most selling food data
    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/bill/mostSellingFood/${currentUser.storeid}`)
      .then((response) => {
        const mostSellingFoodData = response.data.data[0];
        const firstFoodName = mostSellingFoodData["foodName "] || "";
        setMostSellingFood(firstFoodName); // Set the mostSellingFood to the first foodName

        // Assuming you want to set the subtitle as the first most selling food
        if (firstFoodName) {
          setData((prevData) => [
            prevData[0], // Keep the first item unchanged
            {
              ...prevData[1], // Update the second item
              subtitle: firstFoodName,
            },
          ]);
        }
      })
      .catch((error) => {
        console.error("Error fetching most selling food data:", error);
      });
  }, []);

  const formatNumberSubtitle = (number) => {
    if (typeof number === 'number') {
        // Convert number to string
        let formattedNumber = number.toString();

        // Split the number into integer and fractional parts
        const parts = formattedNumber.split('.');

        // Format integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // If there's a fractional part, retain only two digits
        if (parts[1]) {
            parts[1] = parts[1].substring(0, 2);
        }

        // Join the parts back together
        formattedNumber = parts.join('.');

        return formattedNumber;
    } else {
        // If not a number, return the original value
        return number;
    }
};


  return (
    <Grid lg={11} md={12} sm={12} xs={12} xl={10} container spacing={1.7}>
     
        <Grid
          item
          lg={5}
          md={5}
          sm={6}
          xs={11.5}
          xl={5}
        >
          <Card
            className="justify-all-center justify-left-mobile link-card-style"
            sx={{
              marginBottom: "30px",
              maxWidth: {
                sm: 300,
                md: 500,
                xs: "100%",
                lg: 350,
                xl: 450,
              },
              padding: {
                sm: 0.2,
                md: 1.0,
                xs: 2.3,
                lg: 1.8,
              },
              minHeight: {
                sm: "14vh",
                md: "14vh",
                xs: "11vh",
                lg: "16vh",
                xl: "14vh",
              },
              boxShadow: "none",
              backgroundColor: isDarkMode ?  "#5e5e5e !important" : "#EFFCEA !important",
            }}
          >
            <CardMedia
              component="img"
              alt={`Today's Income Icon`}
              image="../images/rs-icon.png"
              sx={{
                width: {
                  sm: 30,
                  md: 40,
                  xs: 30,
                  lg: 45,
                  xl: 50,
                },
                filter: isDarkMode ? "invert(1)" : "none",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CustomTypography
                variant="p"
                className="link-cart-title food-card-text"
                sx={{
                  fontSize: {
                    sm: "13px",
                    md: "15px",
                    xs: "15px ",
                    lg: "17px",
                    xl: "20px",
                  },
                  marginLeft: {
                    sm: "10px",
                    md: "20px",
                    xs: "17px ",
                    lg: "30px",
                    xl: "50px",
                  },
                  fontWeight: isDarkMode ? "500" : "600"
                }}
              >
                Today's Income
              </CustomTypography>
              <CustomTypography
                variant="p"
                className="link-cart-title food-card-text"
                sx={{
                  fontSize: {
                    sm: "12px",
                    md: "14px",
                    xs: "13px ",
                    lg: "16px",
                    xl: "18px",
                  },
                  marginLeft: {
                    sm: "10px",
                    md: "20px",
                    xs: "17px ",
                    lg: "30px",
                    xl: "50px",
                  },
                  width: {
                    xs: "170px",
                  },
                }}
              >
            {todaysSellData !== null ? todaysSellData.toFixed(2) : 'N/A'}
              </CustomTypography>
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          sm={6}
          xs={11.5}
          xl={5}
        >
          <Card
            className="justify-all-center justify-left-mobile link-card-style"
            sx={{
              marginBottom: "30px",
              maxWidth: {
                sm: 300,
                md: 500,
                xs: "100%",
                lg: 350,
                xl: 450,
              },
              padding: {
                sm: 0.2,
                md: 1.0,
                xs: 2.3,
                lg: 1.8,
              },
              minHeight: {
                sm: "14vh",
                md: "14vh",
                xs: "11vh",
                lg: "16vh",
                xl: "14vh",
              },
              boxShadow: "none",
              backgroundColor: isDarkMode ?  "#5e5e5e !important" : "#FFF9D9 !important",
            }}
          >
            <CardMedia
              component="img"
              alt={`Most Selling Food Icon`}
              image="../images/food-icon.png"
              sx={{
                width: {
                  sm: 30,
                  md: 40,
                  xs: 30,
                  lg: 45,
                  xl: 50,
                },
                filter: isDarkMode ? "invert(1)" : "none",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CustomTypography
                variant="p"
                className="link-cart-title food-card-text"
                sx={{
                  fontSize: {
                    sm: "13px",
                    md: "15px",
                    xs: "15px ",
                    lg: "17px",
                    xl: "20px",
                  },
                  marginLeft: {
                    sm: "10px",
                    md: "20px",
                    xs: "17px ",
                    lg: "30px",
                    xl: "50px",
                  },
                  fontWeight: isDarkMode ? "500" : "600"
                }}
              >
                Most Selling Food
              </CustomTypography>
              <CustomTypography
                variant="p"
                className="link-cart-title food-card-text"
                sx={{
                  fontSize: {
                    sm: "12px",
                    md: "14px",
                    xs: "13px ",
                    lg: "16px",
                    xl: "18px",
                  },
                  marginLeft: {
                    sm: "10px",
                    md: "20px",
                    xs: "17px ",
                    lg: "30px",
                    xl: "50px",
                  },
                  width: {
                    xs: "170px",
                  },
                }}
              >
               {mostSellingFood}
              </CustomTypography>
            </Box>
          </Card>
        </Grid>
      
    </Grid>
  );
}

export default SummaryCard;

