import { Box } from "@mui/material";
import React from "react";
import QuickAccessLinks from "../../Components/StoreDashboardComponent/QuickAccessLinks";
import AppMenu from "../../Components/StoreDashboardComponent/AppMenu";
import SuperAdminAppmenu from "../../Components/StoreDashboardComponent/SuperAdminAppmenu";
import { useSelector } from "react-redux";
import { useDarkMode } from "../DarkMode/DarkMode";

function UserDashboard() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const accessLinkData = [
    {
      id: 1,
      title: "Add Menu",
      image: "../images/add-menu-icon.png",
      link: "/food-management-dashboard/add-menu",
    },
    {
      id: 2,
      title: "Add Addons",
      image: "../images/add-addon-icon.png",
      link: "/food-management-dashboard/add-addons",
    },
    {
      id: 3,
      title: "Add Recipe",
      image: "../images/add-recipe.png",
      link: "/food-management-dashboard/add-recipe",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomTypography
          sx={{ mb: 4, fontWeight: "600" }}
          variant="p"
          className="link-cart-title"
        >
          🖐 Hello , {currentUser.username} | 
          <span
            className="link-cart-title"
            style={{ fontWeight: "500",  color: isDarkMode ? "#fff" :"#424242" ,  }}
          >
            {currentDate}
          </span>
        </CustomTypography>

        <CustomTypography
          sx={{
            mb: 4,
            fontSize: {
              sm: "20px",
              md: "22px",
              xs: "20px",
              lg: "23px",
              xl: "25px",
            },
          }}
          variant="p"
          className="link-cart-title"
        >
          Quick Access Links
        </CustomTypography>
        <QuickAccessLinks data={accessLinkData} />
      </Box>
    </Box>
  );
}
export default UserDashboard;
