import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Input,
} from "@mui/material";
import { useSelector } from "react-redux";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useSuccessSnackbar } from "../../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../../Screens/snackbar/ErrorSnackbarProvider";
import { useDrawer } from "../../Sidebar/DrawerContext";
import axios from "axios";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";


function PaymentSettingsForm() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data);
  const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    formMainBox,
    formPaperStyles,
    buttonDisabled,
    capitalizeFirstLetter
  } = useDrawer();

  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();

  const formDataFields = [
    { name: "storeName", label: "Store Name", type: "text" },
    { name: "bankName", label: "Bank Name", type: "text" },
    { name: "branchName", label: "Branch Name", type: "text" },
    { name: "accountNo", label: "Account No", type: "number" },
    { name: "ifscCode", label: "IFSC Code", type: "text" },
    { name: "upiId", label: "UPI Id", type: "text" },
    { name: "cratedBy", label: "Created By", type: "text" },
    { name: "updatedBy", label: "Updated Id", type: "text" }
  ];

  const [formData, setFormData] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
    storeName: currentUser.storeName,
    cratedBy: currentUser.username,
    updatedBy: currentUser.username,
    storeId: currentUser.storeid

  });


  const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const numberRegex = new RegExp(/^\d{1,10}$/);
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    if (fieldName === "price") {
      if (!numberRegex.test(value)) {
        error = `${capitalizedFieldName} should be a number`;
      }
    }
    if (fieldName === "storeName") {
      if (value.length <= 5) {
        error = `${capitalizedFieldName} should be More than 5 characters`;
      }
      
    }
    if (fieldName === "bankName") {
      if (value.length <= 3) {
        error = `${capitalizedFieldName} should  be More than 3 characters`;
      }
      
    }

    if (fieldName === "branchName") {
      if (value.length <= 3) {
        error = `${capitalizedFieldName} should  be More than 3 characters`;
      }
      
    }
    if (fieldName === "accountNo") {
      if (value.length <= 9) {
        error = `${capitalizedFieldName} should  be More than 9 characters`;
      }
      
    }
    if (fieldName === "ifscCode") {
      if (!ifscRegex.test(value)) {
        error = `${capitalizedFieldName} should be a in this form xxxx0000000`;
      }
    
    }
    return error;
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "storeName" && value.length > 45) {
      const truncatedValue = value.slice(0, 45);
      setFormData({ ...formData, [name]: truncatedValue });
      setErrors({ ...errors, [name]: "store Name should not exceed 45 characters" });
    }
    else if (name === "bankName" && value.length > 45) {
      const truncatedValue = value.slice(0, 45);
      setFormData({ ...formData, [name]: truncatedValue });
      setErrors({ ...errors, [name]: "bank Name should not exceed 45 characters" });
    }
   else if (name === "branchName" && value.length > 25) {
      const truncatedValue = value.slice(0, 25);
      setFormData({ ...formData, [name]: truncatedValue });
      setErrors({ ...errors, [name]: "bank Name should not exceed 25 characters" });
    }
    else if (name === "accountNo" && value.length > 16) {
      const truncatedValue = value.slice(0, 16);
      setFormData({ ...formData, [name]: truncatedValue });
      setErrors({ ...errors, [name]: "bank Name should not exceed 16 characters" });
    }
    else if (name === "ifscCode" && value.length > 12) {
      const truncatedValue = value.slice(0, 12);
      setFormData({ ...formData, [name]: truncatedValue });
      setErrors({ ...errors, [name]: "ifsc Code should not be more then  11 characters" });
    }
    else if (name === "upiId" && value.length > 50) {
      const truncatedValue = value.slice(0, 50);
      setFormData({ ...formData, [name]: truncatedValue });
      setErrors({ ...errors, [name]: "ifsc Code should not be more then 50 characters" });
    }
    else {
      setFormData({ ...formData, [name]: value });
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });
    }
  };


  const handleStorePayment = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
      try {
        const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/storePayment/", formData);
        if (response.data.status === true) {
          showSuccessSnackbar("Form Submit Successfull !");

          setFormData({
            ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
            storeName: currentUser.storeName,
            cratedBy: currentUser.username,
            updatedBy: currentUser.username,
            storeId: currentUser.storeid


          });
        }
      } catch (error) {
        showErrorSnackbar(error.response.data.message);
        setFormData({
          ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
          storeName: currentUser.storeName,
          cratedBy: currentUser.username,
          updatedBy: currentUser.username,
          storeId: currentUser.storeid

        });
      }
    }
  };
  const isSubmitDisabled = buttonDisabled(formData, errors);

  return (
    <Box
      component="main"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: "24vh" }}
    >
      <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="primary.light"
              p={1}
              sx={formMainBox}
            >
              <InventoryIcon fontSize="large" />
              <span style={{ marginLeft: "8px" }}>Add New Payment</span>{" "}
              {/* Text */}
            </Box>
          </Grid>
        </Grid>
        {Array.from({ length: 5 }).map((_, index) => (
          <Stack
            key={index}
            spacing={4}
            direction={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            sx={{ marginBottom: 1 }}
          >
            {formDataFields
              .slice(index * 2, index * 2 + 2)
              .map((formDataItem, innerIndex) => (
                <FormControl
                  key={innerIndex}
                  fullWidth
                  error={!!errors[formDataItem.name]}
                  variant="outlined"
                >
                  <CustomTypography
                    htmlFor={`outlined-adornment-${formDataItem.name}`}
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    {formDataItem.label}
                  </CustomTypography>
                  {formDataItem.name === "cratedBy" || formDataItem.name === "updatedBy" ||  formDataItem.name === "storeName" ? (
                    <Input
                      sx={inputStyles}
                      type={formDataItem.type}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      readOnly
                      disableUnderline
                    />
                  ) : (
                    <Input
                      sx={inputStyles}
                      type={formDataItem.type}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      disableUnderline
                    />
                  )}
                  <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                  >
                    {errors[formDataItem.name]}
                  </FormHelperText>
                </FormControl>
              ))}
          </Stack>
        ))}
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleStorePayment}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles} disabled={isSubmitDisabled}
          >
            Add Payment Details
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
export default PaymentSettingsForm;
