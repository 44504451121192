import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Input,
  InputAdornment,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useTableContext } from "../../Screens/tableContext/TableContext";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import PopOverModal from "../Cards/Modal/PopOverModal";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";


const rowsPerPage = 8;
function TaxSettings() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data);
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const [Tax, settax] = useState([]);
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableCellStyles,
    editAndDeleteButtonStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles,
    inputStyles,
    submitButtonStyles,
    buttonDisabled,
    capitalizeFirstLetter,
    errorMessageStyles,
    labelStyle, 
  } = useDrawer();
  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    getFontWeight,
    currentPage,
    handleChangePage, resetSearchInput
  } = useTableContext();

  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
  }, [location.pathname]);

  const filteredRows = filterRows(Tax);
  const [formData, setFormData] = useState({
    name: "",
    rate: "",
    createdBy: currentUser.createdBy,
    updatedBy: currentUser.createdBy,
    storeidfk: currentUser.storeid,

  });
  const [errors, setErrors] = useState({
    name: "",
    rate: "",
  

  });
  
  const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    } else if (fieldName === "name") {
      if (!/^[A-Za-z]+$/.test(value)) {
        error = `${capitalizedFieldName} should only contain alphabets`;
      } else if (value.length > 20) {
        error = `${capitalizedFieldName} should not exceed 20 characters`;
      }
    }
    else if (fieldName === "rate" ) {
      if (value <= 0) {
          error = `${capitalizedFieldName} should be a positive number`;
      }
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: error
    }));
    return error;
};


  const [opens, setOpens] = React.useState(false);

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClickClose = () => {
    setOpens(false);
  };
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const filteredRowsBySearch = filteredRows.filter((Tax) =>
    Object.values(Tax).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = filteredRowsBySearch.slice(startIndex, endIndex);

 const handleSubmit = async (e) => {
  e.preventDefault();
 
    try {
      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/tax/", formData);

      if (response.data.status === true) {
        showSuccessSnackbar("Form Submit Successful !");
        fetchData();
        setFormData({
          name: "",
          rate: "",
          storeidfk: currentUser.storeid,

        });
      }
    } catch (error) {
      showErrorSnackbar(error.response.data.message);
    }

};



const fetchData = () => {
  axios.get(`https://billing.ubsbill.com/apptest/v2/sys/tax/store/${currentUser.storeid}`)
  .then((response) => {
    if (response.data && response.data.data) {
      settax(response.data.data);
    } 
  })
  .catch((error) => {
    showErrorSnackbar("Error Fetching Tax Data");
  });
}

useEffect(() => {
  fetchData()
}, []);

const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
    const handleOpen = (serialNo) => {
       setSerialNoToDelete(serialNo); 
        setOpen(true);
    };



  const handleTaxDelete = async (serialNo) => {

    try {
      const response = await axios.delete(
        `https://billing.ubsbill.com/apptest/v2/sys/tax/${serialNo}`
      );

      if (response.data.status === true) {
       showSuccessSnackbar("Tax Data Deleted successfully.. !");
        handleClose();
        fetchData();
      }
    } catch (error) {
      console.error("Error occurred while deleting Tax Data:", error);
    
    }
  };

  const isSubmitDisabled = buttonDisabled(formData, errors);
  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box display="flex" max bgcolor="primary.light" sx={listMainBox}>
            <SettingsIcon sx={listIconStyles} /> {/* Icon */}
            <Typography variant="span" sx={{ ml: "8px" , color: isDarkMode ? "#fff" :"#fff", }}>
              Tax Setting
            </Typography>
          </Box>
          <Box sx={spaceBetweenTopComponent}>
            <Box bgcolor="#D9D9D9" p={1} sx={searchboxStyles}>
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
              <Divider
                orientation="vertical"
                sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
              />
              <Input
                fullWidth
                placeholder="Search"
                disableUnderline
                sx={{ color: isDarkMode ? "#fff" : "#000",}}
                value={searchInput}
                onChange={handleSearchChange}
              />
            </Box>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              onClick={handleClickOpen}
              sx={pdfAndExcelButtonStyles}
            >
              <span style={{ marginRight: "5px" }}>+</span> Taxes
            </Button>
            <Dialog
              open={opens}
              onClose={handleClickClose}
              PaperProps={{
                sx: { backgroundColor: isDarkMode ? "#737170" : '#D9D9D9', p: 2, borderRadius: "10px" },
              }}
            >
              <DialogTitle
                className="food-card-text"
                sx={{backgroundColor: isDarkMode ? "#48484a" : '#D9D9D9', color: "#fff" }}
              >
                Add Tax
              </DialogTitle>
              
              <DialogContent>
                <FormControl
                  sx={{ mt: 3 }}
                  fullWidth
                  error={!!errors.name}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-name"
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    Tax Type
                  </InputLabel>
                  <Input
                    sx={inputStyles}
                    id="outlined-adornment-name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    disableUnderline
                  />
                  <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                  >
                    {errors.name}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  sx={{ mt: 3 }}
                  fullWidth
                  error={!!errors.rate}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-rate"
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    Tax Rate (%)
                  </InputLabel>
                  <Input
                    sx={inputStyles}
                    id="outlined-adornment-rate"
                    type="number"
                    name="rate"
                    value={formData.rate}
                    onChange={handleInputChange}
                    disableUnderline
                  />
                  <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                  >
                    {errors.rate}
                  </FormHelperText>
                </FormControl>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
     type="submit"
      className="food-card-text mode-button border-radius-10 cart-button final-button"
      sx={{ ...submitButtonStyles, mb: 3 }}
      onClick={handleSubmit} disabled={isSubmitDisabled}
    >
                  Submit
                </Button>
              </DialogActions>
             
            </Dialog>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>
          <Table aria-label="simple table">
            <TableHead sx={tableHeadStyles}>
              <TableRow>
                <TableCell className="all-list-tabelcell" sx={tableCellStyles}>
                  SR No.
                </TableCell>
                <TableCell
                  className="all-list-tabelcell"
                  align="right"
                  sx={tableCellStyles}
                >
                  Tax Name
                </TableCell>
                <TableCell
                  className="all-list-tabelcell"
                  align="right"
                  sx={tableCellStyles}
                >
                  Rate (%)
                </TableCell>
                <TableCell
                  className="all-list-tabelcell"
                  align="right"
                  sx={tableCellStyles}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody>
              {displayedRows.map((row, index) => (
                <TableRow key={index} sx={contentTableRowStyles}>
                  {renderStyledCell(index + 1, getFontWeight(index + 1))}
                  {renderStyledCell(row.name, getFontWeight(row.nmae))}
                  {renderStyledCell(row.rate, getFontWeight(row.rate))}

                  <TableCell sx={contentTableCellStyles} align="left">
                    <Box className="align-items-center">
                    <Box
   
    className="justify-all-center"
    component={Link} to={`/profile-settings/tax-settings/update-tax/${row.serialNo}`}
    sx={{
      ...editAndDeleteButtonStyles,
      border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
    }}
  >
    <ModeEditOutlineRoundedIcon sx={{color: isDarkMode ?  "white" : "#4FC3A1" }} />
  </Box>
  
                      <Box
                       onClick={() => handleOpen(row.serialNo)}
                        className="justify-all-center"
                        sx={{
                          ...editAndDeleteButtonStyles,
                          border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                        }}
                       
                      >
                        <DeleteIcon sx={{ color: isDarkMode ?  "white" : "#f55651"  }} />
                      </Box>
                     
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
               <Modal
                           open={open}
                           onClose={handleClose}
                          
                          >
                          <PopOverModal
                              
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handleTaxDelete(serialNoToDelete)}
                              onClickTwo={handleClose}
                              
                              />
                              </Modal>
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
          <Pagination
            count={Math.ceil(filteredRows.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default TaxSettings;
