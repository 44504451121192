import { Card, CardActionArea, CardMedia, Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import SideBar from "../Sidebar/SideBar";
import "./links.css";
import { Link, useLocation } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
import "../VendorManagement/card.css";

function QuickAccessLinks({ data }) {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const location = useLocation();
  const isUserDashboard = location.pathname === '/user-dashboard';
  return (
    <Grid lg={11} md={11} sm={9.3} xs={12} xl={11} container spacing={1.7}>
      {data.map((card) => (
        <Grid item key={card.id} lg={4} md={4} sm={6} xs={11.5} xl={4}>
          <Link to={card.link} style={{ textDecoration: "none" }}>
            <Card
              className="justify-all-center justify-left-mobile link-card-style"
              sx={{
                background: isDarkMode ? "#5e5e5e"  :"#EEEEEE",
                marginBottom: "30px",
                minHeight: { sm: "10vh", md: "12vh", xs: "7vh", lg: "13vh", xl: "12vh" },
                // maxWidth: { sm: 300, md: 500, xs: "100%", lg: 300, xl: 630 },
                maxWidth: { sm: 200, md: 260, xs: "100%", lg: 300, xl: 310 },
                  minWidth:{ sm: 180, md: 200, xs: "100%", lg: 200, xl: 250 },
                padding: { sm: 0.2, md: 1.0, xs: 2.3, lg: 1.8 },
                boxShadow: "none",marginRight:{sm: isUserDashboard ? '10px' : '0px',md: isUserDashboard ? '20px' : '0px', lg: isUserDashboard ? '25px' : '0px', sm: "0px",  xl: isUserDashboard ? '30px' : '0px',}
              }}
            >
              <Stack direction="row" className="justify-all-center">
                <CardMedia
                  component="img"
                  alt={`${card.title} Icon`}
                  image={card.image}
                  sx={{ width: { sm: 30, md: 40, xs: 30, lg: 45, xl: 50 }, filter: isDarkMode ? "invert(1)" : "none", }}
                  
                />
                <CustomTypography
                  variant="p"
                  className="link-cart-title"
                  sx={{
                    fontSize: { sm: "13px", md: "15px", xs: "17px ", lg: "16px", xl: "18px" },
                    marginLeft: {
                      sm: "10px",
                      md: "20px",
                      xs: "17px ",
                      lg: "10px",
                      xl: "40px",
                    },
                  }}
                >
                  {card.title}
                </CustomTypography>
              </Stack>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export default QuickAccessLinks;