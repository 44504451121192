import React from 'react';
import { AppBar, Container } from '@mui/material';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';

const Footer = () => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const footerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#F3F3F3',  // Adjust the background color as needed
    color: 'black',
    padding: '1rem',
    textAlign: 'center',
  };

  return (
    <AppBar position="static" sx={footerStyle}>
      <Container>
        <CustomTypography variant="body1">
          &copy; 2023 Design & Developed By SYNTIARO
        </CustomTypography>
      </Container>
    </AppBar>
  );
};

export default Footer;
