import { Box, Dialog, Grid, Hidden, Slide, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { QuickOrderFoodCard } from "../Cards/QuickOrderFoodCard";
import AddOnIcons from "./NewOrderComponents/AddOnIcons";
import CartTable from "./NewOrderComponents/CartTable";
import { useDrawer } from "../Sidebar/DrawerContext";
import RedirectButtons from "./NewOrderComponents/RedirectButtons";
import axios from "axios";
import { useSelector } from "react-redux";
function NewOrder(){
  const currentUser = useSelector((state) => state.auth.user.data);
  const { isCartOpen, closeCart } = useDrawer();
  const [foodList, setFoodList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredFoodList, setFilteredFoodList] = useState([]);
  
  
  useEffect(() => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/food/store_all/${currentUser.storeid}`)
      .then((response) => {
        setFoodList(response.data.data)
      })
      .catch((error) => {

      })
  }, [])


  const handleCategorySelect = (category) => {
    setSelectedCategory(category === "All" ? null : category);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };



  useEffect(() => {
    const filteredList = foodList.filter(food =>
      (selectedCategory === null || food.category === selectedCategory) &&
      (searchQuery === '' || (food.foodName && food.foodName.toLowerCase().includes(searchQuery.toLowerCase())))
    );
    setFilteredFoodList(filteredList);
  }, [foodList, searchQuery ,selectedCategory]);


  const [numCards, setNumCards] = useState(0);

  useEffect(() => {
    setNumCards(filteredFoodList.length);
  }, [filteredFoodList]);

  const calculateMaxHeight = () => {
    if (numCards <= 6) {
      return {
        xs: "70vh",
        sm: "70vh",
        md: "65vh",
        lg: "40vh",
        xl: "40vh",
      };
    }  else if(numCards <= 9){
      return {
        xs: "70vh",
        sm: "70vh",
        md: "60vh",
        lg: "55vh",
        xl: "55vh",
      };
     } else if(numCards <= 12){
      return {
        xs: "70vh",
        sm: "70vh",
        md: "90vh",
        lg: "70vh",
        xl: "70vh",
      };
     } else {
      return {
        xs: "70vh",
        sm: "70vh",
        md: "90vh",
        lg: "90vh",
        xl: "90vh",
      };
    }
  };


  return (
    <Box sx={{ display: "block", maxHeight: "90vh", overflow: "hidden", p: "10px 0px" }}>
      <Box sx={{ marginLeft: { sm: "3px", md: "15px", xs: "0px" }, mb: { sm: "0px", md: "10px" } }}>
      <RedirectButtons
  onCategorySelect={handleCategorySelect}
  onSearch={handleSearch}
  foodItems={filteredFoodList}
  onAllClick={() => handleCategorySelect("All")}
/>


      </Box>
      <Box
        component="main"
        sx={{
          display: "flex",
        }}
      >

        <Grid container spacing={1} >

          <Grid
            className="scrollhide"
            item
            xl={1.2}
            lg={1.2}
            md={12}
            sm={12}
            xs={12}
            sx={{
              mt: { lg: "2px", md: "8px", sm: "10px", xs: "30px" },
              overflowX: "auto",
              overflowY: {
                xs: "hidden",
                sm: "hidden",
                md: "hidden",
                lg: "auto",
              },
              paddingBottom: { xs: "10px", sm: "10px", lg: "20vh" },
              borderRight: {
                xs: "2px solid #b5b8bd",
                sm: "2px solid #b5b8bd",
                md: "2px solid #b5b8bd",
                lg: "0px solid #b5b8bd",
              },
              maxHeight: calculateMaxHeight(),
            }}
          >
            <AddOnIcons onCategorySelect={handleCategorySelect} />
          </Grid>
          <Grid
            className="scrollhide"
            item
            xl={6.5}
            lg={6.6}
            md={6.7}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              paddingBottom: { xs: "30px", sm: "30px", lg: "10vh", md: "20vh" },
              overflowX: "auto",
              maxHeight: calculateMaxHeight(),
           
            }}
          > 
            {filteredFoodList.map((food) => (
              <QuickOrderFoodCard key={food.id} food={food} />
            ))}

          </Grid>
          <Hidden only={["sm", "xs"]}>
            <Grid className="scrollhide" item xl={4.1} lg={4} md={5.1} sm={12} xs={12} sx={{ mt: { lg: "-8vh", md: "0vh" } ,
             maxHeight: "100vh", paddingBottom: { md: "200px", lg: "100px" } , paddingRight:"5px", maxHeight: "100vh",  overflowY: "auto"}}>
              <CartTable />
            </Grid>
          </Hidden>
          <Hidden only={["lg", "xl", "md"]}>
            <Dialog
              open={isCartOpen}
              onClose={closeCart}
              keepMounted
              PaperProps={{
                sx: {
                  borderRadius: "15px",
                  minWidth: { xs: "90vw", sm: "60vw" },
                },
              }}
              aria-describedby="alert-dialog-slide-description"
            >
              <Grid
                item
                xl={3.7}
                lg={3.8}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  p: { xs: 0, sm: 0, md: 4, lg: 4 },
                  background: "#fff",
                  borderRadius: "15px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                }}
              >
                <CartTable />
              </Grid>
            </Dialog>
          </Hidden>
        </Grid>
      </Box>
    </Box>
  );
}

export default NewOrder;