import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React, {  useEffect, useState } from "react";
  import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { useDrawer } from "../Sidebar/DrawerContext";
  import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
  import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import axios from "axios";
import { Link } from "react-router-dom";
import PopOverModal from "../Cards/Modal/PopOverModal";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

  function PosSettings() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
const[CategoryButton , setCategoryButton] = useState([]);
const {showSuccessSnackbar} = useSuccessSnackbar();
const {showErrorSnackbar} = useErrorSnackbar ();

    const {
      tableCellStyles,

      listMainGridStyles,
      contentTableCellStyles,
      editAndDeleteButtonStyles,
      contentTableRowStyles,
      tableHeadStyles,
      listMainBox,
      listIconStyles,
      formMainBox,
      formPaperStyles,
      errorMessageStyles,
      labelStyle,inputStyles,submitButtonStyles,buttonDisabled,capitalizeFirstLetter,
    } = useDrawer();
    const currentUser = useSelector((state) => state.auth.user.data);
  

    const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
    const handleOpen = (serialNo) => {
       setSerialNoToDelete(serialNo); 
        setOpen(true);
    };

  
    const [formData, setFormData] = useState({
      name: '',
     
      storeId: `${currentUser.storeid}`,
      cratedBy: `${currentUser.username}`,
      updatedBy: `${currentUser.username}`,

    });
    const [errors, setErrors] =  useState({
      name: '',
    });
    const validateField = (fieldName, value) => {
      let error = "";
      const capitalizedFieldName = capitalizeFirstLetter(fieldName);
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;

      }
     if(value.length >= 40){
        error = `${capitalizedFieldName} should not exceed 40 characters`;
      
      }
     
      return error;
    };


    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if(value.length > 25){
        return;
      }
      setFormData({ ...formData, [name]: value });
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });
      
    };
    

    const handlePosSetting = async (e) => {
      e.preventDefault();
      if (Object.values(errors).every((error) => error === "")) {
   
      try{
        const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/categorybutton/",formData);
   
        if(response.data.status === true){
     
          fetchData();
          showSuccessSnackbar("Form Submit Successfull !");
          setFormData({
            name: '',
            storeId: `${currentUser.storeid}`,
      cratedBy: `${currentUser.username}`,
      updatedBy: `${currentUser.username}`,
      });
        }
        else{ showErrorSnackbar(response.data.message);
          setFormData({
            name: '',
            storeId: currentUser,
      }); }
      }catch (error) {

        showErrorSnackbar(error.response.data.message);
        setFormData({
          name: '',
          storeId: `${`${currentUser.storeid}`}`,
          cratedBy: `${currentUser.username}`,
          updatedBy: `${currentUser.username}`,
         
        });
      }
    }
  };


  const fetchData = () => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/categorybutton/store/${currentUser.storeid}`)
    .then((response) =>{
      if (response.data && response.data.data) {
        setCategoryButton(response.data.data);
        
      } 
    })
    .catch((error) => {
      showErrorSnackbar("Error Fetching Tax Data");
    });
  }
  useEffect(()=>{
    fetchData();
  },[]) 
  const handleDelete = async (id) => {

    try {
      const response = await axios.delete(
        `https://billing.ubsbill.com/apptest/v2/sys/categorybutton/${id}`
      );

      if (response.data.status === true) {
       showSuccessSnackbar("Sub Category Data Deleted successfully.. !");
        handleClose();
        fetchData();
      }
    } catch (error) {
      console.error("Error occurred while deleting Sub Category Data:", error);
    
    }
  };
  const isSubmitDisabled = buttonDisabled(formData,errors);
    return (
      <Box component="main" sx={{ p: 2 }}>
        <Grid xl={12} container spacing={4} sx={{ mb: 4 , mt: 4}}>
          <Grid item xs={12} xl={4} lg={4} md={4} sm={10}>
          
           <Paper elevation={12} sx={{...formPaperStyles,  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" }, boxShadow:"0px 4px 4px 0px #00000040",mt:0}}>
          
              <Box
                display="flex"
                alignItems="center"
                bgcolor="primary.light"
                p={1}
                sx={formMainBox}
              >
  
                <RiceBowlIcon fontSize="large" />
                <CustomTypography variant="span" sx={{ ml: "8px" }}>
                Store Settings
              </CustomTypography>
              </Box>
              <FormControl
                    
                    fullWidth
                    error={!!errors.name}
                    variant="outlined"
                    sx={{mt:6, mb: 3}}
                  >
                    <CustomTypography
                      htmlFor={`outlined-adornment-name`}
                      className="food-card-text"
                      sx={labelStyle}
                    >
                      Category Name
                    </CustomTypography>
  
                      <Input
                        sx={inputStyles}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        disableUnderline
                      />
                  <FormHelperText
                      className="food-card-text"
                      sx={errorMessageStyles}
                    >
                      {errors.name}
                    </FormHelperText>
                  </FormControl>
                  <Button onClick={handlePosSetting}
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={{...submitButtonStyles, mb:3}} disabled={isSubmitDisabled}
            >
              Submit
            </Button>
  
          
          </Paper>
          
          </Grid>
          <Grid item xs={12} xl={8} lg={8} md={8} sm={10} sx={listMainGridStyles}>
            <Box display="flex" max bgcolor="primary.light" sx={listMainBox}>
              <PlaylistAddIcon sx={listIconStyles} /> {/* Icon */}
              <CustomTypography variant="span" sx={{ ml: "8px" }}>
                Category Details
              </CustomTypography>
            </Box>
         
          <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1, mt: 2 }}>
            <Table aria-label="simple table">
              <TableHead sx={tableHeadStyles}>
                <TableRow>
                  <TableCell className="all-list-tabelcell" sx={tableCellStyles}>
                    SR No.
                  </TableCell>
                  <TableCell
                    className="all-list-tabelcell"
                    align="right"
                    sx={tableCellStyles}
                  >
                    Category Name
                  </TableCell>
                  <TableCell
                    className="all-list-tabelcell"
                    align="right"
                    sx={tableCellStyles}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <div style={{ padding: "10px" }} />
              <TableBody>
                {CategoryButton.map((row , index) => (
                  <TableRow key={index} sx={contentTableRowStyles}>
                    <TableCell
                      sx={contentTableCellStyles}
                      component="th"
                      scope="row"
                    >
                      {index +1 }
                    </TableCell>
                    <TableCell
                      sx={contentTableCellStyles}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
  
                    <TableCell sx={contentTableCellStyles} align="left">
                      <Box className="align-items-center">
                        <Box
                          className="justify-all-center"
                          component={Link} to={`/profile-settings/pos-settings/update-subcategory/${row.id}`}
                          sx={{
                            ...editAndDeleteButtonStyles,
                            border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                          }}
                        >
                          <ModeEditOutlineRoundedIcon sx={{ color: isDarkMode ?  "white" : "#4FC3A1"}} />
                        </Box>
                        <Box
                          className="justify-all-center"
                          sx={{
                            ...editAndDeleteButtonStyles,
                            border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                          }}
                          onClick={() => handleOpen(row.id)}
                        >
                          <DeleteIcon sx={{ color: isDarkMode ?  "white" : "#f55651" }} />
                        </Box>
                       
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                 <Modal
                           open={open}
                           onClose={handleClose}
                          
                          >
                          <PopOverModal
                              
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handleDelete(serialNoToDelete)}
                              onClickTwo={handleClose}
                              />
                              </Modal>
              </TableBody>
            </Table>
          </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
  
  export default PosSettings;
  