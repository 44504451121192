import { Box, Button, Divider, Grid, Input, InputAdornment } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useDrawer } from '../../Sidebar/DrawerContext';
import SearchIcon from "@mui/icons-material/Search";
import { useDarkMode } from '../../../Screens/DarkMode/DarkMode';
function RedirectButtons({ onAllClick, foodItems, onSearch }) {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { submitButtonStyles, searchboxStyles } = useDrawer();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const newOrderButtons = [
    { text: "Running Order", link: "/pending-order" },
    { text: "Order List", link: "/order-list" },
    { text: "KOT", link: "/kitchen-order-ticket" },
  ];

  const handleAllClick = () => {
    setSelectedCategory(null);
    onAllClick();
  };


  const handleSearch = (query) => {
    setSearchQuery(query);
    onSearch(query);
  };
  

  const filteredFoodItems = foodItems.filter(item =>
    item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box>
      <Grid
        container
        xl={8.6} lg={8.4} md={12} sm={12} xs={12}
        spacing={{
          xs: 0.6, sm: 1, md: 2, xl: 2,
        }}
        sx={{ mt: 1, minHeight: { lg: "9vh", sm: "7vh", } }}
      >
        <Grid item  >
          <Button
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={{ 
              ...submitButtonStyles, 
              marginRight: "0px", 
              padding: { sm: '6px 27px', md: '8px 26px', xs: '8px 12px', xl: '10px 40px', lg: "8px 40px" },
              backgroundColor: isDarkMode ? "#5e5e5e"  :"#47C01C",
              color: '#FFFFFF', 
            }}
            onClick={handleAllClick} 
          >
            All
          </Button>
        </Grid>
        {newOrderButtons.map((button, index) => (
          <Grid key={index} item>
            <Link to={button.link}>
              <Button
                className="food-card-text mode-button border-radius-10 cart-button final-button"
                sx={{
                  ...submitButtonStyles, 
                  marginRight: "0px", 
                  backgroundColor: location.pathname === button.link
                ? isDarkMode ? '#5e5e5e' : '#28A497' 
                : isDarkMode ? '#5e5e5e' : '#D9D9D9', 
                  color: location.pathname === button.link ?  isDarkMode ? '#fff' : '#fff' 
                  : isDarkMode ? '#fff' : '#000000', 
                }}
              >
                {button.text}
              </Button>
            </Link>
          </Grid>
        ))}
        <Grid xl={3.2} lg={3.2} md={4} sm={3.5} xs={8} item sx={{ width: "auto" }}>
        
      <Box sx={{ padding: '6px 16px', display: 'flex', alignItems: 'center', borderRadius: 10 , backgroundColor: isDarkMode ? '#5e5e5e' : '#D9D9D9', }}>
        <InputAdornment position="start">
          <SearchIcon sx={{  color: isDarkMode ? "#fff" : "#000000",}}/>
        </InputAdornment>
        <Divider orientation="vertical" sx={{ height: 24, mx: 1, backgroundColor: '#424242' }} />
        <Input
          fullWidth
         
          placeholder="Search"
          sx={{  color: isDarkMode ? "#fff" : "#000000",}}
          disableUnderline
          value={searchQuery}
          onChange={e => handleSearch(e.target.value)}
        />
      </Box>
  
        </Grid>
      </Grid>
    
    </Box>
  )
}

export default RedirectButtons