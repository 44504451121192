import { Box, Button, Checkbox, Divider, Grid, IconButton, Input, InputAdornment, Pagination, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useDrawer } from '../Sidebar/DrawerContext';
import { useTableContext } from '../../Screens/tableContext/TableContext';
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';


function CashRegisterList() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const currentUser = useSelector((state) => state.auth.user.data);
    const [CashRegisterList, setCashRegisterList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [value, setValue] = useState("");
    const [total, settotal] = useState([]);


    const {
        pdfAndExcelButtonStyles,
        tableCellStyles,
        searchboxStyles,
        listMainGridStyles,
        contentTableCellStyles,
        contentTableRowStyles,
        tableHeadStyles,
        listMainBox,
        spaceBetweenTopComponent,
        pagignationStyles,
        listIconStyles,
        listTabStyles,
        listTabsStyles,
        fromAndToTextStyles,
        dateFieldStyles,
        filterAndCancelIcon,dailyStyle

    } = useDrawer();
    const {
        searchInput,
        handleSearchChange,
        filterRows,
        renderStyledCell,
        getFontWeight, generatePdf, downloadExcel,  resetSearchInput, resetCurrentPage

    } = useTableContext();





    const tableHeaders = [
        { label: "SR No." },
        { label: "Date" },
        { label: "Opening Balance" },
        { label: "Sales" },
        { label: "Cashier" },
        { label: "Expense" },
        { label: "Amount" },
        { label: "Status" },
        { label: "Remaining Balance" },
        { label: "Handed Over To" },
        { label: "Handed Amount", },
        { label: "Closing Balance", },
       

    ];

    const location = useLocation();
  
    useEffect(() => {
      resetSearchInput();
      resetCurrentPage();
    }, [location.pathname]);
    const filteredRows = filterRows(CashRegisterList);

    const downloadPDF = () => {
      
        generatePdf("CashRegisterList", tableHeaders, reorderedData , 8);
    };
    
    const [reorderedData, setReorderedData] = useState([]);

    const fetchData = async () => {
        try {
     const url =
          fromDate && toDate
          ? `https://billing.ubsbill.com/apptest/v2/sys/balance/store/${currentUser.storeid}?page=${currentPage -1}&size=10&startDate=${fromDate}&endDate=${toDate}`
          : `https://billing.ubsbill.com/apptest/v2/sys/balance/store/${currentUser.storeid}?page=${currentPage -1}&size=10`;

            const response = await fetch(url);
            const json = await response.json();
        

           setCashRegisterList(json.data.balance);
           console.log(json.data.balance)
          
           const reorderedData = json.data.balance.map((register, index) => {
           
            const salesDetails = `Cash: ${register.sales.cash}\nCard: ${register.sales.card}\nUPI: ${register.sales.upi}`;
        
            
            const cashier = json.data.balance[0].transction[0].cashier;
        
           
            const expenses = register.transction.map(transaction => transaction.expense).join('\n');

            const amounts = register.transction.map(transaction => transaction.amount).join('\n');

            const statuses = register.transction.map(transaction => transaction.status).join('\n');
        
            return {
                id: index + 1,
                date: register.date,
                openingBalance: register.openingBalance,
                sales: salesDetails,
                cashier: cashier,
                expense: expenses,
                amount: amounts, 
                status: statuses,
                remainingBalance: register.remainingBalance,
                handedOver: register.handedOver,
                handedAmonut: register.handedAmonut,
            };
        });
        
        setReorderedData(reorderedData)
        settotal(json.data.balance[0].totalPages)

        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };


    useEffect(() => {
        fetchData();

    }, [fromDate, toDate , currentPage]);


    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const filterDailyRecords = () => {
        const today = new Date();
        const fromDateFormatted = formatDate(today);
        const toDateFormatted = formatDate(today);

        setFromDate(fromDateFormatted);
        setToDate(toDateFormatted);
        resetCurrentPage();
    };

    const filterMonthlyRecords = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
        );

        const fromDateFormatted = formatDate(firstDayOfMonth);
        const toDateFormatted = formatDate(lastDayOfMonth);
        setFromDate(fromDateFormatted);
        setToDate(toDateFormatted);
        resetCurrentPage();
    };

    const filterYearlyRecords = () => {
        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

        const fromDateFormatted = formatDate(firstDayOfYear);
        const toDateFormatted = formatDate(lastDayOfYear);

        setFromDate(fromDateFormatted);
        setToDate(toDateFormatted);

        resetCurrentPage();

    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                filterDailyRecords();
                break;
            case 1:
                filterMonthlyRecords();
                break;
            case 2:
                filterYearlyRecords();
                break;
            default:
                break;
        }
    };

    const filteredRowsBySearch = filteredRows.filter((row) =>
        Object.values(row).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(searchInput.toLowerCase())
        )
    );

    
    const displayedRows = filteredRowsBySearch;

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
      };

    const handleClearFields = () => {
        setFromDate(''); // Clear fromDate
        setToDate('');   // Clear toDate
        resetSearchInput();   
        setValue("");
    };

    return (
        <Box component="main" sx={{ p: 2 }}>
            <Grid container spacing={1} sx={{ mb: 4, }}>
                <Grid item xs={12} sx={listMainGridStyles}>
                    <Box
                        display="flex"
                        bgcolor="primary.light"
                        sx={listMainBox}
                    >
                        <AccountBalanceWalletIcon sx={listIconStyles} />
                        <Typography variant='span' sx={{ ml: "8px", color: isDarkMode ? "#fff" :"#fff", }}>Cash Register List</Typography>
                    </Box>
                    <Box sx={spaceBetweenTopComponent}>
                        <Button
                            className='food-card-text mode-button border-radius-10 cart-button final-button'
                            component={Link} to="/cash-management-dashboard/cash-register"
                            sx={pdfAndExcelButtonStyles}
                        >
                            + Cash Register
                        </Button>
                        <Button className='food-card-text mode-button border-radius-10 cart-button final-button'
                            onClick={downloadPDF} sx={pdfAndExcelButtonStyles}>
                            PDF
                        </Button>
                        <Button className='food-card-text mode-button border-radius-10 cart-button final-button' onClick={() => downloadExcel(tableHeaders, reorderedData, "CashRegisterList")} sx={pdfAndExcelButtonStyles}>
                            Excel
                        </Button></Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={6} sm={4} md={3} lg={2.5} xl={2}>
                    <Box
                        bgcolor="#D9D9D9"
                        p={1}
                        sx={searchboxStyles}
                    >
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                        <Divider orientation="vertical" sx={{ height: 24, mx: 1,  backgroundColor: isDarkMode ? "#fff" : "#424242" }} />
                        <Input
                            fullWidth
                            placeholder="Search"
                            sx={{ color: isDarkMode ? "#fff" : "#000",}}
                            disableUnderline
                            value={searchInput}
                            onChange={handleSearchChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={4.2} md={4} lg={2.7} xl={3}>
                    <Box   sx={dailyStyle}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="white"
                            indicatorColor="white"
                            aria-label="tabs"
                            sx={listTabsStyles}
                        >
                            <Tab
                                label="Daily"
                                value={0}
                                className="tabs"
                                sx={{
                                    ...listTabStyles,
                                    bgcolor: value === 0 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                                    color: value === 0 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                                }}
                            />
                            <Tab
                                label="Monthly"
                                value={1}
                                sx={{
                                    ...listTabStyles,
                                    bgcolor: value === 1 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                                    color: value === 1 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                                }}
                            />
                            <Tab
                                label="Yearly"
                                value={2}
                                sx={{
                                    ...listTabStyles,
                                    bgcolor: value === 2 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                                   color: value === 2 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                                }}
                            />

                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={7} sm={4} md={4} lg={2.6} xl={2.3}>
                    <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
                        From
                        <Input fullWidth type="date" id="outlined-basic" label="Outlined" variant="outlined" sx={dateFieldStyles}  value={fromDate}  onChange={(e) => setFromDate(e.target.value)} disableUnderline />
                    </Box>
                </Grid>
                <Grid item xs={7} sm={4} md={4} lg={2.6} xl={2.3}>
                    <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
                        To
                        <Input fullWidth type="date" id="outlined-basic" label="Outlined" variant="outlined" sx={dateFieldStyles}  value={toDate} onChange={(e) => setToDate(e.target.value)} disableUnderline />
                    </Box>
                </Grid>
                <Grid item>
                    <Box bgcolor="#D9D9D9" p={0.5} sx={filterAndCancelIcon}>
                        <IconButton onClick={fetchData}>
                            <FilterAltIcon />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item>
                    <Box bgcolor="#D9D9D9" sx={filterAndCancelIcon}>
                        <IconButton onClick={handleClearFields}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mt: 4 }}>
                <Box sx={{ width: '100%', overflowX: 'auto', borderRadius: 1 }}>
                    <Table aria-label="simple table">
                        <TableHead sx={tableHeadStyles}>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        className="all-list-tabelcell"
                                        align="right"
                                        sx={tableCellStyles}
                                    >
                                        {header.label}
                                        {header.label === "Select All" && (
                                            <Checkbox
                                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <div style={{ padding: "10px" }} />


                        <TableBody>
  {filteredRows.map((row, index) => (
    <TableRow key={index} sx={contentTableRowStyles}>
      {/* Render index, date, opening balance, and sales */}
      {renderStyledCell(index + 1, getFontWeight(index + 1))}
      {renderStyledCell(row.date, getFontWeight(row.date))}
      {renderStyledCell(row.openingBalance, getFontWeight(row.openingBalance))}
      {renderStyledCell(
        <Box>
          <Box>Cash: {row.sales.cash}</Box>
          <Box>Card: {row.sales.card}</Box>
          <Box>UPI: {row.sales.upi}</Box>
        </Box>,
        getFontWeight(row.sales)
      )}

      {/* Render first cashier */}
      {renderStyledCell(row.transction.length > 0 ? row.transction[0].cashier : '', getFontWeight(row.transction.length > 0 ? row.transction[0].cashier : ''))}

      {/* Render expenses, amounts, and statuses */}
      <TableCell  sx={contentTableCellStyles}>
        <Box>
          {row.transction.map(transaction => (
            <Box className='food-card-text' sx={{color: isDarkMode ? "#fff" : "#000"}} key={transaction.id}>
              {transaction.expense}
            </Box>
          ))}
        </Box>
      </TableCell>
      <TableCell sx={contentTableCellStyles}>
        <Box>
          {row.transction.map(transaction => (
            <Box className='food-card-text' sx={{color: isDarkMode ? "#fff" : "#000"}} key={transaction.id}>
              {transaction.amount}
            </Box>
          ))}
        </Box>
      </TableCell>
      <TableCell sx={contentTableCellStyles}>
        <Box>
          {row.transction.map(transaction => (
            <Box className='food-card-text' sx={{color: isDarkMode ? "#fff" : "#000"}} key={transaction.id}>
               {transaction.status}
            </Box>
          ))}
        </Box>
      </TableCell>

      {/* Render remaining balance, handed over, handed amount, and closing balance */}
      {renderStyledCell(row.remainingBalance, getFontWeight(row.remainingBalance))}
      {renderStyledCell(row.handedOver, getFontWeight(row.handedOver))}
      {renderStyledCell(row.handedAmonut, getFontWeight(row.handedAmonut))}
      {/* {renderStyledCell(row.closingBalance, getFontWeight(row.closingBalance))} */}
      {renderStyledCell(row.closingBalance !== null ? row.closingBalance.toFixed(2) : '',getFontWeight(row.closingBalance))}


    </TableRow>
  ))}
</TableBody>


                    </Table>
                </Box>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
                <Grid item>
                <Pagination
            count={total}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
                </Grid>
            </Grid>
        </Box >
    )
}

export default CashRegisterList;