import React from 'react'
import NavLinkCard from '../StoreDashboardComponent/NavLinkCard'
import { Box } from '@mui/material'

function CardMainComponent() {
  return (
    <Box>
        <NavLinkCard />
    </Box>
  )
}

export default CardMainComponent
