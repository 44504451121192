import React, { useState, useEffect } from "react";
import { Box, Button, Stack, FormControl, FormHelperText, Grid, Paper, CircularProgress, InputAdornment, Input, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useSelector } from "react-redux";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

function EditUser() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
const { labelStyle, inputStyles, errorMessageStyles, submitButtonStyles, capitalizeFirstLetter,  } = useDrawer();
const [showPassword, setShowPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);
const { serialNo } = useParams();
const currentUser = useSelector((state) => state.auth.user.data);
const { showSuccessSnackbar } = useSuccessSnackbar();
const { showErrorSnackbar } = useErrorSnackbar();
const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
};

const formDataFields = [
    { name: 'username', label: 'Username', type: 'text' },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'contact', label: 'Contact', type: 'number' },
    { name: 'address', label: 'Address', type: 'text' },
];

const [formData, setFormData] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
    storeId: currentUser.storeId,
    createdBy: currentUser.username,
    updatedBy: currentUser.username,
});

const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
);

const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,30}$/;
    const userNameRegex = /^(?=.*[A-Z])[a-zA-Z0-9_]{4,20}$/; 
    const contactRegex = /^[6-9][0-9]{9}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    if (fieldName === "email") {
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;
      } else if (!emailRegex.test(value)) {
        error = "Please enter a valid email in the format username@example.com";
      }
    }
    if (fieldName === "contact") {
      if (!contactRegex.test(value)) {
        error = "Please enter a valid 10-digit contact number";
      }
      else if (value <= 0) {
        error = `${capitalizedFieldName} should be a positive number`;
    }
    }
    
    else if (fieldName === "username" ){
      if (!userNameRegex.test(value)) {
         error = `Username Must Contain One Uppercase Letter & No Special CharaCters Allowed and 20 below characters!`;
      }
  }
    if (fieldName === "password") {
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;
      } else if (!passwordRegex.test(value)) {
        error = `${capitalizedFieldName} Must Have One Upprcase Letter, One Special Charater & Atleast one Number`;
      }
    }
    if (fieldName === "comfirmpassword") {
      if (value.trim() === "") {
        error = `${capitalizedFieldName} is Mandatory`;
      } else if (value !== formData.password) {
        error = "The confirm password does not match the entered password";
      }else if (value > 30) {
        error = `Confirm Password should not exceed 30 characters`;
      }
    }
    else if (fieldName === "email"){
      if (value.length > 40) {
          error = `${capitalizedFieldName} should not exceed 40 characters`;
      }
  }
  else if (fieldName === "address" ){
    if (value.length > 100) {
        error = `${capitalizedFieldName} should not exceed 100 characters`;
    }
}
   
    return error;
  };

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
     setErrors({ ...errors, [name]: "" });
    }
};

const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
};
const navigate =useNavigate();
useEffect(() => {
    const fetchData = async () => {
     try {
        const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/api/auth/user/id/${serialNo}`);
        
        const userData = response.data.data;
        if(String(response.data.data.storeId) !== String(currentUser.storeid))
        {
           navigate('/unauthorized');
        }
        else{
      setFormData({
         ...formData,
         username: userData.username,
         email: userData.email,
         contact: userData.contact,
         address: userData.address,
        });
      }
     } catch (error) {
        console.error("Failed to fetch user data:", error);
     }
    };
    fetchData();
}, [serialNo]);



const handleUserUpdate = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
     try {
         const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/api/auth/user/${serialNo}`, formData);
         if (response.data.status === true) {
             showSuccessSnackbar("Form updated successfully!");
            
         } else {
             showErrorSnackbar(response.data.message);
            
         }
     } catch (error) {
         if (error.response && error.response.data) {
             showErrorSnackbar(error.response.data.message);
         } else {
            
             showErrorSnackbar("Form update failed. Please try again.");
         }
     }
    }
};


return (
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     <Paper elevation={12} sx={{ backgroundColor: "#EBEBEB", width: { xs: "100%", sm: "80%", md: "60%", lg: "60%", xl: "60%" }, p: 3, mt: 5 }}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
         <Grid item>
            <Box display="flex" alignItems="center" bgcolor="primary.light" p={1} sx={{ fontWeight: "500", backgroundColor: "#28A497", fontSize: "24px", color: "white", borderRadius: 1 }}>
             <PersonAddIcon fontSize="large" />
             <span style={{ marginLeft: '8px' }}>Update User</span>
            </Box>
         </Grid>
        </Grid>
        {Array.from({ length: 4 }).map((_, index) => (
         <Stack key={index} spacing={4} direction={{ xs: "column", sm: "row", md: "row", lg: "row", xl: "row" }} sx={{ marginBottom: 1 }}>
            {formDataFields
             .slice(index * 2, index * 2 + 2)
             .map((formDataItem, innerIndex) => (
                <FormControl key={innerIndex} fullWidth error={!!errors[formDataItem.name]} variant="outlined">
                 <CustomTypography htmlFor={`outlined-adornment-${formDataItem.name}`} className="food-card-text" sx={labelStyle}>
                    {formDataItem.label}
                 </CustomTypography>
                 <Input
                    type={formDataItem.type}
                    sx={inputStyles}
                    onBlur={handleInputBlur}
                    name={formDataItem.name}
                    value={formData[formDataItem.name]}
                    onChange={handleInputChange}
                    disableUnderline
                    endAdornment={
                     (formDataItem.name === "password" || formDataItem.name === "confirmPassword") &&
                     <InputAdornment position="end">
                        <IconButton onClick={(formDataItem.name === "password") ? handleTogglePasswordVisibility : handleToggleConfirmPasswordVisibility} edge="end">
                         {(formDataItem.name === "password" && showPassword) || (formDataItem.name === "confirmPassword" && showConfirmPassword) ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                     </InputAdornment>
                    }
                 />
                 <FormHelperText className="food-card-text" sx={errorMessageStyles}>{errors[formDataItem.name]}</FormHelperText>
                </FormControl>
             ))}
         </Stack>
        ))}
        {/* Loader or Submit Button */}
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
         <Button className='food-card-text mode-button border-radius-10 cart-button final-button' onClick={handleUserUpdate} sx={submitButtonStyles} >
            Submit
         </Button>
        </Box>
     </Paper>
    </Box>
)
}

export default EditUser;