import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Checkbox,
  Modal,
  Typography,

} from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";

import jsPDF from "jspdf";
import VisibilityIcon from '@mui/icons-material/Visibility';
import "jspdf-autotable";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useDrawer } from "../../Sidebar/DrawerContext";
import { useTableContext } from "../../../Screens/tableContext/TableContext";
import { StoreDetailsSuperadmin } from "./StoreDetailsSuperadmin";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";
const rowsPerPage = 10;
function StoreListSuperadmin() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [storeList , setStoreList] = useState([]);
  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableCellStyles,
    editAndDeleteButtonStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles,
    listTabStyles,
    listTabsStyles,
    fromAndToTextStyles,
    dateFieldStyles,
    filterAndCancelIcon,dailyStyle , iconColor
  } = useDrawer();



  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    getFontWeight, generatePdf, downloadExcel ,  currentPage, handleChangePage ,  resetCurrentPage , resetSearchInput
  } = useTableContext();
  
  const [total, settotal] = useState([]);
  const [value, setValue] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reorderedData , setReorderedData] = useState("");
  
  useEffect(() => {

    const url = fromDate && toDate ?
    `https://billing.ubsbill.com/apptest/v2/api/auth/store/?size=10&page=${currentPage -1}&startDate=${fromDate}&endDate=${toDate}` :
    `https://billing.ubsbill.com/apptest/v2/api/auth/store/?size=10&page=${currentPage -1}`;

    axios.get(url)
      .then((response) => {
        setStoreList(response.data.data);
        const reorderedData = response.data.data.map((store , index) => ({
          no: index+1,
          id: store.id,
          storeName: store.storeName, 
          username: store.username,
          email: store.email,
          contact: store.contact,
          registrationNumber: store.registrationNumber,
          state: store.state,
          subscriptionType: store.subscriptionType,
         }));
        setReorderedData(reorderedData);
        settotal(response.data.data[0].total)
      }).catch((error) => {
       
      });
  },  [currentPage, fromDate, toDate]);

  const filteredRows = filterRows(storeList);

  const tableHeaders = [
    { label: "SR No." },
    { label: "Store Id" },
    { label: "Store Name" },
    { label: "Username" },
    { label: "Email" },
    { label: "Contact" },
    { label: "Registration Number" },
    { label: "State" },
    { label: "Subscription Type" },
    { label: "View" },
  ];

  const downloadPDF = () => {
    generatePdf("Store List", tableHeaders, reorderedData , 8);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const filterDailyRecords = () => {
    const today = new Date();
    const fromDateFormatted = formatDate(today);
    const toDateFormatted = formatDate(today);

    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);

    resetCurrentPage();
  };

  const filterMonthlyRecords = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const fromDateFormatted = formatDate(firstDayOfMonth);
    const toDateFormatted = formatDate(lastDayOfMonth);
    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);
    resetCurrentPage();
  };

  const filterYearlyRecords = () => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

    const fromDateFormatted = formatDate(firstDayOfYear);
    const toDateFormatted = formatDate(lastDayOfYear);

    setFromDate(fromDateFormatted);
    setToDate(toDateFormatted);
    resetCurrentPage();
  };
  
  const location = useLocation();
  
  useEffect(() => {
    resetSearchInput();
    resetCurrentPage();
  }, [location.pathname]);

 

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        filterDailyRecords();
        break;
      case 1:
        filterMonthlyRecords();
        break;
      case 2:
        filterYearlyRecords();
        break;
      default:
        break;
    }
  };




 const filteredRowsBySearch = filteredRows.filter((storeList) =>
    Object.values(storeList).some(
      (value) =>
        value && value.toString().toLowerCase().includes(searchInput.toLowerCase())
    )
  );
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedRows = filteredRowsBySearch;
  const [openModal, setOpenModal] = useState(false);

const handleCloseModal = () => {
  setOpenModal(false);
};
 

const [selectedStoreId , setSelectedStoreId] = useState(null);
  const handleOpenModal = (storeId) => {
    setSelectedStoreId(storeId); 
    setOpenModal(true); 
  };

  const handleClearFields = () => {

    setFromDate("");
    setToDate("");   
    resetSearchInput();   
    setValue("");
  };

  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box bgcolor="primary.light" sx={listMainBox}>
            <ReceiptLongIcon sx={listIconStyles} />
            <Typography variant="span" sx={{ ml: "8px" , color: "#fff" }}>
              Store List
            </Typography>
          </Box>
          <Box sx={spaceBetweenTopComponent}>
          <Button
                          className='food-card-text mode-button border-radius-10 cart-button final-button'
                          component={Link}
                          to="/store-management-dashboard/add-new-store" // Specify the link destination here
                          sx={pdfAndExcelButtonStyles}
                      >
                          + Store
                      </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles}
              onClick={downloadPDF}
            >
              PDF
            </Button>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles} onClick={() => downloadExcel(tableHeaders, reorderedData, "StoreList")}
            >
              Excel
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={3} lg={2.5} xl={2}>
          <Box bgcolor="#D9D9D9" p={1} sx={searchboxStyles}>
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
            <Divider
              orientation="vertical"
              sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
            />
            <Input
              fullWidth
              placeholder="Search"
              sx={{ color: isDarkMode ? "#fff" : "#000",}}
              disableUnderline
              value={searchInput}
              onChange={handleSearchChange}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={4.2} md={4} lg={2.7} xl={3}>

          <Box
            bgcolor="#D9D9D9"
            sx={dailyStyle}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="white"
              indicatorColor="white"
              aria-label="tabs"
              sx={listTabsStyles}
            >
              <Tab
                label="Daily"
                value={0}
                className="tabs"
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 0 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 0 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
              <Tab
                label="Monthly"
                value={1}
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 1 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 1 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
              <Tab
                label="Yearly"
                value={2}
                sx={{
                  ...listTabStyles,
                  bgcolor: value === 2 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#7a7a7a" : "inherit"),
                  color: value === 2 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                }}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={7} sm={4} md={4} lg={2.6} xl={2.3}>
          <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
            From
            <Input
              fullWidth
              type="date"
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              sx={dateFieldStyles}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              disableUnderline
            />
          </Box>
        </Grid>
        <Grid item xs={7} sm={4} md={4} lg={2.6} xl={2.3}>
          <Box bgcolor="#D9D9D9" p={1} sx={fromAndToTextStyles}>
            To
            <Input
              fullWidth
              type="date"
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              sx={dateFieldStyles}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              disableUnderline
            />
          </Box>
        </Grid>
        <Grid item>
          <Box bgcolor="#D9D9D9" p={0.5} sx={filterAndCancelIcon}>
            <IconButton >
              <FilterAltIcon sx={iconColor}/>
            </IconButton>
          </Box>
        </Grid>
        <Grid item>
          <Box bgcolor="#D9D9D9" sx={filterAndCancelIcon}>

            <IconButton onClick={handleClearFields}>
              <CloseIcon sx={iconColor}/>

            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>

<div style={{ padding: "10px" }}>
          <Table aria-label="simple table">
            <TableHead sx={tableHeadStyles}>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    className="all-list-tabelcell"
                    align="right"
                    sx={tableCellStyles}
                  >
                    {header.label}
                    {header.label === "Select All" && (
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody>
              {displayedRows.map((storeData, index) => (
                <React.Fragment key={index}>
                  
                    <TableRow key={index} sx={contentTableRowStyles}>
                      {renderStyledCell(index + 1, getFontWeight(index + 1))}
                      {renderStyledCell(storeData.id, getFontWeight(storeData.id))}
                      {renderStyledCell(storeData.storeName, getFontWeight(storeData.storeName))}
                      {renderStyledCell(storeData.username, getFontWeight(storeData.username))}
                      {renderStyledCell(storeData.email, getFontWeight(storeData.email))}
                      {renderStyledCell(storeData.contact, getFontWeight(storeData.contact))}
                      {renderStyledCell(storeData.registrationNumber, getFontWeight(storeData.registrationNumber))}
                      {renderStyledCell(storeData.state, getFontWeight(storeData.state))}
                      {renderStyledCell(storeData.subscriptionType, getFontWeight(storeData.subscriptionType))}
                      <TableCell sx={contentTableCellStyles} align="left"  onClick={() => handleOpenModal(storeData.id)}>
                     <Box className="align-items-center">
                        <VisibilityIcon sx={{color: isDarkMode ? "#fff" : "#7C7C7C"}}/>
                        </Box>
                      </TableCell>
                     
                    </TableRow>
                  
                
                </React.Fragment>
                
              ))}
                <Modal open={openModal} onClose={handleCloseModal}>
                         <StoreDetailsSuperadmin storeId={selectedStoreId} />
                     </Modal>
            </TableBody>
          </Table>

          </div>

        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
          <Pagination
            count={total}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default StoreListSuperadmin;



