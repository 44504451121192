import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Stack, FormControl, FormHelperText, Grid, Paper, Select, MenuItem, Input, } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useNavigate, useParams } from "react-router-dom";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function EditInventory() {
   const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
   const { labelStyle, inputStyles, errorMessageStyles, submitButtonStyles, selectStyles,
      formMainBox, formPaperStyles, buttonDisabled, capitalizeFirstLetter } = useDrawer();
   const navigate = useNavigate();
   const { showSuccessSnackbar } = useSuccessSnackbar();
   const { showErrorSnackbar } = useErrorSnackbar();
   const { serialNo } = useParams();
   const currentUser = useSelector((state) => state.auth.user.data);
   const formDataFields = [
      { name: "name", label: "Product Name", type: "text", star: "" },
      { name: "inventoryCode", label: "Product Code", type: "text" },
      {
        name: "category",
        label: "Category",
        type: "select",
        options: [
          { value: "Select Category", label: "Select Category" },
          { value: "Vegetable", label: "Vegetable" },
          { value: "Fruits", label: "Fruits" },
          { value: "Grocery", label: "Grocery" },
          { value: "Dairy Product", label: "Dairy Product" },
          { value: "Bakery Product", label: "Bakery Product" },
          { value: "Packing Material", label: "Packing Material" },
        ],
      },
      { name: "price", label: "Price Per Unit", type: "number" },
      { name: "quantity", label: "Quantity", type: "number" },
      {
        name: "unit",
        label: "Choose Unit",
        type: "select",
        options: [
          { value: "Select unit", label: "Select unit" },
          { value: "kg", label: "Kg" },
          { value: "gram", label: "Gram" },
          { value: "milligram", label: "Milligram" },
          { value: "pieces", label: "Pieces" },
          { value: "litre", label: "Litre" },
          { value: "mililitre", label: "Mililitre" },
      ],
      },
      { name: "expiryDate", label: "Expiry Date", type: "date" },
      { name: "minLevel", label: "Minimum Level", type: "number" },
      {
        name: "minLevelUnit",
        label: "Minimum Level Unit",
        type: "select",
        options: [
          { value: "Select unit", label: "Select unit" },
          { value: "kg", label: "Kg" },
          { value: "gram", label: "Gram" },
          { value: "milligram", label: "Milligram" },
          { value: "pieces", label: "Pieces" },
          { value: "litre", label: "Litre" },
          { value: "mililitre", label: "Mililitre" },
      ],
      },
    ];

   const [formData, setFormData] = useState({
      ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
      createdBy: currentUser.username,
      updatedBy: currentUser.username,
      storeId: currentUser.storeid,

   });
   const [errors, setErrors] = useState(
      formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
   );
   const validateField = (fieldName, value) => {
      let error = "";
      const numberRegex = new RegExp(/^\d{1,10}$/);
      const capitalizedFieldName = capitalizeFirstLetter(fieldName);
      if (value.trim() === "") {
         error = `${capitalizedFieldName} is Mandatory`;
      }
      if (capitalizedFieldName === "price") {
         if (!numberRegex.test(value)) {
            error = `${capitalizedFieldName} should be a number`;
         }
      }
      return error;
   };
   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      const error = validateField(name, value);
      setErrors({ ...errors, [name]: error });

   };
 
   useEffect(() => {
    
      const fetchData = async () => {
         try {
            const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/inventory/id/${serialNo}`);
            const inventoryData = response.data.data; 
            if(String(response.data.data.storeId) !== String(currentUser.storeid))
            {
               navigate('/unauthorized');
            }
            else{
             setFormData({
               name: inventoryData.name,
               inventoryCode: inventoryData.inventoryCode,
               category: inventoryData.category || "Select Category",
               price: inventoryData.price,
               quantity: inventoryData.quantity,
               unit: inventoryData.unit || "Select Unit",
               expiryDate: inventoryData.expiryDate,
               minLevel: inventoryData.minLevel,
               minLevelUnit: inventoryData.minLevelUnit || "Select Minimum Level Unit",
               createdBy: currentUser.username,
               updatedBy: currentUser.username,
               storeId: currentUser.storeid,
            });
         }
         } catch (error) {
            showErrorSnackbar("Failed to fetch inventory data");
         }
      };

      fetchData();
   }, [serialNo]);

   const handleInventoryUpdate = async (e) => {
      e.preventDefault();

      if (Object.values(errors).every((error) => error === "")) {
         const totals = parseFloat(formData.price) * parseFloat(formData.quantity);
         formData.total = totals;

         try {
            const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/inventory/id/${serialNo}`, formData);
            if (response.data.status === true) {
               showSuccessSnackbar("Form updated successfully!");
               
            } else {
               showErrorSnackbar(response.data.message);
              
            }
         } catch (error) {
            if (error.response && error.response.data) {
               showErrorSnackbar(error.response.data.message);
            } else {
              
               showErrorSnackbar("Form update failed. Please try again.");
            }
         }
      }
   };

   const isSubmitDisabled = buttonDisabled(formData, errors);
   return (
      <Box
         component="main"
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Paper elevation={12} sx={formPaperStyles}>
            <Grid container spacing={1} sx={{ mb: 4 }}>
               <Grid item>
                  <Box
                     display="flex"
                     alignItems="center"
                     bgcolor="primary.light"
                     p={1}
                     sx={formMainBox}
                  >
                     <InventoryIcon fontSize="large" />
                     <span style={{ marginLeft: "8px" }}>Update Inventory</span>{" "}
                     {/* Text */}
                  </Box>
               </Grid>
            </Grid>
            {Array.from({ length: 5 }).map((_, index) => (
               <Stack
                  key={index}
                  spacing={4}
                  direction={{
                     xs: "column",
                     sm: "row",
                     md: "row",
                     lg: "row",
                     xl: "row",
                  }}
                  sx={{ marginBottom: 1 }}
               >
                  {formDataFields
                     .slice(index * 2, index * 2 + 2)
                     .map((formDataItem, innerIndex) => (
                        <FormControl
                           key={innerIndex}
                           fullWidth
                           error={!!errors[formDataItem.name]}
                           variant="outlined"
                        >
                           <CustomTypography
                              htmlFor={`outlined-adornment-${formDataItem.name}`}
                              className="food-card-text"
                              sx={labelStyle}
                           >
                              {formDataItem.label}
                           </CustomTypography>
                           {formDataItem.type === "select" ? (
                              <Select
                                 name={formDataItem.name}
                                 value={
                                    formData[formDataItem.name] ||
                                    formDataItem.options[0].label
                                 }
                                 onChange={handleInputChange}
                                 sx={selectStyles}
                              >
                                 {formDataItem.options.map((option, optionIndex) => (
                                    <MenuItem key={optionIndex} value={option.value}>
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </Select>
                           ) : (
                              <Input
                                 sx={inputStyles}
                                 type={formDataItem.type}
                                 name={formDataItem.name}
                                 value={formData[formDataItem.name]}
                                 onChange={handleInputChange}
                                 disableUnderline
                              />
                           )}
                           <FormHelperText
                              className="food-card-text"
                              sx={errorMessageStyles}
                           >
                              {errors[formDataItem.name]}
                           </FormHelperText>
                        </FormControl>
                     ))}
               </Stack>
            ))}
            <Box style={{ display: "flex", justifyContent: "center" }}>
               <Button onClick={handleInventoryUpdate}
                  className="food-card-text mode-button border-radius-10 cart-button final-button"
                  sx={submitButtonStyles} disabled={isSubmitDisabled}
               >
                  Submit
               </Button>
            </Box>
         </Paper>
      </Box>
   );



}
export default EditInventory;