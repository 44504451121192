import React from "react";
import { useLocation, Link } from "react-router-dom";
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
const transformPathSegment = (pathSegment) => {

  // Remove "-" and capitalize each word
  const transformedSegment = pathSegment.replace(/-/g, ' ')
    .replace(/(?:^|\s)\S/g, char => char.toUpperCase());
  return transformedSegment;
};

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
 
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
  
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
export default function CustomizedBreadcrumbs() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(crumb => crumb !== '');
  const currentUser = useSelector((state) => state.auth.user?.data);
  const excludedRoutes = [
    '/login',
    '/reset-password',
    '/dashboard',
    '/cardMainComponent',
    '/new-order',
    '/pending-order',
    '/unauthorized',
    '/kitchen-order-ticket',
    '/superadmin-dashboard',
    '/technician-dashboard',
    '/update-order/\\d+' // This matches '/update-order/' followed by one or more digits
  ];
  const shouldRenderBreadcrumbs = !excludedRoutes.some(routePattern => {
    const regex = new RegExp(`^${routePattern}$`);
    return regex.test(location.pathname);
  });

  // Function to transform path segment
  const transformPathSegment = (segment) => {
    // Custom logic to transform segment if needed
    return segment;
  };

  // Check if the last segment is a number
  const lastSegmentIsNumber = !isNaN(parseInt(pathSegments[pathSegments.length - 1]));


  const roleToDashboardRoute = {
    ROLE_ADMIN: "/dashboard",
    ROLE_SUPER_ADMIN: "/superadmin-dashboard",
    ROLE_USER: "/user-dashboard",
    ROLE_SUPPORT: "/technician-dashboard",
  };
  if (!shouldRenderBreadcrumbs) {
    return null;
  }
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mt: "15px", mb: "20px" }}>
      {currentUser && currentUser.roles && Object.entries(roleToDashboardRoute).map(([role, route]) => (
        currentUser.roles.includes(role) && (
          <StyledBreadcrumb
            key={role}
            component={Link}
            to={route}
            label="Home"
            className="food-card-text"
            sx={{ fontSize: { xs: "11px", sm: "12px", md: "14px", lg: "14px", xl: "15px" }, cursor: "pointer", marginTop: { xs: "10px", sm: "0px" }, }}
            icon={<HomeIcon fontSize="small" />}
          />
        )
      ))}
     {pathSegments.map((segment, index) => {
            // Exclude the segment before the last number
            if (index === pathSegments.length - 2 && lastSegmentIsNumber) {
              return null;
            }

            const pathToSegment = `/${pathSegments.slice(0, index + 1).join('/')}`;
            const label = transformPathSegment(segment);
        return (
          <StyledBreadcrumb
            key={segment}
            component={Link}
            sx={{ fontSize: { xs: "11px", sm: "12px", md: "14px", lg: "14px", xl: "16px" }, cursor: "pointer", marginTop: { xs: "10px", sm: "0px" } }}
            to={pathToSegment}
            className="food-card-text"
            label={label}
            deleteIcon={<ExpandMoreIcon />}
          />
        );
      })}
    </Breadcrumbs>
  );
}