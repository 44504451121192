import { Box, Button, Checkbox, Divider, Grid, IconButton, Input, InputAdornment, Modal, Pagination, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDrawer } from '../Sidebar/DrawerContext';
import { useTableContext } from '../../Screens/tableContext/TableContext';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useSuccessSnackbar } from '../../Screens/snackbar/SuccessSnackbarProvider';
import PopOverModal from '../Cards/Modal/PopOverModal';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';

const rowsPerPage = 10;
function UserList() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [total, settotal] = useState([]);

  const [user , setUser] = useState([]);
  const currentUser = useSelector((state) => state.auth.user.data);
   const {
      pdfAndExcelButtonStyles,
      tableCellStyles,
      searchboxStyles,
      listMainGridStyles,
      contentTableCellStyles,
      editAndDeleteButtonStyles,
      contentTableRowStyles,
      tableHeadStyles,
      listMainBox,
      spaceBetweenTopComponent,
      pagignationStyles,
      listIconStyles,
    } = useDrawer();
    const {
      searchInput,
      handleSearchChange,
      filterRows,
      renderStyledCell,

      getFontWeight,generatePdf,downloadExcel , resetSearchInput,currentPage, resetCurrentPage,
      
    } = useTableContext();
    const location = useLocation();
    useEffect(() => {
      resetSearchInput();
      resetCurrentPage();
    }, [location.pathname]);
             const tableHeaders = [
               { label: "SR No."},
               { label: "User Name"},
               { label: "User Email"},
               { label: "Address"},
               { label: "Contact No"},
                { label: "Action"},
             ];
  const { showSuccessSnackbar } = useSuccessSnackbar();
 const filteredRows = filterRows(user);
 const [deleteTrigger , setDeleteTrigger] = useState ([]) ;
 const downloadPDF = () => {
   generatePdf("UserList", tableHeaders , filteredRows );
 };
 const fetchUserList = () => {
  axios
    .get(`https://billing.ubsbill.com/apptest/v2/api/auth/user/store/${currentUser.storeid}`)
    .then((response) => {
      const userList = response.data.data.map((user , index) => ({
        serialNo:  user.serialNo,
        username: user.username,
        email: user.email,
        address: user.address,
        contact: user.contact,
      }))

      setUser(userList) 

    })
    .catch((error) => {
      console.error("Error fetching User list:", error);
    });
};
// Usage in useEffect or elsewhere
useEffect(() => {
  fetchUserList();
}, []);
const [open, setOpen] = useState(false);
  const [serialNoToDelete, setSerialNoToDelete] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
    const handleOpen = (serialNo) => {
       setSerialNoToDelete(serialNo);
        setOpen(true);
    };
 const handleUserDelete = async (serialNo) => {
  try {
    const response = await axios.delete(`https://billing.ubsbill.com/apptest/v2/api/auth/user/${serialNo}`);
    if (response.data.status === true) {
      showSuccessSnackbar("User Deleted successfully.. !");
       handleClose();
       fetchUserList();
     }
  } catch (error) {
    console.error("Error deleting User:", error);
  }
};
 return (
    <Box component="main" sx={{ p: 2}}>
   <Grid container spacing={1} sx={{ mb: 4 }}>
  <Grid item xs={12}   sx={listMainGridStyles}>
    <Box
      display="flex"
      max
      bgcolor="primary.light"
      sx={listMainBox}
    >
      <PersonAddIcon sx={listIconStyles} /> {/* Icon */}
      <Typography variant='span' sx={{ ml: "8px" ,color: isDarkMode ? "#fff" :"#fff", }}>User List</Typography>
    </Box>
    <Box sx={spaceBetweenTopComponent}>
      <Box
        sx={searchboxStyles}
      >
            <InputAdornment position="start">
             <SearchIcon />
            </InputAdornment>
            <Divider orientation="vertical" sx={{ height: 24, mx: 1 , backgroundColor:"#424242" }} />
            <Input
            fullWidth
             placeholder="Search"
             disableUnderline
             sx={{ color: isDarkMode ? "#fff" : "#000",}}
             value={searchInput}
             onChange={handleSearchChange}
            />
         </Box>
         <Button className='food-card-text mode-button border-radius-10 cart-button final-button' sx={pdfAndExcelButtonStyles} component={Link} to="/user-management-dashboard/add-user">
  <span style={{ marginRight: '5px' }}>+</span> User
</Button>
          <Button className='food-card-text mode-button border-radius-10 cart-button final-button'
           onClick={downloadPDF}  sx={pdfAndExcelButtonStyles}>
          PDF
          </Button>
          <Button className='food-card-text mode-button border-radius-10 cart-button final-button'
          onClick={() => downloadExcel(tableHeaders, filteredRows, "UserList")} sx={pdfAndExcelButtonStyles}>
          Excel
          </Button></Box>
        </Grid>
     </Grid>
     <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: '100%', overflowX: 'auto', borderRadius: 1 }}>
         <Table aria-label="simple table">
            <TableHead sx={tableHeadStyles}>
            <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    className="all-list-tabelcell"
                    align="right"
                    sx={tableCellStyles}
                  >
                    {header.label}
                    {header.label === "Select All" && (
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody >
             {filteredRows.map((row,index) => (
                <TableRow
                 key={index}
                 sx={contentTableRowStyles}
                >
               {renderStyledCell(index+1, getFontWeight(index+1))}
               {renderStyledCell(row.username, getFontWeight(row.username))}
               {renderStyledCell(row.email, getFontWeight(row.email))}
               {renderStyledCell(row.address, getFontWeight(row.address))}
               {renderStyledCell(row.contact, getFontWeight(row.contact))}
               <TableCell sx={contentTableCellStyles} align="left" >
                    <Box className="align-items-center">
                    <Box
                          className="justify-all-center"
                          component={Link} to={`/user-management-dashboard/users-list/edit-user/${row.serialNo}`}
                          sx={{
                            ...editAndDeleteButtonStyles,
                            border: isDarkMode ?  "1px solid #fff" :  "2px solid #4FC3A1",
                          }}
                        >
                          <ModeEditOutlineRoundedIcon sx={{color: isDarkMode ?  "white" : "#4FC3A1" }} />
                        </Box>
                        <Box
                          className="justify-all-center"
                          sx={{
                            ...editAndDeleteButtonStyles,
                            border: isDarkMode ?  "1px solid #fff" :  "2px solid #f55651",
                          }}
                          onClick={() => handleOpen(row.serialNo)}
                        >
                          <DeleteIcon sx={{ color: isDarkMode ?  "white" : "#f55651"}}/>
                        </Box>
                    </Box>
                 </TableCell>
                </TableRow>
             ))}
              <Modal
                           open={open}
                           onClose={handleClose}
                           aria-labelledby="modal-modal-title"
                           aria-describedby="modal-modal-description"
                          >
                          <PopOverModal
                              iconPath="../images/delete.png"
                              buttonTextTwo="No"
                              topHeading="Are you sure you want to Delete ?"
                              modalTopBorder="10px solid #C62717"
                              buttonTextOne="Yes"
                              printText=""
                              onClickOne={() => handleUserDelete(serialNoToDelete)}
                              onClickTwo={handleClose}
                              />
                              </Modal>
            </TableBody>
         </Table>
        </Box>
     </Grid>
     <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>
         <Pagination
             count={Math.ceil(filteredRows.length / rowsPerPage)}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
         />
       </Grid>
   </Grid>
    </Box >
)
}
export default UserList;