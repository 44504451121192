
import {
  Box,
  Button,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  radioClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { add, remove, removeOneItem, resetState, selectSubtotal } from "../../../Screens/store/foodSlice";

import { useDispatch, useSelector } from "react-redux";
import { useDrawer } from "../../Sidebar/DrawerContext";
import { useErrorSnackbar } from "../../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../../Screens/snackbar/SuccessSnackbarProvider";
import axios from "axios";
import { updateOrderStatus } from "../../../Screens/store/foodSlice";
import PopOverModal from "../../Cards/Modal/PopOverModal";
import { useLocation } from "react-router-dom";
import QuickBill from "./QuickBill";
import { useReactToPrint } from "react-to-print";
import KotBill from "./KotBill";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";

const CartTable = () => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();

  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const currentUser = useSelector((state) => state.auth.user.data);
  const { selectStyles } = useDrawer();
  const foodList = useSelector((state) => state.cart.bill.order[0].orderFoods);
  const orderData = useSelector((state) => state.cart);
  const tableNoValue = useSelector((state) => state.cart.bill.order[0].tableNo);
  const paymentmode = useSelector((state) => state.cart.bill.order[0].paymentMode);
  const isOrderFoodsNotEmpty = foodList.length > 0;
  const dispatch = useDispatch();
  const removeFromCart = (index) => {
    dispatch(remove(index));
  };

///fetched running tables and excluded it from mapped tables
  const [tableNumbers, setTableNumbers] = useState([]);
  const fetchPendingData = () => {
    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/bill/statusreport?orderStatus=Prepared&orderStatus=pending&orderStatus=preparing&orderStatus=Running&storeId=${currentUser.storeid}`)
      .then((response) => {
       const numbers = response.data.data.map(order => order.order[0].tableNo);
         setTableNumbers(numbers);
 })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  
  useEffect(() => {
    fetchPendingData();
  }, []);
  const availableOptions = [1, 2, 3, 4, 5, 6, 7, 8 , 9 , 10 ,11 , 12 ,13].filter(number => {
    // If tableNumbers is provided and not empty, filter out the used numbers
    if (tableNumbers && tableNumbers.length > 0) {
        return !tableNumbers.includes(number.toString()) && number !== "";
    } else {
        // If tableNumbers is empty or not provided, show all tables
        return true;
    }
});


  const handleOneItemRemove = (index) => {
    dispatch(removeOneItem(index));
  };

  const addToOrder = (food) => {
    dispatch(add(food));
    console.log("tableNumbers" , tableNumbers)
  };

  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(resetState());

  };
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [paymentModeValue, setPaymentModeValue] = useState(null);

  const handleOrderTypeChange = (event, newValue) => {
    let orderType = "";
    switch (newValue) {
      case 0:
        orderType = "DINE IN";
        dispatch(add({ orderType, tableNo: tableNo }));
        break;
      case 1:
        orderType = "TAKE AWAY";

        dispatch(add({ orderType, tableNo: "" }));
        break;
      case 2:
        orderType = "DELIVERY";

        dispatch(add({ orderType, tableNo: "" }));
        break;
      default:
        break;
    }
    setValue(newValue);
  };
  const [paymentMode, setPaymentMode] = useState(""); 

  const handlePaymentModeChange = (event, newValue) => {
    let mode = "";
    switch (newValue) {
      case 0:
        mode = "Cash";
        break;
      case 1:
        mode = "Card";
        break;
      case 2:
        mode = "Upi";
        break;
      default:
        break;
    }
    dispatch(add({ paymentMode: mode })); 
    setPaymentMode(mode); // Update the paymentMode state
    setPaymentModeValue(newValue); // Update the payment mode value if needed
  };


  const total = useSelector((state) => state.cart.bill.total);

  const [tableNo, setTableNo] = useState(null);

  const handleTableNoChange = (event) => {
    const { value } = event.target;
    setTableNo(value);
    dispatch(add({ tableNo: value }));
  };

  const [discountValue, setDiscountValue] = useState("");

  const handleDiscountChange = (event) => {

    const inputValue = event.target.value;
    setDiscountValue(inputValue);
    dispatch(add({ discount: inputValue === "" ? "" : inputValue }));
  };


  const handlePlaceOrder = async () => {

    const newOrderStatus = "pending";
    const newPaymentMode = "";
    setPaymentModeValue();
    const updatedOrderData = {
      ...orderData,
      bill: {
        ...orderData.bill,
        storeId: currentUser.storeid,
        paymentMode: newPaymentMode,
        billStatus:"Place Order",
        updatedBy: currentUser.username,
        createdBy: currentUser.username,
        order: [
          {
            ...orderData.bill.order[0],
            orderStatus: newOrderStatus,
            storeId: currentUser.storeid,
            updatedBy: currentUser.username,
            createdName: currentUser.username,
          },
        ],
      },
    };

    try {
      if (value === null || value === undefined) {
        showErrorSnackbar('Please select an Order Type.');
        return;
      }

      if (value === 0 && (tableNoValue === null || tableNoValue === undefined)) {
        showErrorSnackbar('Please select Table No for Dine in Order');
        return;
      }

      dispatch(add({ orderStatus: 'pending', }));
      dispatch(add({ updatedOrderData }));

      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/bill/", updatedOrderData);
      if (response.data.status === true) {
        showSuccessSnackbar('Order placed successfully!');
        dispatch(resetState());
        setTableNo(null);
        setDiscountValue();
      } 
      else {

        const errorResponse = response.data.message;
        const errorMessage = errorResponse ? errorResponse : 'Failed to place the order. Please try again.';
        showErrorSnackbar(errorMessage);
      }
    } catch (error) {
      console.error('Error while placing the order:', error);
      showErrorSnackbar(error.response.data.message);
    }
  };

  const [billId, setBillId] = useState("");
  const [serialNo, setSerialNo] = useState("");

  const handleQuickBill = async (e) => {
    if (paymentModeValue === null || paymentModeValue === undefined) {
      showErrorSnackbar('Please select a payment mode before proceeding.');
      return;
    }
    const newOrderStatus = "Completed";
    const updatedOrderData = {
      ...orderData,
      bill: {

        ...orderData.bill,
        storeId: currentUser.storeid,
        updatedBy: currentUser.username,
        billStatus:"Quick Bill",
        createdBy: currentUser.username,
        order: [
          {
            ...orderData.bill.order[0],
            storeId: currentUser.storeid,
            orderStatus: newOrderStatus,
            updatedBy: currentUser.username,
            createdName: currentUser.username,
          },
        ],
      },
    };
    try {

      if (value === null || value === undefined) {
        showErrorSnackbar('Please select an Order Type.');
        return;
      }
       if (value === 0 && (tableNoValue === null || tableNoValue === undefined)) {
        showErrorSnackbar('Please select Table No for Dine in Order');
        return;
      }
    

      dispatch(add({ orderStatus: 'Completed' }));
      dispatch(add(updatedOrderData));
      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/bill/quick", updatedOrderData);
      if (response.data.status === true) {
        showSuccessSnackbar("Quick Bill Submited Successfully !");
        setOpenModal(true);
        setBillId(response.data.data.billId);
        setSerialNo(response.data.data.serialNo);
        setTableNo(null);
        setDiscountValue();
      } else {
        const errorResponse = response.data.message;
        const errorMessage = errorResponse ? errorResponse : 'Failed to place the order. Please try again.';
        showErrorSnackbar(errorMessage);
      }
    } catch (error) {
      console.error('Error while placing the order:', error);
      showErrorSnackbar(error.response.data.message);
    }
  };


  const [orderNo, setOrderNo] = useState("");
  //Kot 
  const handleKot = async (e) => {

    const newOrderStatus = "Preparing";
    const updatedOrderData = {
      ...orderData,
      bill: {
        ...orderData.bill,
        storeId: currentUser.storeid,
        updatedBy: currentUser.username,
        billStatus:"KOT",
        createdBy: currentUser.username,
        order: [
          {
            ...orderData.bill.order[0],
            storeId: currentUser.storeid,
            orderStatus: newOrderStatus,
            updatedBy: currentUser.username,
            createdName: currentUser.username,
          },
        ],
      },
    };
    try {
      


      dispatch(add({ orderStatus: 'Preparing' }));
      dispatch(add(updatedOrderData));
      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/bill/", updatedOrderData);
      if (response.data.status === true) {
        showSuccessSnackbar("Kot Submited Successfully !");
        setOpen(true);

        console.log("Submit !!!!!");
        console.log(response);
        setOrderNo(response.data.data.order[0].orderId)
        setTableNo();
        setDiscountValue();
      } else {
        const errorResponse = response.data.message;
        const errorMessage = errorResponse ? errorResponse : 'Failed to place the order. Please try again.';
        showErrorSnackbar(errorMessage);
      }
      console.log(response)
    } catch (error) {
      console.error('Error while placing the order:', error);
      showErrorSnackbar(error.response.data.message);
    }
  };

  const printableBillRef = React.useRef();
  const handlePrintBill = useReactToPrint({
    content: () => printableBillRef.current,

  });
  const [tax, setTax] = useState([]);
  // Function to calculate the total tax rate
  const { subtotal, discountAmount } = useSelector(selectSubtotal);
  const fetchTaxData = () => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/tax/store/${currentUser.storeid}`)
      .then((response) => {
        if (response.data && response.data.data) {
          setTax(response.data.data);
          console.log("ffgdg")
          console.log(response.data)
        }
      })
      .catch((error) => {
        showErrorSnackbar("Error Fetching Tax Data");
      });
  }

  useEffect(() => {
    fetchTaxData()
  }, []);

  const calculateTotalTaxRate = () => {
    let totalRate = 0;
    tax.forEach(taxItem => {
      totalRate += taxItem.rate;
    });
    return totalRate;
  }

  const calculateGrandTotal = () => {
    const totalTaxRate = calculateTotalTaxRate();
    const totalTaxAmount = (subtotal * totalTaxRate) / 100;

    return subtotal + totalTaxAmount;

  }
  const handlePrintingQuickBill = () => {
    try {
      const response = axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/bill/printBill/${serialNo}?paymentMode=${paymentMode}&total=${calculateGrandTotal()}`,);
      console.log(response)
      handlePrintBill();
      handleCloseModal();
      setPaymentMode();
      setPaymentMode();
      dispatch(resetState());
    } catch (error) {
      console.error("Error occurred while Printing:", error);
    }
  };

  const handleNoQuickBill = () => {
    try {
      const response = axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/bill/printBill/${serialNo}?paymentMode=${paymentMode}&total=${calculateGrandTotal()}`,);
      console.log(response)
     // handlePrintBill();
      handleCloseModal();
      setPaymentMode();
      setPaymentMode();
      dispatch(resetState());
    } catch (error) {
      console.error("Error occurred while Printing:", error);
    }
  };

  // const handlePrintingQuickBill = () => {
  //   handlePrintBill();
  //   handleCloseModal();
  //   dispatch(resetState());
  // }

  const printableKotRef = React.useRef();
  const handlePrintKot = useReactToPrint({
    content: () => printableKotRef.current,

  });

  const handlePrinting = () => {
    handlePrintKot();
    handleClose();
    dispatch(resetState());
  }
  const location = useLocation();
  useEffect(() => {
    dispatch(resetState());
  }, [location.pathname, dispatch]);
  return (
    <Box>
      <Box
        sx={{
          boxShadow: "0px 4px 4px 0px #00000040",
          backgroundColor: isDarkMode ? '#5e5e5e' : '#EAFCFC',
          borderRadius: "10px",
        }}
      >
        <Box
          className="carttable"
          p={2}
          sx={{
            display: "flex",
            alignItems: "left",
            flexDirection: "column",
            flex: 0,
            gap: { sm: "1", md: 1.5, xs: 1.3, xl: 1.5, lg: 1 },
          }}
        >
          <Box>
          <CustomTypography
            className="food-card-text"
            variant="h6"
            sx={{
              fontSize: {
                sm: "26px",
                md: "20px",
                xs: "16px",
                xl: "24px",
                lg: "23px",
              },
              fontWeight: "500",
              lineHeight: {
                sm: "15px",
                md: "14px",
                xs: "20px",
                xl: "28px",
                lg: "20px",
              },
            }}
          >
            Current Order
          </CustomTypography>
          </Box>
          <Tabs
            className="justify-all-center"
            value={value}
            onChange={handleOrderTypeChange}
            textColor="white"
            indicatorColor="white"
            aria-label="tabs"
            sx={{
              backgroundColor: isDarkMode ? '#8f8d8d' : '#fff',
              display:"flex",
              justifyContent:"space-between",
              
              borderRadius: "5px",
              padding: "10px 4px",
              height: {
                sm: "40px",
                md: "30px",
                xs: "30px",
                xl: "48px",
                lg: "30px",
              },
              minHeight: {
                sm: "20px",
                md: "14px",
                xs: "12px",
                xl: "48px",
                lg: "40px",
              },
              width: {
                sm: "100%",
                md: "100%",
                xs: "100%",
                xl: "100%",
                lg: "100%",
              },
            }}
          >
            <Tab
              label="DINE IN"
              className="food-card-text cart-button justify-all-center"
              sx={{
              
                textAlign: "center",
                bgcolor: value === 0 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#8f8d8d" : "inherit"),
                color: value === 0 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                padding: {
                  xl: "8px 5px",
                  lg: "0px 16px",
                  xs: "0px 5px",
                  sm: "3px 5px",
                },
                fontSize: {
                  sm: "15px",
                  md: "14px",
                  xs: "11px",
                  xl: "16px",
                  lg: "12px",
                },
                minHeight: {
                  sm: "20px",
                  md: "14px",
                  xs: "12px",
                  xl: "38px",
                  lg: "40px",
                },
              }}
            />
            <Tab
              className="food-card-text cart-button justify-all-center"
              label="TAKE AWAY"
              sx={{
                bgcolor: value === 1 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#8f8d8d" : "inherit"),
                color: value === 1 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                padding: {
                  xl: "0px 25px",
                  lg: "0px 16px",
                  xs: "0px 0px",
                  sm: "3px 5px",
                },
                fontSize: {
                  sm: "15px",
                  md: "14px",
                  xs: "11px",
                  xl: "16px",
                  lg: "12px",
                },
              }}
            />
            <Tab
              label="DELIVERY"
              className="food-card-text cart-button justify-all-center"
              sx={{
                bgcolor: value === 2 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#8f8d8d" : "inherit"),
                color: value === 2 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                padding: {
                  xl: "0px 25px",
                  lg: "0px 16px",
                  xs: "0px 0px",
                  sm: "3px 5px",
                },
                fontSize: {
                  sm: "15px",
                  md: "14px",
                  xs: "11px",
                  xl: "16px",
                  lg: "12px",
                },
              }}
            />
          </Tabs>
          {value === 0 && (
            <Box className="align-items-center">
              <CustomTypography
                variant="h6"
                className="food-card-text"
                sx={{
                  fontSize: {
                    sm: "17px",
                    md: "15px",
                    xs: "12px",
                    xl: "16px",
                    lg: "13px",
                  },
                  fontWeight: "500",
                  lineHeight: "30px",
                  minWidth: {
                    sm: "97px",
                    md: "105px",
                    xs: "97px",
                    xl: "97px",
                    lg: "102px",
                  },
                }}
              >
                Table No:
              </CustomTypography>
              <FormControl halfWidth sx={{ flex: 1 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleTableNoChange}
                  value={tableNo}
                  className='food-card-text'
                  sx={{
                    ...selectStyles,
                    color: isDarkMode ? "#fff" : "#000000",
                    background: isDarkMode ? "#8f8d8d" :"#fff",
                    maxHeight: "35px",
                    height: "35px",
                    minHeight: "35px",
                    fontWeight: "500",
                    width: "100px",
                  }}
                >

                     {availableOptions.map((number) => (
            <MenuItem key={number} value={number}>
              {number}
            </MenuItem>
          ))}

                </Select>
              </FormControl>
            </Box>
          )}
          <Box
            className="payment-mode-main"
            sx={{ radius: "5px", display: "flex", alignItems: "center" }}
          >
            <CustomTypography
              className="food-card-text"
              sx={{
                fontSize: {
                  sm: "17px",
                  md: "15px",
                  xs: "12px",
                  xl: "16px",
                  lg: "13px",
                },
                fontWeight: "500",
                lineHeight: "30px",
                minWidth: {
                  sm: "97px",
                  md: "105px",
                  xs: "97px",
                  xl: "97px",
                  lg: "102px",
                },
              }}
            >
              Payment Mode
            </CustomTypography>

            <Tabs
              className=""
              value={paymentModeValue}
              onChange={handlePaymentModeChange}
              textColor="white"
              indicatorColor="white"
              aria-label="tabs"
              sx={{
                padding: "5px",
                radius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Tab
                label="CASH"
                className="food-card-text mode-button border-radius-10 cart-button"
                sx={{
               
                  bgcolor: paymentModeValue === 0 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#8f8d8d" : "#D9D9D9"),
                  color: paymentModeValue === 0 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                  padding: {
                    sm: "10px 25px",
                    md: "5px 8px",
                    xs: "0px 15px",
                    xl: "5px 15px",
                    lg: "7px 5px",
                  },
                  fontSize: {
                    sm: "15px",
                    md: "14px",
                    xs: "12px",
                    xl: "16px",
                    lg: "12px",
                  },
                  minWidth: {
                    sm: "15px",
                    md: "14px",
                    xs: "12px",
                    xl: "90px",
                    lg: "60px",
                  },
                  marginLeft: {
                    sm: "15px",
                    md: "5px",
                    xs: "5px",
                    xl: "7px",
                    lg: "10px",
                  },
                }}
              />
              <Tab
                className="food-card-text mode-button border-radius-10"
                label="CARD"
                sx={{
               
                  bgcolor: paymentModeValue === 1 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#8f8d8d" : "#D9D9D9"),
                  color: paymentModeValue === 1 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                  padding: {
                    sm: "10px 25px",
                    md: "5px 8px",
                    xs: "0px 15px",
                    xl: "5px 15px",
                    lg: "7px 5px",
                  },
                  fontSize: {
                    sm: "15px",
                    md: "14px",
                    xs: "12px",
                    xl: "16px",
                    lg: "12px",
                  },
                  minWidth: {
                    sm: "15px",
                    md: "14px",
                    xs: "12px",
                    xl: "90px",
                    lg: "60px",
                  },
                  marginLeft: {
                    sm: "15px",
                    md: "5px",
                    xs: "5px",
                    xl: "7px",
                    lg: "10px",
                  },
                }}
              />
              <Tab
                label="UPI"
                className="food-card-text mode-button border-radius-10"
                sx={{
                  
                  bgcolor: paymentModeValue === 2 ? (isDarkMode ? "#fff" : "#28A497") : (isDarkMode ? "#8f8d8d" : "#D9D9D9"),
                  color: paymentModeValue === 2 ? (isDarkMode ? "black" : "white") : (isDarkMode ? "white" : "black"),
                  padding: {
                    sm: "10px 25px",
                    md: "5px 8px",
                    xs: "0px 15px",
                    xl: "5px 15px",
                    lg: "7px 5px",
                  },
                  fontSize: {
                    sm: "15px",
                    md: "14px",
                    xs: "12px",
                    xl: "16px",
                    lg: "12px",
                  },
                  minWidth: {
                    sm: "15px",
                    md: "14px",
                    xs: "12px",
                    xl: "90px",
                    lg: "60px",
                  },
                  marginLeft: {
                    sm: "15px",
                    md: "5px",
                    xs: "5px",
                    xl: "7px",
                    lg: "10px",
                  },
                }}
              />
            </Tabs>
          </Box>
          <Box
            className="percent-box"
            sx={{ display: "flex", gap: 4, alignItems: "center" }}
          >
            <CustomTypography
              className="food-card-text"
              sx={{
                fontSize: {
                  sm: "17px",
                  md: "15px",
                  xs: "12px",
                  xl: "16px",
                  lg: "13px",
                },
                fontWeight: "500",
                lineHeight: "30px",
              }}
            >
              Discount Rate
            </CustomTypography>
            <input
              value={discountValue}
              maxLength={2}
              onChange={handleDiscountChange}
              className="percentage-input food-card-text"
              variant="outlined"
              style={{
                paddingLeft: "10px",
                maxWidth: "25%",
                color: isDarkMode ? "#fff" : "#000000",
                background: isDarkMode ? "#8f8d8d" :"#fff",
                fontWeight: "500",
                minHeight: "35px",
                borderRadius: "5px",
                border: "1px solid #8f8d8d",
              }}
            />
          </Box>
        </Box>

        <TableContainer
          className="cart-table"
          
          component={Paper}
          sx={{
            minHeight: {
              sm: "20vh",
              md: "30vh",
              xs: "25vh",
              xl: "40vh",
              lg: "35vh",
            },
            maxHeight: {
              sm: "20vh",
              md: "30vh",
              xs: "25vh",
              xl: "40vh",
              lg: "40vh",
            },
            backgroundColor: isDarkMode ? '#5e5e5e' : '#fff',
          }}
        >
          <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: isDarkMode ? '#8f8d8d' : '#28A497',
                position: "sticky",
                top: 0,
                zIndex: 1,
                padding: {
                  sm: 300,
                  md: 350,
                  xs: 300,
                  xl: "18px 25px",
                  lg: "8px 15px",
                },
                height: {
                  sm: "15px",
                  md: "14px",
                  xs: "12px",
                  xl: "55px",
                  lg: "20px",
                },
              }}
            >
              <TableRow>
                <TableCell
                  className="table-cell food-card-text"
                  size="small"
                  align="left"
                  sx={{
                    fontSize: {
                      xl: "16px",
                      lg: "15px",
                      md: "14px",
                      xs: "12px",
                      sm: "18px",
                    },
                    width: {
                      xl: "80%",
                      lg: "9vw",
                      md: "10vw",
                      xs: "30vw",
                      sm: "40%",
                    },

                    fontWeight: "500",
                    color: "#FFFFFF",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  className="table-cell food-card-text"
                  size="small"
                  align="left"
                  sx={{
                    fontSize: {
                      xl: "16px",
                      lg: "15px",
                      md: "14px",
                      xs: "12px",
                      sm: "18px",
                    },
                    fontWeight: "500",
                    color: "#FFFFFF",
                    width: {
                      xl: "80%",
                      lg: "4vw",
                      md: "20%",
                      xs: "10vw",
                      sm: "20%",
                    },
                    maxWidth: {
                      xl: "80%",
                      lg: "4vw",
                      md: "20%",
                      xs: "150vw",
                      sm: "20%",
                    },
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  className="table-cell food-card-text"
                  size="small"
                  align="left"
                  sx={{
                    fontSize: {
                      xl: "16px",
                      lg: "15px",
                      md: "14px",
                      xs: "12px",
                      sm: "18px",
                    },
                    fontWeight: "500",
                    color: "#FFFFFF",
                    width: {
                      xl: "80%",
                      lg: "10vw",
                      md: "20%",
                      xs: "20%",
                      sm: "20%",
                    },
                    maxWidth: {
                      xl: "80%",
                      lg: "7vw",
                      md: "10vw",
                      xs: "20vw",
                      sm: "40%",
                    },
                    width: {
                      xl: "80%",
                      lg: "7vw",
                      md: "10vw",
                      xs: "20vw",
                      sm: "40%",
                    },
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  className="table-cell food-card-text"
                  size="small"
                  align="left"
                  sx={{
                    fontSize: {
                      xl: "16px",
                      lg: "15px",
                      md: "14px",
                      xs: "12px",
                      sm: "18px",
                    },
                    fontWeight: "500",
                    color: "#FFFFFF",
                    width: {
                      xl: "80%",
                      lg: "5vw",
                      md: "20%",
                      xs: "20%",
                      sm: "10%",
                    },
                    maxWidth: {
                      xl: "80%",
                      lg: "5vw",
                      md: "20%",
                      xs: "20%",
                      sm: "10%",
                    },
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
               
                backgroundColor: isDarkMode ? '#525151' : '#EAFCFC',
                boxShadow: "0px 4px 4px 0px #00000040",
                pb: 3,
                overflowY: "hidden",
                maxHeight: "35vh",
              }}
              className="table-padding cart-table"
            >
              {foodList.map((food, index) => (
                <TableRow
                  className="cart-table"
                  key={index}
                  sx={{
                    border: "none",
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    className="cart-td food-card-text"
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "400",
                      color: isDarkMode ? '#fff' : '#000',
                      minWidth: {
                        xl: "80%",
                        lg: "9vw",
                        md: "10vw",
                        xs: "30vw",
                        sm: "40%",
                      },

                      fontSize: {
                        xl: "16px",
                        lg: "13px",
                        md: "14px",
                        xs: "11px",
                        sm: "15px",
                      },
                      lineHeight: {
                        xl: "30px",
                        lg: "17px",
                        md: "18px",
                        xs: "12px",
                        sm: "20px",
                      },
                    }}
                  >
                    {food.foodName}
                  </TableCell>
                  <TableCell
                    className="cart-td food-card-text"
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "400",
                      color: isDarkMode ? '#fff' : '#000',
                      fontSize: {
                        xl: "16px",
                        lg: "14px",
                        md: "14px",
                        xs: "11px",
                        sm: "15px",
                      },
                      lineHeight: "30px",
                      width: {
                        xl: "80%",
                        lg: "4vw",
                        md: "20%",
                        xs: "10vw",
                        sm: "20%",
                      },
                      maxWidth: {
                        xl: "80%",
                        lg: "4vw",
                        md: "20%",
                        xs: "10vw",
                        sm: "20%",
                      },
                    }}
                  >
                    {food.price}
                  </TableCell>
                    <TableCell
                    className="cart-td food-card-text"
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "400",
                      color: isDarkMode ? '#fff' : '#000',
                      fontSize: {
                        xl: "16px",
                        lg: "16px",
                        md: "14px",
                        xs: "12px",
                        sm: "15px",
                      },
                      lineHeight: "30px",
                      width: {
                        xl: "80%",
                        lg: "7vw",
                        md: "10vw",
                        xs: "18vw",
                        sm: "20%",
                      },
                      maxWidth: {
                        xl: "80%",
                        lg: "7vw",
                        md: "10vw",
                        xs: "18vw",
                        sm: "20%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                       
                        background: isDarkMode ? '#8f8d8d' : '#FFFFFF',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "3px",
                        width: {
                          xl: "100%",
                          lg: "6vw",
                          md: "100%",
                          xs: "20vw",
                          sm: "40%",
                        },
                        minWidth: {
                          xl: "100%",
                          lg: "6vw",
                          md: "100%",
                          xs: "20vw",
                          sm: "40%",
                        },
                        borderRadius: "10px",
                      }}
                      className="cart-td"
                    >
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          onClick={() => addToOrder(food)}
                          variant="span"
                          className="food-card-text food-card-icon"
                          sx={{
                            cursor: "pointer",
                            background: isDarkMode ? '#5e5e5e' : '#D9D9D9',
                            padding: "0px 6px",
                            marginRight: {
                              xl: "6px",
                              lg: "5px",
                              md: "5px",
                              xs: "2px",
                              sm: "6px",
                            },
                            fontSize: {
                              xl: "16px",
                              lg: "14px",
                              md: "14px",
                              xs: "12px",
                              sm: "16px",
                            },
                            borderRadius: {
                              xl: "9px",
                              lg: "8px",
                              md: "5px",
                              xs: "3px",
                              sm: "2px",
                            },
                          }}
                        >
                          +
                        </Typography>
                        <input
                          value={food.quantity}
                          style={{
                            background: isDarkMode ? '#8f8d8d' : '#fff',
                            color: isDarkMode ? '#fff' : '#000',
                            width: "20px",
                            textAlign: "center",
                            border: "none",
                          }}
                          readOnly
                          className="food-card-text"
                        />
                        <Typography
                          onClick={() => handleOneItemRemove(index)}
                          variant="span"
                          className="food-card-text food-card-icon"
                          sx={{
                            cursor: "pointer",
                            background: isDarkMode ? '#5e5e5e' : '#D9D9D9',
                            padding: "0px 6px",
                            marginLeft: {
                              xl: "6px",
                              lg: "5px",
                              md: "5px",
                              xs: "2px",
                              sm: "6px",
                            },
                            fontSize: {
                              xl: "16px",
                              lg: "14px",
                              md: "14px",
                              xs: "12px",
                              sm: "16px",
                            },
                            borderRadius: {
                              xl: "9px",
                              lg: "8px",
                              md: "5px",
                              xs: "3px",
                              sm: "2px",
                            },
                          }}
                        >
                          -
                        </Typography>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    className="table-cell cart-td"
                    size="small"
                    align="left"
                    sx={{
                      padding: "8px",
                      width: {
                        xl: "80%",
                        lg: "5vw",
                        md: "20%",
                        xs: "20%",
                        sm: "10%",
                      },
                      maxWidth: {
                        xl: "80%",
                        lg: "5vw",
                        md: "20%",
                        xs: "20%",
                        sm: "10%",
                      },
                    }}
                  >
                    <Button
                      onClick={() => removeFromCart(index)}
                      size="small"
                      sx={{ color: "#000" }}
                    >
                      <DeleteIcon sx={{color: isDarkMode ? "#fff" : "#000"}}/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          className="justify-all-center"
          sx={{
            background: isDarkMode ? '#8e8e8e' : '#fff',
            padding: {
              sm: "0px 15px",
              md: "0px 15px",
              xs: "0px 15px",
              xl: "10px 25px",
              lg: "10px 12px",
            },
            height: {
              sm: "45px",
              md: "40px",
              xs: "35px",
              xl: "35px",
              mt: "30px",
              lg: "25px",
            },
            marginTop: {
              sm: "25px",
              md: "20px",
              xs: "12px",
              xl: "15px",
              lg: "15px",
            },
          }}
        >
          <CustomTypography
            className="food-card-text"
            size="small"
            align="left"
            sx={{
              fontWeight: "600",
              fontSize: {
                xl: "20px",
                lg: "16px",
                md: "16px",
                xs: "12px",
                sm: "16px",
              },
              lineHeight: "30px",
            }}
          >
            Total
          </CustomTypography>
          <CustomTypography
            className="food-card-text"
            size="small"
            align="left"
            sx={{
              fontWeight: "600",
              fontSize: {
                xl: "20px",
                lg: "16px",
                md: "16px",
                xs: "12px",
                sm: "16px",
              },
              lineHeight: "30px",
              marginLeft: "auto",
            }}
          >
            ₹ {total}
          </CustomTypography>
        </Box>
        <Box
          sx={{
            minHeight: {
              xl: "30px",
              lg: "15px",
              sm: "0px",
              xs: "0px",
              md: "20px",
            },
          }}
        ></Box>
      </Box>

      <Box
        className="justify-all-center"
        sx={{
          boxShadow: "0px 4px 4px 0px #00000040",
          background: isDarkMode ? '#5e5e5e' : '#EAFCFC',
          mt: { sm: 0, md: 2.4, xs: 0, xl: 2.4, lg: 2.4 },
          borderRadius: {
            sm: "0px",
            md: "10px",
            xs: "0px",
            xl: "10px",
            lg: "10px",
          },
          minHeight: {
            sm: "50px",
            md: "45px",
            xs: "50px",
            xl: "70px",
            mt: "30px",
            lg: "50px",
          },
          justifyContent: "space-evenly",
        }}
      >
        {/* <Button
          className="food-card-text border-radius-10 cart-button final-button"
          sx={{
            padding: {
              sm: "5px 15px",
              md: "5px 10px",
              xs: "4px 10px",
              xl: "6px 10px",
              lg: "5px 5px",
            },
            fontSize: {
              sm: "15px",
              md: "14px",
              xs: "12px",
              xl: "16px",
              lg: "10px",
            },
            background: isDarkMode ? '#8f8d8d' : '#D9D9D9',
            color: "#000000",
            fontWeight: "600",
          }}
        >
          <img src="../images/calculator.png" alt={`calculator Icon`} style={{ filter: isDarkMode ? "invert(1)" : "none"}}/>
        </Button> */}
        <Button
          onClick={handleQuickBill}
          disabled={!isOrderFoodsNotEmpty}
          className="food-card-text mode-button border-radius-10 cart-button final-button"
          sx={{
            padding: {
              sm: "5px 15px", md: "5px 4px", xs: "4px 10px", xl: "6px 8px", lg: "5px 15px",
            },
            fontSize: {
              sm: "15px", md: "13px", xs: "12px", xl: "14px", lg: "12px",
            },
            minWidth: {
              sm: "35px", md: "14px", xs: "12px", xl: "85px", lg: "60px",
            },
            background: isDarkMode ? '#8f8d8d' : '#D9D9D9',
           color: isDarkMode ? '#fff' : '#000000',
            fontWeight: "600",
          }}

        >
          Quick Order
        </Button>

        <Modal
          open={openModal}

          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <PopOverModal
            handleCloseModal={handleCloseModal}
            printText="Do you want to print Bill?"
            iconPath="../images/correct.png"
            onClickOne={handleNoQuickBill}
            onClickTwo={handlePrintingQuickBill}
            modalTopBorder="10px solid #47C01C"
            topHeading="Order Placed Successfully!"
            handleClose={handleClose}
            buttonTextTwo="Print Bill"
            buttonTextOne="No"
          />
        </Modal>
        <Box sx={{ display: 'none' }}>
          <Box ref={printableBillRef} >
            <QuickBill billId={billId} />
          </Box>
        </Box>
        <Button
          onClick={handlePlaceOrder}
          disabled={!isOrderFoodsNotEmpty}
          className="food-card-text mode-button border-radius-10 cart-button final-button"
          sx={{
            padding: {
              sm: "5px 15px",
              md: "5px 10px",
              xs: "4px 10px",
              xl: "6px 8px",
              lg: "5px 15px",
            },
            fontSize: {
              sm: "15px",
              md: "14px",
              xs: "12px",
              xl: "14px",
              lg: "12px",
            },
            minWidth: {
              sm: "15px",
              md: "14px",
              xs: "12px",
              xl: "85px",
              lg: "60px",
            },
            background: isDarkMode ? '#8f8d8d' : '#D9D9D9',
           color: isDarkMode ? '#fff' : '#000000',
            fontWeight: "600",
          }}
        >
          Place Order
        </Button>
        <Button
          onClick={handleKot}
          disabled={!isOrderFoodsNotEmpty}
          className="food-card-text mode-button border-radius-10 cart-button final-button"
          sx={{
            padding: {
              sm: "5px 15px",
              md: "5px 10px",
              xs: "4px 10px",
              xl: "6px 8px",
              lg: "5px 15px",
            },
            fontSize: {
              sm: "15px",
              md: "14px",
              xs: "12px",
              xl: "14px",
              lg: "12px",
            },
            minWidth: {
              sm: "15px",
              md: "14px",
              xs: "12px",
              xl: "85px",
              lg: "60px",
            },
            background: isDarkMode ? '#8f8d8d' : '#D9D9D9',
             color: isDarkMode ? '#fff' : '#000000',
            fontWeight: "600",
          }}
        >
          KOT
        </Button>
        <Modal
          open={open}

          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <PopOverModal
            handleClose={handleClose}
            iconPath="../images/correct.png"
            buttonTextTwo="Print Kot"
            modalTopBorder="10px solid #47C01C"
            topHeading="Order Placed Successfully!"
            buttonTextOne="No"
            printText="Do you want to print Token?"
            onClickOne={handleClose}
            onClickTwo={handlePrinting}

          />
        </Modal>
        <Box sx={{ display: 'none' }}>

        <Box ref={printableKotRef} >
          <KotBill orderNo={orderNo}/>

          </Box>
        </Box>
      </Box>
    </Box>
  );


}

export default CartTable;