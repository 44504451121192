import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import IcecreamIcon from '@mui/icons-material/Icecream';
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useDrawer } from "../Sidebar/DrawerContext";
import axios from "axios";
import { useSelector } from "react-redux";
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function AddMenu() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { labelStyle, inputStyles, selectStyles , errorMessageStyles, formMainBox , submitButtonStyles, buttonDisabled, capitalizeFirstLetter , formPaperStyles } = useDrawer();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const currentUser = useSelector((state) => state.auth.user.data);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const apiUrl = `https://billing.ubsbill.com/apptest/v2/sys/categorybutton/store/${currentUser.storeid}`;

    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const categories = data.data.map(item => ({ value: item.name, label: item.name }));

        const updatedFormDataFields = [...formDataFields];
        const categoryFieldIndex = updatedFormDataFields.findIndex(field => field.name === 'category');
        if (categoryFieldIndex !== -1) {
          updatedFormDataFields[categoryFieldIndex].options = [
            { value: '', label: 'Select Category' },
            ...categories,

          ];
        } else {
          console.error('Category field not found in formDataFields');
        }

        setFormDataFields(updatedFormDataFields);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);


  const [formDataFields, setFormDataFields] = useState([
    { name: 'foodName', label: 'Food Name', type: 'text' },
    { name: 'foodCode', label: 'Food Code', type: 'text' },
    { name: 'price', label: 'Price', type: 'number' },
    { name: 'category', label: 'Category', type: 'select', options: [{ value: '', label: 'Select Category' }] },
    {
      name: 'subCategory', label: 'Sub Category', type: 'select', options: [
        { value: 'Select Sub Category', label: 'Select Sub Category' },
        { value: 'Veg', label: 'Veg' },
        { value: 'Nonveg', label: 'Non veg' },
        { value: 'Egg', label: 'Egg' },
      ]
    },
    { name: 'description', label: 'Description', type: 'text' },
    // { name: 'image', label: 'Select Image', type: 'file' },
  ]);
  const [formData, setFormData] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }),
      {}),
    storeId: currentUser.storeid,
    createdBy: currentUser.username,
    updateBy: currentUser.username,

  });



  const [errors, setErrors] = useState({
    ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})

  });

  const validateField = (fieldName, value) => {
    let error = "";
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const numberRegex = new RegExp(/^\d{1,10}$/);
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    else if (fieldName === "price") {
      if (value <= 0) {
          error = `${capitalizedFieldName} should be a positive number`;
      } else if (value.length > 10) {
          error = `${capitalizedFieldName} should not exceed 10 digits`;
      }
  }
    else if (fieldName === "foodCode") {
      if (value.length > 10) {
        error = `${capitalizedFieldName} cannot exceed 10 characters`;
      }

    }
    else if (fieldName === "accountNo") {
      if (value <= 0) {
          error = `${capitalizedFieldName} should be a positive number`;
      } else if (value.length > 15) {
          error = `${capitalizedFieldName} should not exceed 15 digits`;
      }
  }
  else if (fieldName === "description" ){
    if (value.length > 50) {
        error = `${capitalizedFieldName} should not exceed 50 digits`;
    }
}
else if (fieldName === "foodName" ){
  if (value.length > 40) {
      error = `${capitalizedFieldName} should not exceed 40 digits`;
  }
}
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };


  const handleMenu = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {


      try {
        const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/food/", formData);
        if (response.data.status === true) {
          showSuccessSnackbar("Form Submit Successfull !");
          setFormData({
            ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
            storeId: currentUser.storeid,
            createdBy: currentUser.username,
            updateBy: currentUser.username,
          });
        }
        else {
          showErrorSnackbar(response.data.message);
          setFormData({
            ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
            storeId: currentUser.storeid,
            createdBy: currentUser.username,
            updateBy: currentUser.username,
          });
        }

      } catch (error) {

        showErrorSnackbar(error.response.data.message);
        setFormData({
          ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
          storeId: currentUser.storeid,
          createdBy: currentUser.username,
          updateBy: currentUser.username,
        });
      }
    }
  };

  const isSubmitDisabled = buttonDisabled(formData, errors);


  //upload Excel file 

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setError("No file selected.");
      setFile(null); 
      return;
    }
  
    
    const allowedExtensions = [".xlsx", ".xls"];
    const fileExtension = selectedFile.name.slice(-5).toLowerCase(); 
    if (!allowedExtensions.includes(fileExtension)) {
      showErrorSnackbar("Please select a valid Excel file.");
      setFile(null); 
      return;
    }
    setFile(selectedFile);
    setError(""); 
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setMessage("");
  
    if (!file) {
      showErrorSnackbar("Please select an Excel file before uploading.");
      return;
    }
  
    const formData = new FormData();
    formData.append("storeId", currentUser.storeid);
    formData.append("File", file);
  
    try {
      const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/food/uploadexcel", formData );
      setMessage(response.data);
      showSuccessSnackbar("Excel File added Successfully");
      setFile("");
      setTimeout(() => {
        setMessage("");
        setError("");
      }, 3000); 
    } catch (error) {
      if (error.response) {
        showErrorSnackbar(error.response.data);
      } else {
        showErrorSnackbar("An error occurred while uploading the food list.");
      }
    }
  };
  



  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper sx={{...formPaperStyles , display:"flex"}}>
          <Box p={2}>
            <CustomTypography htmlFor={`outlined-adornment-`} className="food-card-text" sx={labelStyle}   > Upload Food List</CustomTypography>
          </Box>
          <Input
            onChange={handleFileChange}
            sx={inputStyles}
            type="file"
          />
          <Box mx={2}>
            <Button
              onClick={handleSubmit}
            >
              <UploadFileSharpIcon sx={{ color: "primary.light", fontSize: "43px" }} />
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper elevation={12}  sx={{...formPaperStyles }}>
          <Grid container spacing={1} sx={{ mb: 4 }}>
            <Grid item>
              <Box display="flex" alignItems="center" bgcolor="primary.light" p={1} sx={formMainBox}>
                <IcecreamIcon fontSize="large" />
                <span style={{ marginLeft: '8px' }}>Add Menu</span> {/* Text */}
              </Box>
            </Grid>
          </Grid>


          {Array.from({ length: 4 }).map((_, index) => (
            <Stack key={index} spacing={4} direction={{ xs: "column", sm: "row", md: "row", lg: "row", xl: "row" }} sx={{ marginBottom: 1 }}>
              {formDataFields
                .slice(index * 2, index * 2 + 2)
                .map((formDataItem, innerIndex) => (
                  <FormControl key={innerIndex} fullWidth error={!!errors[formDataItem.name]} variant="outlined">
                    <CustomTypography htmlFor={`outlined-adornment-${formDataItem.name}`} className="food-card-text" sx={labelStyle}>
                      {formDataItem.label}
                    </CustomTypography>

                    {formDataItem.type === 'select' ? (
                      <Select
                        labelId={`select-${formDataItem.name}`}
                        id={`select-${formDataItem.name}`}
                        name={formDataItem.name}
                        value={
                          formData[formDataItem.name] ||
                          formDataItem.options[0].label
                        }
                        onChange={handleInputChange}
                        sx={selectStyles}
                      >
                        {formDataItem.options.map((option, optionIndex) => (
                          <MenuItem key={optionIndex} value={option.value}>{option.label}</MenuItem>
                        ))}
                      </Select>
                    ) : formDataItem.type === "file" ? (
                      <Input
                        sx={inputStyles}
                        type="file"
                        name={formDataItem.name}
                        value={formData[formDataItem.name]}
                        onChange={handleInputChange}
                        disableUnderline
                      />

                    ) : (
                      <Input
                        sx={inputStyles}
                        type={formDataItem.type}
                        name={formDataItem.name}
                        value={formData[formDataItem.name]}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        disableUnderline
                      />
                    )}

                    <FormHelperText className="food-card-text" sx={errorMessageStyles}>{errors[formDataItem.name]}</FormHelperText>
                  </FormControl>

                ))},
            </Stack>
          ))}

          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleMenu}
              className='food-card-text mode-button border-radius-10 cart-button final-button'
              sx={submitButtonStyles} disabled={isSubmitDisabled}>
              Submit
            </Button>
          </Box>
        </Paper>
      </Box>
    </>

  );

}
export default AddMenu;
