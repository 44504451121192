import { Box, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react'
import NavLinkCard from '../Components/StoreDashboardComponent/NavLinkCard';
import axios from 'axios';
import { useSelector } from 'react-redux';
import UserNavLinkCard from '../Components/UserDashboardComponents/UserNavLinkCard';
import { useDarkMode } from './DarkMode/DarkMode';


function FoodManagement() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [subMenuData, setSubMenuData] = useState([]);
  const currentUser = useSelector((state) => state.auth.user.data);
  const userRoles = currentUser.roles || [];

  useEffect(() => {
    if (userRoles.includes("ROLE_USER")) {
      axios.get(`https://billing.ubsbill.com/apptest/v2/sys/usersidebar/username?username=${currentUser.username}`)
        .then(response => {
          const menuData = response.data.data;
          const foodManagementMenu = menuData.menu.find(menu => menu.title === "Food Management");
          if (foodManagementMenu) {
            setSubMenuData(foodManagementMenu.subMenu || []);
          } else {
            console.error("Menu item 'Cash Management' not found in menu data");
          }
        })
        .catch(error => {
          console.error("Error fetching submenu data:", error);
        });
    } else {
      setSubMenuData([]);
    }
  }, [userRoles]);
 
  const data = [
    { id: 1, title: 'Add Menu', image: '../images/add-menu-icon.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/food-management-dashboard/add-menu", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 2, title: 'Food List', image: '../images/food-list-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/food-management-dashboard/food-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 3, title: 'Add Addons', image: '../images/add-addon-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/food-management-dashboard/add-addons", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 4, title: 'Add-On List', image: '../images/add-on-list.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/food-management-dashboard/addon-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 5, title: 'Add Recipe', image: '../images/add-recipe.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/food-management-dashboard/add-recipe", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 6, title: 'Recipe List', image: '../images/recipe-list-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/food-management-dashboard/recipe-list", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
  ]
  return (
    <Box sx={{ display: "flex" }}>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomTypography sx={{ mb: 4, fontWeight: "500", fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' }, }} variant="p" className="link-cart-title">
          Food Management
        </CustomTypography>
        <Grid lg={11} md={12} sm={12} xs={12} xl={10} container spacing={1.7}  >
          {userRoles.includes("ROLE_USER") ? (
            subMenuData.map(submenuItem => (
              submenuItem.status && <UserNavLinkCard key={submenuItem.id} card={submenuItem} />
            ))
          ) : (
            data.map(card => (
              <NavLinkCard key={card.id} card={card} />
            ))
          )}
        </Grid>
      </Box>
    </Box>
  )
}
export default FoodManagement;
