import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  MenuItem,
  Input,
  } from "@mui/material";

import { useDrawer } from "../Sidebar/DrawerContext";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import axios from "axios";
import UpdateIcon from '@mui/icons-material/Update';
import { useSelector } from "react-redux";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function ProfileSettingSuperAdmin() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    selectStyles,
    formMainBox,
    formPaperStyles,capitalizeFirstLetter,buttonDisabled
  } = useDrawer();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const formDataFields = [
    { name: "username", label: "Username", type: "text" },
    { name: "email", label: "Support Email", type: "email" },
    { name: "contact", label: "Contact", type: "number" },
    { name: "address", label: "Address", type: "text" },
    { name: "id", label: "Super Admin ID", type: "number" },
   
  ];
  const currentUser = useSelector((state) => state.auth.user.data);

  const [formData, setFormData] = useState({
   ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
   id: currentUser.superid
});



  const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const validateField = (fieldName, value) => {
    let error = "";
    const contactRegex = /^[0-9]{10}$/; 
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    const numberRegex = new RegExp(/^\d{1,10}$/);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;
    if (value.trim() === "") {
      error = `${capitalizedFieldName} is Mandatory`;
    }
    if (fieldName === "email") {
        if (value.trim() === "") {
          error = `${capitalizedFieldName} is Mandatory`;
        } else if (!emailRegex.test(value)) {
          error = "Please enter a valid email in the format username@example.com";
        }
      }
    if (fieldName === "contact") {
      if (!contactRegex.test(value)) {
        error = "Please enter a valid 10-digit contact number";
      }
    }
    return error;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  
  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };
  
  useEffect(() => {
    
    const fetchData = async () => {
      console.log(currentUser.superid)
       try {
          const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/api/auth/superadmin/${currentUser.superid}`);
          const superAdminData = response.data.data; 

          setFormData({
            username: superAdminData.username,
            email: superAdminData.email,
            contact: superAdminData.contact ,
            address: superAdminData.address,
            storeid: currentUser.storeid,
          });
       } catch (error) {
          showErrorSnackbar("Failed to fetch Superadmin data");
       }
    };

    fetchData();
 }, []);
  const handleSuperadminUpdate = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {

      try {
        const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/api/auth/superadmin/${currentUser.superid}`, formData);
        if(response.data.status === true){
        showSuccessSnackbar("Form Submit Successfull !");
        setFormData({
          
       });
        }
      } catch (error) {
        showErrorSnackbar("Form Submit Failed");
    
      }
    }
  };

  const isSubmitDisabled = buttonDisabled(formData,errors);
  return (
    <Box
      component="main"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper
        elevation={12}
        sx={formPaperStyles}
      >
        <Grid container spacing={1} sx={{ mb: 4 }}>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              bgcolor="primary.light"
              p={1}
              sx={formMainBox}
            >
              <UpdateIcon fontSize="large" />
              <span style={{ marginLeft: "8px" }}>Update Profile</span>
            </Box>
          </Grid>
        </Grid>

        {Array.from({ length: 5 }).map((_, index) => (
          <Stack
            key={index}
            spacing={4}
            direction={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            sx={{ marginBottom: 1 }}
          >
            {formDataFields
              .slice(index * 2, index * 2 + 2)
              .map((formDataItem, innerIndex) => (
                <FormControl
                  key={innerIndex}
                  fullWidth
                  error={!!errors[formDataItem.name]}
                  variant="outlined"
                >
                  <CustomTypography
                    htmlFor={`outlined-adornment-${formDataItem.name}`}
                    className="food-card-text"
                    sx={labelStyle}
                  >
                    {formDataItem.label}
                  </CustomTypography>

                  {formDataItem.type === "select" ? (
                    <Select
                      name={formDataItem.name}
                      value={
                        formData[formDataItem.name] ||
                        formDataItem.options[0].label
                      }
                      onChange={handleInputChange}
                      sx={selectStyles}
                    >
                      {formDataItem.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      sx={inputStyles}
                      type={formDataItem.type}
                      name={formDataItem.name}
                      value={formData[formDataItem.name]}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      disableUnderline
                      readOnly={formDataItem.name === "id"}
                    />
                  )}
                  <FormHelperText
                    className="food-card-text"
                    sx={errorMessageStyles}
                  >
                    {errors[formDataItem.name]}
                  </FormHelperText>
                </FormControl>
              ))}
          </Stack>
        ))}
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleSuperadminUpdate}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles} disabled={isSubmitDisabled}
          >
            Update
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
export default ProfileSettingSuperAdmin;
