import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Modal,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,

} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDrawer } from "../Sidebar/DrawerContext";
import { useTableContext } from "../../Screens/tableContext/TableContext";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import PopOverModal from "../Cards/Modal/PopOverModal";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

const rowsPerPage = 1;

function UserRoleList() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [UserRoleList, setUserRoleList] = useState([]);

  const {
    pdfAndExcelButtonStyles,
    tableCellStyles,
    searchboxStyles,
    listMainGridStyles,
    contentTableCellStyles,
    editAndDeleteButtonStyles,
    contentTableRowStyles,
    tableHeadStyles,
    listMainBox,
    spaceBetweenTopComponent,
    pagignationStyles,
    listIconStyles, handleOpen, handleClose, open
  } = useDrawer();
  const {
    searchInput,
    handleSearchChange,
    filterRows,
    renderStyledCell,
    getFontWeight,

    generatePdf ,  currentPage, handleChangePage , resetSearchInput , resetCurrentPage

  } = useTableContext();

  const currentUser = useSelector((state) => state.auth.user.data.storeid);
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();

  const filteredRows = filterRows(UserRoleList);

  const tableHeaders = [
    { label: "SR No.", align: "left" },
    { label: "User Name", align: "right" },
    { label: "Menu", align: "right" },
    { label: "Sub Menu", align: "right" },
  ];


  const fetchData = () => {

    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/usersidebar/statusAndStoreId/${currentUser}`)


      .then((response) => {
        setUserRoleList(response.data.data);
      });
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const filteredRowsBySearch = filteredRows.filter((VendorInventoryList) =>

  Object.values(VendorInventoryList).some(
      (value) =>
          value && value.toString().toLowerCase().includes(searchInput.toLowerCase())
  )
);
const startIndex = (currentPage - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const displayedRows = filteredRowsBySearch.slice(startIndex, endIndex);

const location = useLocation();
  
useEffect(() => {
  resetSearchInput();
  resetCurrentPage();
}, [location.pathname]);


  return (
    <Box component="main" sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={listMainGridStyles}>
          <Box max bgcolor="primary.light" sx={listMainBox}>
            <AssignmentIndIcon sx={listIconStyles} />
            <Typography variant="span" sx={{ ml: "8px" , color: "#fff"}}>
              Role List
            </Typography>
          </Box>
          <Box sx={spaceBetweenTopComponent}>
            <Box bgcolor="#D9D9D9" p={1} sx={searchboxStyles}>
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
              <Divider
                orientation="vertical"
                sx={{ height: 24, mx: 1, backgroundColor: "#424242" }}
              />
              <Input
                fullWidth
                placeholder="Search"
                disableUnderline
                sx={{ color: isDarkMode ? "#fff" : "#000",}}
                value={searchInput}
                onChange={handleSearchChange}
              />
            </Box>
            <Button
              className="food-card-text mode-button border-radius-10 cart-button final-button"
              sx={pdfAndExcelButtonStyles} component={Link} to="/user-role-management-dashboard/user-roles-access"
            >
              <span style={{ marginRight: "5px" }}>+</span> Role
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Box sx={{ width: "100%", overflowX: "auto", borderRadius: 1 }}>
          <Table aria-label="simple table">
            <TableHead sx={tableHeadStyles}>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell
                    key={index}
                    className="all-list-tabelcell"
                    align={header.align}
                    sx={tableCellStyles}
                  >
                    {header.label}
                    {header.label === "Select All" && (
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <div style={{ padding: "10px" }} />
            <TableBody>

             {displayedRows.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {row.menu.map((menu, menuIndex) => (
                    // Check if the menu item is not empty
                    (Object.keys(menu).length !== 0) && (

                      <TableRow key={`${rowIndex}-${menuIndex}`} sx={contentTableRowStyles} >

                        {/* Render the SR No., Username (only for the first menu item), Menu, and Submenu */}
                        {menuIndex === 0 && (
                          <>
                            {renderStyledCell(rowIndex + 1, getFontWeight(rowIndex + 1))}
                            {renderStyledCell(row.username, getFontWeight(row.username))}
                          </>
                        )}
                        {menuIndex === 0 ? (
                          <>

                            <TableCell sx={contentTableCellStyles} align="left" className="food-card-text">
                              <img src={menu.icon} alt={menu.title} style={{ marginRight: 8, width: "25px", height: "25px" , filter: isDarkMode ? "invert(1)" : "none"}} />

                              {menu.title}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            {renderStyledCell('', '')}
                            {renderStyledCell('', '')}

                            <TableCell sx={contentTableCellStyles} align="left" className="food-card-text">
                              <img src={menu.icon} alt={menu.title} style={{ marginRight: 8, width: "25px", height: "25px" , filter: isDarkMode ? "invert(1)" : "none", }} />

                              {menu.title}
                            </TableCell>
                          </>
                        )}

                        <TableCell sx={contentTableCellStyles} align="left" className="food-card-text">
                          <ul>
                          {menu.subMenu.length > 0 ? (
  menu.subMenu.map((subItem, subIndex) => (
    <li key={subIndex}>{subItem.title}</li>
  ))
) : (
  <li>N/A</li>
)}

                          </ul>
                        </TableCell>
                      </TableRow>
                    )
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: "flex-end" }}>
        <Grid item>

        <Pagination
            count={Math.ceil(filteredRows.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={pagignationStyles}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserRoleList;

