import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { useDarkMode } from "../DarkMode/DarkMode";

const user = JSON.parse(localStorage.getItem("user"));


export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ username, password, roles }, thunkAPI) => {
    let apiUrl;

    // Choose the appropriate API endpoint based on the role
    switch (roles) {
      case "ROLE_USER":
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/user/signin";
        break;
      case "ROLE_ADMIN":
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/store/signin";
        break;
      case "ROLE_SUPPORT":
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/tech/signin";
        break;
      case "ROLE_SUPER_ADMIN":
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/superadmin/signin";
        break;
      default:
        throw new Error("Invalid role");
    }

    try {
      const response = await axios.post(apiUrl, { username, password }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      localStorage.setItem("user", JSON.stringify(response.data));
      sessionStorage.setItem("user", JSON.stringify(response.data));
      return { user: response.data, roles }; // Include role in the payload
    } catch (error) {
      const errorMessage = error.response?.data;
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  let storedUserData = JSON.parse(localStorage.getItem("user")) || JSON.parse(sessionStorage.getItem("user"));
  
    // Check if storedUserData is null or undefined
    if (!storedUserData) {
      console.error("User data not found in sessionStorage.");
      return; // Exit early
    }
  
    const sessionToken = storedUserData.data.accessToken;
    const userRoles = storedUserData.data.roles;
  
    try {
      let logoutEndpoint = "";
  
      // Check if user has specific roles and set logout endpoint accordingly
      if (userRoles.includes("ROLE_USER")) {
        logoutEndpoint = "https://billing.ubsbill.com/apptest/v2/api/auth/user/logout";
      } else if (userRoles.includes("ROLE_ADMIN")) {
        logoutEndpoint = "https://billing.ubsbill.com/apptest/v2/api/auth/store/logout";
      } else if (userRoles.includes("ROLE_SUPPORT")) {
        logoutEndpoint = "https://billing.ubsbill.com/apptest/v2/api/auth/tech/logout";
      } else if (userRoles.includes("ROLE_SUPER_ADMIN")) {
        logoutEndpoint = "https://billing.ubsbill.com/apptest/v2/api/auth/superadmin/logout";
      } else {
        throw new Error("Invalid role");
      }
  
      const response = await axios.post(logoutEndpoint, null, {
        params: { sessionToken }
      });
      localStorage.setItem('darkMode', JSON.stringify(false));
      
      localStorage.removeItem("user");
      sessionStorage.removeItem("user"); // Remove session token from sessionStorage
    } catch (error) {
      
    }
});
  
export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async ({ email }, thunkAPI) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };
  
      try {
        const response = await axios.post("https://billing.ubsbill.com/apptest/v2/api/auth/store/forgot-password", { email }, config);
        return { message: "OTP sent successfully", response };
      } catch (storeError) {
        try {
          const response = await axios.post("https://billing.ubsbill.com/apptest/v2/api/auth/tech/forgotPassword", { email }, config);
          return { message: "OTP sent successfully", response };
        } catch (techError) {
          try {
            const response = await axios.post("https://billing.ubsbill.com/apptest/v2/api/auth/user/forgotPassword", { email }, config);
            return { message: "OTP sent successfully", response };
          } catch (userError) {
            try {
              const response = await axios.post("https://billing.ubsbill.com/apptest/v2/api/auth/superadmin/forgotPassword", { email }, config);
              return { message: "OTP sent successfully", response };
            } catch (superAdminError) {
              // Handle error where all API calls failed
              if (superAdminError.response && superAdminError.response.status === 404) {
                // Handle the case where the email does not exist
                return thunkAPI.rejectWithValue({ message: "Email does not exist. Please enter a registered email." });
              } else {
                // Handle other errors
                return thunkAPI.rejectWithValue({ message: "Failed to send OTP. Please try again." });
              }
            }
          }
        }
      }
    }
  );
  
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ email, oneTimePassword, password, confirmPassword, roles }, thunkAPI) => {
      let apiUrl;
  
      // Select API endpoint based on roles
      if (roles === "ROLE_USER") {
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/user/resetPassword";
      } else if (roles === "ROLE_ADMIN") {
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/store/reset-password";
      } else if (roles === "ROLE_SUPPORT") {
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/tech/resetPassword";
      } else if (roles === "ROLE_SUPER_ADMIN") {
        apiUrl = "https://billing.ubsbill.com/apptest/v2/api/auth/superadmin/resetPassword";
      } else {
        throw new Error("Invalid role");
      }
  
      try {
        const response = await axios.post(apiUrl, {
          email,
          oneTimePassword,
          password,
          confirmPassword
        }, {
          headers: {
            "Content-Type": "application/json",
          }
        });
        return { user: response.data, roles }; // Include role in the payload
      } catch (error) {
        const errorMessage = error.response.data;
        // console.error('Error during :', errorMessage);
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  );

const initialState = {
    isLoggedIn: !!user,
    user: user || null,
    users: [],
    status: 'idle',
    error: null,
    message: null,
  };
  
  const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        // Handle different login types based on their respective actions
        .addCase(loginUser.fulfilled, (state, action) => {
          const { user, roles } = action.payload;
          state.isLoggedIn = true;
          state.user = user;
          state.message = `${roles} Login successful`; // Update message with role
        })
        .addCase(loginUser.rejected, (state, action) => {
          state.status = 'failed';
          state.isLoggedIn = false;
          state.user = null;
          state.error = action.payload ? action.payload.error.response?.data.message : "Failed to login";
        })
  
        .addCase(logout.fulfilled, (state, action) => {
          state.isLoggedIn = false;
          state.user = null;
        })
        .addCase(logout.rejected, (state, action) => {
          state.error = action.error.message || "Failed to logout";
        })
        .addCase(forgotPassword.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(forgotPassword.fulfilled, (state) => {
          state.status = 'idle';
          // Optionally update state to indicate success
        })
        .addCase(forgotPassword.rejected, (state, action) => {
          state.status = 'idle';
          state.error = action.payload || 'Failed to send OTP';
        })
        .addCase(resetPassword.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(resetPassword.fulfilled, (state) => {
          state.status = 'idle';
          // Optionally update state to indicate success
        })
        .addCase(resetPassword.rejected, (state, action) => {
          state.status = 'idle';
          state.error = action.payload || 'Failed to reset password';
        });
    },
  });
  
  const { reducer } = authSlice;
  export default reducer;