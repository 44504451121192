import { Box, Grid, } from "@mui/material";
import React, { useState } from "react";

import QuickAccessLinks from "../../Components/StoreDashboardComponent/QuickAccessLinks";
import AppMenu from "../../Components/StoreDashboardComponent/AppMenu";
import SuperAdminAppmenu from "../../Components/StoreDashboardComponent/SuperAdminAppmenu";
import { useSelector } from "react-redux";
import { useDarkMode } from "../DarkMode/DarkMode";

function SuperadminDashboard() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const accessLinkData = [
    {
      id: 1,
      title: "Add Store",
      image: "../images/add-store.png",
      link: "/add-new-store",
    },
    {
      id: 2,
      title: "Stores List",
      image: "../images/pending-order-icon.png",
      link: "/store-list",
    },
  ];


  const appMenuData = [
    {
      id: 1,
      title: "Store Management",
      image: "../images/addFoodIcon.png",
      boxShadow: "0px -9px 0px 0px #FACE47",
      link: "/add-food",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 2,
      title: "Technician Management",
      image: "../images/bill-report-icon.png",
      boxShadow: "0px -9px 0px 0px #28A497",
      link: "/bill-report",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "500",
    },
    {
      id: 3,
      title: "Store Settings",
      image: "../images/setting-icon.png",
      boxShadow: "0px -9px 0px 0px #47C01C",
      link: "/add-user",
      iconWidth: "50px",
      iconHeight: "50px",
      titleFontWeight: "50px",
    },
   
  ];

  const [AppMenuLinks, setAppMenuLinks] = useState([]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CustomTypography
          sx={{ mb: 4, fontWeight: "600" }}
          variant="p"
          className="link-cart-title"
        >
          🖐 Hello , {currentUser.username} | 
          <span
            className="link-cart-title"
            style={{ fontWeight: "500", color: "#424242" }}
          >
             {currentDate}
          </span>
        </CustomTypography>
       
        <CustomTypography sx={{ mb: 4 , fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' } }} variant="p" className="link-cart-title">
          Quick Access Links
        </CustomTypography>
        <QuickAccessLinks data={accessLinkData}/>
        <CustomTypography sx={{ mb: 4 }} variant="p" className="link-cart-title">
          App Menu
        </CustomTypography>
       
         <SuperAdminAppmenu data={appMenuData}/>
       
       
      </Box>
    </Box>
  );
}
export default SuperadminDashboard;
