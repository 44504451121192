import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Box,
  Modal,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Tabs,
  Tab,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { useDrawer } from "../../Sidebar/DrawerContext";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { add, fetchedData, selectSubtotal } from "../../../Screens/store/foodSlice";
import { useSuccessSnackbar } from "../../../Screens/snackbar/SuccessSnackbarProvider";
import { useReactToPrint } from "react-to-print";
import QuickBill from "../../OrderManagement/NewOrderComponents/QuickBill";
import { useErrorSnackbar } from "../../../Screens/snackbar/ErrorSnackbarProvider";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "32vw", md: "37vw", sm: "60vw", xs: "90vw", xl: "25vw" },
};

export const PrintOrderModal = ({ orderData, order, serialNo, totalData, fetchPendingData, billId }) => {
  const { isDarkMode, toggleDarkMode, CustomTypography } = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data)
  const { subtotal } = useSelector(selectSubtotal);
  const handleOrderStatus = async (serialNo) => {

    try {
      const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/bill/updatebyStatus/${serialNo}/?orderStatus=Prepared`);
      if (response.data.status === true) {
        setOpen(false);
      }
    } catch (error) {
      console.error("Error occurred while updating order status:", error);

    }
  }
  const [kotOrderCards, setKotOrderCards] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://billing.ubsbill.com/apptest/v2/sys/bill/statusreport?orderStatus=Preparing&storeId=${currentUser.storeid}`);
      setKotOrderCards(response.data.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);

    }
  };
  const {
    runningOrderTitle,
    runningOrderButton,
    runningOrderTable,
    runningOrderTableTotal,
  } = useDrawer();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    dispatch(fetchedData(totalData));

  };
  const handleClose = () => {
    setOpen(false);
    setPaymentModeValue(null);
  };
  const calculateTotalForOrder = () => {
    let total = 0;

    orderData.orderFoods.forEach((foodItem) => {
      total += foodItem.quantity * foodItem.price;
    });
    return total;
  };

  const [paymentMode, setPaymentMode] = useState(null);
  const [paymentModeValue, setPaymentModeValue] = useState(null);
  const handlePaymentModeChange = (event, newValue) => {
    let paymentMode = "";
    switch (newValue) {
      case 0:
        paymentMode = "Cash";
        break;
      case 1:
        paymentMode = "Card";
        break;
      case 2:
        paymentMode = "Upi";
        break;
      default:
        break;
    }

    dispatch(add({ paymentMode }));
    setPaymentModeValue(newValue);
    setPaymentMode(paymentMode);
  };
  const totalForOrder = calculateTotalForOrder();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const printableBillRef = useRef();
  const [tax, setTax] = useState([]);
  // Function to calculate the total tax rate
  const fetchTaxData = () => {
    axios.get(`https://billing.ubsbill.com/apptest/v2/sys/tax/store/${currentUser.storeid}`)
      .then((response) => {
        if (response.data && response.data.data) {
          setTax(response.data.data);
        }
      })
      .catch((error) => {
        showErrorSnackbar("Error Fetching Tax Data");
      });
  }

  useEffect(() => {
    fetchTaxData()
  }, []);

  const calculateTotalTaxRate = () => {
    let totalRate = 0;
    tax.forEach(taxItem => {
      totalRate += taxItem.rate;
    });
    return totalRate;
  }

  const calculateGrandTotal = () => {
    const totalTaxRate = calculateTotalTaxRate();
    const totalTaxAmount = (subtotal * totalTaxRate) / 100;
    return subtotal + totalTaxAmount;

  }

  const handlePrintBill = useReactToPrint({
    content: () => printableBillRef.current,
  })



  const currentDate = new Date();
  const date = currentDate.toLocaleDateString('en-GB'); // Format date as "DD/MM/YYYY"



  const handlePrint = async (serialNo, paymentMode) => {
    if (paymentModeValue === null || paymentModeValue === undefined) {
      showErrorSnackbar('Please select a payment mode before proceeding.');
      return;
    }

    try {
      const response = await axios.patch(`https://billing.ubsbill.com/apptest/v2/sys/bill/printBill/${serialNo}?paymentMode=${paymentMode}&total=${calculateGrandTotal()}`,);

      if (response.data.status === true) {
        showSuccessSnackbar("Order Updated Successfully !");
        handlePrintBill();
        setOpen(false);
        fetchPendingData();
        setPaymentModeValue(null);
      }


    } catch (error) {
      console.error("Error occurred while Printing:", error);
      showErrorSnackbar(error.response.data.message);
    }
  };


  //                 {---------------- for qr code genrate--------------------------}


  const [upi, setText] = useState([]);
  const [qrCodeImage, setQRCodeImage] = useState(null);

  const fetchUpiData = async () => {
    try {
      const url = `https://billing.ubsbill.com/apptest/v2/sys/storePayment/store/${currentUser.storeid}`
      const response = await fetch(url);
      const json = await response.json();
      setText(json.data[0].upiId);
      console.log(json.data[0].upiId);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  };

  const generateQRCode = async () => {
    try {
      const response = await fetch(`https://billing.ubsbill.com/apptest/v2/sys/storePayment/generateQRCode?text=${upi}`);//ok
      if (response.ok) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setQRCodeImage(imageUrl);

      } else {
        console.error('Failed to generate QR code.');
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect((upi) => {
    generateQRCode();
    fetchUpiData();
  }, [upi]);











  return (
    <Box>

      <Button onClick={handleOpen} className='food-card-text mode-button border-radius-10' sx={{ bgcolor: isDarkMode ? "#8f8D8D" : '#D9D9D9', p: "5px", maxWidth: "45px", width: "45px", minWidth: "45px" }}>
        <PrintIcon sx={{ color: isDarkMode ? "#fff" : "#424242" }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Card
            sx={{
              background: isDarkMode ? "#5E5E5E" : "#fff",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "20px 0px 0px 0px",
              borderRadius: "10px",
            }}
          >
            <Box
              className="align-items-center"
              sx={{
                background: orderData.orderStatus === "pending" ? (isDarkMode ? "#48484a" : "#9A9A9A") :
                  orderData.orderStatus === "Prepared" ? (isDarkMode ? "#48484a" : "#28A497") :
                    orderData.orderStatus === "Preparing" ? (isDarkMode ? "#FACE47" : "#FACE47") :
                      null,
                p: "2% 4% ",
                mt: "5px",
              }}
            >
              <CustomTypography sx={{ ...runningOrderTitle, color: "#fff" }}>
                Order Details
              </CustomTypography>
              <Button
                onClick={handleClose}
                sx={{ marginLeft: "auto", color: "#fff" }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <Box
              sx={{
                padding: { lg: 2.5, md: 1.7, sm: 1.7, xs: 1.2 },
                display: "flex",
                flexDirection: "column",
                gap: { xs: 0, sm: 2, xl: 2, lg: 2 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  maxWidth: { xl: "95%", lg: "100%" },
                }}
              >
                <CustomTypography className="food-card-text" sx={runningOrderTable}>
                  Table No : {orderData.tableNo}
                </CustomTypography>
                <CustomTypography className="food-card-text" sx={runningOrderTable}>
                  Order No : {orderData.orderId}
                </CustomTypography>
              </Box>
              <CustomTypography className="food-card-text" sx={runningOrderTable}>
                Order Type : {orderData.orderType}
              </CustomTypography>
              <CustomTypography className="food-card-text" sx={runningOrderTable}>
                Order Date : {orderData.orderDate}
              </CustomTypography>

              <Box
                className="payment-mode-main"
                sx={{ radius: "5px", display: "flex", alignItems: "center", mt: 0.2 }}
              >
                <CustomTypography
                  className="food-card-text"
                  sx={{
                    fontSize: {
                      sm: "17px",
                      md: "15px",
                      xs: "12px",
                      xl: "16px",
                      lg: "13px",
                    },
                    fontWeight: "500",
                    lineHeight: "30px",
                    minWidth: {
                      sm: "97px",
                      md: "105px",
                      xs: "97px",
                      xl: "97px",
                      lg: "102px",
                    },
                  }}
                >
                  PAYMENT MODE
                </CustomTypography>

                <Tabs
                  className=""
                  value={paymentModeValue}
                  onChange={handlePaymentModeChange}
                  textColor="white"
                  indicatorColor="white"
                  aria-label="tabs"
                  sx={{
                    padding: "5px",
                    radius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab
                    label="CASH"
                    className="food-card-text mode-button border-radius-10 cart-button"
                    sx={{
                      bgcolor: paymentModeValue === 0 ? "#28A497" : "#D9D9D9",
                      color: paymentModeValue === 0 ? "white" : "inherit",
                      padding: {
                        sm: "10px 25px",
                        md: "5px 8px",
                        xs: "0px 15px",
                        xl: "5px 15px",
                        lg: "7px 5px",
                      },
                      fontSize: {
                        sm: "15px",
                        md: "14px",
                        xs: "12px",
                        xl: "16px",
                        lg: "12px",
                      },
                      minWidth: {
                        sm: "15px",
                        md: "14px",
                        xs: "12px",
                        xl: "90px",
                        lg: "60px",
                      },
                      marginLeft: {
                        sm: "15px",
                        md: "5px",
                        xs: "5px",
                        xl: "7px",
                        lg: "10px",
                      },
                    }}
                  />
                  <Tab
                    className="food-card-text mode-button border-radius-10"
                    label="CARD"
                    sx={{
                      bgcolor: paymentModeValue === 1 ? "#28A497" : "#D9D9D9",
                      color: paymentModeValue === 1 ? "white" : "inherit",
                      padding: {
                        sm: "10px 25px",
                        md: "5px 8px",
                        xs: "0px 15px",
                        xl: "5px 15px",
                        lg: "7px 5px",
                      },
                      fontSize: {
                        sm: "15px",
                        md: "14px",
                        xs: "12px",
                        xl: "16px",
                        lg: "12px",
                      },
                      minWidth: {
                        sm: "15px",
                        md: "14px",
                        xs: "12px",
                        xl: "90px",
                        lg: "60px",
                      },
                      marginLeft: {
                        sm: "15px",
                        md: "5px",
                        xs: "5px",
                        xl: "7px",
                        lg: "10px",
                      },
                    }}
                  />
                  <Tab
                    label="UPI"
                    className="food-card-text mode-button border-radius-10"
                    sx={{
                      bgcolor: paymentModeValue === 2 ? "#28A497" : "#D9D9D9",
                      color: paymentModeValue === 2 ? "white" : "inherit",
                      padding: {
                        sm: "10px 25px",
                        md: "5px 8px",
                        xs: "0px 15px",
                        xl: "5px 15px",
                        lg: "7px 5px",
                      },
                      fontSize: {
                        sm: "15px",
                        md: "14px",
                        xs: "12px",
                        xl: "16px",
                        lg: "12px",
                      },
                      minWidth: {
                        sm: "15px",
                        md: "14px",
                        xs: "12px",
                        xl: "90px",
                        lg: "60px",
                      },
                      marginLeft: {
                        sm: "15px",
                        md: "5px",
                        xs: "5px",
                        xl: "7px",
                        lg: "10px",
                      },
                    }}
                  />
                </Tabs>
              </Box>
              <TableContainer
                className="cart-table"
                sx={{
                  minHeight: "auto",
                  maxHeight: {
                    sm: "20vh",
                    md: "25vh",
                    xs: "25vh",
                    xl: "31vh",
                    lg: "31vh",
                  },
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      background: isDarkMode ? "#8F8D8D" : "#fff",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      height: {
                        sm: "15px",
                        md: "14px",
                        xs: "12px",
                        xl: "55px",
                        lg: "20px",
                      },
                    }}
                  >
                    <TableRow
                      sx={{
                        border: "none",
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        className="food-card-text"
                        sx={{ ...runningOrderTable, fontWeight: "600" }}
                      >
                        Sr No
                      </TableCell>
                      <TableCell
                        className="food-card-text"
                        sx={{ ...runningOrderTable, fontWeight: "600" }}
                      >
                        Food Name
                      </TableCell>
                      <TableCell
                        className="food-card-text"
                        sx={{ ...runningOrderTable, fontWeight: "600" }}
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        className="food-card-text"
                        sx={{ ...runningOrderTable, fontWeight: "600" }}
                      >
                        Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{}} className="cart-table">
                    {orderData.orderFoods.map((item, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "& td, & th": { border: 0 },
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <TableCell
                          size="small"
                          className="food-card-text"
                          sx={runningOrderTable}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          size="small"
                          className="food-card-text"
                          sx={{
                            ...runningOrderTable,
                            maxWidth: { xl: "7vw", lg: "13vw", md: "9vw" },
                          }}
                        >
                          {item.foodName}
                        </TableCell>
                        <TableCell
                          size="small"
                          className="food-card-text"
                          sx={runningOrderTable}
                        >
                          {item.quantity}
                        </TableCell>
                        <TableCell
                          size="small"
                          className="food-card-text"
                          sx={runningOrderTable}
                        >
                          {item.price}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              className="justify-all-center"
              sx={{
                background: isDarkMode ? "#8F8D8D" : "#D9D9D9",
                p: "4% 4% ",
                marginTop: "10px",
                justifyContent: "space-between",
              }}
            >
              <CustomTypography
                className="food-card-text"
                sx={runningOrderTableTotal}
              >
                Total
              </CustomTypography>
              <CustomTypography
                className="food-card-text"
                sx={runningOrderTableTotal}
              >
                ₹ {totalForOrder}
              </CustomTypography>
            </Box>


            {orderData.orderStatus === "pending" ||
              orderData.orderStatus === "Prepared" ? (
              <Box
                sx={{
                  mt: { xl: "15px", sm: "15px", xs: "30px" },
                  padding: "0px 10px 10px", display: "flex",
                }}
              >
                <Box sx={{ marginLeft: "auto" }}>
                  <Button
                    className="food-card-text mode-button border-radius-10"
                    component={Link} to={`/update-order/${order.serialNo}`}
                    sx={{
                      bgcolor: isDarkMode ? "#8f8D8D" : '#D9D9D9',
                      p: "5px",
                      maxWidth: "45px",
                      width: "45px",
                      minWidth: "45px",
                      mr: 1,
                    }}
                  >
                    <EditIcon sx={{ color: isDarkMode ? "#fff" : "#424242" }} />
                  </Button>
                  <Button
                    className="food-card-text mode-button border-radius-10"
                    onClick={() => handlePrint(order.serialNo, paymentMode)}
                    sx={{
                      bgcolor: isDarkMode ? "#8f8D8D" : '#D9D9D9',
                      p: "5px",
                      maxWidth: "45px",
                      width: "45px",
                      minWidth: "45px",
                    }}
                  >
                    <PrintIcon sx={{ color: isDarkMode ? "#fff" : "#424242" }} />
                  </Button>
                  <Box sx={{ display: 'none' }}>
                    <Box ref={printableBillRef} >
                      <QuickBill billId={billId} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : orderData.orderStatus === "Preparing" && (
              <Box
                sx={{
                  mt: { xl: "15px", sm: "15px", xs: "30px" },
                  padding: "0px 12px 12px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Button
                    className="food-card-text mode-button border-radius-10"
                    label="CARD"
                    sx={runningOrderButton}
                    onClick={() => handleOrderStatus(serialNo)}
                  >
                    Prepar
                  </Button>
                  <Box sx={{ marginLeft: "auto" }}>
                    <Button
                      className="food-card-text mode-button border-radius-10"
                      component={Link} to={`/update-order/${order.serialNo}`}
                      sx={{
                        bgcolor: isDarkMode ? "#8f8D8D" : '#D9D9D9',
                        p: "5px",
                        maxWidth: "45px",
                        width: "45px",
                        minWidth: "45px",
                        mr: 1,
                      }}
                    >
                      <EditIcon sx={{ color: isDarkMode ? "#fff" : "#424242" }} />
                    </Button>
                    <Button
                      className="food-card-text mode-button border-radius-10"
                      sx={{
                        bgcolor: isDarkMode ? "#8f8D8D" : '#D9D9D9',
                        p: "5px",
                        maxWidth: "45px",
                        width: "45px",
                        minWidth: "45px",
                      }}
                    >
                      <PrintIcon sx={{ color: isDarkMode ? "#fff" : "#424242" }} />
                    </Button>
                  </Box>
                </Box>
              </Box>

            )}

          </Card>
        </Box>
      </Modal>
    </Box>
  );
};