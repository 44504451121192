
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardMedia, Grid, Stack } from '@mui/material';
import { useDarkMode } from '../../Screens/DarkMode/DarkMode';
import "../VendorManagement/card.css";


const NavLinkCard = ({ card, index }) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  // Define state to determine boxShadow
  const boxShadowColor = index % 2 === 0 ? '#47C01C' : '#FACE47';

  return (
    
<Grid item key={card.id} lg={4} md={4} sm={6} xs={11.5} xl={4} >
  <Link to={card.link} style={{ textDecoration: 'none' }}>
    <Card
      className="justify-all-center justify-left-mobile link-card-style"
      sx={{
        marginBottom: '30px',
        maxWidth: { sm: 300, md: 500, xs: "100%", lg: 500, xl: 450 },
       height: { sm: "10vh", md: "12vh", xs: "7vh", lg: "13vh", xl: "12vh" },
        padding: { sm: 2, md: 2.7, xs: 3.7, lg: 3, xl: 3.3 },
        boxShadow: card.boxShadow,
        background: isDarkMode ? "#5e5e5e"  :"#EEEEEE",
      }}
    >
      <Stack direction="row" className="justify-all-center">
      <CardMedia
          component="img"
          alt={`${card.title} Icon`}
          
          image={card.image}
          sx={{ width: { sm: 30, md: 40, xs: 30, lg: 55, xl: 55 } ,  filter: isDarkMode ? "invert(1)" : "none", }}
        />
        <CustomTypography
          variant="p"
          className="link-cart-title"
          sx={{
            fontSize: { sm: '12px', md: '12.5px', xs: '14px', lg: '16px', xl: '17.5px' },
            marginLeft: { sm: '5px', md: '5px', xs: '19px', lg: '20px', xl: '40px' },
            fontWeight: card.titleFontWeight,
            display: "flex"
          }}
        >
          {card.title}
        </CustomTypography>
      </Stack>
    </Card>
  </Link>
</Grid>
  );
};

export default NavLinkCard;
