import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Select,
  Box,
  Checkbox,
  InputLabel,
  FormControl,
  Button,
  Card,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ChecklistIcon from "@mui/icons-material/Checklist";
import BpCheckbox from "./BpCheckbox";
import { useDrawer } from "../Sidebar/DrawerContext";
import {

  updateSubmenuStatus,
  updateManagementStatus,
  fetchDataAction,
  resetState,
} from "../../Screens/store/roleSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { useLocation } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";

export default function UpdateRoleAccess() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user.data);
  const { submitButtonStyles } = useDrawer();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();

  //
  const [managementsubmenu, setManagementsubmenu] = useState({
    Dashboard: false,
    "Order Management": false,
    "Food Management": false,
    "Inventory Management": false,
    "Cash Management": false,
    "Vendor Management": false,
    "User Management": false,
    Reports: false,
    Profile: false,
  });
  const dispatch = useDispatch();
  const [activeSections, setActiveSections] = useState({});
  const isAnyCheckboxChecked = Object.values(managementsubmenu).some(
    (item) => item
  );

  //USE: To visible Submenu div when click on checkbox and to update status true in redux state
  const handleManagementClick = (title, status) => {
    setManagementsubmenu((prevsubmenu) => ({
      ...prevsubmenu,
      [title]: !prevsubmenu[title],
    }));

    setActiveSections((prevSections) => ({
      ...prevSections,
      [title]: !prevSections[title],
    }));
    dispatch(
      updateManagementStatus({ title, status: !managementsubmenu[title] })
    );
  };

  const [isActive, setIsActive] = useState(false);

  const handleClick = (title) => {
    setIsActive(title);
  };

  //USE: To update status is true for submenu checkboxes
  const handleItemClick = (section, name) => {
    dispatch(updateSubmenuStatus({ section, name }));
  };

  //USE: TO get Redux state
  const managementSections = useSelector(
    (state) => state.managementSections.managementSections
  );

  //this useeffect is for show submenu data when menu are checked, basically it check for mangsec and menu inside it if its there in it
  //iterate over it and catch title, status, subMenu values, if submenu has some value then it check for status of it if its true then it update the submenu if submenu
  //array dont have status then it check for overall status of menu if one of them is tru then it update Managementsubmenu according to it ,
  //so menu are visible automatically when in managementsubmenu its value is true
  useEffect(() => {
    if (managementSections && managementSections.menu) {
      const updatedSubmenu = {};
      managementSections.menu.forEach(({ title, status, subMenu }) => {
        if (subMenu && subMenu.length > 0) {
          const subMenuStatus = subMenu.some((item) => item.status);
          updatedSubmenu[title] = subMenuStatus || status;
        } else {
          updatedSubmenu[title] = status;
        }
      });
      setManagementsubmenu(updatedSubmenu);
    }
  }, [managementSections]);

  const boxShadowValues = [
    "0px -6px 0px 0px #47C01C",
    " 0px -6px 0px 0px #28A497",
    "0px -6px 0px 0px #fc8c03",
    "0px -6px 0px 0px #FACE47",
    "0px -6px 0px 0px #28A497",
    "0px -6px 0px 0px #fc8c03",
    "0px 4px 4px 0px #00000040",
  ];
  const [usernames, setUsernames] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [userData, setUserData] = useState([]);
  //USE: to get usernames for select input
  useEffect(() => {
    axios
      .get(`https://billing.ubsbill.com/apptest/v2/sys/usersidebar/store/${currentUser.storeid}`)
      .then((response) => {
        const userData = response.data.data;
        setUserData(userData);
        const usernames = userData.map((user) => user.username);
        setUsernames(usernames);
      })
      .catch((error) => {
        console.error("Error fetching usernames:", error);
      });
  }, []);

  //to get selected username userid that we need to get givem roles to particular user and also to call update roles function
  const [selectedUserId, setSelectedUserId] = useState(null);
  useEffect(() => {
  }, [selectedUserId]);

  const handleUsernameChange = (event) => {
    const selectedUsername = event.target.value;
    setSelectedUsername(selectedUsername);

    // Find the user object based on the selected username
    const selectedUser = userData.find(
      (user) => user.username === selectedUsername
    );

    // Update the state with the selected user's ID
    if (selectedUser) {
      setSelectedUserId(selectedUser.userId);
    }

  };

  //USE: to get given roles to particular user by selectedUserId
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://billing.ubsbill.com/apptest/v2/sys/usersidebar/id/${selectedUserId}`
        );
        const data = await response.json();

        dispatch(fetchDataAction(data.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedUserId !== null) {
      fetchData();
    }
  }, [selectedUserId, dispatch]);

  //IMportant: to send updated data to backend
  const backendUrl = `https://billing.ubsbill.com/apptest/v2/sys/usersidebar/id/${selectedUserId}`;


  const sendToBackend = () => {
    if(selectedUsername === null){
      showErrorSnackbar("Please Select User First!");
    }
    else{
    fetch(backendUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(managementSections),
    })
      .then((response) => {
       
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        showSuccessSnackbar("Role Permissions Updated Successfully!");
     
      })
      .catch((error) => {
        showErrorSnackbar(error.response.data.message);
       
      });
    }
  };


  const location = useLocation();
  useEffect(() => {
    dispatch(resetState());
  }, [location.pathname, dispatch]);

  return (
    <Box component="main" sx={{ p: { xs: 0, sm: 1, md: 1, lg: 1.5, xl: 1.5 } }}>
      <Grid container spacing={1} sx={{ mb: 4 }}>
        <Grid item>
          <Box
            display="flex"
            alignsubmenu="center"
            bgcolor="primary.light"
            p={1}
            sx={{
              fontWeight: "500",
              backgroundColor: isDarkMode ? "#48484A" : "#28A497",
              fontSize: "24px",
              color: "white",
              borderRadius: 1,
            }}
          >
            <AdminPanelSettingsIcon fontSize="large" />{" "}
            <span style={{ marginLeft: "8px" }}>Update User Role Access</span>{" "}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} xl={4}>
          <Box
            alignsubmenu="center"
            p={2}
            sx={{
              fontWeight: "500",
              backgroundColor: isDarkMode ? "#48484A" : "#F3F3F3",
              fontSize: "24px",
              color: "black",
              borderRadius: 1,
            }}
          >
            <FormControl fullWidth>
              <InputLabel sx={{color: isDarkMode ? "#fff" : "#000"}} shrink={selectedUsername} htmlFor="username-select">
                Select Username to give Permission
              </InputLabel>
              <Select
                value={selectedUsername}
                label="Select Username to give Permission"
                onChange={handleUsernameChange}
                sx={{ bgcolor: isDarkMode ? "#7A7A7A" : "white" , color: isDarkMode ? "#fff" : "#000" }}
                color="info"
                inputProps={{
                  name: "username",
                  id: "username-select",
                }}
              >
                {usernames.map((username, index) => (
                  <MenuItem key={index} value={username}>
                    {username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              sx={{
                display: "flex",
                alignsubmenu: "center",
                background :  isDarkMode ? "#7A7A7A" : "#28A497" ,
                padding: "8px",
                borderRadius: "4px",
                color: "white",
                my: 2,
              }}
            >
              <ChecklistIcon fontSize="large" />
              <Typography
                variant="h6"
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "17px",
                    lg: "18px",
                    xl: "19px",
                  },
                  ml: 2,
                  color:"#fff" , 
                }}
              >
                Select Assign Permission
              </Typography>
            </Box>

            {managementSections.menu.map(({ title, status }) => (
              <Grid
                container
                key={title}
                alignsubmenu="center"
                sx={{background : isDarkMode ? "transparent" : "#D9D9D9"}}
              >
                <Grid item p={0.5} xs={12}>
                  <FormControlLabel
                    onClick={() => handleClick(title)}
                    sx={{
                      width: "98%",
                      ml: 0.1,
                      backgroundColor: activeSections[title] ? (isDarkMode ? "#323233" : "#fff") : (isDarkMode ? "transparent" : "transparent"),
                      borderRadius: "0px 30px 30px 0px",
                      border: activeSections[title]
                        ? `2px solid #4992A3`
                        : "none",
                    }}
                    control={
                      <BpCheckbox
                        checked={status}
                        onChange={() => handleManagementClick(title)}
                        color="success"
                      />
                    }
                    label={
                      <CustomTypography
                        sx={{
                          fontWeight: "400",
                          textTransform: "capitalize",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                        className="food-card-text"
                      >
                        {title}
                      </CustomTypography>
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} xl={7.8}>
          <Box
            sx={{
              fontWeight: "500",

              fontSize: "24px",
              color: "white",
              borderRadius: 1,
              height: "77vh",
            }}
          >
            {isAnyCheckboxChecked ? (
              <Box
                className="selectscroll"
                sx={{ maxHeight: "70vh", height: "auto", overflowY: "auto" }}
              >
                {managementSections.menu.map(({ title, subMenu, status }) => (
                  <Box
                    key={title}
                    sx={{
                      fontWeight: "900",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor: isDarkMode ? "#48484A" : "#F3F3F3",
                      fontSize: "30px",
                      color: "black",
                      borderRadius: 1,
                      p: 2,
                      mb: 2,
                      display: status ? "block" : "none",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <CustomTypography
                        className="food-card-text"
                        sx={{
                          color: "#28A497",
                          textTransform: "capitalize",
                          fontWeight: "600",
                          marginRight: "10px",
                          marginBottom: "20px",
                          color: "#000",
                        }}
                      >
                        {title}
                        {/* {["reports", "dashboard"].includes(title)
                           ? title
                        : `${title} Management`} */}
                      </CustomTypography>
                      {/* <Box
                        sx={{ marginLeft: "auto" }}
                        className="justify-all-center"
                      >
                        <BpCheckbox />
                        <CustomTypography
                          className="food-card-text"
                          sx={{
                            whiteSpace: "nowrap",
                            fontWeight: "400",
                            fontSize: {
                              sm: "14px",
                              md: "14px",
                              xs: "14px",
                              lg: "15px",
                              xl: "16px",
                            },
                          }}
                        >
                          Select All
                        </CustomTypography>
                      </Box> */}
                    </Box>
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignsubmenu: "flex-start",
                      }}
                    >
                      {subMenu.map((item, index, status) => (
                        <Card
                          key={item.title}
                          className="justify-left-mobile role-card-style "
                          sx={{
                            marginBottom: "20px",
                            cursor: "pointer",
                            marginRight: "15px",
                            maxWidth: {
                              sm: "200px",
                              md: "180px",
                              xs: "140px",
                              lg: "200px",
                              xl: "240px",
                            },
                            minWidth: {
                              sm: "220px",
                              md: "170px",
                              xs: "140px",
                              lg: "180px",
                              xl: "220px",
                            },
                            padding: {
                              sm: "2px 10px",
                              md: "2px 14px",
                              xs: "2px 10px",
                              lg: "7px 15px",
                              xl: "7px 15px",
                            },
                            boxShadow: boxShadowValues[index],
                            background: isDarkMode ? "#7A7A7A" : "#E8E8E8",
                            display: "flex",
                            flexDirection: "row",
                            alignsubmenu: "flex-start",
                            mt: "10px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <BpCheckbox
                                checked={
                                  managementsubmenu[title][item.title] ||
                                  item.status
                                }
                                onChange={() =>
                                  handleItemClick(title, item.title)
                                }
                                name={item.title}
                                color="success"
                                sx={{
                                  marginRight: {
                                    sm: "7px",
                                    md: "10px",
                                    xs: "1px",
                                    lg: "7px",
                                    xl: "20px",
                                  },
                                }}
                              />
                            }
                            label={
                              <CustomTypography
                                className="food-card-text"
                                sx={{
                                  whiteSpace: "nowrap",

                                  fontWeight: { sm: "400", md: "600" },
                                  fontSize: {
                                    sm: "13px",
                                    md: "13px",
                                    xs: "12px",
                                    lg: "15px",
                                    xl: "16px",
                                  },
                                }}
                              >
                                {item.title}
                              </CustomTypography>
                            }
                          />
                        </Card>
                      ))}
                    </FormGroup>
                  </Box>
                ))}
              </Box>
            ) : (
              <Alert severity="error" sx={{ marginTop: "30px" }}>
                <AlertTitle>Info</AlertTitle>
                Please Assign Role First
              </Alert>
            )}

            {isAnyCheckboxChecked ? (
              <Box style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  onClick={sendToBackend}
                  className="food-card-text mode-button border-radius-10 cart-button final-button"
                  sx={{ ...submitButtonStyles, mt: "20px", fontWeight: "600" }}
                >
                  Submit
                </Button>
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
