import { Padding } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Divider,
  Grid,
  Typography,

} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDrawer } from "../../Sidebar/DrawerContext";
import "./Quickbill.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
 selectTotalQuantity,
} from "../../../Screens/store/foodSlice";
import { useErrorSnackbar } from "../../../Screens/snackbar/ErrorSnackbarProvider";
import { useDarkMode } from "../../../Screens/DarkMode/DarkMode";

const PrintBill = ({ data, billId }) => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const currentUser = useSelector((state) => state.auth.user?.data);
  const foodList = useSelector((state) => state.cart.bill.order[0].orderFoods);
  const orderData = useSelector((state) => state.cart.bill);
  const DiscountFromRedux = useSelector((state) => state.cart.bill.discount);
  const tableNo = useSelector((state) => state.cart.bill.order[0].tableNumber);
  const { showErrorSnackbar } = useErrorSnackbar();

  const totalQuantity = useSelector(selectTotalQuantity);

  const [storeData, setStoreData] = useState("");
  useEffect(() => {
    axios
      .get(
        `https://billing.ubsbill.com/apptest/v2/api/auth/store/${currentUser.storeid}`
      )
      .then((response) => {
        const sdata = response.data.data;
        console.log("storeData");

        setStoreData(sdata);
      })
      .catch((error) => {});
  }, []);

  const currentDate = new Date();
  const date = currentDate.toLocaleDateString("en-GB");
  const time = currentDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const { billNormalData, lineStyle } = useDrawer;

  //                 {---------------- for qr code genrate--------------------------}

  const dispatch = useDispatch();
  const [upi, setText] = useState([]);
  const [qrCodeImage, setQRCodeImage] = useState(null);
  const [LogoImage, setLogoImage] = useState(null);

  const fetchUpiData = async () => {
    try {
      const url = `https://billing.ubsbill.com/apptest/v2/sys/storePayment/store/${currentUser.storeid}`;
      const response = await fetch(url);
      const json = await response.json();
      setText(json.data[0].upiId);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  };

  const generateQRCode = async () => {
    try {
      const response = await fetch(
        `https://billing.ubsbill.com/apptest/v2/sys/storePayment/generateQRCode?text=upi://pay?pa=${upi}`
      ); //ok
      if (response.ok) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setQRCodeImage(imageUrl);
      } else {
        console.error("Failed to generate QR code.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const generateLogo = async () => {
  //   try {
  //       const response = await fetch(`https://billing.ubsbill.com/apptest/v2/api/auth/store/logo/${currentUser.storeid}`);//ok
  //       if (response.ok) {
  //         const blob = await response.blob();
  //         const imageUrl = URL.createObjectURL(blob);
  //         setLogoImage(imageUrl);

  //       } else {
  //         console.error('Failed to generate QR code.');
  //       }

  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  useEffect(
    (upi) => {
      generateQRCode();

      fetchUpiData();
    },
    [upi]
  );

  //to calculate total amount of each food
  const calculateAmount = (food) => {
    return food.price * food.quantity;
  };

  //to calculate sub total of each food
  const calculateSubtotal = () => {
    let subTotal = 0;

    foodList.forEach((food) => {
      subTotal += calculateAmount(food);
    });

    return subTotal;
  };
  //to calculate discounted amount only for percentage
  const calculateDiscountedAmount = (foodList, orderData) => {
    const subTotal = calculateSubtotal(foodList);
    const discountAmount = (subTotal * DiscountFromRedux) / 100;
    return discountAmount;
  };

  //to calculate discounted total wrt Subtotal
  const calculateDiscountedTotal = (foodList, orderData) => {
    const subTotal = calculateSubtotal(foodList);

    const discountAmount = (subTotal * DiscountFromRedux) / 100;

    const discountedTotal = subTotal - discountAmount;
    return discountedTotal;
  };

  const [tax, setTax] = useState([]);

  const fetchTaxData = () => {
    axios
      .get(
        `https://billing.ubsbill.com/apptest/v2/sys/tax/store/${currentUser.storeid}`
      )
      .then((response) => {
        if (response.data && response.data.data) {
          setTax(response.data.data);
        }
      })
      .catch((error) => {
        showErrorSnackbar("Error Fetching Tax Data");
      });
  };
  useEffect(() => {
    fetchTaxData();
  }, []);

  // Function to calculate the total tax rate
  const calculateTotalTaxRate = () => {
    let totalRate = 0;
    tax.forEach((taxItem) => {
      totalRate += taxItem.rate;
    });
    return totalRate;
  };

  // Function to calculate the final grand total
  const calculateGrandTotal = (foodList, orderData) => {
    const subtotal = calculateDiscountedTotal(foodList, orderData);
    const totalTaxRate = calculateTotalTaxRate();
    const totalTaxAmount = (subtotal * totalTaxRate) / 100;
    const totalAmount = subtotal + totalTaxAmount;
    return totalAmount.toFixed(2);
  };

  // Function to calculate the amount added to the subtotal for each tax
  const calculateTaxAmount = (taxRate) => {
    const subtotal = calculateDiscountedTotal(foodList, orderData);
    const taxAmount = (subtotal * taxRate) / 100;
    return taxAmount.toFixed(2); // Ensures maximum of two digits after the decimal point
  };
  return (
    <Box sx={{ margin: 0, Padding: 0 }}>
      <Box
        className="justify-all-center"
        sx={{ flexDirection: "column", gap: 0.2 }}
      >
        <CardMedia
          component="img"
          className="image-store"
          sx={{
            maxWidth: {
              sm: "300px",
              md: "300px",
              lg: "300px",
              xs: "300px",
              xl: "20vw",
            },
            objectFit: "cover",
          }}
          //  image={LogoImage}
        />

        <Typography className="food-card-text text">
          {currentUser.storeName}
        </Typography>
        <Typography
          className="food-card-text text"
          sx={{ ...billNormalData, maxWidth: "90vw" }}
        >
          {storeData.storeAddress}
        </Typography>
        <Typography className="food-card-text text" sx={{ ...billNormalData }}>
          Contact No: {storeData.contact}
        </Typography>
        <Typography className="food-card-text text" sx={{ ...billNormalData }}>
          GST No: {storeData.gstNo}
        </Typography>
      </Box>

      <Box sx={{ padding: "0 3%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData }}
          >
            Bill No: {orderData.id}
          </Typography>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData }}
          >
            Cashier: {currentUser.username}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData }}
          >
            Date: {date}{" "}
          </Typography>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData }}
          >
            Payment Mode: {orderData.payment}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData }}
          >
            {time}
          </Typography>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData, marginLeft: "auto" }}
          >
            Table no: {tableNo}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          color: "black",
          border: "none",
          borderTop: "2px dashed #999",
          height: 0,
          margin: "7px 0px",
        }}
      />
      <Grid container>
        <Grid item xs={1.5}>
          <Typography className="food-card-text text" align="center">
            Sr.
          </Typography>
        </Grid>
        <Grid item xs={4.5}>
          <Typography className="food-card-text text" align="left">
            Item
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography className="food-card-text text" align="center">
            Qty
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography className="food-card-text text" align="center">
            Rate
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography className="food-card-text text" align="right">
            Amt
          </Typography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          color: "black",
          border: "none",
          borderTop: "2px dashed #999",
          height: 0,
          margin: "7px 0px",
        }}
      />
      {foodList.map((food, index) => (
        <Grid container className="cart-table" key={index}>
          <Grid item xs={1.5}>
            <Typography className="food-card-text text text" align="center">
              {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={4.5}>
            <Typography className="food-card-text text" align="left">
              {food.foodName}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="food-card-text text" align="center">
              {food.quantity}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="food-card-text text" align="center">
              {food.price}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="food-card-text text" align="right">
              {calculateAmount(food)}
            </Typography>
          </Grid>
        </Grid>
      ))}

      <Divider
        sx={{
          color: "black",
          border: "none",
          borderTop: "2px dashed #999",
          height: 0,
          margin: "7px 0px",
        }}
      />

      <Box sx={{ padding: "0 3%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData }}
          >
            Total Qty: {totalQuantity}
          </Typography>
          <Typography
            className="food-card-text text"
            sx={{ ...billNormalData }}
          >
            Sub Total: &#8377;{calculateSubtotal()}
          </Typography>
        </Box>
        {orderData.discount !== 0 && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              className="food-card-text text"
              sx={{ ...billNormalData, marginLeft: "auto" }}
            >
              Discount ({orderData.discount}%): &#8377;
              {calculateDiscountedAmount()}
            </Typography>
          </Box>
        )}

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {tax.map((taxItem, index) => (
            <Typography
              key={index}
              className="food-card-text text"
              sx={{ ...billNormalData, marginLeft: "auto", display: "block" }}
            >
              {taxItem.name} ({taxItem.rate}%): &#8377;
              {calculateTaxAmount(taxItem.rate)}
            </Typography>
          ))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="food-card-text text food-card-bold-text"
            sx={{ ...billNormalData, marginLeft: "auto", fontWeight: "700" }}
          >
            Grand Total: &#8377; {calculateGrandTotal()}
          </Typography>
        </Box>
      </Box>
      <Box
        className="justify-all-center"
        sx={{ flexDirection: "column", gap: 0.4, textAlign: "center" }}
      >
        <CardMedia
          component="img"
          className="image-qrcode"
          sx={{
            maxWidth: {
              sm: "300px",
              md: "300px",
              lg: "300px",
              xs: "300px",
              xl: "20vw",
            },
            objectFit: "cover",
          }}
          image={qrCodeImage}
        />

        <Typography className="food-card-text text" sx={{ ...billNormalData }}>
          THANKS!!!! VISIT AGAIN
        </Typography>
        <Typography
          className="food-card-text text"
          sx={{ ...billNormalData, fontWeight: "800" }}
        >
          WWW.UBSBILL.COM
        </Typography>
      </Box>
    </Box>
  );
};

export default PrintBill;