import React, { useState } from "react";
import {
Box,
Button,
Stack,
FormControl,
FormHelperText,
Grid,
Paper,
Input,
} from "@mui/material";
import axios from "axios";
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useDrawer } from "../Sidebar/DrawerContext";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
function PushNotification() {
    const { showSuccessSnackbar } = useSuccessSnackbar();
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();

const {
    labelStyle,
    inputStyles,
    errorMessageStyles,
    submitButtonStyles,
    formMainBox,
    formPaperStyles,
    capitalizeFirstLetter,
    buttonDisabled
} = useDrawer();
const formDataFields = [
    { name: "subject", label: "Subject", type: "textarea" },
    { name: "message", label: "Message", type: "textarea" },
    { name: "startDateTime", label: "Start Date", type: "datetime-local" },
    { name: "endDateTime", label: "End Date", type: "datetime-local" },
   

];
const [formData, setFormData] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
);
const [errors, setErrors] = useState(
    formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
);
const validateField = (fieldName, value) => {
    let error = "";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;
    const capitalizedFieldName = capitalizeFirstLetter(fieldName);
    if (value.trim() === "") {
     error = `${capitalizeFirstLetter(fieldName)} is mandatory`;
    } 
    else if (fieldName === "subject" || fieldName === "message"  ) {
      if (value.length > 150) {
        error = `${capitalizedFieldName} cannot exceed 150 characters`;
      }
    }

    return error;
};

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name] && !validateField(name, value)) {
     setErrors({ ...errors, [name]: "" });
    }
};

const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
};
const handleNotification = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
     //Axios Call Methods here

     try {


        const response = await axios.post("https://billing.ubsbill.com/apptest/v2/sys/notification/", formData);
        if(response.data.status === true){
        showSuccessSnackbar("Form Submit Successfull !");
    
        setFormData({
          ...formDataFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {}),
          
       });
    }
     } catch (error) {

     }
    }
};
const isSubmitDisabled = buttonDisabled(formData,errors);

return (
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper elevation={12} sx={formPaperStyles}>
        <Grid container spacing={1} sx={{ mb: 4 }}>
         <Grid item>
            <Box
             display="flex"
             alignItems="center"
             bgcolor="primary.light"
             p={1}
             sx={formMainBox}
            >

             <NotificationAddIcon fontSize="large" />
             <span style={{ marginLeft: "8px" }}>Notification Form</span> {/* Text */}
            </Box>
         </Grid>
        </Grid>

        {Array.from({ length: 4 }).map((_, index) => (
         <Stack
            key={index}
            spacing={4}
            direction={{
             xs: "column",
             sm: "row",
             md: "row",
             lg: "row",
             xl: "row",
            }}
            sx={{ marginBottom: 1 }}
         >
         {formDataFields
             .slice(index * 2, index * 2 + 2)
             .map((formDataItem, innerIndex) => (
                <FormControl
                 key={innerIndex}
                 fullWidth
                 error={!!errors[formDataItem.name]}
                 variant="outlined"
                >
                 <CustomTypography
                    htmlFor={`outlined-adornment-${formDataItem.name}`}
                    className="food-card-text"
                    sx={labelStyle}
                 >
                    {formDataItem.label}
                 </CustomTypography>

                
                 {formDataItem.type === "textarea" ? (
        <Input
          sx={{ ...inputStyles, height: "10vh" }}  // Set the height here
          multiline
          rows={3}  // Adjust the number of rows as needed
          name={formDataItem.name}
          value={formData[formDataItem.name]}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disableUnderline
        />
      ) : (
        <Input
          sx={inputStyles}
          type={formDataItem.type}
          name={formDataItem.name}
          value={formData[formDataItem.name]}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disableUnderline
        />
      )}

          <FormHelperText className="food-card-text" sx={errorMessageStyles}>
            {errors[formDataItem.name]}
          </FormHelperText>
        </FormControl>
      ))}
  </Stack>
))}
         <Box style={{ display: "flex", justifyContent: "center" }}>
         <Button onClick={handleNotification}
            className="food-card-text mode-button border-radius-10 cart-button final-button"
            sx={submitButtonStyles} disabled={isSubmitDisabled}
         >
            Update
         </Button>
        </Box>
     </Paper>
    </Box>
);
}
export default PushNotification;
