import { Box, Grid, Stack, Card, CardMedia } from "@mui/material";
import React, { useState, useEffect } from "react";
import NavLinkCard from "./NavLinkCard";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";
import "../VendorManagement/card.css";



function SuperAdminAppmenu({data}) {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const location = useLocation();
  const isTechnicianDashboard = location.pathname === '/technician-dashboard';

  const boxShadows = [
    "0px -9px 0px 0px #28A497",
    "0px -9px 0px 0px #FACE47",
    "0px -9px 0px 0px #47C01C",
  ];

  return (
    
       <Grid lg={12} md={12} sm={11} xs={12} xl={12} container sx={{ display: "flex" , flexWrap: "wrap" }}  >
        {data.map((card, index) => (
          <Grid item key={card.id} lg={3.8} md={4} sm={5.2} xs={11.5} xl={3.8} sx={{marginRight:{sm: isTechnicianDashboard ? '10px' : '24px',md: isTechnicianDashboard ? '60px' : '24px', lg: isTechnicianDashboard ? '80px' : '15px', sm:"30px",  xl: isTechnicianDashboard ? '80px' : '15px',}}}>
            <Link to={card.link} style={{ textDecoration: "none" }}>
              <Card
                className="justify-all-center justify-left-mobile link-card-style"
                sx={{
                  marginBottom: "30px", cursor: "pointer",
                  background: isDarkMode ? "#5e5e5e"  :"#EEEEEE",
                  padding: { sm: 0.2, md: 1.0, xs: 2.3, lg: 1.8 },
                  maxWidth: "auto",
                  minWidth:{ sm: 220, md: 260, xs: "100%", lg: 300, xl: 330 },
                  boxShadow: card.boxShadow,
                  minHeight: { sm: "10vh", md: "12vh", xs: "7vh", lg: "13vh", xl: "12vh" },
                  iconWidth: "50px",
                  iconHeight: "50px",
                  titleFontWeight: "500",
                
                } }
              >
                <Stack direction="row" className="justify-all-center">
                
                    <CardMedia
          component="img"
          alt={`${card.title} Icon`}
          
          image={card.image}
          sx={{ width: { sm: 30, md: 40, xs: 30, lg: 45, xl: 50 } ,  filter: isDarkMode ? "invert(1)" : "none", }}
        />
                  <CustomTypography
                    variant="p"
                    className="link-cart-title"
                    sx={{
                      fontSize: { sm: "13px", md: "15px", xs: "17px " , lg:"16px", xl:"18px" },
                      marginLeft: {
                        sm: "10px",
                        md: "15px",
                        xs: "17px ",
                        lg: "20px",
                       
                      },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {card.title}
                  </CustomTypography>
                </Stack>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
  
  );
}
export default SuperAdminAppmenu;
