import React, { createContext, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Icon, IconButton, SnackbarContent, Typography, } from '@mui/material';
import { useDarkMode } from '../DarkMode/DarkMode';

const ErrorSnackbarContext = createContext();

export const ErrorSnackbarProvider = ({ children, defaultErrorMessage }) => {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(defaultErrorMessage || '');

  const showErrorSnackbar = (newMessage) => {
    setMessage(newMessage || defaultErrorMessage);
    setOpen(true);
  };

  const hideErrorSnackbar = () => {
    setOpen(false);
  };

  const errorSnackbarValue = {
    showErrorSnackbar,
    hideErrorSnackbar,
  };

  return (
    <ErrorSnackbarContext.Provider value={errorSnackbarValue}>
      {children}
      
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={hideErrorSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SnackbarContent
          sx={{ backgroundColor: '#d32f2f', mt: '7vh' }}
          message={
            <React.Fragment>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ marginRight: '8px', color: 'white' }}>info</Icon>
                <Typography variant="body1" sx={{ color: 'white' }}>
                  {message}
                </Typography>
              </div>
            </React.Fragment>
          }
          action={
            <IconButton size="small" color="inherit" onClick={hideErrorSnackbar} sx={{ color: 'white' }}>
              <Icon>close</Icon>
            </IconButton>
          }
        />
      </Snackbar>
    </ErrorSnackbarContext.Provider>
  );
};

export const useErrorSnackbar = () => {
  return useContext(ErrorSnackbarContext);
};
