import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NavLinkCard from '../Components/StoreDashboardComponent/NavLinkCard';
import { useSelector } from 'react-redux';
import axios from 'axios';
import UserNavLinkCard from '../Components/UserDashboardComponents/UserNavLinkCard';
import { useDarkMode } from './DarkMode/DarkMode';


function UserProfileSettings() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const [subMenuData, setSubMenuData] = useState([]);
  const currentUser = useSelector((state) => state.auth.user.data);
  const userRoles = currentUser.roles || [];

  useEffect(() => {
    if (userRoles.includes("ROLE_USER")) {
      axios.get(`https://billing.ubsbill.com/apptest/v2/sys/usersidebar/username?username=${currentUser.username}`)
        .then(response => {
          const menuData = response.data.data;
          const profileManagementMenu = menuData.menu.find(menu => menu.title === "Profile");
          if (profileManagementMenu) {
            setSubMenuData(profileManagementMenu.subMenu || []);
          } else {
            console.error("Menu item 'profile Management' not found in menu data");
          }
        })
        .catch(error => {
          console.error("Error fetching submenu data:", error);
        });
    } else {
      setSubMenuData([]);
    }
  }, [userRoles]);
  const data = [
    { id: 1, title: 'Store Settings', image: '../images/store-settings-icon.png', boxShadow: "0px -9px 0px 0px #FACE47", link: "/profile-settings/store-settings", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 2, title: 'Payment Settings', image: '../images/payment-icon.png', boxShadow: "0px -9px 0px 0px #28A497", link: "/profile-settings/payment-settings", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 3, title: 'Tax Settings', image: '../images/tax-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/profile-settings/tax-settings", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },
    { id: 4, title: 'Pos Settings', image: '../images/pos-icon.png', boxShadow: "0px -9px 0px 0px #47C01C", link: "/profile-settings/pos-settings", iconWidth: "63px", iconHeight: "63px", titleFontWeight: isDarkMode ? "500" : "600" },

  ]

  

  return (
    <Box sx={{ display: "flex" }}>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          //marginTop: "100px",
         // width: "100%",
          display: "flex",
         // marginLeft: { sm: "22%", md: "22%", xs: "10% ", lg: "22%" },
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
       <CustomTypography sx={{ mb: 4, fontWeight: "500" , fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' }, }} variant="p" className="link-cart-title">
          Profile Settings
        </CustomTypography>
        <Grid  lg={11.3} md={12} sm={12} xs={12} xl={10} container spacing={1.7}  >
         {userRoles.includes("ROLE_USER") ? (
            subMenuData.map(submenuItem => (
              submenuItem.status && <UserNavLinkCard key={submenuItem.id} card={submenuItem} />
            ))
          ) : (
            data.map(card => (
              <NavLinkCard key={card.id} card={card} />
            ))
          )}

        </Grid>
      </Box>
    </Box>
  )
}
export default UserProfileSettings;
