import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSuccessSnackbar } from "../../Screens/snackbar/SuccessSnackbarProvider";
import { useErrorSnackbar } from "../../Screens/snackbar/ErrorSnackbarProvider";
import { forgotPassword, resetPassword } from "../../Screens/store/authSlice";
import { useDarkMode } from "../../Screens/DarkMode/DarkMode";


function ResetPassword() {
  const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
  const { showSuccessSnackbar } = useSuccessSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch();

  const errorMessages = {
    emptyFieldError: "This field cannot be empty.",
    emailIDFormatError: "Please enter a valid email address.",
    passwordFormatError:
      "Password must contain at least one uppercase letter, one special character (@$!%*?&), and one number.",
    oneTimePassword: "OTP should be numeric and at least 6 characters long.",
    passwordMatchError: "Passwords do not match."
  };

  const [showOneTimePasswordErrorMessage, setShowOneTimePasswordErrorMessage] =
    useState("");
  const [showOneTimePasswordLoader, setShowOneTimePasswordLoader] =
    useState(false);
  const [showLoginLoader, setShowLoginLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false); // New state to track OTP request
  const [isGetOtpButtonEnabled, setIsGetOtpButtonEnabled] = useState(false);

  const [errors, setErrors] = useState({
    email: "",
    oneTimePassword: "",
    password: "",
    confirmPassword: "",
  });
  const [formData, setFormData] = useState({
    email: "",
    oneTimePassword: "",
    password: "",
    confirmPassword: "",
  });

  const validateField = (fieldName, value) => {
    let error = "";
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;

    //Validate Email Field
    if (fieldName === "email") {
      if (value.trim() === "") {
        error = errorMessages.emptyFieldError;
      } else if (!emailRegex.test(value)) {
        error = errorMessages.emailIDFormatError;
      }
    }
    if (fieldName === "oneTimePassword") {
      if (value.trim() === "") {
        error = errorMessages.emptyFieldError;
      } else if (!/^\d{6}$/.test(value)) {
        error = errorMessages.oneTimePassword;
      }
    }



    //Validate Password Field
    if (fieldName === "password") {
      if (value.trim() === "") {
        error = errorMessages.emptyFieldError;
      } else if (!passwordRegex.test(value)) {
        error = errorMessages.passwordFormatError;
      }
    }

    //Validate ConfirmPassword Field
    if (fieldName === "confirmPassword") {
      if (value.trim() === "") {
        error = errorMessages.emptyFieldError;
      } else if (!passwordRegex.test(value)) {
        error = errorMessages.passwordFormatError;
      } else if (value !== formData.password) {
        error = errorMessages.passwordMatchError;
      }
    }

    return error;
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOneTimePasswordTrigger = async (e) => {
    e.preventDefault();
    setShowOneTimePasswordLoader(true);

    if (Object.values(errors.email).every((error) => error === "")) {
      console.log({ email: formData.email });
      try {
        // Dispatch the forgotPassword async thunk
        const action = await dispatch(forgotPassword({ email: formData.email }));

        // Check if the action type is fulfilled
        if (action.type === 'auth/forgotPassword/fulfilled') {
          // Show success message if OTP sent successfully
          showSuccessSnackbar(action.payload.message);
          // Set otpRequested to true after successful OTP request
          setOtpRequested(true);
        } else {
          showErrorSnackbar("Please Enter a Registered Email.");
        }
      } catch (error) {
        // Show error message if sending OTP failed
        const errorMessage = error.response?.message;
        showErrorSnackbar(errorMessage);
      }

      setShowOneTimePasswordLoader(false);
    }
  };



  const handleResetPassword = async (e) => {
    e.preventDefault();
    setShowLoginLoader(true);
    const roles = ["ROLE_USER", "ROLE_ADMIN", "ROLE_SUPPORT", "ROLE_SUPER_ADMIN"];
    let anyRoleSucceeded = false; 
    try {
      const promises = roles.map(role =>
        dispatch(
          resetPassword({
            email: formData.email,
            oneTimePassword: formData.oneTimePassword,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
            roles: role
          }))
      );
      const results = await Promise.all(promises);
  
      const errorMessages = results
        .filter(result => result.error)
        .map(result => result.payload?.message || 'Invalid OTP or Email');
  
      anyRoleSucceeded = results.some(result => !result.error);
  
      if (!anyRoleSucceeded) {
        errorMessages.forEach(errorMessage => showErrorSnackbar(errorMessage));
      } else {
        setFormData({
          email: "",
          oneTimePassword: "",
          password: "",
          confirmPassword: ""
        });
        showSuccessSnackbar('Password Reset Successfully');
      }
    } catch (error) {
      showErrorSnackbar(error.message || 'Failed to reset password');
    } finally {
      setShowLoginLoader(false);
    }
  };
  

 
  



  const handleLoginButton = () => {
    navigate("/login");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });

    // Update isGetOtpButtonEnabled based on email format validity
    if (name === "email") {
      setIsGetOtpButtonEnabled(value.trim() !== "" && !error);
    }
  };

  const isFormValid = Object.values(formData).some((error) => error === "");
  // const isGetOtpButtonDisabled = Object.values(errors.email).every(
  //    (error) => error === ""
  // );



  return (
    <Box sx={{ display: "flex", backgroundColor: "#EAFCFC", minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container justifyContent="center" xs={12} sm={10} md={8} lg={6} xl={4}>
        <Card style={{ maxWidth: 400, width: '100%', margin: '0 auto', padding: "10px" }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
            <LockResetIcon fontSize="large" />
            <CustomTypography sx={{ fontWeight: 600, fontSize: "32px", ml: 2 }}>Reset Password</CustomTypography>
          </Box>
          <CardContent>
            <Stack spacing={3}>
              <FormControl error={!!errors.email} variant="outlined">
                <InputLabel>Email Id</InputLabel>
                <OutlinedInput
                  label="Email ID"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  sx={{ bgcolor: '#EBEBEB' }}
                  endAdornment={
                    <InputAdornment position="end">
                      {showOneTimePasswordLoader ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Button
                          variant="contained"
                          onClick={handleOneTimePasswordTrigger}
                          disabled={!isGetOtpButtonEnabled || otpRequested}
                        >
                          Get OTP
                        </Button>
                      )}
                    </InputAdornment>
                  }
                />
                <FormHelperText>{errors.email}</FormHelperText>
              </FormControl>
              <FormControl error={!!errors.oneTimePassword} variant="outlined">
                <InputLabel>OTP</InputLabel>
                <OutlinedInput
                  label="OTP"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }} 
                  name="oneTimePassword"
                  value={formData.oneTimePassword}
                  sx={{ bgcolor: '#EBEBEB' }}
                  onChange={handleInputChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                  }}
                />
                <FormHelperText>{errors.oneTimePassword}</FormHelperText>
              </FormControl>
              <FormControl error={!!errors.password} variant="outlined">
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  sx={{ bgcolor: '#EBEBEB' }}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{errors.password}</FormHelperText>
              </FormControl>
              <FormControl error={!!errors.confirmPassword} variant="outlined">
                <InputLabel>Re-Enter Password</InputLabel>
                <OutlinedInput
                  label="Re-Enter Password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  sx={{ bgcolor: '#EBEBEB' }}
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleConfirmPasswordVisibility} edge="end">
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{errors.confirmPassword}</FormHelperText>
              </FormControl>
              {showLoginLoader ? (
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Button variant="contained" disabled={isFormValid} onClick={handleResetPassword}>
                  Reset Password
                </Button>
              )}

              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ marginRight: '8px' }}>Remember password ?</span>
                <Button onClick={handleLoginButton}>Login</Button>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
}

export default ResetPassword;
