import React from "react";
import { useSelector } from "react-redux";
import { Box, Paper } from "@mui/material";
import { Navigate } from "react-router-dom";

const Profile = () => {
  const currentUser = useSelector((state) => state.auth.user?.data);


  return (
    <Box component="main">
    <Paper sx={{ p: 5 }} elevation={12}>
      <div className="container">
        <header className="jumbotron">
          <h3>
            <strong>{currentUser.username}</strong> Profile
          </h3>
        </header>
        <p>
          <strong>Access Token:</strong> {currentUser.accessToken}
        </p>
        <p>
          <strong>Type:</strong> {currentUser.type}
        </p>
        <p>
          <strong>Username:</strong> {currentUser.username}
        </p>
        <p>
          <strong>Registration Number:</strong> {currentUser.regiNum}
        </p>
        <p>
          <strong>Store Name:</strong> {currentUser.storeName}
        </p>
        <p>
          <strong>Role:</strong> {currentUser.roles}
        </p>
      </div>
    </Paper>
  </Box>
  );
};

export default Profile;


