import { Box, Grid, } from '@mui/material';
import React from 'react'
import NavLinkCard from '../../Components/StoreDashboardComponent/NavLinkCard';
import { useDarkMode } from '../DarkMode/DarkMode';

  function StoreManagementSuperadmin() {
    const { isDarkMode, toggleDarkMode , CustomTypography} = useDarkMode();
    const data =  [
      { id: 1, title: 'Add New Store', image: '../images/store.png' , boxShadow: "0px -9px 0px 0px #FACE47" , link: "/store-management-dashboard/add-new-store" , iconWidth: "63px" , iconHeight : "63px" , titleFontWeight : "600" },
      { id: 2, title: 'Store List', image: '../images/pending-order-icon.png' ,  boxShadow: "0px -9px 0px 0px #28A497" , link: "/store-management-dashboard/store-list" , iconWidth: "63px" , iconHeight : "63px" , titleFontWeight : "600" },
      { id: 3, title: 'Free Trial Access', image: '../images/free-trial.png'  ,  boxShadow: "0px -9px 0px 0px #47C01C"  , link: "/store-management-dashboard/free-tral-access-management" , iconWidth: "63px" , iconHeight : "63px" , titleFontWeight : "600" },
      { id: 4, title: 'Subscription Access', image: '../images/subscription-access.png'  ,  boxShadow: "0px -9px 0px 0px #47C01C"  , link: "/store-management-dashboard/subscription-access" , iconWidth: "63px" , iconHeight : "63px" , titleFontWeight : "600" },
    ]


// const [VendorManagementLinks, setVendorManagementLinks] = useState([]);

  // useEffect(() => {
  //   axios.get("")
  //     .then((response) => {
  //       setVendorManagementLinks(response.data.data || [])
  //     })
  // })

      return (
        <Box sx={{ display: "flex" }}>
          
          <Box
            component="main"
            sx={{
              display: "flex",
             flexDirection: "column",
             justifyContent: "center",
             flex: 1,
           }}
          >
           <CustomTypography sx={{ mb: 4, fontWeight: "600" , fontSize: { sm: '20px', md: '22px', xs: '20px', lg: '23px', xl: '25px' }, }} variant="p" className="link-cart-title">
              Store Management
            </CustomTypography>
            <Grid  lg={11} md={12} sm={12} xs={12} xl={10} container spacing={1.7}  >
             {data.map(card => (
            <NavLinkCard key={card.id} card={card}/>
             ))}



             </Grid>
       </Box>
       </Box>
         )
  }
  export default StoreManagementSuperadmin;
  